import { Campaign } from 'platform/campaign/campaign.type';
import { Advertorial } from 'platform/advertorial/advertorial.type';
import { Teaser, TeaserFormModel } from 'platform/teaser/teaser.type';
import { dataUrlToBase64, base64ToDataUrl } from 'platform/common/utils/file.util';
import { AdBoard } from 'platform/adBoard/adDboard.types';

export const toFormModel = (
    teaser: Partial<Teaser>,
    advertorials: Advertorial[],
    campaigns: Campaign[],
    adBoards: AdBoard[]
): TeaserFormModel => {
    const {
        id,
        name,
        description,
        campaignId,
        imageContent,
        advertorialId,
        imageFilename,
        linkText,
        linkType,
        linkUrl,
        title,
        streamingLink,
        adBoardLayout,
        adBoardId,
    } = teaser;
    return {
        id,
        name: name || '',
        campaignId,
        advertorialId,
        adBoardId,
        description,
        advertorials,
        adBoards,
        campaigns,
        linkText,
        linkType,
        linkUrl,
        title,
        streamingLink,
        image:
            imageContent && imageFilename
                ? {
                      content: base64ToDataUrl(imageContent),
                      name: imageFilename,
                  }
                : undefined,
        adBoardLayout,
    };
};

export const toApi = (teaser: TeaserFormModel): Partial<Teaser> => {
    const {
        id,
        name,
        description,
        campaignId,
        image,
        advertorialId,
        linkText,
        linkType,
        linkUrl,
        title,
        streamingLink,
        adBoardLayout,
        adBoardId,
    } = teaser;
    return {
        id,
        name,
        campaignId,
        advertorialId,
        description,
        linkText,
        linkType,
        linkUrl,
        title,
        streamingLink,
        imageContent: image && dataUrlToBase64(image.content),
        imageFilename: image && image.name,
        adBoardLayout,
        adBoardId,
    };
};
