import React from 'react';
import classNames from 'classnames';
import { Button } from 'reactstrap';

type Props = {
    id: number | string;
    children: React.ReactNode;
    onRemove: () => void;
    className?: string;
    style?: React.CSSProperties;
};

const RemovableLineItem = ({ id, children, onRemove, className, style }: Props) => (
    <div
        key={id}
        style={{
            border: '1px solid #cbd0d9',
            borderRadius: '3px',
            backgroundColor: '#f5f6f9',
            ...style,
        }}
        className={classNames('d-flex p-2 align-items-center', className)}
    >
        <div className="overflow-hidden">{children}</div>
        <Button className="ml-auto text-muted p-0" color="link" onClick={onRemove}>
            <i className="fas fa-times font-xs" />
        </Button>
    </div>
);

export default RemovableLineItem;
