import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Card, CardBody, CardHeader } from 'reactstrap';
import './ControlledCard.scss';

type Props = {
    title: React.ReactNode | string;
    children: React.ReactNode;
    subtitle?: string;
    isExpanded?: boolean;
    className?: string;
    onRemove?: () => void;
    onToggle?: (isExpanded: boolean) => void;
    toggleable?: boolean;
    color?: string;
    renderCollapsedView?: () => React.ReactNode;
    renderCustomActions?: () => React.ReactNode;
};

const ControlledCard = ({
    isExpanded = true,
    className = '',
    toggleable = true,
    color,
    title,
    subtitle,
    children,
    onRemove,
    onToggle,
    renderCollapsedView,
    renderCustomActions,
}: Props) => {
    const [isExpandedInternal, setIsExpandedInternal] = useState(isExpanded);

    useEffect(() => {
        setIsExpandedInternal(isExpanded);
    }, [isExpanded]);

    const toggle = () => {
        const newValue = !isExpandedInternal;
        setIsExpandedInternal(newValue);
        if (onToggle) {
            onToggle(newValue);
        }
    };

    return (
        <Card className={classNames('ControlledCard', className)} color={color}>
            <CardHeader>
                <span
                    role="button"
                    tabIndex={0}
                    onClick={toggleable ? toggle : () => {}}
                    className="ControlledCard-title"
                >
                    {title}
                </span>
                {subtitle && (
                    <span className="font-xs">
                        &nbsp;
                        {subtitle}
                    </span>
                )}
                <div className="card-actions">
                    {renderCustomActions && renderCustomActions()}
                    {onRemove && (
                        <button type="button" onClick={onRemove}>
                            <i className="fa fa-times" />
                        </button>
                    )}
                    {toggleable && (
                        <button type="button" onClick={toggle} className="btn-minimize">
                            <i className={isExpandedInternal ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
                        </button>
                    )}
                </div>
            </CardHeader>
            {isExpandedInternal && <CardBody>{children}</CardBody>}
            {!isExpandedInternal && renderCollapsedView && <CardBody>{renderCollapsedView()}</CardBody>}
        </Card>
    );
};

export default ControlledCard;
