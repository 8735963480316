import { useState, useCallback } from 'react';

const useToggle = (state: boolean): [boolean, () => void] => {
    const [value, setValue] = useState<boolean>(state);
    const toggle = useCallback(() => {
        setValue(v => !v);
    }, [setValue]);
    return [value, toggle];
};

export default useToggle;
