import * as React from 'react';
import { uniqueId } from 'lodash-es';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import classNames from 'classnames';
import { NavigationItem, NavigationItemType } from 'platform/app/app.type';
import PlatformLogo from '../../../common/components/PlatformLogo/PlatformLogo';

const mobileSidebarToggle = () => document.body.classList.toggle('sidebar-mobile-show');

const NavigationLink = (props: { item: NavigationItem }) => {
    const linkTo = props.item.redirectTo || props.item.path;
    if (!linkTo) return null;

    const linkToCurrentPath = linkTo === location.pathname;
    return (
        <NavLink
            to={linkTo}
            replace={linkToCurrentPath}
            className="nav-link"
            activeClassName="active"
            isActive={() => Boolean(props.item.active)}
            onClick={mobileSidebarToggle}
        >
            <i className={props.item.icon} />
            {props.item.name}
        </NavLink>
    );
};

const Dropdown = (props: {
    item: NavigationItem;
    index: number;
    children: React.ReactNode;
    isOpen?: boolean;
    setOpen?: () => void;
}) => (
    <li
        className={classNames('nav-item nav-dropdown', {
            open: props.isOpen,
        })}
    >
        {props.item.redirectTo ? (
            <NavLink
                to={props.item.redirectTo}
                className="nav-link nav-dropdown-toggle"
                role="button"
                tabIndex={props.index}
                onClick={mobileSidebarToggle}
            >
                <i className={props.item.icon} />
                {props.item.name}
                <div
                    role="button"
                    className="nav-dropdown-arrow"
                    tabIndex={props.index}
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        return props.setOpen && props.setOpen();
                    }}
                >
                    {'\u2039'}
                </div>
            </NavLink>
        ) : (
            <a className="nav-link nav-dropdown-toggle" role="button" tabIndex={props.index}>
                <i className={props.item.icon} />
                {props.item.name}
                <div
                    role="button"
                    className="nav-dropdown-arrow"
                    tabIndex={props.index}
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        return props.setOpen && props.setOpen();
                    }}
                >
                    {'\u2039'}
                </div>
            </a>
        )}
        <ul className="nav-dropdown-items">{props.children}</ul>
    </li>
);

const NavList = (props: {
    items: NavigationItem[];
    openDropdownIndex?: number;
    toggleOpenDropdownIndex?: (i: number) => void;
}) => (
    <>
        {props.items.map((item, index) => {
            if (item.type === NavigationItemType.SEPARATOR) {
                return <div key={uniqueId(item.name)} className="nav-separator" />;
            }

            if (item.children) {
                return (
                    <Dropdown
                        isOpen={index === props.openDropdownIndex}
                        setOpen={() => props.toggleOpenDropdownIndex && props.toggleOpenDropdownIndex(index)}
                        item={item}
                        key={item.name}
                        index={index}
                    >
                        <NavList items={item.children} />
                    </Dropdown>
                );
            }

            return (
                <NavItem key={item.name}>
                    <NavigationLink item={item} />
                </NavItem>
            );
        })}
    </>
);

const SidebarView = ({ navItems }: { navItems: NavigationItem[] }) => {
    const activeDropdownIndex = navItems.findIndex(item =>
        item.children ? item.children.some(({ active }) => Boolean(active)) : false
    );
    const [openDropdownIndex, setOpenDropdownIndex] = React.useState<number>(activeDropdownIndex);
    const toggleOpenDropdownIndex = React.useCallback(
        index => setOpenDropdownIndex(activeIndex => (activeIndex === index ? -1 : index)),
        [setOpenDropdownIndex]
    );

    // Changes opened dropdown on url change
    React.useEffect(() => {
        setOpenDropdownIndex(activeDropdownIndex);
    }, [activeDropdownIndex]);

    return (
        <div className="sidebar">
            <nav className="sidebar-nav">
                <Nav>
                    <NavList
                        items={navItems}
                        openDropdownIndex={openDropdownIndex}
                        toggleOpenDropdownIndex={toggleOpenDropdownIndex}
                    />
                </Nav>
            </nav>
            <div className="text-center">
                <PlatformLogo />
            </div>
        </div>
    );
};

export default SidebarView;
