import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Location } from 'history';
import { match as Match } from 'react-router-dom';
import { identity } from 'lodash-es';
import { Dispatch } from 'redux';
import { toastNetworkError, isNetworkError } from 'platform/common/utils/errorMessages';
import { getWrappedComponentDisplayName } from '../../utils';
import SidePanel from '../SidePanel/SidePanel';
import CardFormPlaceholder from '../CardForm/CardFormPlaceholder';
import withErrorBoundary from '../WithErrorBoundary/WithErrorBoundary';

type Props = {
    onOpen?: (match: any, location: any) => any;
    onSubmit: (model: any) => Promise<any>;
    size?: number;
    sidePanel?: boolean;
};

type WithCreateFormProps = {
    // props on component
    redirectTo?: string;
    afterSubmit: Function;
    canEdit: boolean;

    // from router
    match: Match<any>;
    location: Location;

    // from redux state
    dispatch: Dispatch;
};

type WithCreateFormState = {
    isFirstRender: boolean;
    initialValues: any;
    loading: boolean;
};

const withCreateForm = ({ onOpen, onSubmit, size, sidePanel = true }: Props) => (
    WrappedForm: React.ComponentType<any>
) => {
    const WrappedFormWithErrorBoundary = withErrorBoundary(WrappedForm);

    const Form = ({ loading, canEdit, onCreate, redirect, isFirstRender, ...rest }: any) => (
        <React.Fragment>
            {loading || isFirstRender ? (
                <CardFormPlaceholder />
            ) : (
                <WrappedFormWithErrorBoundary
                    {...(rest as any)}
                    isEdit={false}
                    enableReinitialize
                    labels={{
                        submit: 'Save',
                        prefix: 'New',
                    }}
                    onSubmit={onCreate}
                    onClose={redirect}
                    canEdit={canEdit}
                    errorEscape={redirect}
                />
            )}
        </React.Fragment>
    );

    class WithCreateForm extends React.Component<WithCreateFormProps, WithCreateFormState> {
        static displayName = getWrappedComponentDisplayName(WrappedForm, WithCreateForm.name);

        static defaultProps = {
            afterSubmit: identity,
        };

        state: WithCreateFormState = {
            isFirstRender: true,
            loading: true,
            initialValues: null,
        };

        componentDidMount() {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ isFirstRender: false });

            if (onOpen) {
                const promise = onOpen(this.props.match, this.props.location);
                if (promise) {
                    promise
                        .then((initialValues: any) => {
                            this.setState(currentState => ({ ...currentState, initialValues }));
                        })
                        .finally(() => {
                            this.setState(currentState => ({ ...currentState, loading: false }));
                        });
                }
            }

            if (!onOpen) {
                this.setState({ initialValues: {}, loading: false });
            }
        }

        redirect = () => {
            if (this.props.redirectTo) {
                this.props.dispatch(push(this.props.redirectTo));
            }
        };

        create = (model: any) =>
            onSubmit(model)
                .then(data => (this.props.afterSubmit ? this.props.afterSubmit(data) : data))
                .catch(error => {
                    if (isNetworkError(error)) {
                        toastNetworkError(error);
                    } else {
                        // eslint-disable-next-line no-console
                        console.error(error.stack);
                    }
                });

        render() {
            const { canEdit, ...rest } = this.props;
            const { loading, initialValues } = this.state;

            return (
                <React.Fragment>
                    {sidePanel ? (
                        <SidePanel onEscClick={this.redirect} size={size}>
                            <Form
                                loading={loading}
                                canEdit={canEdit}
                                onCreate={this.create}
                                redirect={this.redirect}
                                isFirstRender={this.state.isFirstRender}
                                initialValues={initialValues}
                                {...rest}
                            />
                        </SidePanel>
                    ) : (
                        <Form
                            loading={loading}
                            canEdit={canEdit}
                            onCreate={this.create}
                            redirect={this.redirect}
                            isFirstRender={this.state.isFirstRender}
                            initialValues={initialValues}
                            {...rest}
                        />
                    )}
                </React.Fragment>
            );
        }
    }

    return connect()(WithCreateForm);
};

export default withCreateForm;
