import React from 'react';

export const Button = React.forwardRef(({ className, active, reversed, ...props }: any, ref: any) => (
    <div
        {...props}
        ref={ref}
        className={className}
        style={{
            minWidth: '30px',
            height: '30px',
            backgroundColor: '#eee',
            textAlign: 'center',
            padding: '4px',
            margin: '2px',
            cursor: 'pointer',
            borderRadius: '3px',
            color: '#333',
            fontWeight: 'bold',
            border: `1px solid ${(() => {
                if (reversed) {
                    return active ? '#aaa' : '#333';
                }
                return active ? '#333' : '#aaa';
            })()}`,
            ...props.style,
        }}
    />
));

export const EditorValue = React.forwardRef(({ className, value, ...props }: any, ref: any) => {
    const textLines = value.document.nodes
        .map((node: any) => node.text)
        .toArray()
        .join('\n');
    return (
        <div ref={ref} {...props} className={className} style={{ margin: '30px -20px 0' }}>
            <div
                style={{
                    fontSize: '14px',
                    padding: '5px 20px',
                    color: '#404040',
                    borderTop: '2px solid #eeeeee',
                    background: '#f8f8f8',
                }}
            >
                Slate&apos;s value as text
            </div>
            <div
                style={{
                    color: '#404040',
                    font: '12px monospace',
                    whiteSpace: 'pre-wrap',
                    padding: '10px 20px',
                }}
            >
                {textLines}
            </div>
        </div>
    );
});

export const Icon = ({ children, className, ...props }: any) => (
    <span
        {...props}
        className={className}
        style={{
            fontSize: '18px',
            verticalAlign: 'text-bottom',
        }}
    >
        {children}
    </span>
);

export const Instruction = React.forwardRef(({ className, ...props }: any, ref) => (
    <div
        {...props}
        ref={ref}
        className={className}
        style={{
            whiteSpace: 'pre-wrap',
            margin: '0 -20px 10px',
            padding: '10px 20px',
            fontSize: '14px',
            background: '#f8f8e8',
        }}
    />
));

export const Menu = React.forwardRef(({ className, ...props }: any, ref) => (
    <div {...props} ref={ref} className={className} />
));

export const Toolbar = React.forwardRef(({ className, ...props }: any, ref) => (
    <Menu
        {...props}
        ref={ref}
        className={`d-flex ${className}`}
        style={{
            position: 'sticky',
            top: '-20px',
            backgroundColor: 'white',
            padding: '20px',
            margin: '-20px -20px 20px -20px',
            borderBottom: '2px solid #eee',
            align: 'center',
            zIndex: 1,
        }}
    />
));
