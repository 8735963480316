import { Role } from 'platform/userManagement/roles/role.type';
import { Seat } from 'platform/userManagement/seats/seat.type';
import { UserState } from './userStates.constant';
import { User, UserFormModel } from './user.type';
import { TwoFactorAuthenticationType } from '../userManagement.constant';

const toFormModel = (user: Partial<User>, roles: Role[], seats: Seat[]): UserFormModel => {
    const { id, login, name, status } = user;

    return {
        id: id || undefined,
        name: name || undefined,
        state: status || UserState.ACTIVE,
        login: login || undefined,
        phone: user.phone,
        roleId: user.roleId,
        seatId: user.seatId,
        twoFactorAuthenticationType: user.twoFactorAuthenticationType ?? TwoFactorAuthenticationType.NONE,
        roles,
        seats,
    };
};

const toApi = (formUser: UserFormModel): Partial<User> => {
    const { id, login, name, state, phone, roleId, seatId, twoFactorAuthenticationType } = formUser;

    return {
        id,
        name,
        status: state,
        login,
        phone,
        roleId,
        seatId,
        twoFactorAuthenticationType,
        demoModeEnabled: false,
        platformAccessUrl: undefined,
        companyLogoUrl: undefined,
        platformLogoUrl: undefined,
        advertiserIds: [],
        advertiserAccessLevel: 'ALL',
    };
};

const UserMapper = {
    toFormModel,
    toApi,
};

export default UserMapper;
