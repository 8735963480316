import React from 'react';
import { capitalize, sortBy } from 'lodash-es';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { getChangelogRows, Changelog, renderValue } from 'platform/common/utils/changelog.util';
import { usePromise } from 'platform/common/hooks/usePromise';
import * as teaserService from 'platform/teaser/teaser.service';
import { DATA_TYPES } from 'platform/common/dataType';
import { TableCell } from 'platform/common/common.type';

type Props = {
    id: number;
};

const TeaserChangelog = ({ id }: Props) => {
    const [{ data, loading }] = usePromise(
        [],
        () =>
            teaserService.fetchChangelog(id).then(response =>
                sortBy(response, cl => -cl.version).flatMap((newObject, index, array) => {
                    const oldObject = index === array.length - 1 ? {} : array[index + 1];
                    return getChangelogRows(
                        newObject,
                        oldObject,
                        newObject.lastModifiedOn,
                        newObject.lastModifiedBy,
                        ['version', 'lastModifiedOn', 'lastModifiedBy']
                    );
                })
            ),
        []
    );

    return (
        <FormattedTable
            className="-striped"
            columns={[
                {
                    Header: 'Datetime',
                    accessor: 'dateTime',
                    type: DATA_TYPES.DATE_TIME,
                    width: 140,
                },
                {
                    Header: 'User',
                    accessor: 'user',
                    width: 160,
                },
                {
                    Header: 'Field',
                    maxWidth: 120,
                    Cell: (row: TableCell<Changelog>) => capitalize(row.original.field),
                },
                {
                    Header: 'Old value',
                    minWidth: 180,
                    Cell: (row: TableCell<Changelog>) => (
                        <Tooltip
                            placement="left"
                            className="with-ellipsis"
                            renderTooltip={() => <div className="p-2">{row.original.oldValue}</div>}
                        >
                            {renderValue(row.original.oldValue)}
                        </Tooltip>
                    ),
                },
                {
                    Header: 'New value',
                    minWidth: 180,
                    Cell: (row: TableCell<Changelog>) => (
                        <Tooltip
                            placement="left"
                            className="with-ellipsis"
                            renderTooltip={() => <div className="p-2">{row.original.newValue}</div>}
                        >
                            {renderValue(row.original.newValue)}
                        </Tooltip>
                    ),
                },
            ]}
            data={data}
            loading={loading}
        />
    );
};

export default TeaserChangelog;
