import React from 'react';
import { Editor, Transforms, Range, Node } from 'slate';
import { useSlate } from 'slate-react';
import { useModal } from 'platform/common/components/Modal/Modal';
import { Button as ReactstrapButton, Modal, ModalBody, UncontrolledTooltip, ModalHeader } from 'reactstrap';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { Formik, Form } from 'formik';
import { required, urlHttps } from 'platform/common/utils/validators.util';
import FormInput from 'platform/common/components/FormInput/FormInput';
import FormButtonArray from 'platform/common/components/FormButtonArray/FormButtonArray';
import { elementMatcher, getActiveEntryOfType } from 'platform/advertorial/AdvertorialEditor/utils';
import { Button, Icon } from './components';
import { Target, targetOptions } from './common.types';

type LinkElement = LinkParams & {
    type: 'link';
    children: Node[];
};

type LinkParams = {
    url: string;
    target: Target;
};

const isLinkActive = (editor: Editor) => Boolean(getActiveEntryOfType(editor, 'link'));

const unwrapLink = (editor: Editor) => {
    Transforms.unwrapNodes(editor, { match: n => n.type === 'link' });
};

const wrapLink = (editor: Editor, params: LinkParams) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link: LinkElement = {
        type: 'link',
        ...params,
        children: isCollapsed ? [{ text: params.url }] : [],
    };

    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: 'end' });
    }
};

const insertLink = (editor: Editor, params: LinkParams) => {
    if (editor.selection) {
        wrapLink(editor, params);
    }
};

export const LinkInEditor = ({
    element,
    attributes,
    children,
}: {
    element: LinkElement;
    attributes: any;
    children: React.ReactNode;
}) => {
    const ref = React.useRef<HTMLLinkElement>(null);
    return (
        <a {...attributes} ref={ref} href={element.url} target={element.target}>
            {ref.current && <UncontrolledTooltip target={ref.current}>{element.url}</UncontrolledTooltip>}
            {children}
        </a>
    );
};

const LinkModal = ({
    isOpen,
    toggle,
    done,
    remove,
    initial,
}: {
    isOpen: boolean;
    toggle: () => void;
    done: (params: LinkParams) => void;
    remove?: () => void;
    initial?: LinkParams;
}) => (
    <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Link</ModalHeader>
        <ModalBody>
            <Formik
                initialValues={{
                    url: initial?.url ?? '',
                    target: initial?.target ?? Target._blank,
                }}
                onSubmit={done}
            >
                {() => (
                    <Form>
                        <FormRow label="URL">
                            <FormInput type="text" name="url" validate={[required, urlHttps]} />
                        </FormRow>
                        <FormRow label="Target">
                            <FormButtonArray name="target" buttons={targetOptions} />
                        </FormRow>
                        <div className="d-flex justify-content-end">
                            <ReactstrapButton color="secondary" onClick={toggle}>
                                Cancel
                            </ReactstrapButton>
                            {remove && (
                                <ReactstrapButton className="ml-2" color="secondary" onClick={remove}>
                                    Remove
                                </ReactstrapButton>
                            )}
                            <ReactstrapButton className="ml-2" color="primary" type="submit">
                                Ok
                            </ReactstrapButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalBody>
    </Modal>
);

export const LinkToolbarButton = () => {
    const editor = useSlate();
    const { showModal } = useModal();
    const ref = React.useRef<HTMLButtonElement>(null);
    return (
        <Button
            ref={ref}
            active={isLinkActive(editor)}
            onMouseDown={(event: Event) => {
                event.preventDefault();
                const { selection } = editor;

                const entry = getActiveEntryOfType(editor, 'link');
                if (!entry) {
                    showModal(toggle => (
                        <LinkModal
                            isOpen
                            toggle={toggle}
                            done={params => {
                                Transforms.select(editor, selection || [0]);
                                insertLink(editor, params);
                                toggle();
                            }}
                        />
                    ));
                } else {
                    const element = entry[0] as LinkElement;
                    showModal(toggle => (
                        <LinkModal
                            isOpen
                            toggle={toggle}
                            done={params => {
                                Transforms.select(editor, selection || [0]);
                                Transforms.setNodes(editor, params, elementMatcher('link'));
                                toggle();
                            }}
                            remove={() => {
                                Transforms.select(editor, selection || [0]);
                                unwrapLink(editor);
                                toggle();
                            }}
                            initial={{ url: element.url, target: element.target }}
                        />
                    ));
                }
            }}
        >
            <Icon className="fas fa-link" />
            {ref.current && <UncontrolledTooltip target={ref.current}>Link</UncontrolledTooltip>}
        </Button>
    );
};

export const withLinks = <T extends Editor>(editor: T): T => {
    const { isInline } = editor;
    // eslint-disable-next-line no-param-reassign
    editor.isInline = element => (element.type === 'link' ? true : isInline(element));
    return editor;
};
