import React from 'react';
import { NavLink as RouterNavLink, Route, Switch } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import { USER_MANAGEMENT_TAB_LIST } from './tabs.constant';

const tabs = USER_MANAGEMENT_TAB_LIST.map(tab => (
    <NavItem key={tab.url}>
        <NavLink to={tab.url} tag={RouterNavLink}>
            {tab.label}
        </NavLink>
    </NavItem>
));

const routes = USER_MANAGEMENT_TAB_LIST.map(tab => (
    <Route key={tab.url} path={tab.url} component={tab.component} />
));

const UserManagement = () => (
    <div>
        <div className="mb-2 mt-4">
            <PageHeader title="User management" />
        </div>
        <div className="custom-tabs">
            <Nav tabs className="mb-4">
                {tabs}
            </Nav>
            <Switch>{routes}</Switch>
        </div>
    </div>
);

export default UserManagement;
