import { toastError } from 'platform/common/utils/toast.util';

export type LegacyErrorPayload = {
    errorCode: string;
    errorMsg?: string;
    stacktrace?: string;
};

export type ErrorType = {
    code?: string;
    message: string;
    field?: string;
};

export type ErrorPayload = {
    errors: ErrorType[];
};

type SdoErrorPayload = {
    message: string;
};

export const parseErrorMessages = (
    payload: ErrorPayload | LegacyErrorPayload | SdoErrorPayload | string
): { message: string; field?: string }[] => {
    if (typeof payload === 'string') {
        return [
            {
                message: payload.length > 100 ? `${payload.slice(0, 100)}...` : payload,
            },
        ];
    }
    if (payload && 'errors' in payload) {
        return payload.errors.map(e => {
            if (e.field) {
                return {
                    field: e.field,
                    message: e.message,
                };
            }
            return {
                message: e.message,
            };
        });
    }
    if (payload && ('errorCode' in payload || 'errorMsg' in payload)) {
        return [
            {
                message: payload.errorMsg || payload.errorCode,
            },
        ];
    }
    if (payload && 'message' in payload) {
        return [
            {
                message: payload.message,
            },
        ];
    }
    return [
        {
            message: 'Oops! Something went wrong.',
        },
    ];
};

export const isNetworkError = (error: any) =>
    error.response && (error.message || '').includes('Request failed with status code');

export const toastNetworkError = (error: any) => {
    const payload = error.response.data;
    const traceId = error.response.config.headers['X-B3-TraceId'];
    parseErrorMessages(payload).forEach(e => {
        toastError(e.field ? `${e.field} ${e.message}` : e.message, traceId);
    });
};
