import React from 'react';
import { useField } from 'formik';
import ButtonArray from 'platform/common/components/ButtonArray/ButtonArray';
import FormSelect from 'platform/common/components/FormSelect/FormSelect';
import { SelectItem } from 'platform/common/common.type';
import { required } from 'platform/common/utils/validators.util';
import { ACCESS_OPTIONS, ACCESS_TYPES } from './access.constant';

type Props = {
    buttonAllText: string;
    buttonNoneText: string;
    name: string;
    disabled?: boolean;
    customAccessOptions: SelectItem<number>[];
};

const CustomAccess = ({
    buttonAllText,
    buttonNoneText,
    name,
    disabled = false,
    customAccessOptions,
}: Props) => {
    const [field, , helpers] = useField<{
        mode: string;
        entityIds: number[];
    }>(name);

    const lowerElements = {
        [ACCESS_TYPES.ALL]: buttonAllText,
        [ACCESS_TYPES.NONE]: buttonNoneText,
        [ACCESS_TYPES.CUSTOM]: (
            <FormSelect
                name={`${name}.entityIds`}
                options={customAccessOptions}
                isClearable={false}
                validate={required}
                isMulti
            />
        ),
    };

    return (
        <div>
            <ButtonArray
                buttons={ACCESS_OPTIONS}
                selected={field.value.mode}
                onClick={mode => helpers.setValue({ ...field.value, mode })}
                disabled={disabled}
            />
            <div className="pt-3">{lowerElements[field.value.mode]}</div>
        </div>
    );
};

export default CustomAccess;
