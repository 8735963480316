import React, { Component, Fragment } from 'react';
import { Button, Form, UncontrolledTooltip } from 'reactstrap';
import Search from '../Search/Search';

type Props = {
    onCreate?: (fn: any) => any | undefined;
    createInactiveReason?: string;
    onSearch?: (name: string) => any;
    value?: string;
    addNewButtonTitle?: string;
};

class ListToolbar extends Component<Props> {
    static defaultProps = {
        addNewButtonTitle: 'Add new',
    };

    disableDefaultSubmit = (e: React.FormEvent) => {
        e.preventDefault();
    };

    render() {
        const addNewButtonWrapperId = 'ListToolbar-addNewWrapper';
        const addNewButton = (
            <Fragment>
                {(this.props.onCreate || this.props.createInactiveReason) && (
                    <div id={addNewButtonWrapperId}>
                        <Button
                            size="md"
                            color="primary"
                            className="mr-3"
                            onClick={this.props.onCreate}
                            title={!this.props.onCreate ? this.props.createInactiveReason : undefined}
                            disabled={!this.props.onCreate}
                        >
                            {this.props.addNewButtonTitle}
                        </Button>
                    </div>
                )}
                {!this.props.onCreate && this.props.createInactiveReason && (
                    <UncontrolledTooltip placement="right" target={addNewButtonWrapperId}>
                        {this.props.createInactiveReason}
                    </UncontrolledTooltip>
                )}
            </Fragment>
        );

        return (
            <div>
                <Form onSubmit={this.disableDefaultSubmit} inline>
                    {addNewButton}
                    {this.props.onSearch && (
                        <Search onSearch={this.props.onSearch} value={this.props.value} />
                    )}
                </Form>
            </div>
        );
    }
}

export default ListToolbar;
