export enum TemplateFormatType {
    ADBOARD = 'ADBOARD',
    FIX = 'FIX',
    RESPONSIVE = 'RESPONSIVE',
}

export type AdBoardTemplate = {
    id: number;
    name: string;
    websiteId: number;
    css?: string;
    lastModifiedBy?: string;
    lastModifiedOn?: string;
};

export type TeaserTemplate = {
    id: number;
    name: string;
    formatType: TemplateFormatType;
    websiteId: number;
    additionalCss?: string;
    backgroundColour?: string;
    backgroundImageContent?: string;
    backgroundImageFilename?: string;
    colourDescription?: string;
    colourHeadline?: string;
    colourTitle?: string;
    font?: string;
    sizeDescription?: number;
    sizeHeadline?: number;
    sizeTitle?: number;
    lastModifiedBy?: string;
    lastModifiedOn?: string;
    markAsAdvertisement?: boolean;
    markAsAdvertisementText?: string;
};

export type AdvertorialTemplate = {
    id: number;
    name: string;
    websiteId: number;
    fontH1?: string;
    fontColourH1?: string;
    fontSizeH1?: number;
    fontH2?: string;
    fontColourH2?: string;
    fontSizeH2?: number;
    fontH3?: string;
    fontColourH3?: string;
    fontSizeH3?: number;
    fontBody?: string;
    fontColourBody?: string;
    fontSizeBody?: number;
    fontLinks?: string;
    fontColourLinks?: string;
    fontSizeLinks?: number;
    fontImageCaption?: string;
    fontColourImageCaption?: string;
    fontSizeImageCaption?: number;
    additionalCss?: string;
    lastModifiedBy?: string;
    lastModifiedOn?: string;
    markAsAdvertisement?: boolean;
    markAsAdvertisementText?: string;
};
