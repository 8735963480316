import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink, Route, Switch } from 'react-router-dom';
import TeaserTemplateList from 'platform/adminPanel/masterTemplates/TeaserTemplate/TeaserTemplateList';
import AdvertorialTemplateList from 'platform/adminPanel/masterTemplates/AdvertorialTemplate/AdvertorialTemplateList';
import AdBoardTemplateList from 'platform/adminPanel/masterTemplates/AdBoardTemplate/AdBoardTemplateList';

const MASTER_TEMPLATE_TABS = Object.freeze({
    teaser: {
        url: '/admin-panel/master-templates/teaser',
        label: 'Teaser templates',
    },
    advertorial: {
        url: '/admin-panel/master-templates/advertorial',
        label: 'Advertorial templates',
    },
    adBoard: {
        url: '/admin-panel/master-templates/ad-board',
        label: 'AdBoard templates',
    },
});

const MasterTemplatesPage = () => (
    <div>
        <div className="my-4">
            <PageHeader title="Master templates" />
        </div>

        <div className="custom-tabs">
            <Nav tabs className="mb-4">
                <NavItem>
                    <NavLink to={MASTER_TEMPLATE_TABS.teaser.url} tag={RouterNavLink}>
                        {MASTER_TEMPLATE_TABS.teaser.label}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to={MASTER_TEMPLATE_TABS.advertorial.url} tag={RouterNavLink}>
                        {MASTER_TEMPLATE_TABS.advertorial.label}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to={MASTER_TEMPLATE_TABS.adBoard.url} tag={RouterNavLink}>
                        {MASTER_TEMPLATE_TABS.adBoard.label}
                    </NavLink>
                </NavItem>
            </Nav>
            <Switch>
                <Route path={MASTER_TEMPLATE_TABS.teaser.url} component={TeaserTemplateList} />
                <Route path={MASTER_TEMPLATE_TABS.advertorial.url} component={AdvertorialTemplateList} />
                <Route path={MASTER_TEMPLATE_TABS.adBoard.url} component={AdBoardTemplateList} />
            </Switch>
        </div>
    </div>
);

export default MasterTemplatesPage;
