export enum AccessLevel {
    NO_ACCESS = 'NO_ACCESS',
    VIEW = 'VIEW',
    VIEW_AND_EDIT = 'VIEW_AND_EDIT',
}

export enum MODULES {
    USERS = 'USERS',
    ADMIN_PANEL = 'ADMIN_PANEL',
    CAMPAIGN_MODULE = 'CAMPAIGN_MODULE',
    AD_BOARD_MODULE = 'AD_BOARD_MODULE',
    ADVERTORIAL_MODULE = 'ADVERTORIAL_MODULE',
    TEASER_MODULE = 'TEASER_MODULE',
    CAMPAIGNS = 'CAMPAIGNS',
    AD_GROUPS = 'AD_GROUPS',
    ADS = 'ADS',
    EXPORT_LEADS = 'EXPORT_LEADS',
    CONFIGURE_INTEGRATIONS = 'CONFIGURE_INTEGRATIONS',
}

export enum MODULE_TITLES {
    USERS = 'User management',
    ADMIN_PANEL = 'Admin panel',
    CAMPAIGN_MODULE = 'Campaign module',
    ADVERTORIAL_MODULE = 'Advertorial module',
    TEASER_MODULE = 'Teaser module',
    AD_BOARD_MODULE = 'AdBoard module',
    CAMPAIGNS = 'Campaings',
    AD_GROUPS = 'AdGroups',
    ADS = 'Ads',
    EXPORT_LEADS = 'Export leads',
    CONFIGURE_INTEGRATIONS = 'Configure integrations',
}

export const MODULE_GROUPS = {
    User: [MODULES.USERS],
    'Admin panel': [MODULES.ADMIN_PANEL],
    'Campaign module': [MODULES.CAMPAIGN_MODULE],
    'AdBoard module': [MODULES.AD_BOARD_MODULE],
    'Advertorial module': [MODULES.ADVERTORIAL_MODULE],
    'Teaser module': [MODULES.TEASER_MODULE],
    'Bruce Leads': [
        MODULES.CAMPAIGNS,
        MODULES.AD_GROUPS,
        MODULES.ADS,
        MODULES.EXPORT_LEADS,
        MODULES.CONFIGURE_INTEGRATIONS,
    ],
};
