import React, { useEffect, useState } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { useDebounce } from '../../hooks/useDebounce';

type Props = {
    className?: string;
    onSearch: (newValue: string) => void;
    placeholder?: string;
    minLength?: number;
    value?: string;
    debounce?: number;
};

const Search = ({
    value,
    minLength = 2,
    debounce = 400,
    placeholder = 'Search',
    className,
    onSearch,
}: Props) => {
    const [searchText, setSearchText] = useState(value || '');

    const debouncedSearchText = useDebounce(searchText, debounce);

    useEffect(() => {
        // Sync passed value to internal state
        if (value && value.length > minLength) {
            setSearchText(value);
        }
    }, [minLength, value]);

    useEffect(() => {
        const valueToSet = debouncedSearchText.length > minLength ? debouncedSearchText : '';
        if (value !== valueToSet) {
            onSearch(valueToSet);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchText]);

    return (
        <InputGroup className={className}>
            <InputGroupAddon addonType="prepend">
                <InputGroupText>
                    <i className="fa fa-search" />
                </InputGroupText>
            </InputGroupAddon>
            <Input
                type="text"
                value={searchText}
                onChange={e => {
                    setSearchText(e.target.value);
                }}
                placeholder={placeholder}
            />
        </InputGroup>
    );
};

export default Search;
