import axiosBff from 'platform/axios';
import { AdBoard } from 'platform/adBoard/adDboard.types';
import { IdResponse } from 'platform/common/common.type';

export const fetchAdBoards = (params: { campaignIds?: number[] } = {}) =>
    axiosBff
        .get<AdBoard[]>('/sdo/api/ad-boards', { params })
        .then(response => response.data);

export const fetchAdBoard = (id: number) =>
    axiosBff.get<AdBoard>(`/sdo/api/ad-boards/${id}`).then(response => response.data);

export const createAdBoard = (model: Partial<AdBoard>) =>
    axiosBff.post<IdResponse>('/sdo/api/ad-boards', model).then(response => response.data);

export const updateAdBoard = (model: Partial<AdBoard>) =>
    axiosBff.put<IdResponse>(`/sdo/api/ad-boards/${model.id}`, model).then(response => response.data);
