import * as React from 'react';
import { useField } from 'formik';
import { isString } from 'lodash-es';
import { FormFeedback } from 'reactstrap';
import { validator, Validator } from 'platform/common/utils/validators.util';
import { SelectComponentsConfig } from 'react-select/src/components';
import { SortEnd } from 'react-sortable-hoc';
import Select from '../Select/Select';

type OptionType = { [key: string]: any };

type Props = {
    id?: string;
    options: {
        value: number | string | any;
        label: string;
        default?: boolean;
    }[];
    isClearable?: boolean;
    isMulti?: boolean;
    isLoading?: boolean;
    isSearchable?: boolean;
    disabled?: boolean;
    closeMenuOnSelect?: boolean;
    className?: string;
    returnOnlyValues?: boolean;
    valueKey: string;
    labelKey: string;
    isValidationMessageVisible?: boolean;
    components?: SelectComponentsConfig<OptionType>;
    onInputChange?: (value: string) => void;
    selectStyle?: { [key: string]: any };
    name: string;
    validate?: Validator | Validator[];
    onChange?: (value: string) => void;
    sortEnd?: (values: any, sort: SortEnd) => any;
};

const defaultProps = {
    valueKey: 'value',
    labelKey: 'label',
    options: [],
    isValidationMessageVisible: true,
};

const FormSelect = ({
    id,
    className,
    options,
    isClearable,
    isMulti,
    disabled,
    isSearchable,
    closeMenuOnSelect,
    returnOnlyValues,
    valueKey,
    labelKey,
    isValidationMessageVisible,
    components,
    onInputChange,
    isLoading,
    selectStyle,
    name,
    validate,
    onChange,
    sortEnd,
}: Props) => {
    const [field, meta, helpers] = useField({ name, validate: validate && validator(validate) });
    const isInvalid = Boolean(meta.touched && meta.error);
    return (
        <React.Fragment>
            <Select
                isInvalid={isInvalid}
                inputId={id || name}
                className={className}
                selectStyle={selectStyle}
                options={options}
                isMulti={isMulti}
                isLoading={isLoading}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isDisabled={disabled}
                returnOnlyValues={returnOnlyValues}
                value={field.value || options.find(option => !!option.default)}
                onBlur={() => helpers.setTouched(true)}
                onChange={value => {
                    helpers.setValue(value);
                    if (onChange) onChange(value);
                }}
                closeMenuOnSelect={closeMenuOnSelect}
                getOptionValue={option => option[valueKey]}
                getOptionLabel={option => option[labelKey]}
                components={components}
                onInputChange={onInputChange}
                sortEnd={sort => {
                    if (!sortEnd) {
                        return;
                    }
                    helpers.setValue(sortEnd(field.value, sort));
                }}
            />
            {isInvalid && isString(meta.error) && isValidationMessageVisible && (
                <FormFeedback className="d-block">{meta.error}</FormFeedback>
            )}
        </React.Fragment>
    );
};

FormSelect.defaultProps = defaultProps;

export default FormSelect;
