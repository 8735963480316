import moment from 'moment';
import { Period } from 'platform/common/common.type';
import {
    DATE_MOMENT_FORMAT,
    DATE_TIME_DISPLAY_FORMAT,
    ISO_DATE_FORMAT,
    PRECISE_DATE_TIME_DISPLAY_FORMAT,
    TIME_FORMAT,
} from '../constants/dateConfiguration.constant';

type DateLike = string | Date | moment.Moment;

export const fmtDate = (format?: string) => (date?: DateLike | null): any => {
    if (!date) return undefined;
    const dateObject = moment(date);
    return dateObject.isValid() ? dateObject.format(format) : undefined;
};

export const fmtDateRange = (format?: string) => (dateRangeObject: {
    from?: string | moment.Moment;
    to?: string | moment.Moment;
}) => {
    const fmt = fmtDate(format);
    const from = fmt(dateRangeObject.from) || '';
    const to = fmt(dateRangeObject.to) || '';
    return `${from} - ${to}`;
};

// for backend
export const formatIsoDate = (date?: string | moment.Moment) => fmtDate(ISO_DATE_FORMAT)(moment.utc(date));

export const getPeriodForLast = (days: number, to?: string | moment.Moment) => ({
    from: formatIsoDate(moment(to).subtract(days - 1, 'days')),
    to: formatIsoDate(moment(to)),
});
export const getPeriodForLast7Days = (to?: string | moment.Moment) => getPeriodForLast(7, to);
export const getPeriodForLast30Days = (to?: string | moment.Moment) => getPeriodForLast(30, to);

// for display
export const formatDate = fmtDate(ISO_DATE_FORMAT);
export const formatDateLong = fmtDate(DATE_MOMENT_FORMAT);
export const formatDateTime = fmtDate(DATE_TIME_DISPLAY_FORMAT);
export const formatPreciseDateTime = fmtDate(PRECISE_DATE_TIME_DISPLAY_FORMAT);
export const formatHours = fmtDate(TIME_FORMAT);

export const formatDuration = (from?: DateLike, till?: DateLike) => {
    if (!from || !till) return undefined;
    let seconds = moment(till).diff(moment(from), 's');
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60);
    return minutes ? `${minutes}m ${seconds % 60}s` : `${seconds}s`;
};

export enum DateRangePreset {
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY',
    LAST_7_DAYS = 'LAST_7_DAYS',
    LAST_14_DAYS = 'LAST_14_DAYS',
    LAST_30_DAYS = 'LAST_30_DAYS',
    THIS_MONTH = 'THIS_MONTH',
    LAST_MONTH = 'LAST_MONTH',
    LAST_90_DAYS = 'LAST_90_DAYS',
    LAST_180_DAYS = 'LAST_180_DAYS',
}

export type DateRanges = {
    [key: string]: { label: string; getRange: () => { from: moment.Moment; to: moment.Moment } };
};

export const DATE_RANGE: DateRanges = Object.freeze({
    [DateRangePreset.TODAY]: {
        label: 'Today',
        getRange: () => ({ from: moment(), to: moment() }),
    },
    [DateRangePreset.YESTERDAY]: {
        label: 'Yesterday',
        getRange: () => ({
            from: moment().subtract(1, 'days'),
            to: moment().subtract(1, 'days'),
        }),
    },
    [DateRangePreset.LAST_7_DAYS]: {
        label: 'Last 7 Days',
        getRange: () => ({
            from: moment().subtract(7, 'days'),
            to: moment().subtract(1, 'days'),
        }),
    },
    [DateRangePreset.LAST_14_DAYS]: {
        label: 'Last 14 Days',
        getRange: () => ({
            from: moment().subtract(14, 'days'),
            to: moment().subtract(1, 'days'),
        }),
    },
    [DateRangePreset.LAST_30_DAYS]: {
        label: 'Last 30 Days',
        getRange: () => ({
            from: moment().subtract(30, 'days'),
            to: moment().subtract(1, 'days'),
        }),
    },
    [DateRangePreset.THIS_MONTH]: {
        label: 'This Month',
        getRange: () => ({
            from: moment().startOf('month'),
            to: moment().isSame(moment().startOf('month'), 'day') ? moment() : moment().subtract(1, 'days'),
        }),
    },
    [DateRangePreset.LAST_MONTH]: {
        label: 'Last Month',
        getRange: () => ({
            from: moment()
                .subtract(1, 'month')
                .startOf('month'),
            to: moment()
                .subtract(1, 'month')
                .endOf('month'),
        }),
    },
    [DateRangePreset.LAST_90_DAYS]: {
        label: 'Last 90 days',
        getRange: () => ({
            from: moment().subtract(90, 'days'),
            to: moment().subtract(1, 'days'),
        }),
    },
    [DateRangePreset.LAST_180_DAYS]: {
        label: 'Last 180 days',
        getRange: () => ({
            from: moment().subtract(180, 'days'),
            to: moment().subtract(1, 'days'),
        }),
    },
});

export const getDateRange = (range: DateRangePreset) => DATE_RANGE[range].getRange();

export const datesOverlap = (from1: string, to1: string, from2: string, to2: string) =>
    !moment(from2).isAfter(to1) && !moment(to2).isBefore(from1);

export const getFormattedRangeDates = (dateRange: DateRangePreset): Period => {
    const value = getDateRange(dateRange);

    if (!value) {
        throw new Error(`Specified date range (${dateRange}) not found`);
    }

    return { from: formatDate(value.from), to: formatDate(value.to) };
};

export const isPast = (date?: DateLike) => !!date && moment(date).isBefore(moment().startOf('day'));

export const isFuture = (date?: DateLike) => !!date && moment(date).isAfter(moment().endOf('day'));

export const getEndOfTheMonth = (date: DateLike) => {
    if (!date) {
        return null;
    }
    return formatDate(moment(date).endOf('month'));
};

export const isToday = (date?: DateLike) => !!date && moment(date).isSame(moment(), 'day');

export const getDuration = (from: string | Date | undefined, to?: string | Date) =>
    moment.duration(moment(to).diff(moment(from)));

export const getDefaultCompareRange = (from: string | Date | undefined, to?: string | Date) => {
    const periodLength = getDuration(from, to).asDays();
    const secondaryTo = moment(from).subtract(1, 'days');
    const secondaryFrom = moment(secondaryTo).subtract(periodLength, 'days');

    return {
        from: formatDate(secondaryFrom),
        to: formatDate(secondaryTo),
    };
};
