import { SAGA_ACTION } from '@redux-saga/symbols';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { all, call, put, select, take, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import { get } from 'lodash-es';
import { currentTimeSeconds } from 'platform/common/utils';
import { Action } from 'platform/common/common.type';
import { RootState } from 'platform/rootState.type';
import { fulfilled, rejected } from 'platform/common/utils/actionSuffixes.util';
import { TwoFactorAuthenticationType } from 'platform/userManagement/userManagement.constant';
import { LOGIN as LOGIN_NAV } from './app.navigation';
import {
    actions as authActions,
    LOGIN,
    LOGOUT,
    selectors as authSelectors,
    USER_UNAUTHORIZED,
    VERIFY_2FA_CODE,
} from './auth.duck';

const PATH_2FA = '/login/2fa';

// Helper for redirects
const dispatchActionOnPath = (actionToDispatch: Action, path: string) =>
    function*(action: Action) {
        if (action.payload.location.pathname === path) {
            yield put(actionToDispatch);
        }
    };

// Helper to wait for redux-promise actions to resolve
const putAndWaitToResolve = (action: Action) =>
    call(function*() {
        yield put(action);
        const result: Action = yield take([rejected(action.type), fulfilled(action.type)]);
        if (result.type === rejected(action.type)) return false;
        return true;
    });

// These paths are same for both ROOT and DAP
const isPathSecured = (pathname: string) => {
    if (pathname.startsWith(LOGIN_NAV.path)) return false;
    return true;
};

function* guardAuthorisedPaths() {
    const currentLocation = yield select(state => state.router.location);
    if (!isPathSecured(currentLocation.pathname)) return;

    const isAuthenticated = yield select((state: RootState) => authSelectors.isAuthenticated(state.session));
    const is2FANeeded = yield select((state: RootState) => authSelectors.is2FANeeded(state.session));
    const isFullyAuthenticated = isAuthenticated && !is2FANeeded;

    if (!isFullyAuthenticated) {
        const returnLocation = yield select(state => state.session.returnLocation);
        if (returnLocation !== currentLocation && currentLocation.pathname !== '/') {
            yield put(authActions.saveReturnLocation(currentLocation));
        }
        if (!isAuthenticated) {
            // If we push new route in the same event loop tick hashed history calls its listeners in wrong
            // order, thus making Router to get wrong state and render wrong component. We work around it by
            // postponing action to next tick
            yield delay(0);
            yield put(push(LOGIN_NAV.path));
            return;
        }
        if (is2FANeeded) {
            yield put(push(PATH_2FA));
        }
    }
}

const afterLogin = (finalAction: Action) =>
    function*() {
        if (!(yield putAndWaitToResolve(authActions.fetchCurrentUser()))) return;

        if (yield select((state: RootState) => authSelectors.is2FANeeded(state.session))) {
            const twoFactorAuthenticationType = yield select((state: RootState) =>
                authSelectors.fetch2FaType(state.session)
            );
            if (twoFactorAuthenticationType === TwoFactorAuthenticationType.SMS) {
                yield put(authActions.send2FaSmsCode());
            }
            yield put(push(PATH_2FA));
            return;
        }

        if (!(yield putAndWaitToResolve(authActions.fetchCurrentUserProfile()))) return;

        yield put(finalAction);
    };

const afterVerify2FaCode = (finalAction: Action) =>
    function*() {
        const [userFetched, profileFetched] = yield all([
            putAndWaitToResolve(authActions.fetchCurrentUser()),
            putAndWaitToResolve(authActions.fetchCurrentUserProfile()),
        ]);
        if (!userFetched || !profileFetched) return;

        yield put(finalAction);
    };

function* checkIfTokenExpired(action: Action) {
    // We might know about token expiration from 3 sources:
    // 1. If we get rejected action with 401
    const res = get(action, 'payload.response', {});
    if (res.status === 401 && res.data && res.data.error === 'invalid_token') {
        yield put(authActions.userUnauthorized(action));
        return;
    }

    // 2. If we get error form graphql with 401 (this case handled in apollo client config)

    // 3. If user token expiration time is reached (check only if in secured path)
    const currentLocation = yield select(state => state.router.location);
    if (isPathSecured(currentLocation.pathname)) {
        const tokenExpires = yield select((state: RootState) => state.session.tokenExpires);
        const currentTime = currentTimeSeconds();
        const tokenExpired = tokenExpires < currentTime;
        if (tokenExpired) {
            yield put(authActions.userUnauthorized('Token expired'));
        }
    }
}

// We don't want LOGOUT action performed when we are already in /login, because LOGOUT wipes out the state
// so we do authorization check before dispatching it
function* logoutIfLoggedIn() {
    const isAuthenticated = yield select((state: RootState) => authSelectors.isAuthenticated(state.session));
    if (isAuthenticated) {
        yield put(authActions.logout());
    }
}

export default (redirectToMainRoute: () => any) => [
    takeEvery((action: any) => !action[SAGA_ACTION] || action.type === LOGOUT, guardAuthorisedPaths),
    takeEvery(LOCATION_CHANGE, dispatchActionOnPath(redirectToMainRoute(), '/')),
    takeLatest(fulfilled(LOGIN), afterLogin(redirectToMainRoute())),
    takeLatest(fulfilled(VERIFY_2FA_CODE), afterVerify2FaCode(redirectToMainRoute())),

    takeEvery((action: any) => !action[SAGA_ACTION], checkIfTokenExpired),
    takeEvery(USER_UNAUTHORIZED, logoutIfLoggedIn),
];
