export enum UserState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    ARCHIVED = 'ARCHIVED',
    PENDING = 'PENDING',
    PENDING_INACTIVE = 'PENDING_INACTIVE',
}

export enum UserStateLabel {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    ARCHIVED = 'Archived',
    PENDING = 'Pending',
    PENDING_INACTIVE = 'Pending Inactive',
}

export const USER_STATE_OPTIONS = [
    {
        label: UserStateLabel.ACTIVE,
        value: UserState.ACTIVE,
    },
    {
        label: UserStateLabel.INACTIVE,
        value: UserState.INACTIVE,
    },
    {
        label: UserStateLabel.ARCHIVED,
        value: UserState.ARCHIVED,
    },
    {
        label: UserStateLabel.PENDING,
        value: UserState.PENDING,
    },
    {
        label: UserStateLabel.PENDING_INACTIVE,
        value: UserState.PENDING_INACTIVE,
    },
];

export const USER_STATE_TRANSITIONS = Object.freeze({
    [UserState.ACTIVE]: [UserState.INACTIVE],
    [UserState.INACTIVE]: [UserState.ARCHIVED, UserState.ACTIVE],
    [UserState.ARCHIVED]: [UserState.INACTIVE],
    [UserState.PENDING]: [UserState.PENDING_INACTIVE],
    [UserState.PENDING_INACTIVE]: [UserState.PENDING],
});
