import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { routerActions } from 'connected-react-router';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { useCancelableEffect } from 'platform/common/hooks/useCancelableEffect';
import { TableCell } from 'platform/common/common.type';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import { DATA_TYPES } from 'platform/common/dataType';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import { Advertiser } from 'platform/adminPanel/advertisers/advertiser.type';
import { Website } from 'platform/adminPanel/websites/website.type';
import * as websiteService from 'platform/adminPanel/websites/website.service';
import StateBadge from 'platform/common/components/StateBadge/StateBadge';
import * as advertiserService from 'platform/adminPanel/advertisers/advertiser.service';
import { STATE_OPTIONS, States } from 'platform/common/constants/states.constant';
import { includesIgnoreCase } from 'platform/common/utils/filter.util';
import * as campaignService from './campaign.service';
import { Campaign } from './campaign.type';
import { CampaignFormCreate, CampaignFormEdit } from './CampaignForm';

type State = {
    loading: boolean;
    data: Campaign[];
    advertiserIds: number[];
    websiteIds: number[];
    ids: number[];
    searchQuery: string;
    states: States[];
};

type MetaData = {
    advertisers: Advertiser[];
    websites: Website[];
    loading: boolean;
};

const CampaignList = () => {
    const dispatch = useDispatch();

    const [state, setState] = useState<State>({
        loading: true,
        data: [],
        advertiserIds: [],
        websiteIds: [],
        ids: [],
        states: [States.ACTIVE],
        searchQuery: '',
    });

    const [metaData, setMetaData] = useState<MetaData>({
        loading: true,
        advertisers: [],
        websites: [],
    });

    const fetchCampaigns = (ids: number[], advertiserIds: number[], websiteIds: number[]) => {
        setState(currentState => ({ ...currentState, loading: true }));
        campaignService
            .fetchCampaigns({ ids, advertiserIds, websiteIds })
            .then(response => {
                setState(currentState => ({
                    ...currentState,
                    data: response.map(campaign => ({
                        ...campaign,
                        advertiserName: metaData.advertisers.find(
                            advertiser => advertiser.id === campaign.advertiserId
                        )?.name,
                        websiteName: metaData.websites.find(website => website.id === campaign.websiteId)
                            ?.name,
                    })),
                }));
            })
            .finally(() => {
                setState(currentState => ({ ...currentState, loading: false }));
            });
    };

    useEffect(() => {
        Promise.all([advertiserService.fetchAdvertisers(), websiteService.fetchWebsites()])
            .then(([advertisers, websites]: [Advertiser[], Website[]]) => {
                setMetaData(currentMetaData => ({
                    ...currentMetaData,
                    advertisers,
                    websites,
                }));
            })
            .finally(() => setMetaData(currentMetaData => ({ ...currentMetaData, loading: false })));
    }, []);

    useCancelableEffect(() => {
        if (metaData.loading) return;
        fetchCampaigns(state.ids, state.advertiserIds, state.websiteIds);
    }, [state.ids, state.advertiserIds, state.websiteIds, metaData.loading]);

    const columnDefinitions = [
        {
            Header: 'Status',
            accessor: 'status',
            type: DATA_TYPES.TEXT,
            width: 80,
            Cell: (cell: TableCell<Campaign>) => <StateBadge state={cell.value} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'Name',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Advertiser',
            accessor: 'advertiserName',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Placement',
            accessor: 'websiteName',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Edited',
            accessor: 'lastModifiedOn',
            width: 160,
            Cell: (cell: TableCell<Campaign>) => (
                <UpdatedOn date={cell.original.lastModifiedOn} updatedBy={cell.original.lastModifiedBy} />
            ),
        },
        {
            className: () => 'edit-dropdown pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: (cell: TableCell<Campaign>) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () => dispatch(routerActions.push(`/campaign/${cell.original.id}`)),
                        },
                    ]}
                />
            ),
        },
    ];

    const campaigns = state.data
        .filter(
            c => includesIgnoreCase(c.name, state.searchQuery) || includesIgnoreCase(c.id, state.searchQuery)
        )
        .filter(c => !state.states.length || state.states.includes(c.status));

    return (
        <div>
            <div className="my-4">
                <PageHeader title="Campaign module" />
            </div>
            <ListFilters>
                <SelectWithAddon
                    name="Advertiser"
                    value={state.advertiserIds}
                    isLoading={metaData.loading}
                    options={metaData.advertisers.map(advertiser => ({
                        label: advertiser.name,
                        value: advertiser.id,
                    }))}
                    onChange={advertiserIds => setState(currentState => ({ ...currentState, advertiserIds }))}
                    isMulti
                />
                <SelectWithAddon
                    name="Website"
                    value={state.websiteIds}
                    isLoading={metaData.loading}
                    options={metaData.websites.map(website => ({
                        label: website.name,
                        value: website.id,
                    }))}
                    onChange={websiteIds => setState(currentState => ({ ...currentState, websiteIds }))}
                    isMulti
                />
                <SelectWithAddon
                    name="Campaign"
                    value={state.ids}
                    isLoading={state.loading}
                    options={state.data.map(campaign => ({
                        label: campaign.name,
                        value: campaign.id,
                    }))}
                    onChange={ids => setState(currentState => ({ ...currentState, ids }))}
                    isMulti
                />
                <SelectWithAddon
                    name="Status"
                    value={state.states}
                    options={STATE_OPTIONS}
                    onChange={states => setState(currentState => ({ ...currentState, states }))}
                    isMulti
                />
            </ListFilters>
            <div className="mb-4">
                <ListToolbar
                    value={state.searchQuery}
                    onCreate={() => {
                        dispatch(routerActions.push('/campaign/new'));
                    }}
                    onSearch={searchQuery => setState(currentState => ({ ...currentState, searchQuery }))}
                />
            </div>
            <FormattedTable
                className="-striped"
                columns={columnDefinitions}
                data={campaigns}
                loading={state.loading}
            />
            <Switch>
                <Route
                    path={'/campaign/new'}
                    render={props => (
                        <CampaignFormCreate
                            {...props}
                            redirectTo={'/campaign'}
                            afterSubmit={(campaign: Campaign) =>
                                dispatch(routerActions.push(`/campaign/${campaign.id}`))
                            }
                            canEdit
                        />
                    )}
                />
                <Route
                    path={'/campaign/:id'}
                    render={props => (
                        <CampaignFormEdit
                            {...props}
                            redirectTo={'/campaign'}
                            canEdit
                            onClose={() => fetchCampaigns(state.ids, state.advertiserIds, state.websiteIds)}
                        />
                    )}
                />
            </Switch>
        </div>
    );
};

export default CampaignList;
