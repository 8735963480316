export enum States {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    ARCHIVED = 'ARCHIVED',
}

export enum StatesLabel {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    ARCHIVED = 'Archived',
}

export const STATE_OPTIONS = [
    {
        label: StatesLabel.ACTIVE,
        value: States.ACTIVE,
    },
    {
        label: StatesLabel.INACTIVE,
        value: States.INACTIVE,
    },
    {
        label: StatesLabel.ARCHIVED,
        value: States.ARCHIVED,
    },
];

export const STATE_TRANSITIONS = Object.freeze({
    [States.ACTIVE]: [States.INACTIVE],
    [States.INACTIVE]: [States.ARCHIVED, States.ACTIVE],
    [States.ARCHIVED]: [States.INACTIVE],
});
