import { makeOptions } from 'platform/common/utils/option.util';

const ALL = 'ALL';
const CUSTOM = 'CUSTOM';
const NONE = 'NONE';

export const ACCESS_TYPES = {
    ALL,
    CUSTOM,
    NONE,
};

export const ACCESS_LABELS: { [key: string]: string } = {
    [ALL]: 'All',
    [CUSTOM]: 'Custom',
    [NONE]: 'None',
};

export const ACCESS_OPTIONS = makeOptions(ACCESS_LABELS);
