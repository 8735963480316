import React, { Fragment } from 'react';
import { noop } from 'lodash-es';
import { Button, FormFeedback, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { Validator } from 'platform/common/utils/validators.util';

type Props = {
    addOnTitle: string;
    buttonTitle: string;
    placeholder: string;
    onClick: (value: string) => any;
    inputValidation: Validator | Validator[];
};

type State = {
    value: string;
    error?: string | void;
};

const defaultProps = {
    placeholder: '',
    addOnTitle: '',
    inputValidation: noop,
};

const validate = (validationFunc: Function | Function[], value: any): string | void => {
    if (Array.isArray(validationFunc)) {
        return validationFunc.map(f => f(value)).find(error => error !== undefined);
    }

    return validationFunc(value);
};

class InputButton extends React.Component<Props, State> {
    static defaultProps = defaultProps;

    state: State = {
        value: '',
        error: undefined,
    };

    valueInput: any;

    onButtonClick = () => this.setValue();

    onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === this.enterKeyCode) {
            e.stopPropagation();
            e.preventDefault();
            this.setValue();
        }
    };

    setValue = () => {
        const error = validate(this.props.inputValidation, this.state.value);
        this.setState({ error });
        if (!error) {
            this.props.onClick(this.state.value);
            this.setState({ value: '' });
            this.valueInput.focus();
        }
    };

    enterKeyCode = 13;

    updateValue = (value: string) => {
        if (this.state.error) {
            // we don't do validation on user typing unless there was an error before
            this.setState({ error: validate(this.props.inputValidation, this.state.value) });
        }
        this.setState({ value });
    };

    onBlur = () => {
        this.setState({ error: validate(this.props.inputValidation, this.state.value) });
    };

    render() {
        const { addOnTitle, placeholder, buttonTitle } = this.props;
        return (
            <Fragment>
                <InputGroup>
                    {addOnTitle && (
                        <InputGroupAddon addonType="prepend" className="pb-0 pt-0">
                            {addOnTitle}
                        </InputGroupAddon>
                    )}
                    <Input
                        type="text"
                        onKeyDown={this.onKeyDown}
                        value={this.state.value}
                        placeholder={placeholder}
                        onChange={e => this.updateValue(e.target.value)}
                        onBlur={this.onBlur}
                        innerRef={input => {
                            this.valueInput = input;
                        }}
                    />
                    <Button size="md" color="primary" onClick={this.onButtonClick}>
                        {buttonTitle}
                    </Button>
                </InputGroup>
                {this.state.error && <FormFeedback className="d-block">{this.state.error}</FormFeedback>}
            </Fragment>
        );
    }
}

export default InputButton;
