import * as React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import classnames from 'classnames';

type Option = { key?: string; label: string; action?: () => any; disabled?: boolean };

type Props = {
    items: Option[];
    children: React.ReactNode;
    className?: string;
    color?: string;
    dropdownMenuRight?: boolean;
    disabled?: boolean;
    onItemClick?: (option: Option) => void;
};

const ButtonDropdown = ({
    children,
    items,
    className = '',
    color = 'primary',
    dropdownMenuRight,
    disabled,
    onItemClick,
}: Props) => {
    const onDropdownItemClick = (option: Option) => {
        if (option.action) option.action();
        if (onItemClick) onItemClick(option);
    };
    return (
        <UncontrolledButtonDropdown className={className} disabled={disabled}>
            <DropdownToggle caret color={color} disabled={disabled}>
                {children}
            </DropdownToggle>
            <DropdownMenu right={Boolean(dropdownMenuRight)}>
                {items.map(option => (
                    <DropdownItem
                        className={classnames({ disabled: option.disabled })}
                        onClick={option.disabled ? undefined : () => onDropdownItemClick(option)}
                        key={option.key || option.label}
                    >
                        {option.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
};

export default ButtonDropdown;
