import { isDefined } from 'platform/common/common.type';

export const precisionRound = (number: number, precision: number): number => {
    const factor = 10 ** precision;
    return Math.round(number * factor) / factor;
};

/**
 * Example output:
 * - 'A'
 * - 'A and B'
 * - 'A, B and C'
 */
export const joinByCommaAndConjunction = (items: string[] = []): string => {
    switch (items.length) {
        case 0:
            return '';
        case 1:
            return items[0];
        case 2:
            return items.join(' and ');
        default:
            return `${items.slice(0, -1).join(', ')} and ${items[items.length - 1]}`;
    }
};

export const getInitials = (name?: string) =>
    name &&
    name
        .split(' ')
        .filter(isDefined)
        .map(word => word[0].toUpperCase())
        .join('');

export const hashColorCode = (code: string | undefined) => (code ? `#${code}` : undefined);

export const unHashColorCode = (code: string | undefined) => (code || '').replace('#', '');
