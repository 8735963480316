import * as React from 'react';
import './FormHeader.scss';

type Props = {
    title: string;
    subtitle?: string | React.ReactNode;
    children: React.ReactNode;
};

const FormHeader = ({ title, subtitle, children }: Props) => (
    <div className="FormHeader d-flex justify-content-end align-items-center">
        <div className="mr-auto">
            <span className="FormHeader-title">{title}</span>
            {subtitle && (
                <span className="FormHeader-subtitle">
                    <br />
                    {subtitle}
                </span>
            )}
        </div>
        <div className="mt-2">{children}</div>
    </div>
);

export default FormHeader;
