import axiosBff from 'platform/axios';
import { IdResponse } from 'platform/common/common.type';
import { Advertiser } from './advertiser.type';

export const fetchAdvertisers = () =>
    axiosBff.get<Advertiser[]>('/sdo/api/advertisers').then(response => response.data);

export const fetchAdvertiser = (id: number) =>
    axiosBff.get<Advertiser>(`/sdo/api/advertisers/${id}`).then(response => response.data);

export const createAdvertiser = (model: Partial<Advertiser>) =>
    axiosBff.post<IdResponse>('/sdo/api/advertisers', model).then(response => response.data);

export const updateAdvertiser = (model: Advertiser) =>
    axiosBff.put<IdResponse>('/sdo/api/advertisers', model).then(response => response.data);
