import { uploadImage } from 'platform/advertorial/advertorial.service';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { useModal } from 'platform/common/components/Modal/Modal';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { dataUrlToBase64, FileInfo } from 'platform/common/utils/file.util';
import React from 'react';
import { Button as ReactstrapButton, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { Editor, Node, Transforms } from 'slate';
import { useSlate, useSelected, useFocused, useEditor, ReactEditor } from 'slate-react';
import { Formik, FormikProps, Form } from 'formik';
import { urlHttps, positiveNumber } from 'platform/common/utils/validators.util';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { getActiveEntryOfType, elementMatcher } from 'platform/advertorial/AdvertorialEditor/utils';
import FormButtonArray from 'platform/common/components/FormButtonArray/FormButtonArray';
import BlockControlls from './BlockControlls';
import { Button, Icon } from './components';
import { Float, floatButtons, getFloatClasses } from './float';
import { Alignment, blockAlignmentButtons, lengthOptions, LengthUnit } from './common.types';
import { toBlockAlignmentClass } from './alignment';

enum ImagePosition {
    left = 'left',
    right = 'right',
    top = 'top',
}

type CitationParams = {
    imageUrl: string;
    width: number | undefined;
    float: Float;
    alignment: Alignment;
    imagePosition: ImagePosition;
    widthUnit: LengthUnit;
};

const insertCitation = (editor: Editor, params: CitationParams) => {
    const citationNode: CitationElement = {
        type: 'citation',
        ...params,
        children: [
            {
                type: 'citation-image',
                url: params.imageUrl,
                imagePosition: params.imagePosition,
                children: [{ text: '' }],
            },
            {
                type: 'flex-column',
                children: [
                    { type: 'heading-two', children: [{ text: 'Header' }] },
                    { type: 'paragraph', children: [{ text: 'Factbox body' }] },
                ],
            },
        ],
    };
    Transforms.insertNodes(editor, citationNode);
};

const IMAGE_FLEX_DIRECTION = {
    left: 'flex-row',
    right: 'flex-row-reverse',
    top: 'flex-column',
};

const CitationModal = ({
    isOpen,
    toggle,
    done,
    initial,
}: {
    isOpen: boolean;
    toggle: () => void;
    done: (params: CitationParams) => void;
    initial?: CitationParams;
}) => {
    const [isUploading, setUploading] = React.useState(false);
    return (
        <Modal style={{ maxWidth: 600 }} isOpen={isOpen} toggle={toggle}>
            <ModalHeader>Factbox</ModalHeader>
            <ModalBody>
                {isUploading && <OverlayLoader />}
                <Formik
                    initialValues={{
                        imageUrl: initial?.imageUrl ?? '',
                        float: initial?.float || Float.none,
                        alignment: initial?.alignment || Alignment.left,
                        width: initial?.width || undefined,
                        imagePosition: initial?.imagePosition || ImagePosition.left,
                        widthUnit: initial?.widthUnit || LengthUnit.px,
                    }}
                    onSubmit={done}
                >
                    {(formProps: FormikProps<CitationParams>) => (
                        <Form>
                            <FormRow label="Image source url">
                                <FormInput type="text" name="imageUrl" validate={[urlHttps]} />
                            </FormRow>
                            <FormRow label="">
                                <UploadInput
                                    acceptableMimeTypes="image/jpeg, image/png, image/gif"
                                    onFileUpload={(file: FileInfo) => {
                                        setUploading(true);
                                        uploadImage({
                                            content: dataUrlToBase64(file.content),
                                            filename: file.name,
                                        })
                                            .then(({ fullUrl }) => {
                                                formProps.setValues({
                                                    ...formProps.values,
                                                    imageUrl: fullUrl,
                                                });
                                            })
                                            .finally(() => setUploading(false));
                                    }}
                                    subtitle="Upload jpeg/png/gif"
                                />
                            </FormRow>
                            <FormRow label="Image position">
                                <FormButtonArray
                                    name="imagePosition"
                                    buttons={
                                        [
                                            {
                                                label: 'Left',
                                                value: ImagePosition.left,
                                            },
                                            {
                                                label: 'Right',
                                                value: ImagePosition.right,
                                            },
                                            {
                                                label: 'Top',
                                                value: ImagePosition.top,
                                            },
                                        ] as any
                                    }
                                />
                            </FormRow>
                            <FormRow label="Width unit">
                                <FormButtonArray name="widthUnit" buttons={lengthOptions} />
                            </FormRow>
                            <FormRow label="Width (leave blank for full width)">
                                <FormInput
                                    name="width"
                                    type="number"
                                    validate={positiveNumber}
                                    rightAddOn={{ title: formProps.values.widthUnit }}
                                />
                            </FormRow>
                            <FormRow label="Float (text will wrap around)">
                                <FormButtonArray name="float" buttons={floatButtons} />
                            </FormRow>
                            {formProps.values.float === 'none' && (
                                <FormRow label="Alignment">
                                    <FormButtonArray name="alignment" buttons={blockAlignmentButtons} />
                                </FormRow>
                            )}
                            <div className="d-flex justify-content-end">
                                <ReactstrapButton color="secondary" onClick={toggle}>
                                    Cancel
                                </ReactstrapButton>
                                <ReactstrapButton className="ml-2" color="primary" type="submit">
                                    Ok
                                </ReactstrapButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export const CitationToolbarButton = () => {
    const editor = useSlate();
    const { showModal } = useModal();
    const ref = React.useRef<HTMLButtonElement>(null);
    return (
        <Button
            ref={ref}
            onMouseDown={(event: Event) => {
                event.preventDefault();
                const { selection } = editor;

                const entry = getActiveEntryOfType(editor, 'citation');

                if (!entry) {
                    showModal(toggle => (
                        <CitationModal
                            isOpen
                            toggle={toggle}
                            done={params => {
                                Transforms.select(editor, selection || [0]);
                                insertCitation(editor, params);
                                toggle();
                            }}
                        />
                    ));
                } else {
                    const element = entry[0] as CitationElement;
                    showModal(toggle => (
                        <CitationModal
                            initial={element}
                            isOpen
                            toggle={toggle}
                            done={params => {
                                Transforms.select(editor, selection || [0]);
                                Transforms.setNodes(editor, params, elementMatcher('citation'));
                                Transforms.setNodes(
                                    editor,
                                    { url: params.imageUrl, imagePosition: params.imagePosition },
                                    elementMatcher('citation-image')
                                );
                                toggle();
                            }}
                        />
                    ));
                }
            }}
        >
            <Icon className="fas fa-portrait" />
            {ref.current && <UncontrolledTooltip target={ref.current}>Factbox</UncontrolledTooltip>}
        </Button>
    );
};

type CitationElement = CitationParams & {
    type: 'citation';
    children: Node[];
};

type CitationImageElement = {
    type: 'citation-image';
    children: Text[];
    url?: string;
    imagePosition: ImagePosition;
};

export const CitationImage = ({
    attributes,
    children,
    element,
}: {
    element: CitationImageElement;
    attributes: any;
    children: React.ReactNode;
}) => {
    const IMAGE_MARGIN = {
        left: {
            marginRight: '1rem',
        },
        right: {
            marginLeft: '1rem',
        },
        top: undefined,
    };
    return (
        <figure {...attributes} style={{ width: '150px', ...IMAGE_MARGIN[element.imagePosition] }}>
            <img
                draggable={false}
                alt={`${element.url}`}
                src={element.url}
                className={`d-block `}
                style={{
                    maxWidth: '100%',
                }}
            />
            <figcaption style={{ background: '#e5e5e5' }}>{children}</figcaption>
        </figure>
    );
};

export const CitationInEditor = ({
    attributes,
    children,
    element,
}: {
    element: CitationElement;
    attributes: any;
    children: React.ReactNode;
}) => {
    const selected = useSelected();
    const focused = useFocused();
    const editor = useEditor();
    const { showModal } = useModal();
    return (
        <div
            {...attributes}
            className={`d-flex mb-2 ${toBlockAlignmentClass(element.alignment)} ${getFloatClasses(
                element.float || 'none'
            )}`}
        >
            <div
                className="mb-4 mx-1 d-flex align-items-start pt-4 p-2"
                style={{
                    width: element.width ? `${element.width}${element.widthUnit}` : '100%',
                    boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
                    position: 'relative',
                    background: '#eee',
                }}
            >
                <BlockControlls
                    label="Factbox"
                    element={element}
                    onEdit={() => {
                        showModal(toggle => (
                            <CitationModal
                                initial={element}
                                isOpen
                                toggle={toggle}
                                done={params => {
                                    Transforms.select(editor, ReactEditor.findPath(editor, element));
                                    Transforms.setNodes(editor, params, elementMatcher('citation'));
                                    Transforms.setNodes(
                                        editor,
                                        { url: params.imageUrl, imagePosition: params.imagePosition },
                                        elementMatcher('citation-image')
                                    );
                                    toggle();
                                }}
                            />
                        ));
                    }}
                />
                <div
                    className={`citation d-flex ${IMAGE_FLEX_DIRECTION[element.imagePosition]}`}
                    onDoubleClick={e => e.stopPropagation()}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export const serializeCitation = (element: CitationElement, children: string) => `
<div class="sdo-citation-container d-flex sdo-float-${element.float} sdo-flex-align-${
    element.alignment
}"  style="width: ${element.width ? `${element.width}${element.widthUnit};` : '100%'}">
    <div class="citation d-flex ${IMAGE_FLEX_DIRECTION[element.imagePosition]}">
        ${children}
    </div>
</div>
    `;

export const serializeCitationImage = (element: CitationImageElement, children: string) => {
    const IMAGE_MARGIN = {
        left: 'margin-right: 1rem;',
        right: 'margin-left: 1rem;',
        top: undefined,
    };
    return `
<figure class="sdo-citation-figure" style="${IMAGE_MARGIN[element.imagePosition]}">
    <img class="sdo-citations-image" src="${element.url}">
    <figcaption class="sdo-citation-figcaption">${children}</figcaption>
</figure>
    `;
};
export const withCitations = <T extends Editor>(editor: T): T => {
    const { normalizeNode } = editor;

    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = ([node, path]) => {
        if (node.type === 'citation') {
            if (node.children.length === 0) {
                Transforms.insertNodes(
                    editor,
                    [
                        {
                            type: 'citation-image',
                            url: node.imageUrl,
                            imagePosition: node.imagePosition,
                            children: [{ text: '' }],
                        },
                        {
                            type: 'flex-column',
                            children: [{ type: 'heading-two', children: [{ text: '' }] }],
                        },
                    ],
                    { at: path.concat(0) }
                );
                return null;
            }
            if (node.children[0].type !== 'citation-image') {
                Transforms.insertNodes(
                    editor,
                    {
                        type: 'citation-image',
                        url: node.imageUrl,
                        imagePosition: node.imagePosition,
                        children: [{ text: '' }],
                    },
                    { at: path.concat(0) }
                );
                return null;
            }
            if (node.children[1]?.type !== 'flex-column') {
                Transforms.insertNodes(
                    editor,
                    {
                        type: 'flex-column',
                        children: [{ type: 'heading-two', children: [{ text: '' }] }],
                    },
                    { at: path.concat(1) }
                );
                return null;
            }
            if (node.children[2]?.type === 'heading-two') {
                Transforms.removeNodes(editor, { at: path.concat(2) });
                return null;
            }
            if (node.children[1]?.type === 'flex-column') {
                Array.from(Node.children(editor, path.concat(1))).forEach(([child, childPath], index) => {
                    if (index > 0 && child.type !== 'paragraph') {
                        Transforms.setNodes(editor, { type: 'paragraph' }, { at: childPath });
                    }
                });
                return null;
            }
            return null;
        }

        return normalizeNode([node, path]);
    };

    return editor;
};
