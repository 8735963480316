import { NavigationItem } from 'platform/app/app.type';
import { USER_MANAGEMENT } from 'platform/userManagement/userManagement.navigation';
import { ADMIN_PANEL } from 'platform/adminPanel/adminPanel.navigation';
import { ADVERTISERS } from 'platform/adminPanel/advertisers/advertiser.navigation';
import { MASTER_TEMPLATES } from 'platform/adminPanel/masterTemplates/masterTemplates.navigation';
import { WEBSITES } from 'platform/adminPanel/websites/website.navigation';
import { FEEDS } from 'platform/adminPanel/feeds/feed.navigation';
import { TEASER } from 'platform/teaser/teaser.navigation';
import { AD_BOARD } from 'platform/adBoard/adBoard.navigation';
import { ADVERTORIAL } from 'platform/advertorial/advertorial.navigation';
import { CAMPAIGN } from 'platform/campaign/campaign.navigation';

const navigationItems: NavigationItem[] = [
    CAMPAIGN,
    ADVERTORIAL,
    TEASER,
    AD_BOARD,
    {
        ...ADMIN_PANEL,
        children: [WEBSITES, ADVERTISERS, MASTER_TEMPLATES, FEEDS],
    },
    USER_MANAGEMENT,
];

export default navigationItems;
