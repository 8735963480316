import React from 'react';
import { FormFeedback } from 'reactstrap';
import { validator, Validator } from 'platform/common/utils/validators.util';
import { useField } from 'formik';
import ButtonArray, { ButtonType } from '../ButtonArray/ButtonArray';

type Props<T> = {
    buttons: ButtonType<T>[];
    disabled?: boolean;
    className?: string;
    validate?: Validator | Validator[];
    name: string;
};

const FormButtonArray = <T extends string | number>({
    disabled = false,
    buttons,
    name,
    className,
    validate,
}: Props<T>) => {
    const [field, meta, helpers] = useField({
        name,
        validate: validate && validator(validate),
    });
    const isInvalid = Boolean(meta.touched && meta.error);

    return (
        <>
            <ButtonArray
                onClick={helpers.setValue}
                selected={field.value}
                buttons={buttons}
                disabled={disabled}
                className={className}
            />
            {isInvalid && <FormFeedback className="w-100">{meta.error}</FormFeedback>}
        </>
    );
};

export default FormButtonArray;
