import axiosBff from 'platform/axios';
import { IdResponse } from 'platform/common/common.type';
import { Teaser } from './teaser.type';

type TeaserChangelog = Teaser & { version: number };

export const fetchTeasers = (
    params: {
        campaignIds?: number[];
    } = {}
) =>
    axiosBff
        .get<Teaser[]>('/sdo/api/teasers', { params })
        .then(response => response.data);

export const fetchTeaser = (id: number) =>
    axiosBff.get<Teaser>(`/sdo/api/teasers/${id}`).then(response => response.data);

export const createTeaser = (model: Partial<Teaser>) =>
    axiosBff.post<IdResponse>('/sdo/api/teasers', model).then(response => response.data);

export const updateTeaser = (model: Partial<Teaser>) =>
    axiosBff.put<IdResponse>('/sdo/api/teasers', model).then(response => response.data);

export const fetchChangelog = (id: number) =>
    axiosBff.get<TeaserChangelog[]>(`/sdo/api/teasers/${id}/changelog`).then(response => response.data);
