import { useField } from 'formik';
import { validator, Validator } from 'platform/common/utils/validators.util';
import React from 'react';
import { FormFeedback } from 'reactstrap';
import DynamicOptionList from './DynamicOptionList';

type Props = {
    id?: string;
    addOnTitle?: string;
    buttonTitle: string;
    placeholder?: string;
    inputValidation?: Validator | Validator[];
    valueDecorator?: (value: string) => string;
    validate?: Validator | Validator[];
    name: string;
};

const FormDynamicOptionList = ({
    addOnTitle = '',
    buttonTitle,
    placeholder = '',
    inputValidation = () => undefined,
    valueDecorator,
    name,
    validate,
}: Props) => {
    const [field, meta, helpers] = useField({
        name,
        validate: validate && validator(validate),
    });
    const isInvalid = Boolean(meta.touched && meta.error);

    return (
        <>
            <DynamicOptionList
                key="DynamicOptionList"
                inputValidation={inputValidation}
                className={isInvalid ? 'form-control is-invalid m-0 p-0' : ''}
                values={field.value}
                addOnTitle={addOnTitle}
                buttonTitle={buttonTitle}
                placeholder={placeholder}
                onAdd={helpers.setValue}
                onRemove={helpers.setValue}
                valueDecorator={valueDecorator}
            />
            {isInvalid && <FormFeedback>{meta.error}</FormFeedback>}
        </>
    );
};

export default FormDynamicOptionList;
