import { Switch, Route } from 'react-router-dom';
import { routerActions } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import React from 'react';
import { fetchAdvertorialTemplates } from 'platform/adminPanel/masterTemplates/masterTemplates.service';
import { fetchWebsites } from 'platform/adminPanel/websites/website.service';
import { AdvertorialTemplate } from 'platform/adminPanel/masterTemplates/masterTemplates.type';
import { Website } from 'platform/adminPanel/websites/website.type';
import { nonNil, TableCell } from 'platform/common/common.type';
import { usePromise } from 'platform/common/hooks/usePromise';
import { includesIgnoreCase } from 'platform/common/utils/filter.util';
import { DATA_TYPES } from 'platform/common/dataType';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import {
    AdvertorialTemplateCreate,
    AdvertorialTemplateEdit,
} from 'platform/adminPanel/masterTemplates/AdvertorialTemplate/AdvertorialTemplateForm';

type AdvertorialTemplateWithWebsite = AdvertorialTemplate & {
    website: Website;
};

const fetchAdvertorialTemplatesWithWebsites = async (): Promise<AdvertorialTemplateWithWebsite[]> => {
    const [templates, websites] = await Promise.all([fetchAdvertorialTemplates(), fetchWebsites()]);
    return templates.map(t => ({
        ...t,
        website: nonNil(websites.find(w => t.websiteId === w.id)),
    }));
};

const AdvertorialTemplateList = () => {
    const dispatch = useDispatch();

    const [searchQuery, setSearchQuery] = React.useState('');

    const [{ data, loading }, refetch] = usePromise([], fetchAdvertorialTemplatesWithWebsites, []);

    const advertorialTemplates = data.filter(t => includesIgnoreCase(t.name, searchQuery));

    const columnDefinitions = [
        {
            Header: 'ID',
            accessor: (t: AdvertorialTemplateWithWebsite) => t.id,
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'Name',
            accessor: (t: AdvertorialTemplateWithWebsite) => t.name,
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Website',
            accessor: (t: AdvertorialTemplateWithWebsite) => t.website.name,
            type: DATA_TYPES.TEXT,
            minWidth: 250,
        },
        {
            Header: 'Edited',
            accessor: 'lastModifiedOn',
            width: 160,
            Cell: (cell: TableCell<AdvertorialTemplateWithWebsite>) => (
                <UpdatedOn date={cell.original.lastModifiedOn} updatedBy={cell.original.lastModifiedBy} />
            ),
        },
        {
            className: () => 'edit-dropdown pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: (cell: TableCell<AdvertorialTemplateWithWebsite>) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () =>
                                dispatch(
                                    routerActions.push(
                                        `/admin-panel/master-templates/advertorial/edit/${cell.original.id}`
                                    )
                                ),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <div>
            <div className="mb-4">
                <ListToolbar
                    value={searchQuery}
                    onCreate={() => {
                        dispatch(routerActions.push('/admin-panel/master-templates/advertorial/new'));
                    }}
                    onSearch={setSearchQuery}
                />
            </div>
            <FormattedTable
                className="-striped"
                columns={columnDefinitions}
                data={advertorialTemplates}
                loading={loading}
            />
            <Switch>
                <Route
                    path={'/admin-panel/master-templates/advertorial/new'}
                    render={props => (
                        <AdvertorialTemplateCreate
                            {...props}
                            redirectTo={'/admin-panel/master-templates/advertorial'}
                            canEdit
                            afterSubmit={(advertorialTemplate: AdvertorialTemplate) =>
                                dispatch(
                                    routerActions.push(
                                        `/admin-panel/master-templates/advertorial/edit/${advertorialTemplate.id}`
                                    )
                                )
                            }
                        />
                    )}
                />
                <Route
                    path={'/admin-panel/master-templates/advertorial/edit/:id'}
                    render={props => (
                        <AdvertorialTemplateEdit
                            {...props}
                            redirectTo={'/admin-panel/master-templates/advertorial'}
                            canEdit
                            onClose={refetch}
                        />
                    )}
                />
            </Switch>
        </div>
    );
};

export default AdvertorialTemplateList;
