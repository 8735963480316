import React from 'react';
import { useModal } from 'platform/common/components/Modal/Modal';
import { Editor, Range, Point, Transforms, Node } from 'slate';
import { Button as ReactstrapButton, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { useSlate, useSelected, useFocused } from 'slate-react';
import { times, range } from 'lodash-es';
import { Formik, Form, FormikProps } from 'formik';
import { positiveWholeNumber, required, positiveNumber } from 'platform/common/utils/validators.util';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { getActiveEntryOfType, elementMatcher } from 'platform/advertorial/AdvertorialEditor/utils';
import {
    Alignment,
    blockAlignmentButtons,
    lengthOptions,
    LengthUnit,
} from 'platform/advertorial/AdvertorialEditor/common.types';
import { Float, floatButtons, getFloatClasses } from 'platform/advertorial/AdvertorialEditor/float';
import FormButtonArray from 'platform/common/components/FormButtonArray/FormButtonArray';
import { toBlockAlignmentClass } from 'platform/advertorial/AdvertorialEditor/alignment';
import BlockControlls from './BlockControlls';
import { Button, Icon } from './components';

type TableElement = TableParams & {
    type: 'table';
    children: Node[];
};

type TableParams = {
    rows: number;
    columns: number;
    width: number | undefined;
    float: Float;
    alignment: Alignment;
    widthUnit: LengthUnit;
};

const insertTable = (editor: Editor, params: TableParams) => {
    Transforms.insertNodes(editor, {
        type: 'table',
        ...params,
        children: [
            {
                type: 'table-row',
                children: [
                    {
                        type: 'table-cell',
                        children: [{ text: '' }],
                    },
                ],
            },
        ],
    });
};

const TableModal = ({
    isOpen,
    toggle,
    done,
    initial,
}: {
    isOpen: boolean;
    toggle: () => void;
    done: (params: TableParams) => void;
    initial?: TableParams;
}) => (
    <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Insert table</ModalHeader>
        <ModalBody>
            <Formik
                initialValues={{
                    rows: initial?.rows || 2,
                    columns: initial?.columns || 2,
                    float: initial?.float || Float.none,
                    alignment: initial?.alignment || Alignment.left,
                    width: initial?.width || undefined,
                    widthUnit: initial?.widthUnit || LengthUnit.px,
                }}
                onSubmit={done}
            >
                {(formProps: FormikProps<TableParams>) => (
                    <Form>
                        <FormRow label="Rows">
                            <FormInput type="number" name="rows" validate={[required, positiveWholeNumber]} />
                        </FormRow>
                        <FormRow label="Columns">
                            <FormInput
                                type="number"
                                name="columns"
                                validate={[required, positiveWholeNumber]}
                            />
                        </FormRow>
                        <FormRow label="Width unit">
                            <FormButtonArray name="widthUnit" buttons={lengthOptions} />
                        </FormRow>
                        <FormRow label="Width (leave blank for full width)">
                            <FormInput
                                name="width"
                                type="number"
                                validate={positiveNumber}
                                rightAddOn={{ title: formProps.values.widthUnit }}
                            />
                        </FormRow>
                        <FormRow label="Float (text will wrap around)">
                            <FormButtonArray name="float" buttons={floatButtons} />
                        </FormRow>
                        {formProps.values.float === 'none' && (
                            <FormRow label="Alignment">
                                <FormButtonArray name="alignment" buttons={blockAlignmentButtons} />
                            </FormRow>
                        )}
                        <div className="d-flex justify-content-end">
                            <ReactstrapButton color="secondary" onClick={toggle}>
                                Cancel
                            </ReactstrapButton>
                            <ReactstrapButton className="ml-2" color="primary" type="submit">
                                Ok
                            </ReactstrapButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalBody>
    </Modal>
);

export const TableToolbarButton = () => {
    const editor = useSlate();
    const { showModal } = useModal();
    const ref = React.useRef<HTMLButtonElement>(null);
    return (
        <Button
            ref={ref}
            onMouseDown={(event: Event) => {
                event.preventDefault();
                const { selection } = editor;

                const entry = getActiveEntryOfType(editor, 'table');

                if (!entry) {
                    showModal(toggle => (
                        <TableModal
                            isOpen
                            toggle={toggle}
                            done={params => {
                                Transforms.select(editor, selection || [0]);
                                insertTable(editor, params);
                                toggle();
                            }}
                        />
                    ));
                } else {
                    const element = entry[0] as TableElement;
                    showModal(toggle => (
                        <TableModal
                            initial={element}
                            isOpen
                            toggle={toggle}
                            done={params => {
                                Transforms.select(editor, selection || [0]);
                                insertTable(editor, params);
                                Transforms.setNodes(editor, params, elementMatcher('table'));
                                toggle();
                            }}
                        />
                    ));
                }
            }}
        >
            <Icon className="fas fa-table" />
            {ref.current && <UncontrolledTooltip target={ref.current}>Table</UncontrolledTooltip>}
        </Button>
    );
};

export const TableInEditor = ({
    attributes,
    children,
    element,
}: {
    element: TableElement;
    attributes: any;
    children: React.ReactNode;
}) => {
    const selected = useSelected();
    const focused = useFocused();
    const editor = useSlate();
    const { showModal } = useModal();

    return (
        <div
            {...attributes}
            className={`mb-4 d-flex ${toBlockAlignmentClass(element.alignment)} ${getFloatClasses(
                element.float || 'none'
            )}`}
        >
            <div
                className="mb-4 mx-1 d-flex align-items-center justify-content-center pt-4 p-2"
                style={{
                    width: element.width ? `${element.width}${element.widthUnit}` : '100%',
                    maxWidth: '100%',
                    boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
                    position: 'relative',
                    background: '#eee',
                }}
            >
                <BlockControlls
                    label="Table"
                    onEdit={() => {
                        const { selection } = editor;

                        const entry = getActiveEntryOfType(editor, 'table');

                        if (entry) {
                            const tableElement = entry[0] as TableElement;
                            showModal(toggle => (
                                <TableModal
                                    initial={tableElement}
                                    isOpen
                                    toggle={toggle}
                                    done={params => {
                                        Transforms.select(editor, selection || [0]);
                                        Transforms.setNodes(editor, params, elementMatcher('table'));
                                        toggle();
                                    }}
                                />
                            ));
                        }
                    }}
                    element={element}
                />
                <table style={{ width: '100%', background: 'white' }} className="mb-2">
                    <tbody>{children}</tbody>
                </table>
            </div>
        </div>
    );
};

export const withTables = <T extends Editor>(editor: T): T => {
    const { normalizeNode, deleteFragment, deleteBackward, deleteForward, insertBreak } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.deleteFragment = () => {
        const { selection } = editor;

        if (selection) {
            const [...cells] = Editor.nodes(editor, {
                match: n => n.type === 'table-cell',
            });
            if (cells.length > 1) {
                return;
            }
        }
        deleteFragment();
    };

    // eslint-disable-next-line no-param-reassign
    editor.deleteBackward = unit => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
            const [cell] = Editor.nodes(editor, {
                match: n => n.type === 'table-cell',
            });

            if (cell) {
                const [, cellPath] = cell;
                const start = Editor.start(editor, cellPath);

                if (Point.equals(selection.anchor, start)) {
                    return;
                }
            }
        }

        deleteBackward(unit);
    };

    // eslint-disable-next-line no-param-reassign
    editor.deleteForward = unit => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
            const [cell] = Editor.nodes(editor, {
                match: n => n.type === 'table-cell',
            });

            if (cell) {
                const [, cellPath] = cell;
                const end = Editor.end(editor, cellPath);

                if (Point.equals(selection.anchor, end)) {
                    return;
                }
            }
        }

        deleteForward(unit);
    };

    // eslint-disable-next-line no-param-reassign
    editor.insertBreak = () => {
        const { selection } = editor;

        if (selection) {
            const [table] = Editor.nodes(editor, { match: n => n.type === 'table' });

            if (table) {
                return;
            }
        }

        insertBreak();
    };

    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = ([node, path]) => {
        if (node.type === 'table') {
            // eslint-disable-next-line array-callback-return
            Array.from(Node.children(editor, path))
                .reverse()
                .forEach(([child, childPath]) => {
                    if (child.type !== 'table-row') {
                        Transforms.removeNodes(editor, { at: childPath });
                    }
                });
            if (node.children.length < node.rows) {
                Transforms.insertNodes(
                    editor,
                    times(node.rows - node.children.length, () => ({
                        type: 'table-row',
                        children: [{ type: 'table-cell', children: [{ text: '' }] }],
                    })),
                    { at: path.concat(node.children.length) }
                );
            } else if (node.children.length > node.rows) {
                range(node.rows, node.children.length)
                    .reverse()
                    .forEach(index => {
                        Transforms.removeNodes(editor, { at: path.concat(index) });
                    });
            }

            Array.from(Node.children(editor, path)).forEach(([tr, trPath]) => {
                Array.from(Node.children(editor, trPath))
                    .reverse()
                    .forEach(([child, childPath]) => {
                        if (child.type !== 'table-cell') {
                            const texts = Array.from(Node.texts(child)).map(([n]) => n);
                            Transforms.setNodes(
                                editor,
                                { type: 'table-cell', children: texts.length ? texts : [{ text: '' }] },
                                { at: childPath }
                            );
                        }
                    });

                const { columns } = node;

                if (tr.children.length < columns) {
                    Transforms.insertNodes(
                        editor,
                        times(columns - tr.children.length, () => ({
                            type: 'table-cell',
                            children: [{ text: '' }],
                        })),
                        { at: trPath.concat(tr.children.length) }
                    );
                } else if (tr.children.length > columns) {
                    range(columns, tr.children.length)
                        .reverse()
                        .forEach(index => {
                            Transforms.removeNodes(editor, { at: trPath.concat(index) });
                        });
                }
            });
        }

        return normalizeNode([node, path]);
    };
    return editor;
};
