import React from 'react';
import { useSelector } from 'react-redux';
import './PlatformLogo.style.scss';
import logo from '../../../assets/images/sd_one_blau.svg';
import { RootState } from '../../../rootState.type';

const PlatformLogo = () => {
    const { platformLogoUrl } = useSelector((state: RootState) => state.session.account);

    if (!platformLogoUrl) {
        return null;
    }

    return <img className="PlatformLogo" src={logo} alt="Powered by Styria digital" />;
};

export default PlatformLogo;
