import { useCallback, useEffect, useState } from 'react';
import { uniqueId } from 'lodash-es';

type State<T> = {
    loading: boolean;
    data: T;
};

export const usePromise = <T>(
    initialState: T,
    promiseFn: () => Promise<T>,
    deps: any[] = []
): [State<T>, () => void] => {
    const [state, setState] = useState<State<T> & { requestId: string }>({
        loading: true,
        data: initialState,
        requestId: '',
    });

    const fetchData = useCallback(
        async () => {
            const requestId = uniqueId();
            setState({ requestId, loading: true, data: initialState });
            const response = await promiseFn();
            setState(s => {
                if (s.requestId !== requestId) {
                    return s;
                }
                return { requestId: s.requestId, loading: false, data: response };
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return [{ loading: state.loading, data: state.data }, fetchData];
};
