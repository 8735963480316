import axiosBff from 'platform/axios';
import { IdResponse, Modify } from 'platform/common/common.type';
import { TeaserTemplate, AdvertorialTemplate, AdBoardTemplate } from './masterTemplates.type';

export const fetchAdBoardTemplates = () =>
    axiosBff.get<AdBoardTemplate[]>('/sdo/api/ad-board-templates').then(response => response.data);

export const fetchAdBoardTemplate = (id: number) =>
    axiosBff.get<AdBoardTemplate>(`/sdo/api/ad-board-templates/${id}`).then(response => response.data);

export const createAdBoardTemplate = (template: Modify<AdBoardTemplate, { id: number | undefined }>) =>
    axiosBff.post<IdResponse>('/sdo/api/ad-board-templates', template).then(response => response.data);

export const updateAdBoardTemplate = (template: AdBoardTemplate) =>
    axiosBff
        .put<IdResponse>(`/sdo/api/ad-board-templates/${template.id}`, template)
        .then(response => response.data);

export const fetchTeaserTemplates = () =>
    axiosBff.get<TeaserTemplate[]>('/sdo/api/teaser-templates').then(response => response.data);

export const fetchTeaserTemplate = (id: number) =>
    axiosBff.get<TeaserTemplate>(`/sdo/api/teaser-templates/${id}`).then(response => response.data);

export const createTeaserTemplate = (teaserTemplate: Modify<TeaserTemplate, { id: number | undefined }>) =>
    axiosBff.post<IdResponse>('/sdo/api/teaser-templates', teaserTemplate).then(response => response.data);

export const updateTeaserTemplate = (teaserTemplate: TeaserTemplate) =>
    axiosBff.put<IdResponse>('/sdo/api/teaser-templates', teaserTemplate).then(response => response.data);

export const fetchAdvertorialTemplates = () =>
    axiosBff.get<AdvertorialTemplate[]>('/sdo/api/advertorial-templates').then(response => response.data);

export const fetchAdvertorialTemplate = (id: number) =>
    axiosBff.get<AdvertorialTemplate>(`/sdo/api/advertorial-templates/${id}`).then(response => response.data);

export const createAdvertorialTemplate = (
    advertorialTemplate: Modify<AdvertorialTemplate, { id: number | undefined }>
) =>
    axiosBff
        .post<IdResponse>('/sdo/api/advertorial-templates', advertorialTemplate)
        .then(response => response.data);

export const updateAdvertorialTemplate = (advertorialTemplate: AdvertorialTemplate) =>
    axiosBff
        .put<IdResponse>('/sdo/api/advertorial-templates', advertorialTemplate)
        .then(response => response.data);
