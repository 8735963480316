import * as React from 'react';
import { components } from 'react-select';
import { difference } from 'lodash-es';
import { Button } from 'reactstrap';

const MenuWithSelectAll: typeof components.Menu = props => {
    const allValues = props.options;
    const selectedValues = props.getValue();
    const valuesInOptions = difference(allValues, selectedValues).filter(option =>
        props.selectProps.filterOption
            ? props.selectProps.filterOption(option as any, props.selectProps.inputValue || '')
            : true
    );
    if (valuesInOptions.length === 0) {
        return <components.Menu {...props} />;
    }
    const onSelectAll = () => props.setValue([...selectedValues, ...valuesInOptions], 'select-option');
    return (
        <components.Menu {...props}>
            <>
                {props.children}
                <Button className="w-100 border-0 font-xs py-0 my-0" onClick={onSelectAll}>
                    {props.selectProps.inputValue ? 'Add all found' : 'Add all'}
                </Button>
            </>
        </components.Menu>
    );
};

export default MenuWithSelectAll;
