import * as React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { copyToClipboard } from './clipboard.util';

let toastOptions = {
    showGenericErrorMessage: false,
    genericErrorMessageText: 'An error has occurred',
};

export const setToastOptions = (options: typeof toastOptions) => {
    toastOptions = { ...toastOptions, ...options };
};

const traceIdButton = (traceId: string): React.ReactElement<any> => (
    <span
        role="button"
        tabIndex={0}
        onClick={() => copyToClipboard(`${envConfig.ENV} ${window.location.href} TraceId: ${traceId}`)}
    >
        Trace ID: {traceId}
    </span>
);

const toastNoDuplicate = (message: string, traceId: string | undefined, type: any): void => {
    if (!toast.isActive(message)) {
        const messageElement = (
            <div>
                <span>{message}</span>
                <br />
                {traceId && traceIdButton(traceId)}
            </div>
        );
        toast(messageElement, {
            toastId: message,
            type,
        });
    }
};

export const toastError = (message: string, traceId?: string) => {
    toastNoDuplicate(
        toastOptions.showGenericErrorMessage ? toastOptions.genericErrorMessageText : message,
        traceId,
        toast.TYPE.ERROR
    );
};

export const toastSuccess = (message: string, traceId?: string) =>
    toastNoDuplicate(message, traceId, toast.TYPE.SUCCESS);
