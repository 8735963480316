import * as React from 'react';
import * as Ladda from 'ladda';
import classNames from 'classnames';

type Props = {
    disabled?: boolean;
    className?: string;
    spinnerColor?: string;
    children: string | React.ReactNode;
    animationDelay: number;
    onClick: () => any;
};

const defaultProps = {
    animationDelay: 200,
};

class AnimatedButton extends React.Component<Props> {
    static defaultProps = defaultProps;

    node: HTMLButtonElement;
    laddaInstance: any;

    componentDidMount() {
        this.laddaInstance = Ladda.create(this.node);
    }

    componentWillUnmount() {
        this.laddaInstance.remove();
    }

    onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const promise = this.props.onClick();
        if (!promise || !promise.finally) {
            return;
        }

        setTimeout(() => {
            this.laddaInstance.start();

            promise.finally(() => {
                if (this.laddaInstance) {
                    this.laddaInstance.stop();
                }
            });
        }, this.props.animationDelay);
    };

    setNode = (node: HTMLButtonElement) => {
        this.node = node;
    };

    render() {
        return (
            <button
                data-spinner-color={this.props.spinnerColor}
                type="button"
                onClick={this.onClick}
                className={classNames('btn btn-primary ladda-button', this.props.className)}
                data-style="zoom-out"
                ref={this.setNode}
                disabled={this.props.disabled}
            >
                <span>{this.props.children}</span>
            </button>
        );
    }
}

export default AnimatedButton;
