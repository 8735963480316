import React from 'react';
import { Nav, NavItem, NavbarToggler, NavbarBrand } from 'reactstrap';
import CompanyLogo from 'platform/common/components/CompanyLogo/CompanyLogo';
import UserButton from './UserButton';

const sidebarMinimize = (e: React.MouseEvent) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
};

const mobileSidebarToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
};

const Header = () => (
    <header className="app-header navbar rt-sticky-thead-push">
        <NavbarToggler className="d-lg-none" onClick={mobileSidebarToggle}>
            &#9776;
        </NavbarToggler>
        <NavbarToggler className="d-md-down-none" onClick={sidebarMinimize}>
            &#9776;
        </NavbarToggler>
        <NavbarBrand href="/">
            <CompanyLogo />
        </NavbarBrand>
        <Nav className="ml-auto" navbar>
            <NavItem className="pl-3 pr-4">
                <UserButton />
            </NavItem>
        </Nav>
    </header>
);

export default Header;
