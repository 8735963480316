import React from 'react';
import { Formik } from 'formik';
import { Alert, Button, Form } from 'reactstrap';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { minLength, required } from 'platform/common/utils/validators.util';
import FormRow from 'platform/common/components/FormRow/FormRow';
import SimpleCard from 'platform/common/components/SimpleCard/SimpleCard';

type Props = {
    submitError: boolean;
    onSubmit: (data: any) => void;
};

const ProfileChangePasswordForm = ({ submitError, onSubmit }: Props) => (
    <Formik
        initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        }}
        onSubmit={onSubmit}
    >
        {formProps => (
            <Form onSubmit={formProps.handleSubmit}>
                <SimpleCard title="Change password">
                    <FormRow label="Old Password">
                        <FormInput name="oldPassword" type="password" validate={required} />
                    </FormRow>

                    <FormRow label="New Password">
                        <FormInput name="newPassword" type="password" validate={[required, minLength(8)]} />
                    </FormRow>

                    <FormRow label="Confirm New Password">
                        <FormInput
                            name="confirmNewPassword"
                            type="password"
                            validate={[
                                required,
                                value =>
                                    value !== formProps.values.newPassword
                                        ? 'passwords do not match'
                                        : undefined,
                            ]}
                        />
                    </FormRow>

                    {submitError && <Alert color="danger">Old password is incorrect.</Alert>}

                    <div className="d-flex justify-content-end">
                        <Button type="submit" color="primary">
                            Save
                        </Button>
                    </div>
                </SimpleCard>
            </Form>
        )}
    </Formik>
);

export default ProfileChangePasswordForm;
