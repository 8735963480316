import axiosBff from 'platform/axios';
import { IdResponse } from 'platform/common/common.type';
import { States } from 'platform/common/constants/states.constant';
import { Campaign } from './campaign.type';

export const fetchCampaigns = (
    params: {
        ids?: number[];
        advertiserIds?: number[];
        websiteIds?: number[];
        statuses?: States[];
    } = {}
) =>
    axiosBff
        .get<Campaign[]>('/sdo/api/campaigns', { params })
        .then(response => response.data);

export const fetchCampaign = (id: number) =>
    axiosBff.get<Campaign>(`/sdo/api/campaigns/${id}`).then(response => response.data);

export const createCampaign = (model: Partial<Campaign>) =>
    axiosBff.post<IdResponse>('/sdo/api/campaigns', model).then(response => response.data);

export const updateCampaign = (model: Partial<Campaign>) =>
    axiosBff.put<IdResponse>('/sdo/api/campaigns', model).then(response => response.data);
