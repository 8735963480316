import React from 'react';
import { Input } from 'reactstrap';
import { noop } from 'lodash-es';
import { FormikHandlers } from 'formik';

type Props = {
    onChange: FormikHandlers['handleChange'];
    value: number | string;
    onFocus?: () => any;
    isInvalid?: boolean;
    placeholder?: string;
    type: string;
};

const defaultProps = {
    isInvalid: false,
    onFocus: noop,
    placeholder: '',
};

const PlainInput = (props: Props) => {
    const { isInvalid, placeholder, type, onFocus, ...rest } = props;
    const onInputFocus = () => onFocus && onFocus();

    // default height for textarea
    const rows = type === 'textarea' ? { rows: 5 } : ({} as any);
    return (
        <Input
            onClick={onFocus && onInputFocus}
            type={type as any}
            invalid={isInvalid}
            placeholder={placeholder}
            {...rows}
            {...rest}
        />
    );
};

PlainInput.defaultProps = defaultProps;

export default PlainInput;
