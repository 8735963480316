import React, { Fragment } from 'react';
import { useField } from 'formik';
import { FormFeedback } from 'reactstrap';
import classNames from 'classnames';
import { DateRanges } from 'platform/common/utils/date.util';
import { validator, Validator } from 'platform/common/utils/validators.util';
import DateRangePicker from '../DateRangePicker/DateRangePicker';

type Props = {
    id?: string;
    name: string;
    minDate?: string;
    optionalEndDate?: boolean;
    ranges?: DateRanges;
    disabled?: boolean;
    inputGroupStyle?: React.CSSProperties;
    className?: string;
    validate?: Validator | Validator[];
};

const FormDateRangePicker = ({
    id,
    minDate,
    ranges,
    disabled,
    inputGroupStyle,
    className,
    validate,
    name,
}: Props) => {
    const [field, meta, helpers] = useField<{
        from: string;
        to: string;
    }>({ name, validate: validate && validator(validate) });
    const isInvalid = !!(meta.touched && meta.error);

    return (
        <Fragment>
            <DateRangePicker
                id={id || name}
                from={field.value.from}
                to={field.value.to}
                onChange={(from, to) => {
                    helpers.setValue({ from, to });
                }}
                onBlur={() => {
                    field.onBlur(name);
                }}
                minDate={minDate}
                className={classNames({ 'form-control is-invalid p-0': isInvalid }, className)}
                ranges={ranges}
                disabled={disabled}
                inputGroupStyle={inputGroupStyle}
            />
            {isInvalid && <FormFeedback>{meta.error}</FormFeedback>}
        </Fragment>
    );
};

export default FormDateRangePicker;
