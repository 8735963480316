import * as React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import './SimpleCard.scss';

type Props = {
    title: string;
    subtitle?: string;
    children: React.ReactNode;
};

/**
 * Simple styled card without collapse action
 */
const SimpleCard = (props: Props) => (
    <Card className="SimpleCard">
        <CardHeader>
            <span className="SimpleCard-title">{props.title}</span>
            {props.subtitle && (
                <span className="font-xs">
                    &nbsp;
                    {props.subtitle}
                </span>
            )}
        </CardHeader>
        <CardBody>{props.children}</CardBody>
    </Card>
);

export default SimpleCard;
