import React from 'react';
import classNames from 'classnames';
import './ListFilters.scss';

type Props = {
    children: React.ReactNode;
    className?: string;
};

const ListFilters = ({ children, className = '' }: Props) => (
    <div className={classNames('ListFilters d-flex flex-wrap', className)}>
        {React.Children.map(children, child => (
            <div className="mb-2 mr-2">{child}</div>
        ))}
    </div>
);

export default ListFilters;
