export enum Authority {
    ROLE_USERS_VIEW_EDIT = 'ROLE_USERS_VIEW_EDIT',
    ROLE_TEASERS_VIEW_EDIT = 'ROLE_TEASERS_VIEW_EDIT',
    ROLE_AD_BOARD_VIEW_EDIT = 'ROLE_AD_BOARD_VIEW_EDIT',
    ROLE_ADMIN_PANEL_VIEW_EDIT = 'ROLE_ADMIN_PANEL_VIEW_EDIT',
    ROLE_CAMPAIGNS_VIEW_EDIT = 'ROLE_CAMPAIGNS_VIEW_EDIT',
    ROLE_ADVERTORIALS_VIEW_EDIT = 'ROLE_ADVERTORIALS_VIEW_EDIT',
    ROLE_CAMPAIGNS = 'ROLE_CAMPAIGNS',
    ROLE_AD_GROUPS = 'ROLE_AD_GROUPS',
    ROLE_ADS = 'ROLE_ADS',
    ROLE_EXPORT_LEADS = 'ROLE_EXPORT_LEADS',
    ROLE_CONFIGURE_INTEGRATIONS = 'ROLE_CONFIGURE_INTEGRATIONS',
}
