import axiosBff from 'platform/axios';
import { IdResponse } from 'platform/common/common.type';
import { Feed } from './feed.type';

export const fetchFeeds = () => axiosBff.get<Feed[]>('/sdo/api/feeds').then(response => response.data);

export const fetchFeed = (id: number) =>
    axiosBff.get<Feed>(`/sdo/api/feeds/${id}`).then(response => response.data);

export const createFeed = (model: Partial<Feed>) =>
    axiosBff.post<IdResponse>('/sdo/api/feeds', model).then(response => response.data);

export const updateFeed = (model: Feed) =>
    axiosBff.put<IdResponse>('/sdo/api/feeds', model).then(response => response.data);
