import React from 'react';
import { Formik, FormikProps } from 'formik';
import { WithFormProps } from 'platform/common/common.type';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { required } from 'platform/common/utils/validators.util';
import FormRow from 'platform/common/components/FormRow/FormRow';
import CardForm from 'platform/common/components/CardForm/CardForm';
import withEditForm from 'platform/common/components/WithEditForm/WithEditForm';
import withCreateForm from 'platform/common/components/WithCreateForm/WithCreateForm';
import {
    createAdBoardTemplate,
    fetchAdBoardTemplate,
    updateAdBoardTemplate,
} from 'platform/adminPanel/masterTemplates/masterTemplates.service';
import { AdBoardTemplate } from 'platform/adminPanel/masterTemplates/masterTemplates.type';
import FormSelect from 'platform/common/components/FormSelect/FormSelect';
import { fetchWebsites } from 'platform/adminPanel/websites/website.service';
import { usePromise } from 'platform/common/hooks/usePromise';
import Separator from 'platform/common/components/Separator/Separator';
import FormCodeMirror from 'platform/common/components/CodeMirror/FormCodeMirror';

export type AdBoardTemplateForm = {
    id: number;
    name: string;
    websiteId: number;
    css?: string;
};

const AdBoardTemplateForm = (props: WithFormProps<AdBoardTemplateForm>) => {
    const { onSubmit, onClose, labels, initialValues, canEdit } = props;

    const [{ data: websiteOptions, loading: isWebsitesLoading }] = usePromise(
        [],
        () => fetchWebsites().then(websites => websites.map(w => ({ value: w.id, label: w.name }))),
        []
    );

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(formProps: FormikProps<AdBoardTemplateForm>) => (
                <CardForm
                    title={`${labels.prefix} ad board template`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    onClose={onClose}
                    disabled={!canEdit}
                    onSubmit={formProps.submitForm}
                >
                    <ControlledCard title="General info">
                        <FormRow label="Name">
                            <FormInput name="name" type="text" validate={required} />
                        </FormRow>
                        <FormRow label="Website">
                            <FormSelect
                                name="websiteId"
                                validate={required}
                                options={websiteOptions}
                                isLoading={isWebsitesLoading}
                            />
                        </FormRow>
                        <Separator />

                        <FormRow label="CSS">
                            <FormCodeMirror name="css" options={{ mode: 'css' }} />
                        </FormRow>
                    </ControlledCard>
                </CardForm>
            )}
        </Formik>
    );
};

export const toAdBoardTemplateForm = (template: AdBoardTemplate): AdBoardTemplateForm => ({
    css: template.css,
    id: template.id,
    name: template.name,
    websiteId: template.websiteId,
});

export const toAdBoardTemplateApi = (adBoardTemplate: AdBoardTemplateForm): AdBoardTemplate => ({
    id: adBoardTemplate.id,
    name: adBoardTemplate.name,
    css: adBoardTemplate.css,
    websiteId: adBoardTemplate.websiteId,
});

const newAdBoardTemplate: Partial<AdBoardTemplateForm> = {
    id: undefined,
    websiteId: undefined,
    name: '',
    css: '',
};

const withCreateOptions = {
    onOpen: async () => newAdBoardTemplate,
    onSubmit: async (template: AdBoardTemplateForm) => createAdBoardTemplate(toAdBoardTemplateApi(template)),
};

const withEditOptions = {
    onOpen: async (params: { id: string }) => {
        const template = await fetchAdBoardTemplate(Number(params.id));
        return toAdBoardTemplateForm(template);
    },
    onSubmit: async (template: AdBoardTemplateForm) => updateAdBoardTemplate(toAdBoardTemplateApi(template)),
};

export const AdBoardTemplateCreate = withCreateForm(withCreateOptions)(AdBoardTemplateForm);

export const AdBoardTemplateEdit = withEditForm(withEditOptions)(AdBoardTemplateForm);
