import React from 'react';
import { Spinner } from 'reactstrap';
import './OverlayLoader.scss';

const OverlayLoader = () => (
    <div className="OverlayLoader">
        <Spinner color="dark" />
    </div>
);

export default OverlayLoader;
