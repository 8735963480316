import React from 'react';
import { Formik } from 'formik';
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { required } from 'platform/common/utils/validators.util';
import { TwoFactorAuthenticationType } from 'platform/userManagement/userManagement.constant';

type Props = {
    loading: boolean;
    submitError: boolean;
    errorMessage?: string;
    phoneNumber?: string;
    twoFactorAuthenticationType: TwoFactorAuthenticationType;
    onSubmit: (data: any) => void;
    onResendCode: () => any;
};

const TwoFactorAuthForm = ({
    loading,
    phoneNumber,
    submitError,
    errorMessage,
    twoFactorAuthenticationType,
    onSubmit,
    onResendCode,
}: Props) => (
    <Formik
        initialValues={{
            verificationCode: '',
        }}
        onSubmit={data => onSubmit({ code: data.verificationCode })}
    >
        {formProps => (
            <Form onSubmit={formProps.handleSubmit}>
                {submitError && <Alert color="danger">{errorMessage}</Alert>}

                <p className="font-weight-bold">Verification code</p>

                {twoFactorAuthenticationType === TwoFactorAuthenticationType.SMS && (
                    <p className="text-muted">
                        {phoneNumber &&
                            `We've sent a verification code to your mobile phone (${phoneNumber}). Please enter code below to continue.`}
                        {!phoneNumber && `Mobile phone is not set!`}
                    </p>
                )}
                <FormGroup>
                    <FormInput
                        id="verificationCode"
                        name="verificationCode"
                        type="text"
                        validate={[required]}
                        label="Verification code"
                    />
                </FormGroup>

                <Row>
                    <Col xs="6">
                        <Button type="submit" color="primary" className="px-4 mb-4 w-100" disabled={loading}>
                            Verify
                        </Button>
                    </Col>
                    {twoFactorAuthenticationType === TwoFactorAuthenticationType.SMS && phoneNumber && (
                        <Col xs="6" className="text-right">
                            <Button color="link" className="px-0" onClick={onResendCode} disabled={loading}>
                                Resend code
                            </Button>
                        </Col>
                    )}
                </Row>
            </Form>
        )}
    </Formik>
);

export default TwoFactorAuthForm;
