import { Node, Element, Editor, Transforms, Range, Point } from 'slate';
import { elementMatcher } from './utils';

export const withParagraph = <T extends Editor>(editor: T): T => {
    const { normalizeNode, deleteForward, deleteBackward } = editor;

    /*
     * When deleting forward at the end of paragraph slate tries to merge it with the
     * next block sometimes causing strange behaviour - e.g. citation block header extracted
     * into paragraph and citation distorted. So next functions are a fix of that for specific cases -
     * when the paragraph is empty and user usually wants to remove the paragraph his in.
     */

    // eslint-disable-next-line no-param-reassign
    editor.deleteForward = unit => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
            const [paragraphEntry] = Editor.nodes(editor, elementMatcher('paragraph'));

            if (paragraphEntry) {
                const [, path] = paragraphEntry;
                const end = Editor.end(editor, path);

                if (Point.equals(selection.anchor, end) && Editor.string(editor, path).trim() === '') {
                    Transforms.removeNodes(editor, { at: path });
                    return;
                }
            }
        }

        deleteForward(unit);
    };

    // eslint-disable-next-line no-param-reassign
    editor.deleteBackward = unit => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
            const [paragraphEntry] = Editor.nodes(editor, elementMatcher('paragraph'));

            if (paragraphEntry) {
                const [, path] = paragraphEntry;
                const start = Editor.start(editor, path);

                if (Point.equals(selection.anchor, start) && Editor.string(editor, path).trim() === '') {
                    Transforms.removeNodes(editor, { at: path });
                    return;
                }
            }
        }

        deleteBackward(unit);
    };

    // https://docs.slatejs.org/concepts/10-normalizing
    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = ([node, path]) => {
        // If the element is a paragraph, ensure its children are valid.
        if (Element.isElement(node) && node.type === 'paragraph') {
            Array.from(Node.children(editor, path)).forEach(([child, childPath]) => {
                if (Element.isElement(child) && !editor.isInline(child)) {
                    Transforms.unwrapNodes(editor, { at: childPath });
                }
            });

            return;
        }

        // Fall back to the original `normalizeNode` to enforce other constraints.
        normalizeNode([node, path]);
    };
    return editor;
};
