import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './PageHeader.scss';

type Props = {
    title: string;
    parentTitle?: string;
    parentLink?: string;
    dataTestId?: string;
    className?: string;
};

const PageHeader = ({ parentTitle, parentLink, title, className }: Props) => {
    let parent: React.ReactNode = parentTitle;
    if (parentTitle && parentLink) {
        parent = (
            <Fragment>
                <Link to={parentLink}>{parentTitle}</Link>
                &nbsp;
                <i className="fa fa-angle-right" />
                &nbsp;
            </Fragment>
        );
    }
    return (
        <h4 className={classNames('PageHeader-title', className)}>
            {parent}
            {title}
        </h4>
    );
};

export default PageHeader;
