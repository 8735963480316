import React from 'react';
import { Formik } from 'formik';
import { WithFormProps } from 'platform/common/common.type';
import { required, url } from 'platform/common/utils/validators.util';
import FormInput from 'platform/common/components/FormInput/FormInput';
import FormRow from 'platform/common/components/FormRow/FormRow';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import CardForm from 'platform/common/components/CardForm/CardForm';
import withEditForm from 'platform/common/components/WithEditForm/WithEditForm';
import withCreateForm from 'platform/common/components/WithCreateForm/WithCreateForm';
import * as websiteService from './website.service';
import { Website } from './website.type';

const WebsiteForm = (props: WithFormProps<Website>) => {
    const { onClose, labels, initialValues, onSubmit, canEdit } = props;
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {formProps => (
                <CardForm
                    title={`${labels.prefix} Website`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    onClose={onClose}
                    disabled={!canEdit}
                    onSubmit={formProps.submitForm}
                >
                    <ControlledCard title="General info">
                        <FormRow label="Site ID">
                            <FormInput name="siteId" type="text" validate={required} />
                        </FormRow>
                        <FormRow label="Name">
                            <FormInput name="name" type="text" validate={required} />
                        </FormRow>
                        <FormRow label="URL">
                            <FormInput name="url" type="text" validate={required} />
                        </FormRow>
                        <FormRow label="Base URL">
                            <FormInput name="baseUrl" type="text" validate={[required, url]} />
                        </FormRow>
                    </ControlledCard>
                </CardForm>
            )}
        </Formik>
    );
};

const withCreateOptions = {
    onOpen: () => Promise.resolve({ name: '', url: '', baseUrl: '', siteId: null }),
    onSubmit: (model: Website) => websiteService.createWebsite(model),
};

const withEditOptions = {
    onOpen: (params: { id: string }) => websiteService.fetchWebsite(Number(params.id)),
    onSubmit: (model: Website) => websiteService.updateWebsite(model),
};

export const WebsiteFormCreate = withCreateForm(withCreateOptions)(WebsiteForm);

export const WebsiteFormEdit = withEditForm(withEditOptions)(WebsiteForm);
