import React from 'react';
import { useField } from 'formik';
import { validator, Validator } from 'platform/common/utils/validators.util';
import { FormFeedback } from 'reactstrap';
import AdvertorialEditor, { Node } from './AdvertorialEditor';

export { Node };

type Props = {
    validate?: Validator | Validator[];
    name: string;
    placeholder?: string;
    onPreview: () => void;
    streamingLink: string;
};

const FormAdvertorialEditor = ({ validate, name, placeholder, onPreview, streamingLink }: Props) => {
    const [field, meta, helpers] = useField<Node[]>({
        name,
        validate: validate && validator(validate),
    });

    const isInvalid = Boolean(meta.touched && meta.error);
    return (
        <div>
            <AdvertorialEditor
                name={name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={helpers.setValue}
                onPreview={onPreview}
                placeholder={placeholder}
                streamingLink={streamingLink}
            />
            {isInvalid && <FormFeedback>{meta.error}</FormFeedback>}
        </div>
    );
};

export default FormAdvertorialEditor;
