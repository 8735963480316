const CHAR_TO_PIXELS_MAP: { [key: string]: number | undefined } = Object.freeze({
    ' ': 4,
    ',': 4,
    '!': 4,
    '<': 9,
    '>': 9,

    '0': 8,
    '1': 6,
    '2': 8,
    '3': 9,
    '4': 9,
    '5': 8,
    '6': 9,
    '7': 8,
    '8': 9,
    '9': 9,

    a: 7,
    b: 8,
    c: 7,
    d: 8,
    e: 8,
    f: 5,
    g: 8,
    h: 7,
    i: 4,
    j: 4,
    k: 7,
    l: 4,
    m: 11,
    n: 8,
    o: 8,
    p: 8,
    q: 8,
    r: 5,
    s: 6,
    t: 5,
    u: 8,
    v: 7,
    w: 10,
    x: 7,
    y: 7,
    z: 7,

    A: 9,
    B: 9,
    C: 9,
    D: 9,
    E: 8,
    F: 7,
    G: 9,
    H: 10,
    I: 4,
    J: 7,
    K: 9,
    L: 7,
    M: 11,
    N: 9,
    O: 9,
    P: 8,
    Q: 9,
    R: 8,
    S: 8,
    T: 8,
    U: 9,
    V: 9,
    W: 12,
    X: 9,
    Y: 8,
    Z: 8,
});

export const calculateColumnWidth = (columnText: string): number =>
    columnText.split('').reduce((acc, char) => acc + (CHAR_TO_PIXELS_MAP[char] || 9), 0);
