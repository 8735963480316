import { groupBy } from 'lodash-es';
import { AccessLevel, MODULES } from './roles.constant';
import { AuthorityConnector } from './authorityConnector.constant';

export type ModuleAccessLevel = { module: MODULES; accessLevel: string; moduleIsEditable: boolean };

const findConnectorByModule = (connectors: AuthorityConnector[], module: string) =>
    connectors.find(connector => connector.module === module);

const findAuthoritiesByModuleAccessLevel = (
    connectors: AuthorityConnector[],
    module: string,
    accessLevel: string
) => {
    const connector = findConnectorByModule(connectors, module);
    if (!connector) {
        return [];
    }
    if (accessLevel === AccessLevel.VIEW_AND_EDIT) {
        if (!connector.editAuthority) {
            throw new Error('Connector missing edit authority');
        }
        return [connector.editAuthority];
    }
    if (accessLevel === AccessLevel.VIEW) {
        return [connector.viewAuthority];
    }
    return [];
};

export const mapModuleAccessLevelsToAuthorities = (
    connectors: AuthorityConnector[],
    moduleAccessLevels: ModuleAccessLevel[] = []
): string[] =>
    moduleAccessLevels.reduce<string[]>(
        (accessLevels, moduleAccessLevel) =>
            accessLevels.concat(
                findAuthoritiesByModuleAccessLevel(
                    connectors,
                    moduleAccessLevel.module,
                    moduleAccessLevel.accessLevel
                )
            ),
        []
    );

export const mapAuthoritiesToModuleAccessLevels = (
    connectors: AuthorityConnector[],
    modules: { [key: string]: string },
    authorities: string[] = []
): ModuleAccessLevel[] =>
    Object.values(modules).map((module: any) => {
        const connector = findConnectorByModule(connectors, module);
        if (!connector) {
            throw new Error('Connector not found');
        }

        const hasView = authorities.some(authority => authority === connector.viewAuthority);
        const hasEdit = authorities.some(authority => authority === connector.editAuthority);
        const moduleIsEditable = !!connector.editAuthority;

        let accessLevel = AccessLevel.NO_ACCESS;
        if (hasEdit) {
            accessLevel = AccessLevel.VIEW_AND_EDIT;
        } else if (hasView) {
            accessLevel = AccessLevel.VIEW;
        }

        return { module, accessLevel, moduleIsEditable };
    });

export const rolesByGroup = (
    moduleGroups: { [key: string]: string[] },
    moduleAccessLevels: ModuleAccessLevel[]
) =>
    groupBy(moduleAccessLevels, mal =>
        Object.keys(moduleGroups).find(key => moduleGroups[key].includes(mal.module))
    );
