import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { routerActions } from 'connected-react-router';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { useCancelableEffect } from 'platform/common/hooks/useCancelableEffect';
import { TableCell } from 'platform/common/common.type';
import { useModal } from 'platform/common/components/Modal/Modal';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import StateBadge from 'platform/common/components/StateBadge/StateBadge';
import ConfirmationModal from 'platform/common/components/ConfirmationModal/ConfirmationModal';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import { DATA_TYPES } from 'platform/common/dataType';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import LabelWithSubtext from 'platform/common/components/LabelWithSubtext/LabelWithSubtext';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import { RootState } from 'platform/rootState.type';
import { actions as authActions, selectors as authSelectors } from 'platform/app/auth.duck';
import { formatDateTime } from 'platform/common/utils/date.util';
import { UserFormCreate, UserFormEdit } from './UserForm';
import { USER_STATE_OPTIONS, UserState } from './userStates.constant';
import { User } from './user.type';
import * as userService from '../userManagement.service';

type State = {
    loading: boolean;
    data: User[];
    states: UserState[];
    searchQuery: string;
};

const UserList = () => {
    const dispatch = useDispatch();
    const canImpersonate = useSelector((rootState: RootState) =>
        authSelectors.canImpersonate(rootState.session)
    );
    const { showModal } = useModal();

    const [state, setState] = useState<State>({
        loading: true,
        data: [],
        searchQuery: '',
        states: [UserState.ACTIVE],
    });

    const fetchUsersData = (states: string[], searchQuery: string) => {
        setState(currentState => ({ ...currentState, loading: true }));
        userService
            .fetchUsersWithSeats({ states, name: searchQuery.toLowerCase() })
            .then(response => {
                setState(currentState => ({ ...currentState, data: response }));
            })
            .finally(() => {
                setState(currentState => ({ ...currentState, loading: false }));
            });
    };

    useCancelableEffect(() => {
        fetchUsersData(state.states, state.searchQuery);
    }, [state.searchQuery, state.states]);

    const columnDefinitions = [
        {
            Header: 'Status',
            accessor: 'status',
            type: DATA_TYPES.TEXT,
            width: 80,
            Cell: (cell: TableCell<User>) => <StateBadge state={cell.value} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'Name',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Email',
            accessor: 'login',
            type: DATA_TYPES.TEXT,
            minWidth: 250,
        },
        {
            Header: 'Seat',
            accessor: 'seatTitle',
            type: DATA_TYPES.TEXT,
            minWidth: 150,
        },
        {
            Header: 'Role',
            accessor: 'roleTitle',
            type: DATA_TYPES.TEXT,
            minWidth: 150,
        },
        {
            Header: 'Last activity',
            width: 180,
            Cell: (props: TableCell<User>) => {
                const user = props.original;
                const formatActivityDate = (date?: string) => (date ? formatDateTime(date) : 'Never');

                return (
                    <LabelWithSubtext
                        label={formatActivityDate(user.lastSuccessfulLogin)}
                        subtext={`Tried: ${formatActivityDate(user.lastLoginAttempt)}`}
                    />
                );
            },
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 160,
            Cell: (cell: TableCell<User>) => (
                <UpdatedOn date={cell.original.updatedOn} updatedBy={cell.original.updatedUserName} />
            ),
        },
        {
            className: () => 'edit-dropdown pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: (cell: TableCell<User>) => {
                const user = cell.original;
                const items = [
                    {
                        label: 'Edit',
                        action: () => dispatch(routerActions.push(`/user-management/users/${user.id}`)),
                    },
                ] as PopoverDropdownItem[];

                if ([UserState.ARCHIVED, UserState.PENDING_INACTIVE].includes(user.status)) {
                    items.push({
                        label: 'Delete',
                        action: () =>
                            showModal(toggle => (
                                <ConfirmationModal
                                    isOpen
                                    toggle={toggle}
                                    onOk={async () => {
                                        await userService.deleteUser(user.id);
                                        toggle();
                                        fetchUsersData(state.states, state.searchQuery);
                                    }}
                                    title="Delete user"
                                    okLabel="Delete"
                                >
                                    {`User '${user.login}' will be deleted. You will not be able to undo this action.`}
                                </ConfirmationModal>
                            )),
                    });
                }

                if (canImpersonate && user.status === UserState.ACTIVE) {
                    items.push({
                        label: 'Impersonate',
                        action: () => dispatch(authActions.impersonate(user.login)),
                    });
                }

                if (user.status === UserState.PENDING || user.status === UserState.PENDING_INACTIVE) {
                    items.push({
                        label: 'Resend invitation',
                        action: () => dispatch(authActions.resendInvitation(user)),
                    });
                }

                return <InlineDropdown items={items} />;
            },
        },
    ];

    return (
        <div>
            <ListFilters>
                <SelectWithAddon
                    name="Status"
                    value={state.states}
                    options={USER_STATE_OPTIONS}
                    onChange={states => setState(currentState => ({ ...currentState, states }))}
                    isMulti
                />
            </ListFilters>
            <div className="mb-4">
                <ListToolbar
                    value={state.searchQuery}
                    onCreate={() => {
                        dispatch(routerActions.push('/user-management/users/new'));
                    }}
                    onSearch={searchQuery => setState(currentState => ({ ...currentState, searchQuery }))}
                />
            </div>
            <FormattedTable
                className="-striped"
                columns={columnDefinitions}
                data={state.data}
                loading={state.loading}
            />
            <Switch>
                <Route
                    path={'/user-management/users/new'}
                    render={props => (
                        <UserFormCreate
                            {...props}
                            redirectTo={'/user-management/users'}
                            canEdit
                            afterSubmit={(user: User) =>
                                dispatch(routerActions.push(`/user-management/users/${user.id}`))
                            }
                        />
                    )}
                />
                <Route
                    path={'/user-management/users/:id'}
                    render={props => (
                        <UserFormEdit
                            {...props}
                            redirectTo={'/user-management/users'}
                            canEdit
                            onClose={() => fetchUsersData(state.states, state.searchQuery)}
                        />
                    )}
                />
            </Switch>
        </div>
    );
};

export default UserList;
