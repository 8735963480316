import React from 'react';
import { FormFeedback } from 'reactstrap';
import { useField } from 'formik';
import { validator, Validator } from 'platform/common/utils/validators.util';
import UploadInput from '../UploadInput/UploadInput';
import { ReadFileAs } from '../../utils/file.util';

type Props = {
    acceptableMimeTypes: string;
    subtitle?: string;
    readFileAs?: ReadFileAs;
    disabled?: boolean;
    validate?: Validator | Validator[];
    name: string;
};

const FormUploadInput = ({
    subtitle = '',
    disabled = false,
    acceptableMimeTypes,
    readFileAs,
    validate,
    name,
}: Props) => {
    const [{ value }, meta, helpers] = useField({
        name,
        validate: validate && validator(validate),
    });
    const isInvalid = Boolean(meta.touched && meta.error);

    return (
        <div>
            <UploadInput
                subtitle={subtitle}
                acceptableMimeTypes={acceptableMimeTypes}
                disabled={disabled}
                invalid={isInvalid}
                readFileAs={readFileAs}
                onFileUpload={helpers.setValue}
                value={value}
            />
            {isInvalid && <FormFeedback>{meta.error}</FormFeedback>}
        </div>
    );
};

export default FormUploadInput;
