import { DeepPartial, Store } from 'redux';
import { Action } from 'platform/common/common.type';
import { RootState } from './rootState.type';
import localStorage from './common/utils/localStorage.util';
import { currentTimeSeconds } from './common/utils';

const isAuthenticated = ({ token, tokenExpires }: { token?: string; tokenExpires: number }) => {
    if (!token) {
        return false;
    }
    const currentTime = currentTimeSeconds();
    const tokenExpired = tokenExpires < currentTime;
    return !tokenExpired;
};

export const loadStateFromStorage = (): DeepPartial<RootState> => {
    const session = localStorage.get('session');

    return {
        session: isAuthenticated(session || {}) ? session : undefined,
    };
};

export const handleStateToStorageSync = (globalStore: Store<RootState, Action>) => {
    let prevState: RootState;

    globalStore.subscribe(() => {
        const state = globalStore.getState();

        const session = state.session;
        const prevSession = prevState && prevState.session;
        if (session !== prevSession) {
            localStorage.set('session', session);
        }

        prevState = state;
    });
};
