import axios from 'axios';
import { Store } from 'redux';
import qs from 'query-string';
import { actions as authActions } from 'platform/app/auth.duck';
import { generateTraceHeaders } from './common/utils/trace.util';
import { Action } from './common/common.type';

export const axiosInstance = axios.create({
    paramsSerializer: params => qs.stringify(params),
    baseURL: '/bff',
});

let previousAccessToken: string | undefined;

export const setAxiosAccessToken = (store: Store<any, Action>) => {
    store.subscribe(() => {
        const accessToken = store.getState().session.token;
        if (accessToken && previousAccessToken !== accessToken) {
            previousAccessToken = accessToken;
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        }
    });

    axiosInstance.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 401) {
                store.dispatch(authActions.logout());
            }
            return Promise.reject(error);
        }
    );
};

axiosInstance.interceptors.request.use(config => {
    Object.assign(config.headers, generateTraceHeaders());
    return config;
});

export default axiosInstance;
