import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';
import { TwoFactorAuthenticationType } from 'platform/userManagement/userManagement.constant';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import SetPasswordForm from './SetPasswordForm';
import LoginLogo from './LoginLogo';
import { actions as authActions } from '../../auth.duck';
import TwoFactorSetupForm from './TwoFactorSetupForm';
import TwoFactorAuthForm from './TwoFactorAuthForm';

type Props = {
    loading: boolean;
    submitError: boolean;
    errorMessage?: string;
    recoveryLinkSent: boolean;
    phoneNumber?: string;
    twoFactorAuthenticationType: TwoFactorAuthenticationType;
    actions: {
        login: typeof authActions.login;
        sendPasswordResetEmail: typeof authActions.sendPasswordResetEmail;
        setPassword: typeof authActions.setPassword;
        verify2FaCode: typeof authActions.verify2FaCode;
        fetch2FaCode: typeof authActions.fetch2FaCode;
        send2FaSmsCode: typeof authActions.send2FaSmsCode;
        confirm2FaSetup: typeof authActions.confirm2FaSetup;
    };
};

class LoginPage extends Component<Props> {
    createLoginForm = () => (
        <LoginForm
            loading={this.props.loading}
            submitError={this.props.submitError}
            errorMessage={this.props.errorMessage}
            onSubmit={this.props.actions.login}
        />
    );

    createForgotPasswordForm = () => (
        <ForgotPasswordForm
            recoveryLinkSent={this.props.recoveryLinkSent}
            loading={this.props.loading}
            submitError={this.props.submitError}
            onSubmit={this.props.actions.sendPasswordResetEmail}
        />
    );

    createSetPasswordForm = ({ match }: any) => (
        <SetPasswordForm
            activationKey={match.params.activationKey}
            loading={this.props.loading}
            errorMessage={this.props.errorMessage}
            submitError={this.props.submitError}
            onSubmit={this.props.actions.setPassword}
        />
    );

    createResetPasswordForm = ({ match }: any) => (
        <SetPasswordForm
            resetPassword
            activationKey={match.params.activationKey}
            loading={this.props.loading}
            errorMessage={this.props.errorMessage}
            submitError={this.props.submitError}
            onSubmit={this.props.actions.setPassword}
        />
    );

    createTwoFactorSetupForm = ({ match }: any) => (
        <TwoFactorSetupForm
            activationKey={match.params.activationKey}
            loading={this.props.loading}
            errorMessage={this.props.errorMessage}
            submitError={this.props.submitError}
            onSubmit={this.props.actions.confirm2FaSetup}
            getCode={this.props.actions.fetch2FaCode}
        />
    );

    createTwoFactorAuthForm = () => (
        <TwoFactorAuthForm
            loading={this.props.loading}
            errorMessage={this.props.errorMessage}
            submitError={this.props.submitError}
            phoneNumber={this.props.phoneNumber}
            twoFactorAuthenticationType={this.props.twoFactorAuthenticationType}
            onSubmit={this.props.actions.verify2FaCode}
            onResendCode={this.props.actions.send2FaSmsCode}
        />
    );

    render() {
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup className="mb-0">
                                <Card className="p-4">
                                    <CardBody className="card-body">
                                        <div className="mb-4">
                                            <LoginLogo />
                                        </div>

                                        <Switch>
                                            <Route exact path="/login" component={this.createLoginForm} />
                                            <Route
                                                exact
                                                path="/login/forgot-password"
                                                component={this.createForgotPasswordForm}
                                            />
                                            <Route
                                                exact
                                                path="/login/set-password/:activationKey"
                                                component={this.createSetPasswordForm}
                                            />
                                            <Route
                                                exact
                                                path="/login/password-reminder/:activationKey"
                                                component={this.createResetPasswordForm}
                                            />
                                            <Route
                                                exact
                                                path="/login/activate-2FA/:activationKey"
                                                component={this.createTwoFactorSetupForm}
                                            />
                                            <Route
                                                exact
                                                path="/login/2fa"
                                                component={this.createTwoFactorAuthForm}
                                            />
                                        </Switch>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default LoginPage;
