import React, { ChangeEvent } from 'react';
import { Input, Label } from 'reactstrap';
import classNames from 'classnames';
import './Switch.scss';

type Props = {
    name?: string;
    className?: string;
    disabled?: boolean;
    onChange: (e: string | ChangeEvent<any>) => void;
    value: boolean;
};

const Switch = (props: Props) => {
    const { name, className, value, onChange, ...rest } = props;
    return (
        <Label className={classNames('Switch', className, { disabled: props.disabled, checked: value })}>
            <Input
                name={name}
                type="checkbox"
                className="Switch-input"
                checked={value}
                onChange={onChange}
                {...rest}
            />
            <span className="Switch-label" />
            <span className="Switch-handle" />
        </Label>
    );
};

export default Switch;
