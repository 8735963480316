import React from 'react';
import { Editor, Text, Transforms } from 'slate';
import { ReactEditor, useEditor, useFocused, useSelected, useSlate } from 'slate-react';
import { Button as ReactstrapButton, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import FormRow from 'platform/common/components/FormRow/FormRow';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { useModal } from 'platform/common/components/Modal/Modal';
import { toBlockAlignmentClass } from 'platform/advertorial/AdvertorialEditor/alignment';
import FormButtonArray from 'platform/common/components/FormButtonArray/FormButtonArray';
import { Form, Formik, FormikProps } from 'formik';
import {
    Alignment,
    blockAlignmentButtons,
    NodeWithAlignment,
} from 'platform/advertorial/AdvertorialEditor/common.types';
import { required } from 'platform/common/utils/validators.util';
import { Float, getFloatClasses } from 'platform/advertorial/AdvertorialEditor/float';
import FormSelect from 'platform/common/components/FormSelect/FormSelect';
import FormInput from 'platform/common/components/FormInput/FormInput';
import BlockControlls from './BlockControlls';
import { Button, Icon } from './components';
import { elementMatcher, getActiveEntryOfType, handleDragStart } from './utils';

export enum ShariaVia {
    FACEBOOK = 'FACEBOOK',
    TWITTER = 'TWITTER',
    MAIL = 'MAIL',
    WHATSAPP = 'WHATSAPP',
}

export const SHARIA_VIA_SETTINGS: {
    [key: string]: {
        image: string;
        getURL: ({
            encodedDescription,
            encodedSubject,
        }: {
            encodedDescription: string;
            encodedSubject: string;
        }) => string;
        label: string;
        name: string;
    };
} = {
    [ShariaVia.FACEBOOK]: {
        image:
            'https://s3-eu-west-1.amazonaws.com/sdo-prod-templates/advertorial/images/4df4d892-97b3-4b88-b45d-40f130b214f3_facebook_square.png',
        getURL: () => `https://www.facebook.com/sharer/sharer.php?u=[advertorialURL]`,
        label: 'Share via facebook',
        name: 'facebook',
    },
    [ShariaVia.TWITTER]: {
        image:
            'https://s3-eu-west-1.amazonaws.com/sdo-prod-templates/advertorial/images/f319555e-dd5f-49b9-918b-8aecc501565a_Twitter.png',
        getURL: ({ encodedDescription }: { encodedDescription: string }) =>
            `https://twitter.com/intent/tweet?url=[advertorialURL]&text=${encodedDescription}`,
        label: 'Share via twitter',
        name: 'twitter',
    },
    [ShariaVia.MAIL]: {
        image:
            'https://s3-eu-west-1.amazonaws.com/sdo-prod-templates/advertorial/images/600ba8a8-8fb2-42a2-8db7-4d018c9b0d65_mail-icon.png',
        getURL: ({
            encodedDescription,
            encodedSubject,
        }: {
            encodedDescription: string;
            encodedSubject: string;
        }) => `mailto:?subject=${encodedSubject}&body=[advertorialURL] ${encodedDescription}`,
        label: 'Share via mail',
        name: 'mail',
    },
    [ShariaVia.WHATSAPP]: {
        image:
            'https://s3-eu-west-1.amazonaws.com/sdo-prod-templates/advertorial/images/5eeab655-8aee-4f7d-9ac4-3095c209ce9e_whatsapp.png',
        getURL: () => `https://api.whatsapp.com/send?text=[advertorialURL]`,
        label: 'Share via Whatsapp',
        name: 'whatsapp',
    },
};

type ShareButtonParams = NodeWithAlignment & {
    streamingLink: string;
    shareVia: ShariaVia[];
    description?: string;
    subject?: string;
    buttons: {
        image?: string;
        url?: string;
        label?: string;
    }[];
};

type ShareButtonElement = NodeWithAlignment &
    ShareButtonParams & {
        type: 'share-button';
        children: Text[];
    };

const insertShareButton = (editor: Editor, params: ShareButtonParams) => {
    const shareButtonNode: ShareButtonElement = {
        type: 'share-button',
        children: [{ text: '' }],
        ...params,
    };
    Transforms.insertNodes(editor, shareButtonNode);
};

const ShareButtonModal = ({
    initial,
    isOpen,
    toggle,
    done,
}: {
    isOpen: boolean;
    toggle: () => void;
    done: (params: ShareButtonParams) => void;
    initial: ShareButtonParams;
}) => (
    <Modal style={{ maxWidth: 600 }} isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Share button</ModalHeader>
        <ModalBody>
            <Formik
                initialValues={initial}
                onSubmit={async params => {
                    done({
                        ...params,
                        buttons: params.shareVia.map(shareVia => {
                            const { image, getURL, label } = SHARIA_VIA_SETTINGS[shareVia];
                            return {
                                image,
                                label,
                                url: getURL({
                                    encodedSubject: params.subject ? encodeURIComponent(params.subject) : '',
                                    encodedDescription: params.description
                                        ? encodeURIComponent(params.description)
                                        : '',
                                }),
                            };
                        }),
                    });
                }}
            >
                {(formProps: FormikProps<ShareButtonParams>) => (
                    <Form>
                        {formProps.isSubmitting && <OverlayLoader />}
                        <FormRow label="Share via">
                            <FormSelect
                                name="shareVia"
                                options={Object.keys(SHARIA_VIA_SETTINGS).map(key => ({
                                    value: key,
                                    label: SHARIA_VIA_SETTINGS[key].label,
                                }))}
                                validate={required}
                                isMulti
                            />
                        </FormRow>
                        {formProps.values.shareVia.some(shareVia =>
                            [ShariaVia.TWITTER, ShariaVia.MAIL].includes(shareVia)
                        ) && (
                            <FormRow label="Text">
                                <FormInput type="text" name="description" />
                            </FormRow>
                        )}
                        {formProps.values.shareVia.includes(ShariaVia.MAIL) && (
                            <FormRow label="Subject">
                                <FormInput type="text" name="subject" />
                            </FormRow>
                        )}
                        <FormRow label="Alignment">
                            <FormButtonArray name="alignment" buttons={blockAlignmentButtons} />
                        </FormRow>
                        <div className="d-flex justify-content-end">
                            <ReactstrapButton color="secondary" onClick={toggle}>
                                Cancel
                            </ReactstrapButton>
                            <ReactstrapButton className="ml-2" color="primary" type="submit">
                                Ok
                            </ReactstrapButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalBody>
    </Modal>
);

export const ShareButtonToolbarButton = ({ streamingLink }: { streamingLink: string }) => {
    const editor = useSlate();
    const { showModal } = useModal();
    const ref = React.useRef<HTMLButtonElement>(null);
    return (
        <Button
            ref={ref}
            onMouseDown={(event: Event) => {
                event.preventDefault();
                const { selection } = editor;
                const entry = getActiveEntryOfType(editor, 'share-button');
                if (!entry) {
                    showModal(toggle => (
                        <ShareButtonModal
                            initial={{
                                streamingLink,
                                alignment: Alignment.right,
                                shareVia: [],
                                buttons: [],
                            }}
                            isOpen
                            toggle={toggle}
                            done={params => {
                                Transforms.select(editor, selection || [0]);
                                insertShareButton(editor, params);
                                toggle();
                            }}
                        />
                    ));
                } else {
                    const element = entry[0] as ShareButtonElement;
                    showModal(toggle => (
                        <ShareButtonModal
                            initial={element}
                            isOpen
                            toggle={toggle}
                            done={params => {
                                Transforms.select(editor, selection || [0]);
                                Transforms.setNodes(editor, params, elementMatcher('share-button'));
                                toggle();
                            }}
                        />
                    ));
                }
            }}
        >
            <Icon className="fas fa-share-square" />
            {ref.current && <UncontrolledTooltip target={ref.current}>Share via</UncontrolledTooltip>}
        </Button>
    );
};

export const ShareButtonInEditor = ({
    attributes,
    element,
    children,
}: {
    element: ShareButtonElement;
    attributes: any;
    children: React.ReactNode;
}) => {
    const selected = useSelected();
    const focused = useFocused();
    const editor = useEditor();
    const { showModal } = useModal();
    return (
        <div
            {...attributes}
            className={`mb-2 d-flex ${toBlockAlignmentClass(element.alignment)} ${getFloatClasses(
                Float.none
            )}`}
        >
            <div
                className="position-relative d-block pt-4 p-2"
                contentEditable={false}
                draggable
                style={{ background: '#eee', minWidth: '140px' }}
                onDragStart={handleDragStart(editor, element)}
            >
                <BlockControlls
                    label="Share"
                    onEdit={() => {
                        const { selection } = editor;
                        showModal(toggle => (
                            <ShareButtonModal
                                initial={element}
                                isOpen
                                toggle={toggle}
                                done={params => {
                                    Transforms.select(editor, selection || [0]);
                                    Transforms.setNodes(editor, params, elementMatcher('share-button'));
                                    toggle();
                                }}
                            />
                        ));
                    }}
                    element={element}
                />
                <div
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role="button"
                    className="d-flex"
                    tabIndex={0}
                    onClick={() => {
                        Transforms.select(editor, {
                            path: ReactEditor.findPath(editor, element).concat(0),
                            offset: 0,
                        });
                    }}
                >
                    {element.buttons.map(button => (
                        <img
                            key={button.label}
                            draggable={false}
                            alt={`${button.label}`}
                            src={button.image}
                            className={`d-block `}
                            style={{
                                maxWidth: '100%',
                                boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
                                width: '38px',
                                height: '38px',
                                marginRight: '12px',
                            }}
                        />
                    ))}
                </div>
                {children}
            </div>
        </div>
    );
};

export const serializeShareButtonNode = (node: ShareButtonElement): string => {
    const shareButton = `<div>${node.buttons
        .map(
            button =>
                `<span onclick="onShareButtonClick('${button.url}')"><img style="width: 38px; height: 38px; margin-right: 12px; cursor: pointer" className="d-block"
                                                   title="${button.label}" src="${button.image}"></span>`
        )
        .join('\n')}</div>`;
    return `<div class="sdo-image-block d-flex sdo-flex-align-${node.alignment || ''}">${shareButton}</div>`;
};
