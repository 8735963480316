import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import { toastNetworkError, isNetworkError } from 'platform/common/utils/errorMessages';

const onUnhandledRejection = (event: PromiseRejectionEvent) => {
    const error = event ? event.reason : undefined;
    if (!error) {
        return;
    }
    if (isNetworkError(error)) {
        event.preventDefault();
        toastNetworkError(error);
    } else {
        // eslint-disable-next-line no-console
        console.error(error.stack);
    }
};

const unhandledRejectionHandler = <P extends any>(WrappedComponent: React.ComponentType<P>) => (props: P) => {
    React.useEffect(() => {
        window.onunhandledrejection = onUnhandledRejection;
    }, []);

    return (
        <>
            <WrappedComponent {...props} />
            <ToastContainer autoClose={8000} closeOnClick={false} />
        </>
    );
};

export default unhandledRejectionHandler;
