import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { FormFeedback, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { validator, Validator } from 'platform/common/utils/validators.util';
import './FormColorInput.scss';
import { useField } from 'formik';

type Props = {
    disabled?: boolean;
    className?: string;
    validate?: Validator | Validator[];
    name: string;
};

const filterHash = (str?: string): string => (str && str.replace('#', '')) || '';

const FormColorInput = ({ disabled, className = '', validate, name }: Props) => {
    const [field, meta, helpers] = useField({
        name,
        validate: validate && validator(validate),
    });
    const isInvalid = Boolean(meta.touched && meta.error);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => helpers.setValue(filterHash(e.target.value));

    return (
        <InputGroup className={classNames('FormColorInput', '', className)}>
            <InputGroupAddon addonType="append">#</InputGroupAddon>
            <Input
                className="FormColorInput-textInput"
                type="text"
                invalid={isInvalid}
                disabled={disabled}
                {...field}
                onChange={onChange}
            />
            <div
                className="FormColorInput-colorPreview"
                style={{ backgroundColor: `#${(!meta.error && field.value) || 'ffffff'}` }}
            />
            {isInvalid && <FormFeedback className="w-100">{meta.error}</FormFeedback>}
        </InputGroup>
    );
};

export default FormColorInput;
