import Login from './components/LoginPage/Login';
import Profile from './components/Profile/Profile';
import { LOGIN, PROFILE } from './app.navigation';

export const login = {
    ...LOGIN,
    component: Login,
};

export const profile = {
    ...PROFILE,
    component: Profile,
};
