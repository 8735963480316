import React, { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

type Props = {
    children: React.ReactNode;
    renderTooltip: () => React.ReactElement;
    className?: string;
    placement?: 'top' | 'bottom-start' | 'left';
};

const Tooltip = ({ children, renderTooltip, className, placement = 'top' }: Props) => {
    const [ref, setRef] = useState<HTMLDivElement | null>(null);

    return (
        <div ref={setRef} className={className}>
            {children}
            {ref && (
                <UncontrolledTooltip
                    className="pl-tooltip"
                    placement={placement}
                    target={ref}
                    delay={50}
                    modifiers={{
                        preventOverflow: { enabled: false, boundariesElement: 'viewport' },
                        flip: { enabled: true, behavior: ['top', 'bottom'], boundariesElement: 'viewport' },
                    }}
                >
                    {renderTooltip()}
                </UncontrolledTooltip>
            )}
        </div>
    );
};

export default Tooltip;
