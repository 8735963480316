import React from 'react';
import { Formik } from 'formik';
import { WithFormProps } from 'platform/common/common.type';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/common/components/FormInput/FormInput';
import FormRow from 'platform/common/components/FormRow/FormRow';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import CardForm from 'platform/common/components/CardForm/CardForm';
import withEditForm from 'platform/common/components/WithEditForm/WithEditForm';
import withCreateForm from 'platform/common/components/WithCreateForm/WithCreateForm';
import { dataUrlToBase64, FileInfo } from 'platform/common/utils/file.util';
import { uploadImage } from 'platform/image/imageUpload.service';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { Advertiser } from './advertiser.type';
import * as advertiserService from './advertiser.service';

const AdvertiserForm = (props: WithFormProps<Advertiser>) => {
    const { onClose, labels, initialValues, onSubmit, canEdit } = props;
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {formProps => (
                <CardForm
                    title={`${labels.prefix} Advertiser`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    onClose={onClose}
                    disabled={!canEdit}
                    onSubmit={formProps.submitForm}
                >
                    <ControlledCard title="General info">
                        <FormRow label="Name">
                            <FormInput name="name" type="text" validate={required} />
                        </FormRow>
                        <FormRow label="URL">
                            <FormInput name="url" type="text" />
                        </FormRow>
                        <FormRow label="External ID">
                            <FormInput name="externalId" type="text" />
                        </FormRow>
                        <FormRow label="Logo">
                            <UploadInput
                                acceptableMimeTypes="image/jpeg, image/png, image/gif"
                                onFileUpload={(file: FileInfo) => {
                                    uploadImage({
                                        content: dataUrlToBase64(file.content),
                                        fileName: file.name,
                                    }).then(({ fullUrl }) => {
                                        formProps.setValues({ ...formProps.values, iconUrl: fullUrl });
                                    });
                                }}
                                subtitle="Upload jpeg/png/svg"
                            />
                        </FormRow>
                        {formProps.values.iconUrl && (
                            <FormRow label="Logo Preview">
                                <img src={formProps.values.iconUrl} alt="logo" />
                            </FormRow>
                        )}
                    </ControlledCard>
                </CardForm>
            )}
        </Formik>
    );
};

const withCreateOptions = {
    onOpen: () => Promise.resolve({ name: '', url: '' }),
    onSubmit: (model: Advertiser) => advertiserService.createAdvertiser(model),
};

const withEditOptions = {
    onOpen: (params: { id: string }) => advertiserService.fetchAdvertiser(Number(params.id)),
    onSubmit: (model: Advertiser) => advertiserService.updateAdvertiser(model),
};

export const AdvertiserFormCreate = withCreateForm(withCreateOptions)(AdvertiserForm);

export const AdvertiserFormEdit = withEditForm(withEditOptions)(AdvertiserForm);
