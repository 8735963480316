import React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { required } from 'platform/common/utils/validators.util';

type Props = {
    loading: boolean;
    submitError: boolean;
    recoveryLinkSent: boolean;
    onSubmit: (data: any) => void;
};

const ForgotPasswordForm = (props: Props) => {
    const { loading, submitError, recoveryLinkSent, onSubmit } = props;
    return (
        <Formik
            initialValues={{
                userName: '',
            }}
            onSubmit={onSubmit}
        >
            {formProps => (
                <Form onSubmit={formProps.handleSubmit}>
                    {submitError && (
                        <Alert color="danger">Provided user does not exist or an error occurred.</Alert>
                    )}

                    <p className="font-weight-bold">{recoveryLinkSent ? 'Email sent' : 'Reset password'}</p>
                    <p className="text-muted">
                        {recoveryLinkSent
                            ? "We just sent you an email with next steps. Please give it a few minutes to arrive. If you don't see the email in your inbox, be sure to check your spam folder. If you cannot find it there, please contact support."
                            : 'Enter your email address and we will send you a link to reset your password.'}
                    </p>

                    <FormGroup>
                        <FormInput
                            id="username"
                            name="username"
                            type="text"
                            leftAddOn={{
                                className: 'fa fa-user',
                            }}
                            label="Email"
                            validate={required}
                        />
                    </FormGroup>

                    <Row>
                        <Col xs="6">
                            <Button type="submit" color="primary" className="px-4" disabled={loading}>
                                {recoveryLinkSent ? 'Resend' : 'Send'}
                            </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                            <Link className="px-0" to="/login">
                                Return to login page
                            </Link>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default ForgotPasswordForm;
