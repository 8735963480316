import { applyMiddleware, compose, createStore, DeepPartial } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { RootState } from 'platform/rootState.type';
import createRootReducer from './root.reducer';
import rootSaga from './root.saga';
import { loadStateFromStorage } from './storageAndStoreSync';

export const history = createBrowserHistory();

export function configureStoreProd(initialState: DeepPartial<RootState>) {
    const reactRouterMiddleware = routerMiddleware(history);
    const sagaMiddleware = createSagaMiddleware();

    const middlewares = [reactRouterMiddleware, promise, thunk, sagaMiddleware];

    const store = createStore(
        createRootReducer(history),
        initialState as any,
        compose(applyMiddleware(...middlewares))
    );

    sagaMiddleware.run(rootSaga);

    return store;
}

function configureStoreDev(initialState: DeepPartial<RootState>) {
    const reactRouterMiddleware = routerMiddleware(history);
    const sagaMiddleware = createSagaMiddleware({
        sagaMonitor: (window as any)['__SAGA_MONITOR_EXTENSION__'],
    });

    const middlewares = [promise, thunk, reactRouterMiddleware, sagaMiddleware];

    const suppressReduxLogger = ['error', 'crit', 'warn'].includes(envConfig.LOGGING_LEVEL);
    if (!suppressReduxLogger) {
        middlewares.push(
            createLogger({
                level: 'log',
                collapsed: envConfig.REDUX_LOGGER_COLLAPSED,
            })
        );
    }

    const composeEnhancers: typeof compose =
        (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;
    const store = createStore(
        createRootReducer(history),
        initialState as any,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    sagaMiddleware.run(rootSaga);
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./root.reducer', () => {
            store.replaceReducer(createRootReducer(history));
        });
    }

    return store;
}

const initialState = loadStateFromStorage();
const globalStore = envConfig.IS_PRODUCTION
    ? configureStoreProd(initialState)
    : configureStoreDev(initialState);

export default globalStore;
