import React from 'react';
import { uniqueId } from 'lodash-es';
import ReactTableWithStickyColumns from './ReactTableWithStickyColumns';
import './ReactTableWithStickyHeader.scss';

const STICKED = 'sticked';

type Props = {
    data: Object[];
};

class ReactTableWithStickyHeader extends React.Component<Props> {
    tableId = uniqueId('FormattedTable');
    theadId = uniqueId('FormattedTable-thead');
    theadGroupId = uniqueId('FormattedTable-theadGroup');
    tableElement: HTMLElement | null;
    theadElement: HTMLElement | null;
    navbarElement: HTMLElement | null;

    // Pseudo state for the component - it is used both for manually updating DOM and giving it to ReactTable
    // props so React wouldn't override our manual changes on render
    sticked = false;
    translate: number;

    componentDidMount() {
        this.tableElement = document.getElementById(this.tableId);
        this.theadElement = document.getElementById(this.theadId);
        this.navbarElement = document.querySelector('.rt-sticky-thead-push');

        this.handleScroll();
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    getTransform = () => (this.translate ? `translateY(${this.translate}px)` : 'none');

    getTheadProps = () => ({
        id: this.theadId,
        className: this.sticked ? STICKED : '',
        style: {
            transform: this.getTransform(),
        },
    });

    getTheadGroupProps = () => ({
        id: this.theadGroupId,
        className: this.sticked ? STICKED : '',
        style: {
            transform: this.getTransform(),
        },
    });

    getTableProps = () => ({ id: this.tableId });

    getNavbarHeight = () => (this.navbarElement || {}).offsetHeight || 0;

    updateDOM = () => {
        const { theadElement, sticked } = this;
        if (!theadElement) return;

        if (sticked !== theadElement.classList.contains(STICKED)) {
            // eslint-disable-next-line no-unused-expressions
            sticked ? theadElement.classList.add(STICKED) : theadElement.classList.remove(STICKED);
        }

        if (theadElement.style.transform !== this.getTransform()) {
            theadElement.style.transform = this.getTransform();
        }

        // Optional group header. React table renders it later, so we cant get it's reference in
        // componentDidMount
        const theadGroupElement = document.getElementById(this.theadGroupId);
        if (theadGroupElement) {
            if (sticked !== theadGroupElement.classList.contains(STICKED)) {
                // eslint-disable-next-line no-unused-expressions
                sticked
                    ? theadGroupElement.classList.add(STICKED)
                    : theadGroupElement.classList.remove(STICKED);
            }
            if (theadGroupElement.style.transform !== this.getTransform()) {
                theadGroupElement.style.transform = this.getTransform();
            }
        }
    };

    handleScroll = () => {
        if (!this.tableElement) return;
        const tablePositionToViewport = this.tableElement.getBoundingClientRect().top;

        if (this.getNavbarHeight() > tablePositionToViewport) {
            this.sticked = true;
            this.translate = this.getNavbarHeight() - tablePositionToViewport - 1;
        } else {
            this.sticked = false;
            this.translate = 0;
        }
        this.updateDOM();
    };

    render() {
        return (
            <ReactTableWithStickyColumns
                {...(this.props as any)}
                getTheadGroupProps={this.getTheadGroupProps}
                getTheadProps={this.getTheadProps}
                getTableProps={this.getTableProps}
            />
        );
    }
}

export default ReactTableWithStickyHeader;
