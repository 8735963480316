import React, { ComponentProps, ComponentType } from 'react';
import classNames from 'classnames';
import memoizeOne from 'memoize-one';
import ReactTable from 'react-table';
import { noop } from 'lodash-es';
import './RecursiveTable.scss';
import TablePlaceholder from './TablePlaceholder';
import { TableColumnProcessed } from './FormattedTable';
import ReactTableWithStickyHeader from './ReactTableWithStickyHeader';
import { TableCell } from '../../common.type';

type Props = {
    columns: TableColumnProcessed[];
    elements: Record<string, any>[];
    childrenField: string;
    columnsAlignedFromIndex: number;
    offset: number;
    expandAllNodesOnDataChange: boolean;
    getTableRef: () => any;
    loading?: boolean;
    defaultSorted?: { id: string; desc: boolean }[];
    depth: number;
    className?: string;
    NoDataComponent: ComponentType<any>;
    childrenFetchFn?: (params: Object) => Promise<any[]>;
    childrenCountField?: string;
    getParentId?: (value: Record<string, any>) => number;
};

type State = {
    expandedNodes: Record<string, any>;
    allElements: Record<string, any>[];
};

const defaultProps = {
    depth: 0,
    getTableRef: noop,
};

class RecursiveTable extends React.Component<Props, State> {
    static defaultProps = defaultProps;

    state: State = {
        expandedNodes: this.props.elements.reduce((acc, elem, index) => {
            if (!elem[this.props.childrenField]) {
                return acc;
            }
            return { ...acc, [index]: this.props.expandAllNodesOnDataChange };
        }, {}),
        allElements: this.props.elements,
    };

    addExpanderColumn = memoizeOne(columns => [this.fakeExpanderColumn, this.expanderColumn, ...columns]);

    // We need fake expander to avoid additional render for React Table
    // Removing it is not an option, because if table has SubComponent, React Table automatically adds expander
    fakeExpanderColumn: TableColumnProcessed = {
        id: 'fake-expander',
        expander: true,
        show: false,
    };

    expanderColumn: TableColumnProcessed = {
        id: 'expander',
        className: 'cell-align-right RecursiveTable-expander',
        headerClassName: 'cell-align-right',
        width: 40 + this.props.depth * this.props.offset,
        Cell: ({ viewIndex, original }: TableCell<any>) => {
            const { childrenFetchFn, childrenCountField, childrenField } = this.props;
            if (childrenFetchFn && childrenCountField && !original[childrenCountField]) {
                return null;
            }
            if (!childrenFetchFn && (!original[childrenField] || !original[childrenField].length)) {
                return null;
            }
            return (
                <i
                    tabIndex={-1}
                    role="button"
                    onClick={() => this.toggleExpanded(original.id, viewIndex, original.advertiserId)}
                    className={classNames('fa', 'fa-angle-right', 'RecursiveTable-expandBtn', {
                        'RecursiveTable-expandBtn--expanded': this.state.expandedNodes[viewIndex],
                    })}
                />
            );
        },
    };

    componentDidUpdate(prevProps: Props) {
        const { elements, expandAllNodesOnDataChange, childrenField } = this.props;
        if (elements.length && elements !== prevProps.elements) {
            // eslint-disable-next-line
            this.setState(() => ({
                expandedNodes: elements.reduce((acc, elem, index) => {
                    if (!elem[childrenField]) {
                        return acc;
                    }
                    return { ...acc, [index]: expandAllNodesOnDataChange };
                }, {}),
                allElements: elements,
            }));
        }
    }

    toggleExpanded = async (id: string, index: number, advertiserId?: number) => {
        const { childrenFetchFn, childrenField, getParentId } = this.props;
        const hasChildren = this.state.allElements.find(e => e.id === id && e[childrenField].length);
        if (childrenFetchFn && !hasChildren) {
            const loadedChildren = await childrenFetchFn({ id, advertiserId });

            this.setState(state => ({
                allElements: state.allElements.map(e =>
                    loadedChildren.filter(loadedC => (getParentId ? getParentId(loadedC) : loadedC) === e.id)
                        .length
                        ? {
                              ...e,
                              [childrenField]: loadedChildren,
                          }
                        : e
                ),
            }));
        }
        this.setState(state => ({
            expandedNodes: {
                ...state.expandedNodes,
                [index]: !state.expandedNodes[index],
            },
        }));
    };

    render() {
        const { depth, elements, className, childrenField, offset, columns } = this.props;

        const isRootLevel = depth === 0;

        const innerColumns = columns.map((column, index) => {
            const newColumn = { ...column };

            if (index === this.props.columnsAlignedFromIndex) {
                const width = column.width;
                const minWidth = column.minWidth;
                if (width) {
                    newColumn.width = width - offset;
                }
                if (minWidth) {
                    newColumn.minWidth = minWidth - offset;
                }
            }

            return newColumn;
        });

        const TableComponent: ComponentType<
            ComponentProps<typeof ReactTableWithStickyHeader> | ComponentProps<typeof ReactTable>
        > = isRootLevel ? ReactTableWithStickyHeader : ReactTable;

        return (
            <TableComponent
                className={classNames('-highlight RecursiveTable', isRootLevel ? className : '')}
                TheadComponent={isRootLevel ? undefined : () => <div className="RecursiveTable-header" />}
                columns={this.addExpanderColumn(this.props.columns)}
                data={this.state.allElements}
                showPagination={false}
                pageSize={elements.length}
                expanded={this.state.expandedNodes}
                onSortedChange={() => {
                    this.setState({ expandedNodes: {} });
                }}
                SubComponent={(params: any) => (
                    <RecursiveTable
                        {...this.props}
                        columns={innerColumns}
                        elements={params.original[childrenField] || []}
                        depth={depth + 1}
                    />
                )}
                ref={isRootLevel ? this.props.getTableRef : noop}
                loadingText={isRootLevel ? 'Loading...' : ''}
                noDataText={isRootLevel ? 'No rows found' : ''}
                loading={isRootLevel ? this.props.loading : false}
                LoadingComponent={TablePlaceholder}
                NoDataComponent={isRootLevel ? this.props.NoDataComponent : undefined}
                defaultSorted={this.props.defaultSorted}
                resizable={false}
            />
        );
    }
}

export default RecursiveTable;
