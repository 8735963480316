import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import QRCode from 'qrcode.react';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { required } from 'platform/common/utils/validators.util';

type Props = {
    loading: boolean;
    submitError: boolean;
    errorMessage?: string;
    activationKey: string;
    onSubmit: (activationKey: string, { code }: { code: string }) => void;
    getCode: (code: string) => any;
};

interface State {
    loading: boolean;
    secretKey?: string;
}

const TwoFactorSetupForm = ({
    loading,
    activationKey,
    submitError,
    errorMessage,
    onSubmit,
    getCode,
}: Props) => {
    const [state, setState] = useState<State>({ loading: true });
    useEffect(() => {
        getCode(activationKey)
            .then((data: any) => setState(cs => ({ ...cs, secretKey: data.value.data.secretKey })))
            .finally(() => setState(cs => ({ ...cs, loading: false })));
    }, [activationKey, getCode]);

    if (state.loading) {
        return <div>Loading...</div>;
    }
    if (!state.loading && !state.secretKey) {
        return <Alert color="danger">Error... could not get security code</Alert>;
    }
    return (
        <Formik
            initialValues={{
                code: '',
            }}
            onSubmit={data => onSubmit(activationKey, { code: data.code })}
        >
            {formProps => (
                <Form onSubmit={formProps.handleSubmit}>
                    {submitError && <Alert color="danger">{errorMessage}</Alert>}

                    <div className="text-center">
                        <QRCode value={state.secretKey} />
                    </div>
                    <hr />

                    <p className="font-weight-bold">Verification code</p>

                    <FormGroup>
                        <FormInput
                            id="code"
                            name="code"
                            type="text"
                            leftAddOn={{
                                className: 'fa fa-lock',
                            }}
                            validate={[required]}
                            label="6 digit code"
                        />
                    </FormGroup>

                    <Row>
                        <Col xs="6">
                            <Button type="submit" color="primary" className="px-4" disabled={loading}>
                                Confirm
                            </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                            <Link className="px-0" to="/login">
                                Return to login page
                            </Link>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default TwoFactorSetupForm;
