import * as React from 'react';
import { Input } from 'reactstrap';
import moment from 'moment';

type Props = {
    value: string;
    className?: string;
    onBlur: (value: string) => any;
    onFocus?: () => any;
    disabled?: boolean;
};

type State = {
    value: string;
};

class DateInput extends React.Component<Props, State> {
    state: State = {
        value: this.props.value,
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.value !== prevProps.value) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: this.props.value });
        }
    }

    onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ value: event.target.value });
    };

    onInputBlur = () => {
        const { value } = this.state;
        const momentValue = moment(value);
        if (!momentValue.isValid()) {
            this.setState({ value: this.props.value });
            return;
        }
        this.props.onBlur(value);
    };

    render() {
        return (
            <Input
                type="text"
                className={this.props.className}
                value={this.state.value}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
                onFocus={this.props.onFocus}
                disabled={this.props.disabled}
            />
        );
    }
}

export default DateInput;
