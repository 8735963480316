import React from 'react';
import { Editor, Transforms, Text } from 'slate';
import { useSelected, useFocused, useSlate, useEditor, ReactEditor } from 'slate-react';
import { Button as ReactstrapButton, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import FormRow from 'platform/common/components/FormRow/FormRow';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { useModal } from 'platform/common/components/Modal/Modal';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { FileInfo, dataUrlToBase64, getImageSize } from 'platform/common/utils/file.util';
import { uploadImage } from 'platform/advertorial/advertorial.service';
import { toBlockAlignmentClass } from 'platform/advertorial/AdvertorialEditor/alignment';
import FormInput from 'platform/common/components/FormInput/FormInput';
import FormButtonArray from 'platform/common/components/FormButtonArray/FormButtonArray';
import { Formik, FormikProps, Form } from 'formik';
import { required, urlHttps, positiveNumber } from 'platform/common/utils/validators.util';
import { UnreachableCaseError } from 'platform/common/common.type';
import Separator from 'platform/common/components/Separator/Separator';
import {
    targetOptions,
    Target,
    NodeWithAlignment,
    blockAlignmentButtons,
    Alignment,
    lengthOptions,
    LengthUnit,
} from 'platform/advertorial/AdvertorialEditor/common.types';
import { floatButtons, getFloatClasses, Float } from 'platform/advertorial/AdvertorialEditor/float';
import BlockControlls from './BlockControlls';
import { Button, Icon } from './components';
import { getActiveEntryOfType, elementMatcher, handleDragStart } from './utils';

type OriginalDimensions = { width: number; height: number };
type ImageParams = NodeWithAlignment & {
    url: string;
    dimensions: Dimensions;
    originalDimensions: OriginalDimensions;
    linkUrl?: string;
    linkTarget?: string;
    float: Float;
    widthUnit: LengthUnit;
};

const insertImage = (editor: Editor, params: ImageParams) => {
    const imageNode: ImageElement = {
        type: 'image',
        ...params,
        alignment: Alignment.left,
        children: [{ text: '' }],
    };
    Transforms.insertNodes(editor, imageNode);
};

type Dimensions =
    | {
          type: 'ORIGINAL';
      }
    | {
          type: 'FULL_WIDTH';
      }
    | {
          type: 'WIDTH';
          width: number;
      }
    | {
          type: 'HEIGHT';
          height: number;
      };

const DimensionButtons = [
    {
        value: 'ORIGINAL',
        label: 'Original',
    },
    {
        value: 'FULL_WIDTH',
        label: 'Full width',
    },
    {
        value: 'WIDTH',
        label: 'Set width',
    },
    {
        value: 'HEIGHT',
        label: 'Set height',
    },
];

const ImageModal = ({
    initial,
    isOpen,
    toggle,
    done,
}: {
    isOpen: boolean;
    toggle: () => void;
    done: (params: ImageParams) => void;
    initial?: ImageParams;
}) => {
    const [isUploading, setUploading] = React.useState(false);

    return (
        <Modal style={{ maxWidth: 600 }} isOpen={isOpen} toggle={toggle}>
            <ModalHeader>Image</ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        url: initial?.url ?? '',
                        dimensions: initial?.dimensions || { type: 'ORIGINAL' },
                        originalDimensions: initial?.originalDimensions || { width: 100, height: 100 },
                        linkUrl: initial?.linkUrl ?? '',
                        linkTarget: initial?.linkTarget ?? Target._blank,
                        float: initial?.float ?? Float.none,
                        alignment: initial?.alignment ?? Alignment.left,
                        widthUnit: initial?.widthUnit || LengthUnit.px,
                    }}
                    onSubmit={async (params, helpers) => {
                        try {
                            const { width, height } = await getImageSize(params.url);
                            done({
                                ...params,
                                originalDimensions: { width, height },
                            });
                        } catch (e) {
                            helpers.setFieldError('url', 'Image could not be loaded');
                            helpers.setSubmitting(false);
                        }
                    }}
                >
                    {(formProps: FormikProps<ImageParams>) => (
                        <Form>
                            {(isUploading || formProps.isSubmitting) && <OverlayLoader />}
                            <FormRow label="Image source url">
                                <FormInput type="text" name="url" validate={[required, urlHttps]} />
                            </FormRow>
                            <FormRow label="">
                                <UploadInput
                                    acceptableMimeTypes="image/jpeg, image/png, image/gif"
                                    onFileUpload={(file: FileInfo) => {
                                        setUploading(true);
                                        uploadImage({
                                            content: dataUrlToBase64(file.content),
                                            filename: file.name,
                                        })
                                            .then(({ fullUrl }) => {
                                                formProps.setValues({ ...formProps.values, url: fullUrl });
                                            })
                                            .finally(() => setUploading(false));
                                    }}
                                    subtitle="Upload jpeg/png/gif"
                                />
                            </FormRow>
                            <FormRow label="Image link url">
                                <FormInput type="text" name="linkUrl" validate={[urlHttps]} />
                            </FormRow>
                            <FormRow label="Link target">
                                <FormButtonArray name="linkTarget" buttons={targetOptions} />
                            </FormRow>
                            <Separator label="" />
                            <FormRow label="Dimensions">
                                <FormButtonArray name="dimensions.type" buttons={DimensionButtons} />
                            </FormRow>
                            {(() => {
                                const type = formProps.values.dimensions.type;
                                switch (type) {
                                    case 'ORIGINAL':
                                    case 'FULL_WIDTH':
                                        return null;
                                    case 'HEIGHT':
                                        return (
                                            <FormRow label="Image height (with will stay in proportion)">
                                                <FormInput
                                                    name="dimensions.height"
                                                    type="number"
                                                    validate={[required, positiveNumber]}
                                                    rightAddOn={{ title: 'px' }}
                                                />
                                            </FormRow>
                                        );
                                    case 'WIDTH':
                                        return (
                                            <>
                                                <FormRow label="Width unit">
                                                    <FormButtonArray
                                                        name="widthUnit"
                                                        buttons={lengthOptions}
                                                    />
                                                </FormRow>
                                                <FormRow label="Image width (height will stay in proportion)">
                                                    <FormInput
                                                        name="dimensions.width"
                                                        type="number"
                                                        validate={[required, positiveNumber]}
                                                        rightAddOn={{ title: formProps.values.widthUnit }}
                                                    />
                                                </FormRow>
                                            </>
                                        );
                                    default:
                                        throw new UnreachableCaseError(type);
                                }
                            })()}

                            <FormRow label="Float (text will wrap around)">
                                <FormButtonArray name="float" buttons={floatButtons} />
                            </FormRow>
                            {formProps.values.float === 'none' && (
                                <FormRow label="Alignment">
                                    <FormButtonArray name="alignment" buttons={blockAlignmentButtons} />
                                </FormRow>
                            )}
                            <div className="d-flex justify-content-end">
                                <ReactstrapButton color="secondary" onClick={toggle}>
                                    Cancel
                                </ReactstrapButton>
                                <ReactstrapButton className="ml-2" color="primary" type="submit">
                                    Ok
                                </ReactstrapButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export const ImageToolbarButton = () => {
    const editor = useSlate();
    const { showModal } = useModal();
    const ref = React.useRef<HTMLButtonElement>(null);
    return (
        <Button
            ref={ref}
            onMouseDown={(event: Event) => {
                event.preventDefault();
                const { selection } = editor;

                const entry = getActiveEntryOfType(editor, 'image');

                if (!entry) {
                    showModal(toggle => (
                        <ImageModal
                            isOpen
                            toggle={toggle}
                            done={params => {
                                Transforms.select(editor, selection || [0]);
                                insertImage(editor, params);
                                toggle();
                            }}
                        />
                    ));
                } else {
                    const element = entry[0] as ImageElement;
                    showModal(toggle => (
                        <ImageModal
                            initial={element}
                            isOpen
                            toggle={toggle}
                            done={params => {
                                Transforms.select(editor, selection || [0]);
                                Transforms.setNodes(editor, params, elementMatcher('image'));
                                toggle();
                            }}
                        />
                    ));
                }
            }}
        >
            <Icon className="fas fa-image" />
            {ref.current && <UncontrolledTooltip target={ref.current}>Image</UncontrolledTooltip>}
        </Button>
    );
};

const getStyleForDimensions = (
    dimensions: Dimensions,
    originalDimensions: OriginalDimensions,
    widthUnit: LengthUnit
) => {
    switch (dimensions.type) {
        case 'ORIGINAL':
            return originalDimensions;
        case 'FULL_WIDTH':
            return {
                width: '100%',
            };
        case 'WIDTH':
            return {
                width: `${dimensions.width}${widthUnit}`,
            };
        case 'HEIGHT':
            return {
                height: dimensions.height,
                width: (originalDimensions.width / originalDimensions.height) * dimensions.height,
            };
        default:
            throw new UnreachableCaseError(dimensions);
    }
};

const serializeDimensions = (
    dimensions: Dimensions,
    originalDimensions: OriginalDimensions,
    widthUnit: LengthUnit
) => {
    switch (dimensions.type) {
        case 'ORIGINAL':
            return `width: ${originalDimensions.width}px; height: auto`;
        case 'FULL_WIDTH':
            return 'width: 100%; height: auto;';
        case 'WIDTH':
            return `width: ${dimensions.width}${widthUnit}; height: auto;`;
        case 'HEIGHT':
            return `height: ${dimensions.height}px; width: auto;`;
        default:
            throw new UnreachableCaseError(dimensions);
    }
};

const serializeDimensionsForFigure = (
    dimensions: Dimensions,
    originalDimensions: { width: number; height: number },
    widthUnit: LengthUnit
) => {
    switch (dimensions.type) {
        case 'ORIGINAL':
            return `width: ${originalDimensions.width}px;`;
        case 'FULL_WIDTH':
            return 'width: 100%;';
        case 'WIDTH':
            return `width: ${dimensions.width}${widthUnit};`;
        case 'HEIGHT':
            return `width: ${(originalDimensions.width / originalDimensions.height) * dimensions.height}px;`;
        default:
            throw new UnreachableCaseError(dimensions);
    }
};

type ImageElement = NodeWithAlignment &
    ImageParams & {
        type: 'image';
        children: Text[];
    };

const hasCaption = (element: ImageElement): boolean =>
    !(element.children.length === 1 && element.children[0].text === '');

export const ImageInEditor = ({
    attributes,
    children,
    element,
}: {
    element: ImageElement;
    attributes: any;
    children: React.ReactNode;
}) => {
    const selected = useSelected();
    const focused = useFocused();
    const editor = useEditor();
    const { showModal } = useModal();
    return (
        <div
            {...attributes}
            className={`mb-2 d-flex position-relative ${toBlockAlignmentClass(
                element.alignment
            )} ${getFloatClasses(element.float || 'none')}`}
        >
            <figure>
                <div
                    className="position-relative"
                    contentEditable={false}
                    draggable
                    onDragStart={handleDragStart(editor, element)}
                >
                    <BlockControlls
                        label="Image"
                        onEdit={() => {
                            const { selection } = editor;
                            showModal(toggle => (
                                <ImageModal
                                    initial={element}
                                    isOpen
                                    toggle={toggle}
                                    done={params => {
                                        Transforms.select(editor, selection || [0]);
                                        Transforms.setNodes(editor, params, elementMatcher('image'));
                                        toggle();
                                    }}
                                />
                            ));
                        }}
                        element={element}
                    />
                    <img
                        draggable={false}
                        alt={`${element.url}`}
                        src={element.url}
                        className={`d-block `}
                        style={{
                            maxWidth: '100%',
                            boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
                            ...getStyleForDimensions(
                                element.dimensions || { type: 'ORIGINAL' },
                                element.originalDimensions,
                                element.widthUnit
                            ),
                        }}
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            Transforms.select(editor, {
                                path: ReactEditor.findPath(editor, element).concat(0),
                                offset: 0,
                            });
                        }}
                    />
                </div>
                {hasCaption(element) || selected ? (
                    <figcaption style={{ background: '#eee' }}>{children}</figcaption>
                ) : (
                    children
                )}
            </figure>
        </div>
    );
};

export const serializeImageNode = (node: ImageElement, children: string): string => {
    const imgTag = `<img style="${serializeDimensions(
        node.dimensions,
        node.originalDimensions,
        node.widthUnit
    )}" class="d-block" src="${node.url}">`;
    const maybeLink = node.linkUrl
        ? `<a href="${node.linkUrl}" style="${serializeDimensions(
              node.dimensions,
              node.originalDimensions,
              node.widthUnit
          )}" target="${node.linkTarget}">${imgTag}</a>`
        : imgTag;

    const maybeFigure = hasCaption(node)
        ? `<figure style="${serializeDimensionsForFigure(
              node.dimensions,
              node.originalDimensions,
              node.widthUnit
          )}">${maybeLink}<figcaption>${children}</figcaption></figure>`
        : maybeLink;

    return `<div class="sdo-image-block d-flex sdo-float-${node.float} sdo-flex-align-${node.alignment ||
        ''}">${maybeFigure}</div>`;
};

export const withImages = <T extends Editor>(editor: T): T => {
    const { normalizeNode, insertBreak } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.insertBreak = () => {
        const { selection } = editor;

        if (selection) {
            const [slideshow] = Editor.nodes(editor, { match: n => n.type === 'image' });

            if (slideshow) {
                return;
            }
        }

        insertBreak();
    };

    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = ([node, path]) => {
        if (node.type === 'image') {
            Transforms.setNodes(editor, { children: [{ text: 'Hello' }] }, { at: path });
            if (!node.alignment) {
                Transforms.setNodes(editor, { alignment: Alignment.left }, { at: path });
            }
            if (!node.dimensions) {
                Transforms.setNodes(editor, { dimensions: { type: 'ORIGINAL' } }, { at: path });
            }
            if (!node.float) {
                Transforms.setNodes(editor, { float: 'none' }, { at: path });
            }
            if (!node.originalDimensions) {
                Transforms.setNodes(
                    editor,
                    { originalDimensions: { width: 200, height: 300 } },
                    { at: path }
                );
            }
        }

        return normalizeNode([node, path]);
    };
    return editor;
};
