import React from 'react';
import classNames from 'classnames';
import { FormGroup } from 'reactstrap';
import { validator, Validator } from 'platform/common/utils/validators.util';
import './CodeMirror.scss';
import { useField } from 'formik';
import CodeMirror from './CodeMirror';

type Props = {
    options: {
        mode?: string;
        readOnly?: boolean;
        lineWrapping?: boolean;
    };
    caption?: string | Function;
    validate?: Validator | Validator[];
    name: string;
};

const FormCodeMirror = ({ options = {}, caption, validate, name }: Props) => {
    const [field, meta, helper] = useField({
        name,
        validate: validate && validator(validate),
    });
    const isInvalid = Boolean(meta.touched && meta.error);

    return (
        <FormGroup>
            <CodeMirror
                name={name}
                className={classNames('form-control', { 'is-invalid': isInvalid })}
                options={{
                    mode: options.mode || 'javascript',
                    readOnly: !!options.readOnly,
                    lineWrapping: options.lineWrapping,
                }}
                {...field}
                onChange={helper.setValue}
                onBlur={() => helper.setTouched(true)}
            />
            {caption && <small className="text-muted">{caption}</small>}
            {isInvalid && <div className="invalid-feedback pl-invalid-feedback">{meta.error}</div>}
        </FormGroup>
    );
};

export default FormCodeMirror;
