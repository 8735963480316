import { Card } from 'reactstrap';
import * as React from 'react';
import classNames from 'classnames';
import memoizeOne from 'memoize-one';
import { isNil } from 'lodash-es';
import { Modify, TableCell, TypeDefinition, Sort } from '../../common.type';
import DataExportButton, { ExportOptions } from '../DataExportButton/DataExportButton';
import RecursiveTable from './RecursiveTable';
import TablePlaceholder from './TablePlaceholder';
import { NoDataMessage } from './NoDataMessage';
import ReactTableWithStickyHeader from './ReactTableWithStickyHeader';
import './FormattedTable.scss';
import ReactTableWithStickyColumns from './ReactTableWithStickyColumns';
import { formatColumns } from './formatColumns';

export type ReactTableSort = { id: string; desc: boolean };

const toTableSort = (sort: Sort): ReactTableSort => ({
    id: sort.orderBy,
    desc: sort.direction === 'DESC',
});

const toAppSort = (sort: ReactTableSort): Sort => ({
    orderBy: sort.id,
    direction: sort.desc ? 'DESC' : 'ASC',
});

export type TableColumn = {
    Header?: string | React.ComponentType<{ formattedValue: string }>;
    HeaderText?: string;
    Footer?: string | Function;
    accessor?: string | Function;
    type?: TypeDefinition;
    headerClassName?: (className: string) => string;
    className?: (className: string) => string;
    exportOnly?: boolean;
    screenOnly?: boolean;
    sortable?: boolean;
    maxWidth?: number;
    width?: number;
    minWidth?: number;
    expander?: boolean;
    Expander?: (props: TableCell<any>) => any;
    Cell?: (props: TableCell<any>) => any;
    exportCell?: (row: any) => any;
    columns?: TableColumn[];
    sticky?: boolean;
    headerStyle?: any;
    style?: any;
    id?: string;
    show?: boolean;
    autoWidth?: boolean;
    autoWidthAdditionalWidth?: number;
    getProps?: Function;
    collapsable?: boolean;
    collapsed?: boolean;
};

export type TableColumnProcessed = Modify<
    TableColumn,
    {
        id: string;
        headerClassName?: string;
        className?: string;
        columns?: TableColumnProcessed[];
    }
>;

type Props = {
    topToolbar?: React.ReactNode;
    onExpand?: (id: number, isExpanded: boolean) => void;
    topRightToolbar?: React.ReactNode;
    visible: boolean;
    columns: TableColumn[];
    data: Object[];
    exportData?: Modify<ExportOptions, { loadData?: Function }>;
    isTree: boolean;
    childrenField: string;
    childrenOffset: number;
    columnsAlignedFromIndex: number;
    expandAllNodesOnDataChange: boolean;
    loading?: boolean;
    minRows: number;
    className?: string;
    resizable: boolean;
    showPagination: boolean;
    pageSize?: number;
    defaultPageSize: number;
    onPageSizeChange?: Function;
    onPageChange?: Function;
    onSortedChange?: (sort: Sort[]) => void;
    pages?: number;
    verticalBorders?: boolean;
    pageSizeOptions: number[];
    stickyHeader: boolean;
    NoDataComponent?: () => React.ReactNode;
    containerClass?: string;
    defaultSorted?: Sort[];
    manual?: boolean;
    multiSort?: boolean;
    sortable?: boolean;
    sorted?: Sort[];
    page?: number;
    getTdProps?: Function;
    getTrProps?: (instance: any, row: any) => any;
    getTheadTrProps?: Function;
    TrComponent?: React.ComponentType;
    TfootComponent?: React.ComponentType;
    childrenFetchFn?: (params: Object) => Promise<any[]>;
    childrenCountField?: string;
    getParentId?: (value: Record<string, any>) => number;
};

type State = {
    pageSize: number;
    collapsed: string[];
};

const filterColumnsAndChildren = (
    columns: TableColumn[],
    predicate: (tc: TableColumn) => boolean
): TableColumn[] =>
    columns.filter(predicate).map(column =>
        column.columns
            ? {
                  ...column,
                  columns: column.columns.filter(predicate),
              }
            : column
    );

const flattenGroups = (columns: TableColumn[]): TableColumn[] =>
    columns.reduce((acc, col) => {
        if (col.columns && col.columns.length) {
            return [...acc, ...col.columns];
        }
        return [...acc, col];
    }, []);

const defaultProps = {
    visible: true,
    columns: [],
    data: [],
    isTree: false,
    childrenField: 'children',
    childrenOffset: 20,
    columnsAlignedFromIndex: 4,
    expandAllNodesOnDataChange: false,
    minRows: 0,
    resizable: false,
    showPagination: true,
    defaultPageSize: 100,
    verticalBorders: false,
    pageSizeOptions: [20, 50, 100, 200],
    stickyHeader: true,
};

/**
 * React-table wrapper which formats columns/values based on column.type
 * See `dataTypes.js` for available formats.
 */
class FormattedTable extends React.Component<Props, State> {
    static defaultProps = defaultProps;

    state: State = {
        pageSize: this.props.defaultPageSize,
        collapsed: [],
    };

    tableRef: {
        state?: {
            sortedData: any[];
        };
    } | null;

    formatColumns = memoizeOne(formatColumns);

    onCollapseClick = (id: string) => () => {
        this.setState(state => {
            if (state.collapsed.includes(id)) {
                return { ...state, collapsed: state.collapsed.filter(c => c !== id) };
            }
            return { ...state, collapsed: [...state.collapsed, id] };
        });
    };

    getExportOptions = (): ExportOptions => {
        const { exportData } = this.props;
        if (!exportData) {
            throw new Error('No export options passed');
        }

        const defaultDataLoad = () => {
            const tableState = this.tableRef && this.tableRef.state;
            return tableState ? tableState.sortedData.map(row => row._original) : this.props.data;
        };

        return {
            ...exportData,
            loadData: exportData.loadData || defaultDataLoad,
        };
    };

    getScreenColumns = memoizeOne(formattedColumns =>
        filterColumnsAndChildren(formattedColumns || [], col => !col.exportOnly)
    );

    getExportColumns = memoizeOne(formattedColumns =>
        flattenGroups(filterColumnsAndChildren(formattedColumns || [], col => !col.screenOnly)).map(col => ({
            ...col,
            Header: col.HeaderText,
        }))
    );

    onPageSizeChange = (pageSize: number) => {
        if (!isNil(this.props.pageSize) && this.props.onPageSizeChange) {
            this.props.onPageSizeChange(pageSize);
            return;
        }
        this.setState({ pageSize });
    };

    renderTableComponent = (screenColumns: TableColumn[], hasToolbar: boolean) => {
        const {
            visible,
            isTree,
            childrenField,
            childrenOffset,
            columnsAlignedFromIndex,
            data,
            minRows,
            className,
            showPagination,
            pages,
            verticalBorders,
            pageSizeOptions,
            stickyHeader,
            expandAllNodesOnDataChange,
            NoDataComponent,
            onExpand,
            childrenFetchFn,
            sorted,
            defaultSorted,
            onSortedChange,
            ...rest
        } = this.props;

        if (!visible) {
            return null;
        }

        if (isTree) {
            return (
                <RecursiveTable
                    {...(rest as any)}
                    columns={screenColumns}
                    elements={data}
                    onExpand={onExpand}
                    childrenField={childrenField}
                    columnsAlignedFromIndex={columnsAlignedFromIndex}
                    offset={childrenOffset}
                    childrenFetchFn={childrenFetchFn}
                    expandAllNodesOnDataChange={expandAllNodesOnDataChange}
                    setRef={(ref: any) => {
                        this.tableRef = ref;
                    }}
                    NoDataComponent={NoDataComponent || NoDataMessage}
                    className={className}
                    sorted={sorted?.map(toTableSort)}
                    defaultSorted={defaultSorted?.map(toTableSort)}
                    onSortedChange={
                        onSortedChange
                            ? (sorts: ReactTableSort[]) => onSortedChange(sorts.map(toAppSort))
                            : undefined
                    }
                />
            );
        }

        const TableComponent = stickyHeader ? ReactTableWithStickyHeader : ReactTableWithStickyColumns;

        const pageSize = this.props.pageSize || this.state.pageSize;

        return (
            <TableComponent
                {...(rest as any)}
                columns={screenColumns}
                data={data}
                setRef={(ref: any) => {
                    this.tableRef = ref;
                }}
                minRows={minRows}
                className={classNames(
                    '-highlight',
                    {
                        '-verticalBorders': verticalBorders,
                        'mx-3': hasToolbar,
                    },
                    className
                )}
                pageSize={pageSize}
                pages={pages}
                showPagination={showPagination && (data.length > pageSize || (pages || 0) > 1)}
                onPageSizeChange={this.onPageSizeChange}
                NoDataComponent={NoDataComponent || NoDataMessage}
                LoadingComponent={TablePlaceholder}
                pageSizeOptions={pageSizeOptions}
                onExpand={undefined}
                sorted={sorted?.map(toTableSort)}
                defaultSorted={defaultSorted?.map(toTableSort)}
                onSortedChange={
                    onSortedChange
                        ? (sorts: ReactTableSort[]) => onSortedChange(sorts.map(toAppSort))
                        : undefined
                }
            />
        );
    };

    render() {
        const {
            topToolbar,
            topRightToolbar,
            exportData,
            visible,
            data,
            columns,
            loading,
            containerClass,
        } = this.props;

        const formattedColumns = this.formatColumns(
            columns,
            data,
            this.state.collapsed,
            this.onCollapseClick
        );
        const screenColumns = this.getScreenColumns(formattedColumns);
        const showExportData = exportData && visible;
        const hasToolbar = topToolbar || topRightToolbar || showExportData;

        return (
            <Card className={classNames('FormattedTable', containerClass)}>
                {hasToolbar && (
                    <div className="FormattedTable-toolbarContainer">
                        <div className="FormattedTable-leftToolbar">{topToolbar}</div>
                        <div className="FormattedTable-rightToolbarContainer">
                            <div className={classNames({ 'FormattedTable-rightToolbar': showExportData })}>
                                {topRightToolbar}
                            </div>
                            {showExportData && (
                                <DataExportButton
                                    className="FormattedTable-exportButton"
                                    disabled={loading || !data.length}
                                    columns={this.getExportColumns(formattedColumns)}
                                    exportOptions={this.getExportOptions()}
                                />
                            )}
                        </div>
                    </div>
                )}
                {this.renderTableComponent(screenColumns, !!hasToolbar)}
            </Card>
        );
    }
}

export default FormattedTable;
