import { SHARIA_VIA_SETTINGS, ShariaVia } from 'platform/advertorial/AdvertorialEditor/editorShareButton';

const onShareButtonClickStatic = `<script>
      function onShareButtonClickStatic(url) {
        window.open(url.replace('[advertorialURL]', encodeURIComponent(document.referrer)));
      }
  </script>`;

export const generateStaticSocialButtons = (subject?: string, text?: string): string => {
    const encodedDescription = text ? encodeURIComponent(text) : '';
    const encodedSubject = subject ? encodeURIComponent(subject) : '';
    const socialHtmlpart = [
        SHARIA_VIA_SETTINGS[ShariaVia.FACEBOOK],
        SHARIA_VIA_SETTINGS[ShariaVia.TWITTER],
        SHARIA_VIA_SETTINGS[ShariaVia.MAIL],
        SHARIA_VIA_SETTINGS[ShariaVia.WHATSAPP],
    ]
        .map(
            button =>
                `<span class='social-static-${
                    button.name
                }' onclick="onShareButtonClickStatic('${button.getURL({
                    encodedDescription,
                    encodedSubject,
                })}')"></span>`
        )
        .join('\n');
    return `
    ${socialHtmlpart}
    ${onShareButtonClickStatic}
    `;
};
