import { UnreachableCaseError } from 'platform/common/common.type';
import { capitalize } from 'lodash-es';

export enum Float {
    none = 'none',
    left = 'left',
    right = 'right',
}

export const floatButtons = Object.keys(Float).map(value => ({ label: capitalize(value), value }));

export const getFloatClasses = (float: Float): string => {
    switch (float) {
        case Float.left:
            return 'float-left mr-3';
        case Float.right:
            return 'float-right ml-3';
        case Float.none:
            return '';
        default:
            throw new UnreachableCaseError(float);
    }
};
