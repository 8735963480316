import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { routerActions } from 'connected-react-router';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import { DATA_TYPES } from 'platform/common/dataType';
import { TableCell } from 'platform/common/common.type';
import StateBadge from 'platform/common/components/StateBadge/StateBadge';
import { formatDateTime } from 'platform/common/utils/date.util';
import { FeedFormCreate, FeedFormEdit } from './FeedForm';
import * as feedService from './feed.service';
import { Feed } from './feed.type';

type State = {
    loading: boolean;
    data: Feed[];
    searchQuery: string;
};

const FeedList = () => {
    const dispatch = useDispatch();

    const [state, setState] = useState<State>({
        loading: true,
        data: [],
        searchQuery: '',
    });

    const fetchFeeds = () => {
        setState(currentState => ({ ...currentState, loading: true }));
        feedService
            .fetchFeeds()
            .then(response => {
                setState(currentState => ({ ...currentState, data: response }));
            })
            .finally(() => {
                setState(currentState => ({ ...currentState, loading: false }));
            });
    };

    useEffect(() => {
        fetchFeeds();
    }, []);

    const columnDefinitions = [
        {
            Header: 'Status',
            accessor: 'status',
            type: DATA_TYPES.TEXT,
            width: 80,
            Cell: (cell: TableCell<Feed>) => <StateBadge state={cell.value} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'Name',
            accessor: 'name',
            minWidth: 200,
            type: DATA_TYPES.TEXT,
            Cell: (row: TableCell<Feed>) => <span>{row.original.name}</span>,
        },
        {
            Header: 'Last import status',
            accessor: 'lastImportSuccessful',
            type: DATA_TYPES.TEXT,
            width: 150,
            Cell: (row: TableCell<Feed>) => <span>{row.original.importStatus}</span>,
        },
        {
            Header: 'Last import',
            width: 200,
            Cell: (row: TableCell<Feed>) => {
                const formatActivityDate = (date?: string) => (date ? formatDateTime(date) : 'Never');

                return <span>{formatActivityDate(row.original.lastImportOn)}</span>;
            },
        },
        {
            className: () => 'edit-dropdown pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: (row: TableCell<Feed>) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () =>
                                dispatch(routerActions.push(`/admin-panel/feeds/${row.original.id}`)),
                        },
                    ]}
                />
            ),
        },
    ];

    const filterList = (list: Feed[], searchQuery: string) => {
        if (!searchQuery) return list;
        const query = searchQuery.toLowerCase();
        return list.filter(
            r =>
                String(r.id)
                    .toLowerCase()
                    .includes(query) || r.name.toLowerCase().includes(query)
        );
    };

    return (
        <div>
            <div className="my-4">
                <PageHeader title="Feeds" />
            </div>
            <div className="mb-4">
                <ListToolbar
                    value={state.searchQuery}
                    onCreate={() => dispatch(routerActions.push('/admin-panel/feeds/new'))}
                    onSearch={searchQuery => setState(currentState => ({ ...currentState, searchQuery }))}
                />
            </div>
            <FormattedTable
                columns={columnDefinitions}
                data={filterList(state.data, state.searchQuery)}
                loading={state.loading}
            />
            <Switch>
                <Route
                    path="/admin-panel/feeds/new"
                    render={props => (
                        <FeedFormCreate
                            {...props}
                            redirectTo="/admin-panel/feeds"
                            canEdit
                            afterSubmit={(feed: Feed) =>
                                dispatch(routerActions.push(`/admin-panel/feeds/${feed.id}`))
                            }
                        />
                    )}
                />
                <Route
                    path="/admin-panel/feeds/:id"
                    render={props => (
                        <FeedFormEdit
                            {...props}
                            redirectTo="/admin-panel/feeds"
                            canEdit
                            onClose={fetchFeeds}
                        />
                    )}
                />
            </Switch>
        </div>
    );
};

export default FeedList;
