import * as React from 'react';
import { Badge } from 'reactstrap';

const STATE_INFO: { [key: string]: { label: string; color: string } } = Object.freeze({
    ACTIVE: {
        label: 'Active',
        color: 'success',
    },
    INACTIVE: {
        label: 'Inactive',
        color: 'light',
    },
    ARCHIVED: {
        label: 'Archived',
        color: 'dark',
    },
    PENDING: {
        label: 'Pending',
        color: 'warning',
    },
    PENDING_INACTIVE: {
        label: 'Pending Inactive',
        color: 'secondary',
    },
    INACTIVATED_BY_PARENT: {
        label: 'Inactivated by parent',
        color: 'light',
    },
});

type Props = {
    state?: string;
};

const StateBadge = (props: Props) => {
    const { state } = props;

    if (!state) {
        return null;
    }

    const stateInfo = STATE_INFO[state];
    const { color, label } = stateInfo || { color: 'warning', label: state };

    return (
        <Badge className="badge-outline" color={color}>
            {label}
        </Badge>
    );
};

export default StateBadge;
