import React, { ComponentType, ReactNode, useContext, useState } from 'react';

export type ShowModalType = (modalFn: (toggle: () => void) => ReactNode) => void;

export type ModalContextType = {
    showModal: ShowModalType;
};

const ModalContext = React.createContext<ModalContextType>({ showModal: () => null });

type ModalContextProviderProps = {
    children: ReactNode;
};

export const ModalProvider = (props: ModalContextProviderProps) => {
    const [modal, setModal] = useState<ReactNode | undefined>(undefined);

    const toggle = () => {
        setModal(undefined);
    };

    const showModal: ShowModalType = modalFn => {
        setModal(modalFn(toggle));
    };

    return (
        <ModalContext.Provider value={{ showModal }}>
            {props.children}
            {modal}
        </ModalContext.Provider>
    );
};

export type withModalInjectedProps = ModalContextType;

export const withModal = <P extends withModalInjectedProps>(Component: ComponentType<P>) => (
    props: Pick<P, Exclude<keyof P, keyof withModalInjectedProps>>
) => (
    <ModalContext.Consumer>
        {modalContext => <Component {...(props as P)} {...modalContext} />}
    </ModalContext.Consumer>
);

export const useModal = () => useContext(ModalContext);
