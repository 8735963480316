import * as React from 'react';
import { connect } from 'react-redux';
import { Location } from 'history';
import { push } from 'connected-react-router';
import { match as Match } from 'react-router-dom';
import { identity } from 'lodash-es';
import { Dispatch } from 'redux';
import { isNetworkError, toastNetworkError } from 'platform/common/utils/errorMessages';
import { getWrappedComponentDisplayName } from '../../utils';
import SidePanel from '../SidePanel/SidePanel';
import withErrorBoundary from '../WithErrorBoundary/WithErrorBoundary';
import CardFormPlaceholder from '../CardForm/CardFormPlaceholder';

type Props = {
    onOpen: ({ id }: { id: string }, match: any, location: any) => any;
    onSubmit: (model: any) => Promise<any>;
    size?: number;
    sidePanel?: boolean;
    submitLabel?: string;
};

type WithEditFormProps = {
    // props on component
    redirectTo: string;
    afterSubmit: (model: any) => void;
    canEdit: boolean;
    onClose?: () => void;
    // from router
    match: Match<any>;
    location: Location<any>;

    // from redux state
    dispatch: Dispatch;
};

type WithEditFormState = {
    isFirstRender: boolean;
    initialValues: any;
    loading: boolean;
};

const withEditForm = ({ onOpen, onSubmit, size, sidePanel = true, submitLabel }: Props) => (
    WrappedForm: React.ComponentType<any>
) => {
    const WrappedFormWithErrorBoundary = withErrorBoundary(WrappedForm);

    const Form = ({ loading, canEdit, onUpdate, redirect, isFirstRender, onClose, ...rest }: any) => (
        <React.Fragment>
            {loading || isFirstRender ? (
                <CardFormPlaceholder />
            ) : (
                <WrappedFormWithErrorBoundary
                    {...(rest as any)}
                    isEdit
                    enableReinitialize
                    labels={{
                        submit: submitLabel || 'Update',
                        prefix: canEdit ? 'Edit' : 'View',
                    }}
                    onSubmit={onUpdate}
                    onClose={() => {
                        if (onClose) onClose();
                        redirect();
                    }}
                    canEdit={canEdit}
                    errorEscape={redirect}
                />
            )}
        </React.Fragment>
    );

    class WithEditForm extends React.Component<WithEditFormProps, WithEditFormState> {
        static displayName = getWrappedComponentDisplayName(WrappedForm, WithEditForm.name);
        static defaultProps = {
            afterSubmit: identity,
        };

        state: WithEditFormState = {
            isFirstRender: true,
            loading: true,
            initialValues: null,
        };

        componentDidMount() {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ isFirstRender: false });

            if (this.id) {
                const promise = onOpen({ id: this.id }, this.props.dispatch, this.props.match);
                if (promise) {
                    promise
                        .then((initialValues: any) => {
                            this.setState(currentState => ({ ...currentState, initialValues }));
                        })
                        .finally(() => {
                            this.setState(currentState => ({ ...currentState, loading: false }));
                        });
                }
            }
        }

        id = this.props.match.params.id || '';

        redirect = () => {
            this.props.dispatch(push(this.props.redirectTo));
        };

        update = (model: any) =>
            onSubmit(model)
                .then(() => this.props.afterSubmit(model))
                .catch(error => {
                    if (isNetworkError(error)) {
                        toastNetworkError(error);
                    } else {
                        // eslint-disable-next-line no-console
                        console.error(error.stack);
                    }
                });

        render() {
            const { canEdit, ...rest } = this.props;
            const { loading, initialValues } = this.state;

            return (
                <React.Fragment>
                    {sidePanel ? (
                        <SidePanel onEscClick={this.redirect} size={size}>
                            <Form
                                loading={loading}
                                canEdit={canEdit}
                                onUpdate={this.update}
                                redirect={this.redirect}
                                isFirstRender={this.state.isFirstRender}
                                initialValues={initialValues}
                                {...rest}
                            />
                        </SidePanel>
                    ) : (
                        <Form
                            loading={loading}
                            canEdit={canEdit}
                            onUpdate={this.update}
                            redirect={this.redirect}
                            isFirstRender={this.state.isFirstRender}
                            initialValues={initialValues}
                            {...rest}
                        />
                    )}
                </React.Fragment>
            );
        }
    }

    return connect()(WithEditForm);
};

export default withEditForm;
