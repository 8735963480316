import React from 'react';
import { Validator } from 'platform/common/utils/validators.util';
import FormSelect from '../FormSelect/FormSelect';
import { STATE_OPTIONS, STATE_TRANSITIONS } from '../../constants/states.constant';
import './FormStateSelect.scss';

type Props = {
    initialValue?: string;
    options: { label: string; value: string }[];
    transitions: { [key: string]: string[] };
    id?: string;
    disabled: boolean;
    name: string;
    validate?: Validator | Validator[];
};

const defaultProps = {
    options: STATE_OPTIONS,
    transitions: STATE_TRANSITIONS,
    disabled: false,
};

const FormStateSelect = ({ options, transitions, initialValue, id, disabled, name, validate }: Props) => {
    const getOptions = (currentValue?: string) => {
        if (!currentValue) {
            return options;
        }

        const availableTransitions = transitions[currentValue];
        return options.map(option => ({
            ...option,
            disabled: !availableTransitions.includes(option.value) && option.value !== initialValue,
        }));
    };

    return (
        <FormSelect
            id={id || name}
            name={name}
            options={getOptions(initialValue)}
            isSearchable={false}
            isClearable={false}
            disabled={disabled}
            className="pl-stateSelect"
            validate={validate}
        />
    );
};

FormStateSelect.defaultProps = defaultProps;

export default FormStateSelect;
