import React from 'react';
import { Editor, Transforms, Node } from 'slate';
import { useSelected, useFocused, useSlate } from 'slate-react';
import { Button as ReactstrapButton, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { useModal } from 'platform/common/components/Modal/Modal';
import { getActiveEntryOfType, elementMatcher } from 'platform/advertorial/AdvertorialEditor/utils';
import {
    NodeWithAlignment,
    Alignment,
    blockAlignmentButtons,
    LengthUnit,
    lengthOptions,
} from 'platform/advertorial/AdvertorialEditor/common.types';
import { FormikProps, Formik, Form } from 'formik';
import FormButtonArray from 'platform/common/components/FormButtonArray/FormButtonArray';
import FormRow from 'platform/common/components/FormRow/FormRow';
import FormCodeMirror from 'platform/common/components/CodeMirror/FormCodeMirror';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { positiveNumber } from 'platform/common/utils/validators.util';
import { Float, floatButtons, getFloatClasses } from 'platform/advertorial/AdvertorialEditor/float';
import { toBlockAlignmentClass } from 'platform/advertorial/AdvertorialEditor/alignment';
import { Button, Icon } from './components';
import BlockControlls from './BlockControlls';

type HtmlBlockParams = NodeWithAlignment & {
    content: string;
    className?: string;
    width: number | undefined;
    float: Float;
    widthUnit: LengthUnit;
};

const insertHtmlBlock = (editor: Editor, params: HtmlBlockParams) => {
    Transforms.insertNodes(editor, { type: 'html-block', ...params, children: [{ text: '' }] });
};

const HtmlBlockModal = ({
    isOpen,
    toggle,
    done,
    initial,
}: {
    isOpen: boolean;
    toggle: () => void;
    done: (params: HtmlBlockParams) => void;
    initial?: HtmlBlockParams;
}) => (
    <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Raw Html Block</ModalHeader>
        <ModalBody>
            <Formik
                initialValues={{
                    content: initial?.content ?? '',
                    width: initial?.width ?? undefined,
                    float: initial?.float ?? Float.none,
                    className: initial?.className || '',
                    alignment: initial?.alignment ?? Alignment.left,
                    widthUnit: initial?.widthUnit || LengthUnit.px,
                }}
                onSubmit={done}
            >
                {(formProps: FormikProps<HtmlBlockParams>) => (
                    <Form>
                        <FormCodeMirror
                            name="content"
                            options={{ readOnly: false, lineWrapping: true, mode: 'htmlmixed' }}
                        />
                        <FormRow label="Width unit">
                            <FormButtonArray name="widthUnit" buttons={lengthOptions} />
                        </FormRow>
                        <FormRow label="Width (leave blank for full width)">
                            <FormInput
                                name="width"
                                type="number"
                                validate={positiveNumber}
                                rightAddOn={{ title: formProps.values.widthUnit }}
                            />
                        </FormRow>
                        <FormRow label="Css class name">
                            <FormInput name="className" type="text" />
                        </FormRow>
                        <FormRow label="Float (text will wrap around)">
                            <FormButtonArray name="float" buttons={floatButtons} />
                        </FormRow>
                        {formProps.values.float === 'none' && (
                            <FormRow label="Alignment">
                                <FormButtonArray name="alignment" buttons={blockAlignmentButtons} />
                            </FormRow>
                        )}
                        <div className="d-flex justify-content-end">
                            <ReactstrapButton color="secondary" onClick={toggle}>
                                Cancel
                            </ReactstrapButton>
                            <ReactstrapButton className="ml-2" color="primary" type="submit">
                                Ok
                            </ReactstrapButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalBody>
    </Modal>
);

export const HtmlBlockToolbarButton = () => {
    const editor = useSlate();
    const { showModal } = useModal();
    const ref = React.useRef<HTMLButtonElement>(null);
    return (
        <Button
            ref={ref}
            onMouseDown={(event: Event) => {
                event.preventDefault();
                const { selection } = editor;

                const entry = getActiveEntryOfType(editor, 'html-block');

                if (!entry) {
                    showModal(toggle => (
                        <HtmlBlockModal
                            isOpen
                            toggle={toggle}
                            done={params => {
                                Transforms.select(editor, selection || [0]);
                                insertHtmlBlock(editor, params);
                                toggle();
                            }}
                        />
                    ));
                } else {
                    const htmlBlockElement = entry[0] as HtmlBlockElement;
                    showModal(toggle => (
                        <HtmlBlockModal
                            initial={htmlBlockElement}
                            isOpen
                            toggle={toggle}
                            done={params => {
                                Transforms.select(editor, selection || [0]);
                                Transforms.setNodes(editor, params, elementMatcher('html-block'));
                                toggle();
                            }}
                        />
                    ));
                }
            }}
        >
            <Icon className="fas fa-file-code" />
            {ref.current && <UncontrolledTooltip target={ref.current}>Raw html block</UncontrolledTooltip>}
        </Button>
    );
};

type HtmlBlockElement = HtmlBlockParams & {
    type: 'slideshow';
    children: Node[];
};

export const HtmlBlockInEditor = ({
    attributes,
    children,
    element,
}: {
    element: HtmlBlockElement;
    attributes: any;
    children: React.ReactNode;
}) => {
    const selected = useSelected();
    const focused = useFocused();
    const editor = useSlate();
    const { showModal } = useModal();
    return (
        <div
            {...attributes}
            className={`mb-4 d-flex ${toBlockAlignmentClass(element.alignment)} ${getFloatClasses(
                element.float || 'none'
            )}`}
        >
            <div
                style={{
                    boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
                    position: 'relative',
                    background: '#eee',
                    width: element.width ? `${element.width}${element.widthUnit}` : '100%',
                }}
                className={`pt-4 pb-3 px-3 d-flex ${toBlockAlignmentClass(element.alignment)}`}
                contentEditable={false}
            >
                <BlockControlls
                    label="Raw Html"
                    onEdit={() => {
                        const { selection } = editor;

                        const entry = getActiveEntryOfType(editor, 'html-block');

                        if (entry) {
                            const htmlBlockElement = entry[0] as HtmlBlockElement;
                            showModal(toggle => (
                                <HtmlBlockModal
                                    initial={htmlBlockElement}
                                    isOpen
                                    toggle={toggle}
                                    done={params => {
                                        Transforms.select(editor, selection || [0]);
                                        Transforms.setNodes(editor, params, elementMatcher('html-block'));
                                        toggle();
                                    }}
                                />
                            ));
                        }
                    }}
                    element={element}
                />
                <code className="p-2 d-block" style={{ background: 'white' }}>
                    {element.content}
                </code>
                {children}
            </div>
        </div>
    );
};

export const withHtmlBlock = <T extends Editor>(editor: T): T => {
    const { isVoid } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.isVoid = element => (element.type === 'html-block' ? true : isVoid(element));
    return editor;
};
