import { Form, Formik, FormikProps, FieldArray } from 'formik';
import { uploadImage } from 'platform/advertorial/advertorial.service';
import FormInput from 'platform/common/components/FormInput/FormInput';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { useModal } from 'platform/common/components/Modal/Modal';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { dataUrlToBase64, FileInfo } from 'platform/common/utils/file.util';
import { required, urlHttps } from 'platform/common/utils/validators.util';
import React from 'react';
import { Button as ReactstrapButton, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Element, Text, Transforms } from 'slate';
import { ReactEditor, useEditor, useSlate, useFocused, useSelected } from 'slate-react';
import BlockControlls from 'platform/advertorial/AdvertorialEditor/BlockControlls';
import { Button } from './components';
import { elementMatcher, handleDragStart } from './utils';

type SlideParams = {
    url: string;
};

export type SlideElement = SlideParams & {
    type: 'slide';
    children: Text[];
};

const SlideImageModal = ({
    initial,
    isOpen,
    toggle,
    done,
}: {
    isOpen: boolean;
    toggle: () => void;
    done: (params: SlideParams) => void;
    initial?: SlideParams;
}) => {
    const [isUploading, setUploading] = React.useState(false);

    return (
        <Modal style={{ maxWidth: 600 }} isOpen={isOpen} toggle={toggle}>
            <ModalHeader>Slide</ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        url: initial?.url ?? '',
                    }}
                    onSubmit={done}
                >
                    {(formProps: FormikProps<SlideParams>) => (
                        <Form>
                            {(isUploading || formProps.isSubmitting) && <OverlayLoader />}
                            <FormRow label="Image source url">
                                <FormInput type="text" name="url" validate={[required, urlHttps]} />
                            </FormRow>
                            <FormRow label="">
                                <UploadInput
                                    acceptableMimeTypes="image/jpeg, image/png, image/gif"
                                    onFileUpload={(file: FileInfo) => {
                                        setUploading(true);
                                        uploadImage({
                                            content: dataUrlToBase64(file.content),
                                            filename: file.name,
                                        })
                                            .then(({ fullUrl }) => {
                                                formProps.setValues({ ...formProps.values, url: fullUrl });
                                            })
                                            .finally(() => setUploading(false));
                                    }}
                                    subtitle="Upload jpeg/png/gif"
                                />
                            </FormRow>
                            <div className="d-flex justify-content-end">
                                <ReactstrapButton color="secondary" onClick={toggle}>
                                    Cancel
                                </ReactstrapButton>
                                <ReactstrapButton className="ml-2" color="primary" type="submit">
                                    Ok
                                </ReactstrapButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

const SlideMultiImageModal = ({
    isOpen,
    toggle,
    done,
}: {
    isOpen: boolean;
    toggle: () => void;
    done: (params: { urls: string[] }) => void;
}) => {
    const [isUploading, setUploading] = React.useState(false);

    return (
        <Modal style={{ maxWidth: 600 }} isOpen={isOpen} toggle={toggle}>
            <ModalHeader>Insert Slides</ModalHeader>
            <ModalBody>
                <Formik initialValues={{ urls: [''] }} onSubmit={done}>
                    {(formProps: FormikProps<{ urls: string[] }>) => (
                        <Form>
                            {(isUploading || formProps.isSubmitting) && <OverlayLoader />}
                            <FormRow label="Image source url">
                                <FieldArray
                                    name="urls"
                                    render={arrayHelpers => (
                                        <div>
                                            {formProps.values.urls.map((friend, index) => (
                                                <div
                                                    className="d-flex justify-content-between mb-2"
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={index}
                                                >
                                                    <FormInput
                                                        type="text"
                                                        name={`urls.${index}`}
                                                        validate={[required, urlHttps]}
                                                        className="flex-grow"
                                                    />
                                                    <ReactstrapButton
                                                        onClick={() => arrayHelpers.remove(index)}
                                                        className="ml-2"
                                                    >
                                                        -
                                                    </ReactstrapButton>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                />
                                <ReactstrapButton
                                    onClick={() =>
                                        formProps.setValues({
                                            ...formProps.values,
                                            urls: [...formProps.values.urls, ''],
                                        })
                                    }
                                >
                                    Add url
                                </ReactstrapButton>
                            </FormRow>
                            <FormRow label="Or add files">
                                <UploadInput
                                    multiple
                                    acceptableMimeTypes="image/jpeg, image/png, image/gif"
                                    onFileUpload={(files: FileInfo[]) => {
                                        setUploading(true);
                                        Promise.all(
                                            files.map(file =>
                                                uploadImage({
                                                    content: dataUrlToBase64(file.content),
                                                    filename: file.name,
                                                })
                                            )
                                        )
                                            .then(results => {
                                                formProps.setValues({
                                                    ...formProps.values,
                                                    urls: [
                                                        ...formProps.values.urls.filter(Boolean),
                                                        ...results.map(r => r.fullUrl),
                                                    ],
                                                });
                                            })
                                            .finally(() => setUploading(false));
                                    }}
                                    subtitle="Upload jpeg/png/gif files"
                                />
                            </FormRow>
                            <div className="d-flex justify-content-end">
                                <ReactstrapButton color="secondary" onClick={toggle}>
                                    Cancel
                                </ReactstrapButton>
                                <ReactstrapButton className="ml-2" color="primary" type="submit">
                                    Ok
                                </ReactstrapButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export const AddSlideButton = ({ parentElement }: { parentElement: Element }) => {
    const editor = useSlate();
    const { showModal } = useModal();
    return (
        <Button
            onMouseDown={() => {
                showModal(toggle => (
                    <SlideMultiImageModal
                        isOpen
                        toggle={toggle}
                        done={params => {
                            const path = ReactEditor.findPath(editor, parentElement);
                            const slideNodes: SlideElement[] = params.urls.map(url => ({
                                type: 'slide',
                                url,
                                children: [{ text: '' }],
                            }));

                            Transforms.insertNodes(editor, slideNodes, { at: path.concat(0) });
                            toggle();
                        }}
                    />
                ));
            }}
        >
            Add slides
        </Button>
    );
};

export const SlideInEditor = ({
    attributes,
    children,
    element,
}: {
    element: SlideElement;
    attributes: any;
    children: React.ReactNode;
}) => {
    const selected = useSelected();
    const focused = useFocused();
    const { showModal } = useModal();
    const editor = useEditor();
    return (
        <div
            {...attributes}
            style={{ boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none' }}
            className={`my-2 d-flex position-relative `}
        >
            <div contentEditable={false} className="flex-shrink-0" style={{ width: 100, background: '#bbb' }}>
                <BlockControlls
                    element={element}
                    onDragStart={handleDragStart(editor, element, 'application/x-slate-cdo-slide')}
                    label=""
                    onEdit={() => {
                        showModal(toggle => (
                            <SlideImageModal
                                initial={element}
                                isOpen
                                toggle={toggle}
                                done={params => {
                                    Transforms.select(editor, ReactEditor.findPath(editor, element));
                                    Transforms.setNodes(editor, params, elementMatcher('slide'));
                                    toggle();
                                }}
                            />
                        ));
                    }}
                    showSpacingControlls={false}
                />
                <img
                    alt={element.url}
                    src={element.url}
                    className={`d-block `}
                    style={{ width: '100%', height: 'auto' }}
                />
            </div>
            <div className="flex-grow-1 px-2" style={{ background: '#f5f5f5' }}>
                {children}
            </div>
        </div>
    );
};
