import { isEmpty, isEqual, omit, reduce, isNil } from 'lodash-es';

type Object = { [key: string]: any };

export type Changelog = {
    dateTime: string;
    field: string;
    newValue: string;
    oldValue: string;
    user: string;
};

const getObjectsDiff = (newObject: Object, oldObject: Object): Object =>
    reduce(
        newObject,
        (result, value, key) => {
            if ((!isNil(value) || !isNil(oldObject[key])) && !isEqual(value, oldObject[key]))
                return { ...result, [key]: value };
            return result;
        },
        {}
    );

export const getChangelogRows = (
    newObject: Object,
    oldObject: Object,
    dateTime: string,
    user: string,
    ignoreFields: string[]
): Changelog[] => {
    const diff = omit<Object>(getObjectsDiff(newObject, oldObject), ignoreFields);
    if (isEmpty(diff)) return [];
    return Object.keys(diff).map(field => ({
        dateTime,
        user,
        field,
        newValue: diff[field],
        oldValue: oldObject[field],
    }));
};

export const renderValue = (value: any) => (isNil(value) ? '-' : value);
