export const FONT_OPTIONS = [
    '"Times New Roman", Times, serif',
    'Arial, Helvetica, sans-serif',
    'Volkhov,serif',
    'Verdana,sans-serif',
    'Open Sans,sans-serif',
    'Lato,sans-serif',
    'passion_regular',
    'Source Sans Pro,Helvetica,Arial,sans-serif',
    'Roboto Slab,Times New Roman,sans-serif',
    'Oswald,sans-serif',
    'Saira, Arial, sans-serif',
    'Montserrat, sans-serif',
].map(value => ({
    value,
    label: value,
}));
