import React from 'react';
import { Formik, FormikProps } from 'formik';
import { WithFormProps } from 'platform/common/common.type';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { hexColor, positiveNumber, required } from 'platform/common/utils/validators.util';
import FormRow from 'platform/common/components/FormRow/FormRow';
import CardForm from 'platform/common/components/CardForm/CardForm';
import withEditForm from 'platform/common/components/WithEditForm/WithEditForm';
import withCreateForm from 'platform/common/components/WithCreateForm/WithCreateForm';
import {
    createAdvertorialTemplate,
    fetchAdvertorialTemplate,
    updateAdvertorialTemplate,
} from 'platform/adminPanel/masterTemplates/masterTemplates.service';
import { AdvertorialTemplate } from 'platform/adminPanel/masterTemplates/masterTemplates.type';
import FormSelect from 'platform/common/components/FormSelect/FormSelect';
import { fetchWebsites } from 'platform/adminPanel/websites/website.service';
import { usePromise } from 'platform/common/hooks/usePromise';
import FormColorInput from 'platform/common/components/FormColorInput/FormColorInput';
import Separator from 'platform/common/components/Separator/Separator';
import FormCodeMirror from 'platform/common/components/CodeMirror/FormCodeMirror';
import { FONT_OPTIONS } from 'platform/adminPanel/masterTemplates/masterTemplates.constant';
import { hashColorCode, unHashColorCode } from 'platform/common/utils/formatters.util';
import FormSwitch from 'platform/common/components/FormSwitch/FormSwitch';

const pxAddOn = {
    title: 'px',
};

const AdvertorialTemplateForm = (props: WithFormProps<AdvertorialTemplate>) => {
    const { onSubmit, onClose, labels, initialValues, canEdit } = props;

    const [{ data: websiteOptions, loading: isWebsitesLoading }] = usePromise(
        [],
        () => fetchWebsites().then(websites => websites.map(w => ({ value: w.id, label: w.name }))),
        []
    );

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(formProps: FormikProps<AdvertorialTemplate>) => (
                <CardForm
                    title={`${labels.prefix} advertorial template`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    onClose={onClose}
                    disabled={!canEdit}
                    onSubmit={formProps.submitForm}
                >
                    <ControlledCard title="General info">
                        <FormRow label="Name">
                            <FormInput name="name" type="text" validate={required} />
                        </FormRow>
                        <FormRow label="Website">
                            <FormSelect
                                name="websiteId"
                                validate={required}
                                options={websiteOptions}
                                isLoading={isWebsitesLoading}
                            />
                        </FormRow>

                        <Separator />
                        <FormRow label="Font">
                            <FormRow label="H1">
                                <FormSelect name="fontH1" options={FONT_OPTIONS} />
                            </FormRow>
                            <FormRow label="H2">
                                <FormSelect name="fontH2" options={FONT_OPTIONS} />
                            </FormRow>
                            <FormRow label="H3">
                                <FormSelect name="fontH3" options={FONT_OPTIONS} />
                            </FormRow>
                            <FormRow label="Body">
                                <FormSelect name="fontBody" options={FONT_OPTIONS} />
                            </FormRow>
                            <FormRow label="Links">
                                <FormSelect name="fontLinks" options={FONT_OPTIONS} />
                            </FormRow>
                            <FormRow label="Image caption">
                                <FormSelect name="fontImageCaption" options={FONT_OPTIONS} />
                            </FormRow>
                        </FormRow>

                        <Separator />
                        <FormRow label="Font color">
                            <FormRow label="H1">
                                <FormColorInput name="fontColourH1" validate={hexColor} />
                            </FormRow>
                            <FormRow label="H2">
                                <FormColorInput name="fontColourH2" validate={hexColor} />
                            </FormRow>
                            <FormRow label="H3">
                                <FormColorInput name="fontColourH3" validate={hexColor} />
                            </FormRow>
                            <FormRow label="Body">
                                <FormColorInput name="fontColourBody" validate={hexColor} />
                            </FormRow>
                            <FormRow label="Links">
                                <FormColorInput name="fontColourLinks" validate={hexColor} />
                            </FormRow>
                            <FormRow label="Image caption">
                                <FormColorInput name="fontColourImageCaption" validate={hexColor} />
                            </FormRow>
                        </FormRow>

                        <Separator />
                        <FormRow label="Font size">
                            <FormRow label="H1">
                                <FormInput
                                    name="fontSizeH1"
                                    type="text"
                                    validate={positiveNumber}
                                    rightAddOn={pxAddOn}
                                />
                            </FormRow>
                            <FormRow label="H2">
                                <FormInput
                                    name="fontSizeH2"
                                    type="text"
                                    validate={positiveNumber}
                                    rightAddOn={pxAddOn}
                                />
                            </FormRow>
                            <FormRow label="H3">
                                <FormInput
                                    name="fontSizeH3"
                                    type="text"
                                    validate={positiveNumber}
                                    rightAddOn={pxAddOn}
                                />
                            </FormRow>
                            <FormRow label="Body">
                                <FormInput
                                    name="fontSizeBody"
                                    type="text"
                                    validate={positiveNumber}
                                    rightAddOn={pxAddOn}
                                />
                            </FormRow>
                            <FormRow label="Links">
                                <FormInput
                                    name="fontSizeLinks"
                                    type="text"
                                    validate={positiveNumber}
                                    rightAddOn={pxAddOn}
                                />
                            </FormRow>
                            <FormRow label="Image caption">
                                <FormInput
                                    name="fontSizeImageCaption"
                                    type="text"
                                    validate={positiveNumber}
                                    rightAddOn={pxAddOn}
                                />
                            </FormRow>
                        </FormRow>

                        <FormRow label="Mark as advertisement">
                            <FormSwitch name="markAsAdvertisement" />
                        </FormRow>
                        {formProps.values.markAsAdvertisement && (
                            <FormRow label="Custom advertisement label">
                                <FormInput type="text" name="markAsAdvertisementText" placeholder="Anzeige" />
                            </FormRow>
                        )}

                        <Separator />
                        <FormRow label="Additional CSS">
                            <FormCodeMirror name="additionalCss" options={{ mode: 'css' }} />
                        </FormRow>
                    </ControlledCard>
                </CardForm>
            )}
        </Formik>
    );
};

const newAdvertorialTemplate: Partial<AdvertorialTemplate> = {
    id: undefined,
    websiteId: undefined,
    name: '',
    fontH1: undefined,
    fontColourH1: undefined,
    fontSizeH1: undefined,
    fontH2: undefined,
    fontColourH2: undefined,
    fontSizeH2: undefined,
    fontH3: undefined,
    fontColourH3: undefined,
    fontSizeH3: undefined,
    fontBody: undefined,
    fontColourBody: undefined,
    fontSizeBody: undefined,
    fontLinks: undefined,
    fontColourLinks: undefined,
    fontSizeLinks: undefined,
    fontImageCaption: undefined,
    fontColourImageCaption: undefined,
    fontSizeImageCaption: undefined,
    additionalCss: undefined,
    markAsAdvertisement: false,
    markAsAdvertisementText: undefined,
};

const toAdvertorialTemplateApi = (advertorial: AdvertorialTemplate): AdvertorialTemplate => ({
    id: advertorial.id,
    websiteId: advertorial.websiteId,
    name: advertorial.name,
    fontH1: advertorial.fontH1 || undefined,
    fontColourH1: hashColorCode(advertorial.fontColourH1),
    fontSizeH1: advertorial.fontSizeH1 || undefined,
    fontH2: advertorial.fontH2 || undefined,
    fontColourH2: hashColorCode(advertorial.fontColourH2),
    fontSizeH2: advertorial.fontSizeH2 || undefined,
    fontH3: advertorial.fontH3 || undefined,
    fontColourH3: hashColorCode(advertorial.fontColourH3),
    fontSizeH3: advertorial.fontSizeH3 || undefined,
    fontBody: advertorial.fontBody || undefined,
    fontColourBody: hashColorCode(advertorial.fontColourBody),
    fontSizeBody: advertorial.fontSizeBody || undefined,
    fontLinks: advertorial.fontLinks || undefined,
    fontColourLinks: hashColorCode(advertorial.fontColourLinks),
    fontSizeLinks: advertorial.fontSizeLinks || undefined,
    fontImageCaption: advertorial.fontImageCaption || undefined,
    fontColourImageCaption: hashColorCode(advertorial.fontColourImageCaption),
    fontSizeImageCaption: advertorial.fontSizeImageCaption || undefined,
    additionalCss: advertorial.additionalCss || undefined,
    markAsAdvertisement: advertorial.markAsAdvertisement || undefined,
    markAsAdvertisementText: advertorial.markAsAdvertisementText || undefined,
});

const withCreateOptions = {
    onOpen: async () => newAdvertorialTemplate,
    onSubmit: async (advertorialTemplate: AdvertorialTemplate) =>
        createAdvertorialTemplate(toAdvertorialTemplateApi(advertorialTemplate)),
};

const withEditOptions = {
    onOpen: (params: { id: string }) =>
        fetchAdvertorialTemplate(Number(params.id)).then(advertorial => ({
            ...advertorial,
            fontColourH1: unHashColorCode(advertorial.fontColourH1),
            fontColourH2: unHashColorCode(advertorial.fontColourH2),
            fontColourH3: unHashColorCode(advertorial.fontColourH3),
            fontColourBody: unHashColorCode(advertorial.fontColourBody),
            fontColourLinks: unHashColorCode(advertorial.fontColourLinks),
            fontColourImageCaption: unHashColorCode(advertorial.fontColourImageCaption),
        })),
    onSubmit: (advertorialTemplate: AdvertorialTemplate) =>
        updateAdvertorialTemplate(toAdvertorialTemplateApi(advertorialTemplate)),
};

export const AdvertorialTemplateCreate = withCreateForm(withCreateOptions)(AdvertorialTemplateForm);

export const AdvertorialTemplateEdit = withEditForm(withEditOptions)(AdvertorialTemplateForm);
