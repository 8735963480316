import { toastError } from './toast.util';

export type FileInfo = { name: string; size: string; type: string; content: string };

export type ReadFileAs = 'binaryString' | 'text' | 'dataURL';

const readFile = (file: File, readFileAs: ReadFileAs): Promise<string> =>
    new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            resolve(fileReader.result as string);
        };
        fileReader.onerror = (e: ProgressEvent<FileReader>) => {
            fileReader.abort();
            reject(e);
        };

        switch (readFileAs) {
            case 'binaryString':
                fileReader.readAsBinaryString(file);
                break;
            case 'text':
                fileReader.readAsText(file);
                break;
            case 'dataURL':
                fileReader.readAsDataURL(file);
                break;
            default:
                throw new Error(`Unknow readFileAs param '${readFileAs}'`);
        }
    });

const mapFileResultToInfo = ({ file, result }: { file: File; result: string }) => ({
    content: result,
    name: file.name,
    type: file.type,
    size: String(file.size),
});

export const readFileData = ({
    acceptedFiles,
    rejectedFiles,
    readFileAs = 'dataURL',
    callback,
}: {
    acceptedFiles: File[];
    rejectedFiles: File[];
    readFileAs: ReadFileAs;
    callback: (file: FileInfo) => void;
}) => {
    if (rejectedFiles && rejectedFiles.length) {
        toastError(
            `Could not upload file(s): ${rejectedFiles
                .map(f => f.name)
                .join(', ')}. Files with type (${rejectedFiles
                .map(f => f.type)
                .join(', ')}) cannot be uploaded`
        );
        return;
    }

    const file = acceptedFiles[0];
    if (!file) return;

    readFile(file, readFileAs).then(result => callback(mapFileResultToInfo({ file, result })));
};

export const readFileListData = ({
    acceptedFiles,
    rejectedFiles,
    readFileAs = 'dataURL',
    callback,
}: {
    acceptedFiles: File[];
    rejectedFiles: File[];
    readFileAs: ReadFileAs;
    callback: (files: FileInfo[]) => void;
}) => {
    if (rejectedFiles && rejectedFiles.length) {
        toastError(
            `Could not upload file(s): ${rejectedFiles
                .map(f => f.name)
                .join(', ')}. Files with type (${rejectedFiles
                .map(f => f.type)
                .join(', ')}) cannot be uploaded`
        );
        return;
    }

    Promise.all(
        acceptedFiles.map(file => readFile(file, readFileAs).then(result => ({ file, result })))
    ).then(results => callback(results.map(mapFileResultToInfo)));
};

export const base64ToDataUrl = (base64: string, mimeType?: string) => {
    const safeMimeType = mimeType && mimeType !== 'UNKNOWN' ? mimeType : 'image/jpeg';

    return `data:${safeMimeType};base64,${base64}`;
};

export const dataUrlToBase64 = (dataUrl?: string) => {
    if (!dataUrl) {
        throw new Error('Invalid data url');
    }
    return dataUrl.split(';base64,')[1];
};

export const getImageSize = (src: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            resolve(image);
        };
        image.onerror = reject;
        image.src = src;
    });
