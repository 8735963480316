import React from 'react';
import { ReactEditor, useEditor } from 'slate-react';
import { Element, Transforms } from 'slate';
import { handleDragStart } from 'platform/advertorial/AdvertorialEditor/utils';

const BlockControllsButton = ({
    icon,
    onClick,
    onDragStart,
    className,
    style,
    label,
    draggable,
}: {
    className?: string;
    icon: string;
    style?: React.HtmlHTMLAttributes<HTMLDivElement>['style'];
    onClick?: () => void;
    onDragStart?: (e: React.DragEvent<any>) => void;
    label?: string;
    draggable?: boolean;
}) => (
    // we can not have button as draggable source, because it somehow breaks drop position
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
        style={{
            lineHeight: '1.4em',
            borderRadius: '1px',
            ...style,
        }}
        className={`cursor-pointer bg-white px-1 ${className}`}
        onClick={onClick}
        contentEditable={false}
        draggable={draggable}
        onDragStart={onDragStart}
    >
        <i className={icon} />
        {label}
    </div>
);

type Props = {
    element: Element;
    label: string;
    onDragStart?: (e: React.DragEvent<any>) => void;
    onEdit: () => void;
    showSpacingControlls?: boolean;
};

export const BlockControlls = ({
    label,
    onDragStart,
    onEdit,
    element,
    showSpacingControlls = true,
}: Props) => {
    const editor = useEditor();
    return (
        <>
            <div contentEditable={false} className="position-absolute d-flex" style={{ left: 3, top: 3 }}>
                <BlockControllsButton
                    icon="fas fa-arrows-alt"
                    style={{ cursor: 'move' }}
                    draggable
                    label={label}
                    onDragStart={onDragStart ?? handleDragStart(editor, element)}
                />
                <BlockControllsButton className="bg-white px-1 ml-1" icon="fas fa-edit" onClick={onEdit} />
                <BlockControllsButton
                    className="ml-1"
                    icon="fas fa-trash-alt"
                    onClick={() => {
                        const path = ReactEditor.findPath(editor, element);
                        Transforms.removeNodes(editor, { at: path });
                    }}
                />
            </div>
            {showSpacingControlls && (
                <>
                    <BlockControllsButton
                        icon="fas fa-level-up-alt"
                        className="bg-white position-absolute"
                        style={{
                            right: 6,
                            top: -3,
                            fontSize: '10px',
                            cursor: 'pointer',
                            padding: 3,
                            transform: 'rotate(-90deg)',
                        }}
                        onClick={() => {
                            const path = ReactEditor.findPath(editor, element);
                            Transforms.insertNodes(
                                editor,
                                { type: 'paragraph', children: [{ text: '' }] },
                                { at: path }
                            );
                            Transforms.select(editor, path);
                        }}
                    />
                    <div
                        contentEditable={false}
                        className="position-absolute"
                        style={{
                            background: 'white',
                            right: 6,
                            bottom: -3,
                            fontSize: '10px',
                            cursor: 'pointer',
                            padding: 3,
                            transform: 'rotate(90deg)',
                        }}
                        role="button"
                        tabIndex={0}
                        onClick={e => {
                            e.stopPropagation();
                            const path = ReactEditor.findPath(editor, element);
                            const pathAfter = [...path.slice(0, path.length - 1), path.slice(-1)[0] + 1];
                            Transforms.insertNodes(
                                editor,
                                { type: 'paragraph', children: [{ text: '' }] },
                                { at: pathAfter }
                            );
                            Transforms.select(editor, pathAfter);
                        }}
                    >
                        <i className="fas fa-level-down-alt" />
                    </div>
                </>
            )}
        </>
    );
};

export default BlockControlls;
