import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './AdvertorialPreview.scss';
import { fetchWebsites } from 'platform/adminPanel/websites/website.service';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { usePromise } from 'platform/common/hooks/usePromise';

type Props = {
    isOpen: boolean;
    toggle: () => any;
    bodyContent: string;
};

const useForceSecondRender = () => {
    const [isFirst, set] = React.useState(true);

    React.useEffect(() => {
        set(false);
    }, []);

    return isFirst;
};

const renderContent = (bodyContent: string) => `<!doctype html>
<html>
<head>
  <meta charset="utf-8">
 <link href="style1.css" rel="stylesheet" type="text/css" title="s1" id="siteStylesheet"/>
</head>
<body>
  <div class="advertisement"><div>
  ${bodyContent}
</body>
</html>
`;

const AdvertorialModal = ({ isOpen, toggle, bodyContent }: Props) => {
    const isFirst = useForceSecondRender();

    const frameRef = React.useRef<HTMLIFrameElement>(null);

    const [siteId, setSiteId] = React.useState<string | undefined>(undefined);
    const [{ data: siteOptions, loading }] = usePromise(
        [],
        () =>
            fetchWebsites().then(sites => {
                if (!sites.find(site => site.siteId === siteId)) {
                    setSiteId(undefined);
                }
                return sites.map(w => ({ value: w.siteId, label: w.name }));
            }),
        []
    );

    React.useEffect(() => {
        const { current } = frameRef;
        if (current && current.contentWindow) {
            const body = renderContent(bodyContent);
            current.contentWindow.document.write(body);
        }
    }, [bodyContent, isFirst]);

    React.useEffect(() => {
        const { current } = frameRef;
        if (!isFirst && current && current.contentWindow) {
            const styleElement = current.contentWindow.document.getElementById('siteStylesheet');
            if (styleElement) {
                (styleElement as HTMLLinkElement).href = siteId
                    ? `https://sdo-prod-templates.s3-eu-west-1.amazonaws.com/advertorial_template/${siteId}/${siteId}.css?timestamp=${Date.now()}`
                    : '';
            }
        }
    }, [isFirst, siteId]);

    return (
        <Modal className="AdvertorialModal" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Advertorial preview</ModalHeader>
            <ModalBody className="d-flex flex-column">
                <div className="d-flex mb-4 justify-content-end flex-grow-0">
                    <SelectWithAddon
                        name="Preview with styles from website"
                        className="w-auto"
                        value={siteId}
                        isLoading={loading}
                        options={siteOptions}
                        onChange={setSiteId}
                    />
                </div>
                <iframe title="Advertorial preview" ref={frameRef} />
            </ModalBody>
        </Modal>
    );
};

export default AdvertorialModal;
