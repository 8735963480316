import numeral from 'numeral';
import { sampleSize } from 'lodash-es';

export const getPercent = (value: any, fraction = 1) => numeral(value).format(`0.${'0'.repeat(fraction)} %`);

export const getMoney = (value: any, fraction = 2) => numeral(value).format(`0.${'0'.repeat(fraction)}`);

export const calculatePercentFromSum = (values = [] as number[], fraction = 2) => {
    const sum = values.reduce((s, val) => s + val, 0);
    return values.map(value => Number(getMoney((100 * value) / sum, fraction)));
};

export const isPositiveNumber = (value: any) => !(!value || isNaN(value) || Number(value) <= 0);

export const isPositiveWholeNumber = (value: any) =>
    isPositiveNumber(value) && Number.isInteger(Number(value));

export const randomHash = (length: number = 10) => {
    const chars = 'abcdefghijklmnopqrstufwxyzABCDEFGHIJKLMNOPQRSTUFWXYZ';
    const selected = sampleSize(chars, length);
    return selected.join('');
};
