import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { actions as authActions } from '../../auth.duck';
import ProfileGeneralInfoForm from './ProfileGeneralInfoForm';
import ProfileChangePasswordForm from './ProfileChangePasswordForm';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import { toastSuccess } from '../../../common/utils/toast.util';
import { RootState } from '../../../rootState.type';

const Profile = () => {
    const dispatch = useDispatch();

    const { profile, error: submitError } = useSelector((state: RootState) => state.session);

    return (
        <div>
            <div className="my-4">
                <PageHeader title="Profile" />
            </div>
            <Row>
                <Col xs={6}>
                    <ProfileGeneralInfoForm
                        initialValues={profile}
                        onSubmit={async (params: any) => {
                            await dispatch(
                                authActions.updateCurrentUserProfile({
                                    ...profile,
                                    ...params,
                                })
                            );
                            toastSuccess('Profile updated successfully');
                        }}
                    />
                    <ProfileChangePasswordForm
                        submitError={submitError}
                        onSubmit={async (params: any) => {
                            await dispatch(authActions.updateCurrentUserPassword(params));
                            toastSuccess('Password updated successfully');
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default Profile;
