import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { TwoFactorAuthenticationType } from 'platform/userManagement/userManagement.constant';
import LoginPage from './LoginPage';
import { actions as authActions } from '../../auth.duck';
import { RootState } from '../../../rootState.type';

type StateProps = {
    loading: boolean;
    submitError: boolean;
    errorMessage?: string;
    recoveryLinkSent: boolean;
    phoneNumber?: string;
    twoFactorAuthenticationType: TwoFactorAuthenticationType;
};

type DispatchProps = {
    actions: {
        login: typeof authActions.login;
        sendPasswordResetEmail: typeof authActions.sendPasswordResetEmail;
        setPassword: typeof authActions.setPassword;
        verify2FaCode: typeof authActions.verify2FaCode;
        fetch2FaCode: typeof authActions.fetch2FaCode;
        send2FaSmsCode: typeof authActions.send2FaSmsCode;
        confirm2FaSetup: typeof authActions.confirm2FaSetup;
    };
};

const Login = (props: StateProps & DispatchProps) => <LoginPage {...props} />;

const mapStateToProps = ({ session }: RootState): StateProps => ({
    loading: session.loading,
    submitError: session.error,
    errorMessage: session.errorMessage,
    recoveryLinkSent: session.recoveryLinkSent,
    phoneNumber: session.account ? session.account.phone : undefined,
    twoFactorAuthenticationType: session.account.twoFactorAuthenticationType
        ? session.account.twoFactorAuthenticationType
        : TwoFactorAuthenticationType.NONE,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
