import React from 'react';
import { Formik } from 'formik';
import { WithFormProps } from 'platform/common/common.type';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { required } from 'platform/common/utils/validators.util';
import FormSelect from 'platform/common/components/FormSelect/FormSelect';
import FormDateRangePicker from 'platform/common/components/FormDateRangePicker/FormDateRangePicker';
import FormRow from 'platform/common/components/FormRow/FormRow';
import CardForm from 'platform/common/components/CardForm/CardForm';
import withEditForm from 'platform/common/components/WithEditForm/WithEditForm';
import withCreateForm from 'platform/common/components/WithCreateForm/WithCreateForm';
import FormStateSelect from 'platform/common/components/FormStateSelect/FormStateSelect';
import * as websiteService from 'platform/adminPanel/websites/website.service';
import * as advertiserService from 'platform/adminPanel/advertisers/advertiser.service';
import * as CampaignMapper from './campaign.mapper';
import { CampaignFormModel } from './campaign.type';
import * as campaignService from './campaign.service';

const CampaignForm = (props: WithFormProps<CampaignFormModel>) => {
    const { onSubmit, onClose, labels, initialValues, canEdit } = props;

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {formProps => (
                <CardForm
                    title={`${labels.prefix} Campaign`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    onClose={onClose}
                    disabled={!canEdit}
                    onSubmit={formProps.submitForm}
                >
                    <ControlledCard title="General info">
                        <FormRow label="Name">
                            <FormInput name="name" type="text" validate={required} />
                        </FormRow>
                        <FormRow label="Status">
                            <FormStateSelect
                                name="status"
                                initialValue={initialValues.status}
                                validate={required}
                            />
                        </FormRow>
                        <FormRow label="Advertiser">
                            <FormSelect
                                name="advertiserId"
                                options={initialValues.advertisers.map(advertiser => ({
                                    value: advertiser.id,
                                    label: advertiser.name,
                                }))}
                                isClearable={false}
                                validate={required}
                            />
                        </FormRow>
                        <FormRow label="Website">
                            <FormSelect
                                name="websiteId"
                                options={initialValues.websites.map(website => ({
                                    value: website.id,
                                    label: website.name,
                                }))}
                                isClearable={false}
                                validate={required}
                            />
                        </FormRow>
                        <FormRow label="Notes">
                            <FormInput name="notes" type="textarea" />
                        </FormRow>
                        <FormRow label="Creation / end dates">
                            <FormDateRangePicker name="dates" />
                        </FormRow>
                    </ControlledCard>
                </CardForm>
            )}
        </Formik>
    );
};

const withCreateOptions = {
    onOpen: async () => {
        const [advertisers, websites]: any = await Promise.all([
            advertiserService.fetchAdvertisers(),
            websiteService.fetchWebsites(),
        ]);
        return CampaignMapper.toFormModel({}, websites, advertisers);
    },
    onSubmit: (model: CampaignFormModel) => campaignService.createCampaign(CampaignMapper.toApi(model)),
};

const withEditOptions = {
    onOpen: async (params: { id: string }) => {
        const [campaign, websites, advertisers]: any = await Promise.all([
            campaignService.fetchCampaign(Number(params.id)),
            websiteService.fetchWebsites(),
            advertiserService.fetchAdvertisers(),
        ]);
        return CampaignMapper.toFormModel(campaign, websites, advertisers);
    },
    onSubmit: (model: CampaignFormModel) => campaignService.updateCampaign(CampaignMapper.toApi(model)),
};

export const CampaignFormCreate = withCreateForm(withCreateOptions)(CampaignForm);

export const CampaignFormEdit = withEditForm(withEditOptions)(CampaignForm);
