import React from 'react';
import { Col, Row } from 'reactstrap';
import Placeholder from '../Placeholder/Placeholder';
import './CardFormPlaceholder.scss';

const CardRowPlaceholder = ({ labelWidth }: { labelWidth: string }) => (
    <Row className="form-group d-flex align-items-center">
        <Col md="4">
            <Placeholder className="ml-auto" height="17px" width={labelWidth} />
        </Col>
        <Col md="8">
            <Placeholder height="33px" width="100%" />
        </Col>
    </Row>
);

const CardFormPlaceholder = () => (
    <div className="CardFormPlaceholder h-100 CardForm card">
        <div className="card-header d-flex align-items-center">
            <Placeholder height="18px" width="50%" />
            <div className="d-flex ml-auto">
                <Placeholder className="ml-2 mt-1" height="33px" width="60px" />
                <Placeholder className="ml-2 mt-1" height="33px" width="60px" />
            </div>
        </div>
        <div className="card-body">
            <div className="card">
                <div className="card-header bg-white">
                    <Placeholder className="mt-1" height="17px" width="30%" />
                </div>
                <div className="card-body bg-white">
                    <CardRowPlaceholder labelWidth="90%" />
                    <CardRowPlaceholder labelWidth="70%" />
                    <CardRowPlaceholder labelWidth="90%" />
                    <CardRowPlaceholder labelWidth="70%" />
                </div>
            </div>
        </div>
    </div>
);

export default CardFormPlaceholder;
