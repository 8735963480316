export type SelectOption = {
    value: any;
    label: string;
};

export type Action = {
    type: string;
    payload?: any;
    meta?: any;
};

export type TableCell<T> = {
    original: T;
    value: any;
    formattedValue: any;
    row: any;
    viewIndex: number;
};

export type Period = {
    to: string;
    from: string;
};

export type TypeDefinition = {
    typeId: string;
    parse: Function;
    parseForExport: Function;
    format: (value: any, options?: any) => string;
    formatTitle: Function;
};

export type Series = {
    labels: string[];
    values: number[];
};

export type Modify<T, R> = Pick<T, Exclude<keyof T, keyof R>> & R;
export type Unpacked<T> = T extends (infer U)[] ? U : T;

export const isDefined = <T>(item: T | null | undefined): item is T => Boolean(item);

export type FieldArrayProps<T = any> = {
    fields: {
        name: string;
        length: number;
        map<R>(callback: (field: string, index: number) => R): R[];
        get(index: number): T;
        getAll(): T[];
        remove(index: number): void;
        push(value: T): void;
        insert(index: number, value: T): void;
        unshift(value: T): void;
    };
    meta: {
        invalid: boolean;
        submitFailed: boolean;
        error?: string | boolean;
        warning?: string;
    };
};

export type ReduxFormProps<T = any> = {
    submitForm: (submitFn: (formData: T) => void) => any;
    change: (key: string, value: any) => void;
    array: {
        remove: (key: string, index: number) => void;
        push: (key: string, value: any) => void;
        unshift: (key: string, value: any) => void;
    };
    error: string;
    submit: () => void;
    touch: () => void;
    resetSection: (...sections: string[]) => void;
    reset: () => void;
    pristine: boolean;
    dirty: boolean;
    valid: boolean;
    form: string;
    initialValues: T;
};

export type WithFormProps<T = any> = ReduxFormProps<T> & {
    labels: {
        prefix: string;
        submit: string;
    };
    onSubmit: (formData: any) => Promise<any>;
    onClose: () => any;
    afterSubmit: () => void;
    isEdit: boolean;
    canEdit: boolean;
};

export type IdResponse = {
    id: string;
};

export type Sort = { orderBy: string; direction: 'ASC' | 'DESC' };

export type SelectItem<T = any> = {
    value: T;
    label: string;
};

export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
    if (val === undefined || val === null) {
        throw new Error(`AssertionError: Expected 'val' to be defined, but received ${val}`);
    }
}

export function nonNil<T>(val: T): NonNullable<T> {
    assertIsDefined(val);
    return val;
}

// For exhaustive checks, reworked from http://ideasintosoftware.com/exhaustive-switch-in-typescript/
export class UnreachableCaseError extends Error {
    constructor(val: never) {
        super(`Unreachable case: ${JSON.stringify(val)}`);
    }
}
