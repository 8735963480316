import React, { useEffect, useState } from 'react';
import { Campaign } from 'platform/campaign/campaign.type';
import { useCancelableEffect } from 'platform/common/hooks/useCancelableEffect';
import * as campaignService from 'platform/campaign/campaign.service';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import { DATA_TYPES } from 'platform/common/dataType';
import { TableCell } from 'platform/common/common.type';
import StateBadge from 'platform/common/components/StateBadge/StateBadge';
import { AdBoard } from 'platform/adBoard/adDboard.types';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { STATE_OPTIONS, States } from 'platform/common/constants/states.constant';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import { routerActions } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import * as teaserService from 'platform/teaser/teaser.service';
import { Route, Switch } from 'react-router-dom';
import { Teaser } from 'platform/teaser/teaser.type';
import { AdBoardFormCreate, AdBoardFormEdit } from 'platform/adBoard/AdBoardForm';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import { includesIgnoreCase } from 'platform/common/utils/filter.util';
import { copyToClipboard } from 'platform/common/utils/clipboard.util';
import { toastSuccess } from 'platform/common/utils/toast.util';
import * as adBoardService from './adBoard.service';

type AdBoardWithDependencies = AdBoard & {
    campaign?: Campaign;
    teasers?: Teaser[];
};

type State = {
    loading: boolean;
    campaignIds: number[];
    states: States[];
    searchQuery: string;
    data: AdBoardWithDependencies[];
};

type MetaData = {
    campaigns: Campaign[];
    teasers: Teaser[];
    loading: boolean;
};

const AdBoardList = () => {
    const dispatch = useDispatch();

    const [state, setState] = useState<State>({
        loading: true,
        searchQuery: '',
        campaignIds: [],
        states: [],
        data: [],
    });

    const [metaData, setMetaData] = useState<MetaData>({
        loading: true,
        campaigns: [],
        teasers: [],
    });

    const fetchAdBoards = (campaignIds: number[]) => {
        setState(currentState => ({ ...currentState, loading: true }));
        adBoardService
            .fetchAdBoards({ campaignIds })
            .then(response => {
                setState(currentState => ({
                    ...currentState,
                    data: response.map(adBoard => ({
                        ...adBoard,
                        campaign: metaData.campaigns.find(campaign => campaign.id === adBoard.campaignId),
                        teasers: metaData.teasers.filter(teaser =>
                            adBoard.teaserIds.find(tid => tid === teaser.id)
                        ),
                    })),
                }));
            })
            .finally(() => {
                setState(currentState => ({ ...currentState, loading: false }));
            });
    };

    useEffect(() => {
        Promise.all([campaignService.fetchCampaigns(), teaserService.fetchTeasers()])
            .then(result => {
                const [campaigns, teasers] = result;
                setMetaData(current => ({
                    ...current,
                    campaigns,
                    teasers,
                }));
            })
            .finally(() => setMetaData(currentMetaData => ({ ...currentMetaData, loading: false })));
    }, []);

    useCancelableEffect(() => {
        if (metaData.loading) return;
        fetchAdBoards(state.campaignIds);
    }, [state.campaignIds, metaData.loading]);

    const columnDefinitions = [
        {
            Header: 'Status',
            type: DATA_TYPES.TEXT,
            width: 80,
            Cell: (cell: TableCell<AdBoardWithDependencies>) => (
                <StateBadge state={cell.original.campaign?.status} />
            ),
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'AdBoard',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Campaign',
            accessor: (a: AdBoardWithDependencies) => a.campaign?.name || '-',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Teasers',
            accessor: (a: AdBoardWithDependencies) => a.teasers?.map(t => t.name)?.join(', '),
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Edited',
            accessor: 'lastModifiedOn',
            width: 160,
            Cell: (cell: TableCell<AdBoardWithDependencies>) => (
                <UpdatedOn date={cell.original.lastModifiedOn} updatedBy={cell.original.lastModifiedBy} />
            ),
        },
        {
            className: () => 'edit-dropdown pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: (cell: TableCell<AdBoardWithDependencies>) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () => dispatch(routerActions.push(`/ad-board/${cell.original.id}`)),
                        },
                        {
                            label: 'Copy streaming link',
                            action: () => {
                                copyToClipboard(cell.original.streamingLink);
                                toastSuccess(
                                    `Streaming link for teaser "${cell.original.name}" copied to clipboard.`
                                );
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    const adBoards = state.data
        .filter(
            t => includesIgnoreCase(t.name, state.searchQuery) || includesIgnoreCase(t.id, state.searchQuery)
        )
        .filter(a => !state.states.length || (a.campaign && state.states.includes(a.campaign.status)));

    return (
        <div>
            <div className="my-4">
                <PageHeader title="AdBoard module" />
            </div>
            <ListFilters>
                <SelectWithAddon
                    name="Campaign"
                    value={state.campaignIds}
                    isLoading={metaData.loading}
                    options={metaData.campaigns.map(campaign => ({
                        label: campaign.name,
                        value: campaign.id,
                    }))}
                    onChange={campaignIds => setState(currentState => ({ ...currentState, campaignIds }))}
                    isMulti
                />
                <SelectWithAddon
                    name="Status"
                    value={state.states}
                    options={STATE_OPTIONS}
                    onChange={states => setState(currentState => ({ ...currentState, states }))}
                    isMulti
                />
            </ListFilters>
            <div className="mb-4">
                <ListToolbar
                    value={state.searchQuery}
                    onCreate={() => {
                        dispatch(routerActions.push('/ad-board/new'));
                    }}
                    onSearch={searchQuery => setState(currentState => ({ ...currentState, searchQuery }))}
                />
            </div>
            <FormattedTable
                className="-striped"
                columns={columnDefinitions}
                data={adBoards}
                loading={state.loading}
            />
            <Switch>
                <Route
                    path={'/ad-board/new'}
                    render={props => (
                        <AdBoardFormCreate
                            {...props}
                            redirectTo={'/ad-board'}
                            canEdit
                            afterSubmit={(teaser: Teaser) =>
                                dispatch(routerActions.push(`/ad-board/${teaser.id}`))
                            }
                        />
                    )}
                />
                <Route
                    path={'/ad-board/:id'}
                    render={props => (
                        <AdBoardFormEdit
                            {...props}
                            redirectTo={'/ad-board'}
                            canEdit
                            onClose={() => fetchAdBoards(state.campaignIds)}
                        />
                    )}
                />
            </Switch>
        </div>
    );
};

export default AdBoardList;
