import * as React from 'react';
import './Placeholder.scss';

type Props = {
    width?: string;
    height: string;
    className?: string;
};

const Placeholder = (props: Props) => (
    <div style={{ width: props.width, height: props.height }} className={props.className}>
        <div className="Placeholder" style={{ width: '100%', height: '100%' }} />
    </div>
);

Placeholder.defaultProps = {
    width: '100%',
    className: undefined,
};

export default Placeholder;
