import { ACCESS_TYPES } from './access.constant';
import { States } from '../../common/constants/states.constant';
import { Seat, SeatFormModel } from './seat.type';
import { User } from '../users/user.type';

const toApiModel = (data: SeatFormModel): Partial<Seat> => {
    const { id, name, companyName, state, websiteAccess, advertiserAccess, reportLabelSetName } = data;

    return {
        id,
        name,
        status: state,
        companyName,
        websiteAccess: websiteAccess.mode,
        websiteIds: websiteAccess.mode === ACCESS_TYPES.NONE ? [] : websiteAccess.entityIds,
        advertiserAccess: advertiserAccess.mode,
        advertiserIds: advertiserAccess.mode === ACCESS_TYPES.NONE ? [] : advertiserAccess.entityIds,
        reportLabelSetName: reportLabelSetName || 'Default',
        platformAccessUrl: 'https://native.sdo.at',
        publisherAccess: 'ALL',
    };
};

const toFormModel = (seat: Partial<Seat>, userList: User[]): SeatFormModel => {
    const {
        id,
        name,
        status,
        companyName,
        websiteAccess,
        websiteIds,
        advertiserAccess,
        advertiserIds,
    } = seat;

    const assignedUsers = userList
        .filter(user => user.seatId === id)
        .map(user => ({ name: user.name, email: user.login }));

    return {
        id,
        name: name || '',
        state: status || States.ACTIVE,
        companyName: companyName || '',
        websiteAccess: {
            mode: websiteAccess || ACCESS_TYPES.ALL,
            entityIds: websiteIds || [],
        },
        advertiserAccess: {
            mode: advertiserAccess || ACCESS_TYPES.ALL,
            entityIds: advertiserIds || [],
        },
        assignedUsers,
    };
};

export default {
    toApiModel,
    toFormModel,
};
