import * as React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { Button, Popover } from 'reactstrap';
import 'react-day-picker/lib/style.css';
import './DateRangePickerPopover.scss';
import '../DatePicker/DatePicker.scss';
import { DateRanges, formatDate } from '../../utils/date.util';
import DateInput from './DateInput/DateInput';
import Switch from '../Switch/Switch';

type Props = {
    target: any;
    placement: string;
    toggle: () => any;
    from: moment.Moment | undefined;
    to: moment.Moment | undefined;
    preset: string | null;
    minDate: moment.Moment | undefined;
    maxDate: moment.Moment | undefined;
    ranges?: DateRanges;
    onChange: (from: string, to: string, preset: string | null) => any;
    onCancel: () => any;
    optionalEndDate?: boolean;
    forceRangeSelection: boolean;
};

type State = {
    preset: string | null;
    from: Date | undefined;
    to: Date | undefined;
    minDate: Date | undefined;
    maxDate: Date | undefined;
    enteredTo: Date | undefined;
    noEndDate: boolean;
};

const MONTHS = moment.monthsShort();

const momentToDate = (date: moment.Moment | undefined) => (date ? date.toDate() : undefined);

class DateRangePickerPopover extends React.Component<Props, State> {
    state: State = {
        preset: this.props.preset,
        from: momentToDate(this.props.from),
        to: momentToDate(this.props.to),
        minDate: momentToDate(this.props.minDate),
        maxDate: momentToDate(this.props.maxDate),
        enteredTo: momentToDate(this.props.to),
        noEndDate: this.props.optionalEndDate || false,
    };

    handleDayClick = (day: Date, modifiers: DayModifiers) => {
        if (modifiers.disabled || this.props.forceRangeSelection) {
            return;
        }

        const { from, to, noEndDate } = this.state;
        if (!from || (from && to) || day < from || noEndDate) {
            this.setState({
                preset: null,
                from: day,
                to: undefined,
                enteredTo: undefined,
            });
            return;
        }

        if (!this.state.noEndDate) {
            this.setState({
                preset: null,
                to: day,
                enteredTo: day,
            });
        }
    };

    handleDayMouseEnter = (day: Date, modifiers: DayModifiers) => {
        if (modifiers.disabled || this.props.forceRangeSelection) {
            return;
        }

        if (this.state.from && !this.state.to) {
            this.setState({
                enteredTo: day,
            });
        }
    };

    onPresetSelect = (range: string) => () => {
        const { ranges } = this.props;
        if (!ranges) {
            return;
        }
        const { from, to } = ranges[range].getRange();
        this.setState(
            {
                preset: range,
                from: from.toDate(),
                to: to.toDate(),
                enteredTo: to.toDate(),
            },
            this.applyChanges
        );
    };

    onFromDateInputBlur = (value: string) => {
        const momentValue = moment(value);
        const dateValue = momentValue.toDate();

        if (momentValue.isAfter(moment(this.state.to), 'day')) {
            this.setState({
                preset: null,
                from: dateValue,
                to: dateValue,
            });
            return;
        }

        this.setState({ from: dateValue });
    };

    onToDateInputBlur = (value: string) => {
        const momentValue = moment(value);
        const dateValue = momentValue.toDate();

        if (momentValue.isBefore(moment(this.state.from), 'day')) {
            this.setState({ preset: null, from: dateValue, to: dateValue });
            return;
        }

        this.setState({ preset: null, to: dateValue, enteredTo: dateValue });
    };

    onNoEndInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            this.setState({ to: undefined });
        }
        this.setState({ noEndDate: event.target.checked });
    };

    applyChanges = () => {
        this.props.onChange(formatDate(this.state.from), formatDate(this.state.to), this.state.preset);
    };

    renderSidebar = () => {
        const { ranges } = this.props;

        if (!ranges) {
            return null;
        }

        return (
            <div className="DateRangePickerPopover--sidebar">
                <ul className="DateRangePickerPopover--presets">
                    {Object.keys(ranges).map(rangeKey => (
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                        <li
                            key={rangeKey}
                            className={classNames({ active: this.state.preset === rangeKey })}
                            onClick={this.onPresetSelect(rangeKey)}
                        >
                            {ranges[rangeKey].label || rangeKey}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    render() {
        const { from, to, enteredTo, minDate, maxDate, noEndDate } = this.state;
        const { target, placement, toggle, forceRangeSelection } = this.props;
        const modifiers = noEndDate
            ? { selected: from }
            : {
                  start: from,
                  end: to || enteredTo,
                  range: { from, to: to || enteredTo },
                  disabled: { before: minDate, after: maxDate },
              };
        const alignment = this.props.optionalEndDate ? 'justify-content-between' : 'justify-content-end';

        return (
            <Popover
                placement={placement as any}
                target={target}
                isOpen
                toggle={toggle}
                className="DateRangePickerPopover"
                trigger="legacy"
                flip={false}
            >
                <div className="d-flex">
                    <div>
                        <div className="d-flex">
                            <DateInput
                                value={from ? formatDate(moment(from)) : ''}
                                onBlur={this.onFromDateInputBlur}
                                disabled={forceRangeSelection}
                            />
                            <DateInput
                                className="ml-2"
                                value={to ? formatDate(moment(to)) : ''}
                                onBlur={this.onToDateInputBlur}
                                disabled={noEndDate || forceRangeSelection}
                            />
                        </div>

                        <div>
                            <DayPicker
                                showOutsideDays
                                showWeekNumbers
                                firstDayOfWeek={1}
                                month={from}
                                months={MONTHS}
                                numberOfMonths={2}
                                modifiers={modifiers as any}
                                onDayClick={this.handleDayClick}
                                onDayMouseEnter={this.handleDayMouseEnter}
                            />
                        </div>

                        <div className={`d-flex ${alignment} DateRangePickerPopover--actions`}>
                            {this.props.optionalEndDate && (
                                <div className="d-flex align-items-center DateRangePickerPopover-noDateContainer">
                                    <Switch value={noEndDate} onChange={this.onNoEndInputChange} />
                                    <div className="ml-3">No end date</div>
                                </div>
                            )}
                            <div>
                                <Button className="mx-1" onClick={this.props.onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    className="mx-1"
                                    onClick={this.applyChanges}
                                    disabled={!((from && noEndDate) || (from && to))}
                                >
                                    Apply
                                </Button>
                            </div>
                        </div>
                    </div>
                    {this.renderSidebar()}
                </div>
            </Popover>
        );
    }
}

export default DateRangePickerPopover;
