import React from 'react';
import { Formik } from 'formik';
import { Alert, Button } from 'reactstrap';
import { WithFormProps } from 'platform/common/common.type';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/common/components/FormInput/FormInput';
import FormRow from 'platform/common/components/FormRow/FormRow';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import CardForm from 'platform/common/components/CardForm/CardForm';
import withEditForm from 'platform/common/components/WithEditForm/WithEditForm';
import withCreateForm from 'platform/common/components/WithCreateForm/WithCreateForm';
import * as userManagementService from '../userManagement.service';
import { AUTHORITY_CONNECTORS } from './authorityConnector.constant';
import {
    mapModuleAccessLevelsToAuthorities,
    mapAuthoritiesToModuleAccessLevels,
    rolesByGroup,
} from './authorityConnector.utils';
import { MODULE_GROUPS, MODULES } from './roles.constant';
import RoleEditList from './RoleEditList/RoleEditList';
import { Role } from './role.type';

const RoleForm = (props: WithFormProps<Role>) => {
    const { onClose, labels, error, initialValues, onSubmit, canEdit, isEdit, afterSubmit } = props;
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {formProps => (
                <CardForm
                    title={`${labels.prefix} Role`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    onClose={onClose}
                    disabled={!canEdit}
                    onSubmit={formProps.submitForm}
                >
                    {error && <Alert color="danger">{error}</Alert>}
                    <ControlledCard title="General info">
                        <FormRow label="Name">
                            <FormInput name="title" type="text" validate={required} />
                        </FormRow>
                        <RoleEditList
                            moduleAccessLevels={rolesByGroup(
                                MODULE_GROUPS,
                                mapAuthoritiesToModuleAccessLevels(
                                    AUTHORITY_CONNECTORS,
                                    MODULES,
                                    formProps.values.authorities
                                )
                            )}
                            onChange={authorities =>
                                formProps.setFieldValue(
                                    'authorities',
                                    mapModuleAccessLevelsToAuthorities(AUTHORITY_CONNECTORS, authorities)
                                )
                            }
                        />
                    </ControlledCard>
                    {isEdit && (
                        <ControlledCard title="Delete role">
                            {initialValues.assignedUsers.length > 0 && (
                                <div>
                                    <p>This role cannot be deleted because it is assigned to a user:</p>
                                    {initialValues.assignedUsers.map(user => (
                                        <p key={user} className="mb-1">
                                            {user}
                                        </p>
                                    ))}
                                </div>
                            )}
                            <Button
                                disabled={
                                    !!initialValues.assignedUsers.length ||
                                    !canEdit ||
                                    initialValues.adminRole
                                }
                                color="secondary"
                                onClick={() => {
                                    userManagementService.deleteRole(initialValues.id).then(() => {
                                        onClose();
                                        afterSubmit();
                                    });
                                }}
                            >
                                Delete role
                            </Button>
                        </ControlledCard>
                    )}
                </CardForm>
            )}
        </Formik>
    );
};

const withCreateOptions = {
    onOpen: () => Promise.resolve({ authorities: [], title: '' }),
    onSubmit: (model: Role) => userManagementService.createRole(model),
};

const withEditOptions = {
    onOpen: (params: { id: string }) => userManagementService.fetchRole(Number(params.id)),
    onSubmit: (model: Role) => userManagementService.updateRole(model),
};

export const RoleFormCreate = withCreateForm(withCreateOptions)(RoleForm);

export const RoleFormEdit = withEditForm(withEditOptions)(RoleForm);
