import isHotkey from 'is-hotkey';
import { SlideInEditor } from 'platform/advertorial/AdvertorialEditor/editorSlide';
import React, { useMemo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Editor, Node, Transforms } from 'slate';
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate, useSlate } from 'slate-react';
import { AlignButton } from './alignment';
import { Alignment } from './common.types';
import { Button, Icon, Toolbar } from './components';
import { createAdvertorialEditor } from './createAdvertorialEditor';
import { CitationInEditor, CitationToolbarButton, CitationImage } from './editorCitation';
import { HtmlBlockInEditor, HtmlBlockToolbarButton } from './editorHtmlBlock';
import { ImageInEditor, ImageToolbarButton } from './editorImage';
import { ShareButtonInEditor, ShareButtonToolbarButton } from './editorShareButton';
import { LinkInEditor, LinkToolbarButton } from './editorLink';
import { SlideshowInEditor, SlideshowToolbarButton } from './editorSlideshow';
import { TableInEditor, TableToolbarButton } from './editorTable';

export { Node };

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const renderElement = (props: RenderElementProps) => <Element {...props} />;

const renderLeaf = (props: RenderLeafProps) => <Leaf {...props} />;

const isBlockActive = (editor: Editor, format: string) => {
    const [match] = Editor.nodes(editor, {
        match: n => n.type === format,
    });

    return !!match;
};

const isMarkActive = (editor: Editor, format: string) => {
    try {
        // There is a groop of exceptions 'can't find dom dode' which crashes editor when actually its not
        // critical problem
        const marks = Editor.marks(editor);
        return marks ? marks[format] === true : false;
    } catch {
        return false;
    }
};

const toggleMark = (editor: Editor, format: string) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const toggleBlock = (editor: Editor, format: string) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: n => LIST_TYPES.includes(n.type),
        split: true,
    });

    Transforms.setNodes(editor, {
        type: (() => {
            if (isActive) return 'paragraph';
            if (isList) return 'list-item';
            return format;
        })(),
    });

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

type Props = {
    name: string;
    value: Node[];
    onChange: (value: Node[]) => void;
    onBlur: (e: React.FocusEvent) => void;
    placeholder?: string;
    onPreview: () => void;
    streamingLink: string;
};

const AdvertorialEditor = ({
    value,
    onChange,
    onBlur,
    onPreview,
    name,
    placeholder,
    streamingLink,
}: Props) => {
    const editor: ReactEditor = useMemo(createAdvertorialEditor, []);

    // If initial value is not legal (after some editor changes) rendering without normalization
    // might crash editor, so here is some workarount to normalize before first render
    const [isNormalized, setNormalized] = React.useState(false);
    React.useEffect(() => {
        editor.children = value;
        Editor.normalize(editor, { force: true });
        onChange(editor.children);
        setNormalized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isNormalized) return null;
    return (
        <Slate editor={editor} value={value} onChange={onChange}>
            <Toolbar>
                <MarkButton alt="Bold" format="bold" icon="fas fa-bold" />
                <MarkButton alt="Italic" format="italic" icon="fas fa-italic" />
                <MarkButton alt="Underline" format="underline" icon="fas fa-underline" />

                <AlignButton alt="Align left" alignment={Alignment.left} />
                <AlignButton alt="Align right" alignment={Alignment.right} />
                <AlignButton alt="Align center" alignment={Alignment.center} />
                <AlignButton alt="Justify" alignment={Alignment.justify} />
                <BlockButton alt="Heading 1" format="heading-one" icon="fas fa-heading">
                    1
                </BlockButton>
                <BlockButton alt="Heading 2" format="heading-two" icon="fas fa-heading">
                    2
                </BlockButton>
                <BlockButton alt="Heading 3" format="heading-three" icon="fas fa-heading">
                    3
                </BlockButton>
                <BlockButton alt="Quote" format="block-quote" icon="fas fa-quote-right" />
                <BlockButton alt="Ordered list" format="numbered-list" icon="fas fa-list-ol" />
                <BlockButton alt="Unordered list" format="bulleted-list" icon="fas fa-list-ul" />
                <LinkToolbarButton />
                <ImageToolbarButton />
                <SlideshowToolbarButton />
                <CitationToolbarButton />
                <TableToolbarButton />
                <HtmlBlockToolbarButton />
                <ShareButtonToolbarButton streamingLink={streamingLink} />
                <Button onClick={onPreview}>
                    <Icon className={'fas fa-eye'} /> Preview
                </Button>
                <UndoButton />
                <RedoButton />
            </Toolbar>
            <Editable
                name={name}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={placeholder ?? 'Enter some text…'}
                spellCheck
                onBlur={onBlur}
                onKeyDown={(event: any) => {
                    Object.keys(HOTKEYS).forEach((hotkey: keyof typeof HOTKEYS) => {
                        if (isHotkey(hotkey)(event)) {
                            event.preventDefault();
                            const mark = HOTKEYS[hotkey];
                            toggleMark(editor, mark);
                        }
                    });
                }}
            />
        </Slate>
    );
};

const Element = ({ attributes, children, element }: any) => {
    switch (element.type) {
        case 'flex-column':
            return <div className="d-flex flex-column">{children}</div>;
        case 'block-quote':
            return (
                <blockquote
                    {...attributes}
                    className={`text-${element.alignment} ${attributes.className || ''}`}
                >
                    {children}
                </blockquote>
            );
        case 'heading-one':
            return (
                <h1 {...attributes} className={`text-${element.alignment} ${attributes.className || ''}`}>
                    {children}
                </h1>
            );
        case 'heading-two':
            return (
                <h2 {...attributes} className={`text-${element.alignment} ${attributes.className || ''}`}>
                    {children}
                </h2>
            );
        case 'heading-three':
            return (
                <h3 {...attributes} className={`text-${element.alignment} ${attributes.className || ''}`}>
                    {children}
                </h3>
            );
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        case 'link':
            return (
                <LinkInEditor attributes={attributes} element={element}>
                    {children}
                </LinkInEditor>
            );
        case 'image':
            return (
                <ImageInEditor attributes={attributes} element={element}>
                    {children}
                </ImageInEditor>
            );
        case 'slideshow':
            return (
                <SlideshowInEditor attributes={attributes} element={element}>
                    {children}
                </SlideshowInEditor>
            );
        case 'slide':
            return (
                <SlideInEditor attributes={attributes} element={element}>
                    {children}
                </SlideInEditor>
            );
        case 'citation':
            return (
                <CitationInEditor attributes={attributes} element={element}>
                    {children}
                </CitationInEditor>
            );
        case 'citation-image':
            return (
                <CitationImage attributes={attributes} element={element}>
                    {children}
                </CitationImage>
            );
        case 'table':
            return (
                <TableInEditor element={element} attributes={attributes}>
                    {children}
                </TableInEditor>
            );
        case 'table-row':
            return <tr {...attributes}>{children}</tr>;
        case 'table-cell':
            return (
                <td
                    style={{ border: '1px solid black' }}
                    {...attributes}
                    className={`text-${element.alignment} ${attributes.className || ''}`}
                >
                    {children}
                </td>
            );

        case 'html-block':
            return (
                <HtmlBlockInEditor attributes={attributes} element={element}>
                    {children}
                </HtmlBlockInEditor>
            );

        case 'share-button':
            return (
                <ShareButtonInEditor attributes={attributes} element={element}>
                    {children}
                </ShareButtonInEditor>
            );

        default:
            return (
                <p {...attributes} className={`text-${element.alignment} ${attributes.className || ''}`}>
                    {children}
                </p>
            );
    }
};

const Leaf = ({ attributes, children, leaf }: any) => {
    let c = children;
    if (leaf.bold) {
        c = <strong>{c}</strong>;
    }

    if (leaf.italic) {
        c = <em>{c}</em>;
    }

    if (leaf.underline) {
        c = <u>{c}</u>;
    }

    return <span {...attributes}>{c}</span>;
};

const UndoButton = () => {
    const editor = useSlate();
    const ref = React.useRef<HTMLButtonElement>(null);
    return (
        <Button
            ref={ref}
            active={editor.history.undos.length > 0}
            onMouseDown={(event: Event) => {
                event.preventDefault();
                editor.undo();
            }}
        >
            <Icon className="fas fa-undo" />
            {ref.current && <UncontrolledTooltip target={ref.current}>Undo</UncontrolledTooltip>}
        </Button>
    );
};

const RedoButton = () => {
    const editor = useSlate();
    const ref = React.useRef<HTMLButtonElement>(null);
    return (
        <Button
            ref={ref}
            active={editor.history.redos.length > 0}
            onMouseDown={(event: Event) => {
                event.preventDefault();
                editor.redo();
            }}
        >
            <Icon className="fas fa-redo" />
            {ref.current && <UncontrolledTooltip target={ref.current}>Undo</UncontrolledTooltip>}
        </Button>
    );
};

const BlockButton = ({
    format,
    icon,
    children,
    alt,
}: {
    format: string;
    icon: string;
    alt: string;
    children?: any;
}) => {
    const editor = useSlate();
    const ref = React.useRef<HTMLButtonElement>(null);
    return (
        <Button
            ref={ref}
            active={isBlockActive(editor, format)}
            onMouseDown={(event: Event) => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        >
            <Icon className={icon} />
            {children}
            {ref.current && <UncontrolledTooltip target={ref.current}>{alt}</UncontrolledTooltip>}
        </Button>
    );
};

const MarkButton = ({ format, icon, alt }: { format: string; icon: string; alt: string }) => {
    const editor = useSlate();
    const ref = React.useRef<HTMLButtonElement>(null);
    return (
        <Button
            ref={ref}
            active={isMarkActive(editor, format)}
            onMouseDown={(event: Event) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            <Icon className={icon} />
            {ref.current && <UncontrolledTooltip target={ref.current}>{alt}</UncontrolledTooltip>}
        </Button>
    );
};

export default AdvertorialEditor;
