import { Campaign, CampaignFormModel } from 'platform/campaign/campaign.type';
import { Advertiser } from 'platform/adminPanel/advertisers/advertiser.type';
import { Website } from 'platform/adminPanel/websites/website.type';
import { States } from 'platform/common/constants/states.constant';

export const toFormModel = (
    campaign: Partial<Campaign>,
    websites: Website[],
    advertisers: Advertiser[]
): CampaignFormModel => ({
    id: campaign.id,
    name: campaign.name || '',
    advertiserId: campaign.advertiserId,
    websiteId: campaign.websiteId,
    notes: campaign.notes || undefined,
    dates: {
        from: campaign.startDate || '',
        to: campaign.endDate || '',
    },
    websites,
    advertisers,
    status: campaign.status || States.ACTIVE,
});

export const toApi = (campaign: CampaignFormModel): Partial<Campaign> => {
    const { id, name, advertiserId, websiteId, notes, dates, status } = campaign;
    return {
        id,
        name,
        advertiserId,
        websiteId,
        notes,
        startDate: dates.from,
        endDate: dates.to,
        status,
    };
};
