import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { routerActions } from 'connected-react-router';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import { DATA_TYPES } from 'platform/common/dataType';
import { TableCell } from 'platform/common/common.type';
import { WebsiteFormCreate, WebsiteFormEdit } from './WebsiteForm';
import * as websiteService from './website.service';
import { Website } from './website.type';

type State = {
    loading: boolean;
    data: Website[];
    searchQuery: string;
};

const WebsiteList = () => {
    const dispatch = useDispatch();

    const [state, setState] = useState<State>({
        loading: true,
        data: [],
        searchQuery: '',
    });

    const fetchWebsites = () => {
        setState(currentState => ({ ...currentState, loading: true }));
        websiteService
            .fetchWebsites()
            .then(response => {
                setState(currentState => ({ ...currentState, data: response }));
            })
            .finally(() => {
                setState(currentState => ({ ...currentState, loading: false }));
            });
    };

    useEffect(() => {
        fetchWebsites();
    }, []);

    const columnDefinitions = [
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'Site ID',
            accessor: 'siteId',
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'Name',
            accessor: 'name',
            minWidth: 200,
            type: DATA_TYPES.TEXT,
            Cell: (row: TableCell<Website>) => <span>{row.original.name}</span>,
        },
        {
            Header: 'URL',
            accessor: 'url',
            minWidth: 200,
            type: DATA_TYPES.TEXT,
            Cell: (row: TableCell<Website>) => <span>{row.original.url}</span>,
        },
        {
            Header: 'Base URL',
            accessor: 'baseUrl',
            minWidth: 200,
            type: DATA_TYPES.TEXT,
            Cell: (row: TableCell<Website>) => <span>{row.original.baseUrl}</span>,
        },
        {
            Header: 'Edited',
            accessor: 'lastModifiedOn',
            width: 160,
            Cell: (row: TableCell<Website>) => (
                <UpdatedOn date={row.original.lastModifiedOn} updatedBy={row.original.lastModifiedBy} />
            ),
        },
        {
            className: () => 'edit-dropdown pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: (row: TableCell<Website>) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () =>
                                dispatch(routerActions.push(`/admin-panel/websites/${row.original.id}`)),
                        },
                    ]}
                />
            ),
        },
    ];

    const filterList = (list: Website[], searchQuery: string) => {
        if (!searchQuery) return list;
        const query = searchQuery.toLowerCase();
        return list.filter(
            r =>
                String(r.id)
                    .toLowerCase()
                    .includes(query) || r.name.toLowerCase().includes(query)
        );
    };

    return (
        <div>
            <div className="my-4">
                <PageHeader title="Websites" />
            </div>
            <div className="mb-4">
                <ListToolbar
                    value={state.searchQuery}
                    onCreate={() => dispatch(routerActions.push('/admin-panel/websites/new'))}
                    onSearch={searchQuery => setState(currentState => ({ ...currentState, searchQuery }))}
                />
            </div>
            <FormattedTable
                columns={columnDefinitions}
                data={filterList(state.data, state.searchQuery)}
                loading={state.loading}
            />
            <Switch>
                <Route
                    path="/admin-panel/websites/new"
                    render={props => (
                        <WebsiteFormCreate
                            {...props}
                            redirectTo="/admin-panel/websites"
                            canEdit
                            afterSubmit={(website: Website) =>
                                dispatch(routerActions.push(`/admin-panel/websites/${website.id}`))
                            }
                        />
                    )}
                />
                <Route
                    path="/admin-panel/websites/:id"
                    render={props => (
                        <WebsiteFormEdit
                            {...props}
                            redirectTo="/admin-panel/websites"
                            canEdit
                            onClose={fetchWebsites}
                        />
                    )}
                />
            </Switch>
        </div>
    );
};

export default WebsiteList;
