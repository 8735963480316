import React, { useState } from 'react';
import classNames from 'classnames';
import { Formik, FormikProps } from 'formik';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { WithFormProps } from 'platform/common/common.type';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { required, url } from 'platform/common/utils/validators.util';
import FormSelect from 'platform/common/components/FormSelect/FormSelect';
import FormRow from 'platform/common/components/FormRow/FormRow';
import CardForm from 'platform/common/components/CardForm/CardForm';
import FormUploadInput from 'platform/common/components/FormUploadInput/FormUploadInput';
import withEditForm from 'platform/common/components/WithEditForm/WithEditForm';
import withCreateForm from 'platform/common/components/WithCreateForm/WithCreateForm';
import * as campaignService from 'platform/campaign/campaign.service';
import * as advertorialService from 'platform/advertorial/advertorial.service';
import * as adBoardService from 'platform/adBoard/adBoard.service';
import { States } from 'platform/common/constants/states.constant';
import Separator from 'platform/common/components/Separator/Separator';
import TeaserChangelog from './TeaserChangelog';
import * as TeaserMapper from './teaser.mapper';
import { TeaserFormModel } from './teaser.type';
import * as teaserService from './teaser.service';

const FormFields = ({
    initialValues,
    formProps,
}: {
    initialValues: TeaserFormModel;
    formProps: FormikProps<TeaserFormModel>;
}) => (
    <ControlledCard title="General info">
        <FormRow label="Name">
            <FormInput name="name" type="text" validate={required} />
        </FormRow>
        <FormRow label="Campaign">
            <FormSelect
                name="campaignId"
                options={initialValues.campaigns.map(campaign => ({
                    value: campaign.id,
                    label: campaign.name,
                }))}
                onChange={() => {
                    formProps.setFieldValue('advertorialId', null);
                }}
                isClearable={false}
                validate={required}
            />
        </FormRow>
        <FormRow label="Streaming link">
            <FormInput name="streamingLink" type="text" disabled />
        </FormRow>
        <FormRow label="Title" inline>
            <FormInput style={{ width: '100%' }} name="title" type="text" />
            <span className="pl-1 text-muted font-xs">{formProps.values.title?.length || 0}</span>
        </FormRow>
        <FormRow label="Description" inline>
            <FormInput style={{ width: '100%' }} name="description" type="textarea" />
            <span className="pl-1 text-muted font-xs">{formProps.values.description?.length || 0}</span>
        </FormRow>
        <FormRow label="Image">
            <FormUploadInput name="image" acceptableMimeTypes="image/jpeg, image/png, image/gif" />
        </FormRow>
        <FormRow label="Link text" inline>
            <FormInput style={{ width: '100%' }} name="linkText" type="text" />
            <span className="pl-1 text-muted font-xs">{formProps.values.linkText?.length || 0}</span>
        </FormRow>
        <FormRow label="Linked">
            <FormSelect
                name="linkType"
                options={[
                    { value: 'ADBOARD', label: 'AdBoard' },
                    { value: 'ADVERTORIAL', label: 'Advertorial' },
                    { value: 'URL', label: 'URL' },
                ]}
                onChange={value => {
                    if (value === 'URL') {
                        formProps.setFieldValue('advertorialId', null);
                        formProps.setFieldValue('adBoardId', null);
                    } else if (value === 'ADBOARD') {
                        formProps.setFieldValue('advertorialId', null);
                    } else if (value === 'ADVERTORIAL') {
                        formProps.setFieldValue('adBoardId', null);
                    }
                }}
                validate={required}
                isClearable={false}
            />
        </FormRow>
        {formProps.values.linkType === 'ADBOARD' && (
            <FormRow label="Linked AdBoard">
                <FormSelect
                    name="adBoardId"
                    options={initialValues.adBoards
                        .filter(advertorial => advertorial.campaignId === formProps.values.campaignId)
                        .map(advertorial => ({
                            value: advertorial.id,
                            label: advertorial.name,
                        }))}
                    validate={required}
                    isClearable={false}
                />
            </FormRow>
        )}
        {formProps.values.linkType === 'ADVERTORIAL' && (
            <FormRow label="Linked Advertorial">
                <FormSelect
                    name="advertorialId"
                    options={initialValues.advertorials
                        .filter(advertorial => advertorial.campaignId === formProps.values.campaignId)
                        .map(advertorial => ({
                            value: advertorial.id,
                            label: advertorial.name,
                        }))}
                    validate={required}
                    isClearable={false}
                />
            </FormRow>
        )}
        {formProps.values.linkType === 'URL' && (
            <FormRow label="Link URL">
                <FormInput name="linkUrl" type="text" validate={[required, url]} />
            </FormRow>
        )}
        <Separator label="Optional" />
        <FormRow label="Adboard layout">
            <FormSelect
                name="adBoardLayout"
                options={[
                    { value: 'image-left', label: 'Image left' },
                    { value: 'image-right', label: 'Image right' },
                    { value: 'image-top', label: 'Image top' },
                ]}
            />
        </FormRow>
    </ControlledCard>
);

const TeaserForm = (props: WithFormProps<TeaserFormModel>) => {
    enum Tab {
        TEASER = 'Teaser',
        CHANGELOG = 'Changelog',
    }

    const { onSubmit, onClose, labels, initialValues, canEdit } = props;

    const [activeTab, setActiveTab] = useState(Tab.TEASER);

    const isTabActive = (tab: Tab) => activeTab === tab;

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(formProps: FormikProps<TeaserFormModel>) => (
                <CardForm
                    title={`${labels.prefix} Teaser`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    onClose={onClose}
                    disabled={!canEdit}
                    onSubmit={formProps.submitForm}
                >
                    {initialValues.id && (
                        <>
                            <div className="mb-4 custom-tabs">
                                <Nav tabs>
                                    {Object.values(Tab).map(label => (
                                        <NavItem key={label}>
                                            <NavLink
                                                onClick={() => setActiveTab(label)}
                                                className={classNames({ active: isTabActive(label) })}
                                            >
                                                {label}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </div>
                            {isTabActive(Tab.TEASER) && (
                                <FormFields initialValues={initialValues} formProps={formProps} />
                            )}
                            {isTabActive(Tab.CHANGELOG) && <TeaserChangelog id={initialValues.id} />}
                        </>
                    )}
                    {!initialValues.id && <FormFields initialValues={initialValues} formProps={formProps} />}
                </CardForm>
            )}
        </Formik>
    );
};

const withCreateOptions = {
    onOpen: async () => {
        const [advertorials, campaigns, adBoards]: any = await Promise.all([
            advertorialService.fetchAdvertorials(),
            campaignService.fetchCampaigns({ statuses: [States.ACTIVE] }),
            adBoardService.fetchAdBoards(),
        ]);
        return TeaserMapper.toFormModel({}, advertorials, campaigns, adBoards);
    },
    onSubmit: (model: TeaserFormModel) => teaserService.createTeaser(TeaserMapper.toApi(model)),
};

const withEditOptions = {
    onOpen: async (params: { id: string }) => {
        const [teaser, advertorials, campaigns, adBoards]: any = await Promise.all([
            teaserService.fetchTeaser(Number(params.id)),
            advertorialService.fetchAdvertorials(),
            campaignService.fetchCampaigns({ statuses: [States.ACTIVE] }),
            adBoardService.fetchAdBoards(),
        ]);
        return TeaserMapper.toFormModel(teaser, advertorials, campaigns, adBoards);
    },
    onSubmit: (model: TeaserFormModel) => teaserService.updateTeaser(TeaserMapper.toApi(model)),
};

export const TeaserFormCreate = withCreateForm(withCreateOptions)(TeaserForm);

export const TeaserFormEdit = withEditForm(withEditOptions)(TeaserForm);
