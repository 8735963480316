import React from 'react';
import { Button } from 'reactstrap';
import { isFunction, flow, isString, mapValues, uniqueId } from 'lodash-es';
import { DATA_TYPES } from '../../dataType';
import { TableColumn, TableColumnProcessed } from './FormattedTable';
import { calculateColumnWidth } from './TableColumnWidthCalculator';
import { TableCell, Modify } from '../../common.type';

type TableColumnVsId = Modify<
    TableColumn,
    {
        id: string;
        columns?: TableColumnVsId[];
    }
>;

export const DEFAULT_COLUMN_CLASS_NAMES: {
    [key: string]: { headerClassName: string; className: string };
} = mapValues(DATA_TYPES, type => {
    switch (type.typeId) {
        case DATA_TYPES.TEXT.typeId:
            return {
                headerClassName: 'cell-align-left',
                className: 'cell-align-left',
            };
        default:
            return {
                headerClassName: 'cell-align-right',
                className: 'cell-align-right',
            };
    }
});

const getColumnMinWidth = (data: { [key: string]: any }[], column: TableColumnVsId) => {
    if (!data || column.exportOnly || column.width) {
        return undefined;
    }

    if (column.minWidth) {
        return column.minWidth;
    }

    const cellPadding = 20;
    const sortMarkerWidth = 7;
    const maxWidth = 400 - cellPadding - sortMarkerWidth;

    const header = column.HeaderText || (isString(column.Header) && column.Header) || '';

    const textWidth = Math.max(
        ...data.map(row => {
            const accessor = typeof column.accessor === 'function' ? column.accessor(row) : column.accessor;
            const rowValue = row[accessor || ''];
            return calculateColumnWidth(`${column.type ? column.type.format(rowValue) : rowValue}`);
        }),
        ...header.split(' ').map(calculateColumnWidth)
    );
    return Math.min(maxWidth, textWidth) + cellPadding + sortMarkerWidth;
};

const addColumnId = (column: TableColumn): TableColumnVsId => {
    if (column.id) return column as TableColumnVsId;
    if (column.accessor && typeof column.accessor === 'string')
        return {
            ...column,
            id: column.accessor,
        } as TableColumnVsId;
    return {
        ...column,
        id: uniqueId('th'),
    } as TableColumnVsId;
};

const addDefaultClasses = (column: TableColumnVsId): TableColumnProcessed => {
    const { type = DATA_TYPES.TEXT } = column;
    const { typeId } = type;
    return {
        ...column,
        headerClassName: column.headerClassName
            ? column.headerClassName(DEFAULT_COLUMN_CLASS_NAMES[typeId].headerClassName)
            : DEFAULT_COLUMN_CLASS_NAMES[typeId].headerClassName,
        className: column.className
            ? column.className(DEFAULT_COLUMN_CLASS_NAMES[typeId].className)
            : DEFAULT_COLUMN_CLASS_NAMES[typeId].className,
    };
};

const passFormattedValueToCell = (column: TableColumnProcessed): TableColumnProcessed => ({
    ...column,
    Cell: (props: TableCell<any>) => {
        const formattedValue = column.type ? column.type.format(props.value) : props.value;
        if (column.Cell) return column.Cell({ ...props, formattedValue });
        // undefined value can not be returned from component render function
        return formattedValue === undefined ? null : formattedValue;
    },
});

const wrapCollapsableHeader = (collapsed: string[], onCollapseClick: (id: string) => () => void) => (
    column: TableColumnProcessed
) => {
    if (!column.collapsable) return column;

    const isCollapsed = collapsed.includes(column.id);
    const { Header } = column;
    if (!isCollapsed) {
        return {
            ...column,
            Header: (props: any) => {
                const WrappedComponent = isFunction(Header)
                    ? Header
                    : ({ formattedValue }: { formattedValue: any }) => <>{formattedValue || ''}</>;
                return (
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <WrappedComponent {...props} />
                        <Button
                            color="link"
                            className="text-muted mx-1 p-1 font-xs"
                            onClick={onCollapseClick(column.id)}
                        >
                            <i className="fas fa-angle-double-left" />
                        </Button>
                    </div>
                );
            },
        };
    }

    return {
        ...column,
        Header: () => (
            <div>
                <Button
                    color="link"
                    className="text-muted mx-0 p-0 font-xs"
                    onClick={onCollapseClick(column.id)}
                >
                    <i className="fas fa-angle-double-right" />
                </Button>
            </div>
        ),
        columns:
            column.columns && column.columns.length
                ? [
                      {
                          headerClassName: () => 'collapsed collapsed-title',
                          className: () => 'collapsed',
                          Header,
                          id: column.columns[0].id,
                          Cell: () => '',
                          width: 35,
                      },
                  ]
                : undefined,
        Cell: column.columns && column.columns.length ? undefined : () => '.',
    };
};

const passFormattedValueToHeader = (column: TableColumnProcessed): TableColumnProcessed => {
    const { Header, type = DATA_TYPES.TEXT } = column;
    if (isString(Header)) {
        return {
            ...column,
            Header: type.formatTitle(Header),
            HeaderText: type.formatTitle(Header),
        };
    }
    if (Header) {
        return {
            ...column,
            Header: props => <Header {...props} formattedValue={type.formatTitle(column.HeaderText || '')} />,
            HeaderText: type.formatTitle(column.HeaderText),
        };
    }
    return column;
};

const calcAutoWidth = (data: Object[]) => (column: TableColumnProcessed): TableColumnProcessed => {
    if (!column.autoWidth) return column;
    const minWidth = getColumnMinWidth(data, column);
    return {
        ...column,
        minWidth: minWidth !== undefined ? minWidth + (column.autoWidthAdditionalWidth || 0) : undefined,
    };
};

export const formatColumns = (
    columns: TableColumn[],
    data: Object[],
    collapsed: string[],
    onCollapseClick: (id: string) => () => void
): TableColumnProcessed[] =>
    columns.map(
        flow(
            addColumnId,
            addDefaultClasses,
            passFormattedValueToCell,
            wrapCollapsableHeader(collapsed, onCollapseClick),
            passFormattedValueToHeader,
            calcAutoWidth(data),
            (c: TableColumnProcessed): TableColumnProcessed => ({
                ...c,
                columns: c.columns && formatColumns(c.columns, data, collapsed, onCollapseClick),
            })
        )
    );
