import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Alert } from 'reactstrap';
import { WithFormProps } from 'platform/common/common.type';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/common/components/FormInput/FormInput';
import FormStateSelect from 'platform/common/components/FormStateSelect/FormStateSelect';
import FormRow from 'platform/common/components/FormRow/FormRow';
import CardForm from 'platform/common/components/CardForm/CardForm';
import withEditForm from 'platform/common/components/WithEditForm/WithEditForm';
import withCreateForm from 'platform/common/components/WithCreateForm/WithCreateForm';
import * as websiteService from 'platform/adminPanel/websites/website.service';
import * as advertiserService from 'platform/adminPanel/advertisers/advertiser.service';
import { Website } from 'platform/adminPanel/websites/website.type';
import { Advertiser } from 'platform/adminPanel/advertisers/advertiser.type';
import CustomAccess from './CustomAccess';
import SeatMapper from './seat.mapper';
import { SeatFormModel } from './seat.type';
import * as userService from '../userManagement.service';

const SeatForm = ({
    onClose,
    onSubmit,
    labels,
    error,
    canEdit,
    initialValues,
}: WithFormProps<SeatFormModel>) => {
    const [websites, setWebsites] = useState<Website[]>([]);
    const [advertisers, setAdvertisers] = useState<Advertiser[]>([]);

    useEffect(() => {
        websiteService.fetchWebsites().then(setWebsites);
        advertiserService.fetchAdvertisers().then(setAdvertisers);
    }, []);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {formProps => (
                <CardForm
                    title={`${labels.prefix} Seat`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    onClose={onClose}
                    disabled={!canEdit}
                    onSubmit={formProps.submitForm}
                >
                    {error && <Alert color="danger">{error}</Alert>}
                    <ControlledCard title="General info">
                        <FormRow label="Name">
                            <FormInput name="name" type="text" validate={required} />
                        </FormRow>
                        <FormRow label="Status">
                            <FormStateSelect
                                name="state"
                                initialValue={initialValues.state}
                                validate={required}
                            />
                        </FormRow>
                        <FormRow label="Company name">
                            <FormInput name="companyName" type="text" validate={required} />
                        </FormRow>
                    </ControlledCard>
                    <ControlledCard title="User access" subtitle="required">
                        <FormRow label="Website access">
                            <CustomAccess
                                name="websiteAccess"
                                buttonAllText="User has access to all websites."
                                buttonNoneText="User has no access to websites."
                                customAccessOptions={websites.map(w => ({
                                    value: w.id,
                                    label: w.name,
                                }))}
                            />
                        </FormRow>
                        <FormRow label="Advertiser access">
                            <CustomAccess
                                name="advertiserAccess"
                                buttonAllText="User has access to all advertisers."
                                buttonNoneText="User has no access to advertisers."
                                customAccessOptions={advertisers.map(w => ({
                                    value: w.id,
                                    label: w.name,
                                }))}
                            />
                        </FormRow>
                    </ControlledCard>
                    {initialValues.assignedUsers.length > 0 && (
                        <ControlledCard title="Usage">
                            <p>This seat is assigned to these users:</p>
                            {initialValues.assignedUsers.map(user => (
                                <p key={user.email}>
                                    {user.name} - {user.email}
                                </p>
                            ))}
                        </ControlledCard>
                    )}
                </CardForm>
            )}
        </Formik>
    );
};

const withCreateOptions = {
    onOpen: () => Promise.resolve(SeatMapper.toFormModel({}, [])),
    onSubmit: (model: SeatFormModel) => userService.createSeat(SeatMapper.toApiModel(model)),
};

const withEditOptions = {
    onOpen: ({ id }: { id: string }) =>
        Promise.all([
            userService.fetchUsers({ seatId: Number(id) }),
            userService.fetchSeat(Number(id)),
        ]).then(([userList, seat]) => SeatMapper.toFormModel(seat, userList)),
    onSubmit: (model: SeatFormModel) => userService.updateSeat(SeatMapper.toApiModel(model)),
};

export const SeatFormCreate = withCreateForm(withCreateOptions)(SeatForm);

export const SeatFormEdit = withEditForm(withEditOptions)(SeatForm);
