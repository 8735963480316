import React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { minLength, required } from 'platform/common/utils/validators.util';

type Props = {
    loading: boolean;
    submitError: boolean;
    resetPassword: boolean;
    errorMessage?: string;
    activationKey: string;
    onSubmit: (activationKey: string, password: string, params: { reset: boolean }) => void;
};

const defaultProps = {
    resetPassword: false,
};

const SetPasswordForm = ({
    loading,
    activationKey,
    resetPassword,
    submitError,
    errorMessage,
    onSubmit,
}: Props) => (
    <Formik
        initialValues={{
            userName: '',
            password: '',
        }}
        onSubmit={data => onSubmit(activationKey, data.password, { reset: resetPassword })}
    >
        {formProps => (
            <Form onSubmit={formProps.handleSubmit}>
                {submitError && <Alert color="danger">{errorMessage}</Alert>}

                <p className="font-weight-bold">Please enter your new password</p>

                <FormGroup>
                    <FormInput
                        id="password"
                        name="password"
                        type="password"
                        leftAddOn={{
                            className: 'fa fa-lock',
                        }}
                        validate={[required, minLength(8)]}
                        label="New password"
                    />
                </FormGroup>

                <FormGroup>
                    <FormInput
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        leftAddOn={{
                            className: 'fa fa-lock',
                        }}
                        validate={[
                            required,
                            value =>
                                value !== formProps.values.password ? 'passwords do not match' : undefined,
                        ]}
                        label="Confirm new password"
                    />
                </FormGroup>
                <Row>
                    <Col xs="6">
                        <Button type="submit" color="primary" className="px-4" disabled={loading}>
                            Set password
                        </Button>
                    </Col>
                    <Col xs="6" className="text-right">
                        <Link className="px-0" to="/login">
                            Return to login page
                        </Link>
                    </Col>
                </Row>
            </Form>
        )}
    </Formik>
);

SetPasswordForm.defaultProps = defaultProps;

export default SetPasswordForm;
