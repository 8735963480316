import escapeHtml from 'escape-html';
import { Node, Text } from 'slate';
import { serializeImageNode } from 'platform/advertorial/AdvertorialEditor/editorImage';
import { serializeSlideshow } from 'platform/advertorial/AdvertorialEditor/editorSlideshow';
import { serializeShareButtonNode } from 'platform/advertorial/AdvertorialEditor/editorShareButton';
import { gliderJs, gliderCss } from './slideshowLib.src';
import { serializeCitation, serializeCitationImage } from './editorCitation';

export const defaultStyles = `
<style>
    table { width: 100%; }
    figure { margin: 0 }

    .d-flex { display: flex; }
    .d-block { display: block; }
    .flex-column { flex-direction: column; }
    .flex-row { flex-direction: row; }
    .flex-row-reverse { flex-direction: row-reverse; }

    .sdo-image { display: flex; }
    .sdo-image > img { display: block; }

    .sdo-flex-align-left { justify-content: flex-start; }
    .sdo-flex-align-right { justify-content: flex-end; }
    .sdo-flex-align-center { justify-content: center; }
    .sdo-flex-align-left { justify-content: space-between; }

    .sdo-text-align-left { text-align: left; }
    .sdo-text-align-right { text-align: right; }
    .sdo-text-align-center { text-align: center; }
    .sdo-text-align-justify { text-align: justify; }

    .sdo-float-left { float: left; margin-right: 1em; }
    .sdo-float-right { float: right; margin-left: 1em; }

</style>
`;

const serializeTextNode = (node: Text) => {
    let text = escapeHtml(node.text);
    if (node.bold) {
        text = `<strong>${text}</strong>`;
    }

    if (node.italic) {
        text = `<em>${text}</em>`;
    }

    if (node.underline) {
        text = `<u>${text}</u>`;
    }

    return `<span>${text}</span>`;
};

export const serializeToHtml = (nodes: Node[]): string =>
    nodes
        .map(node => {
            if (Text.isText(node)) {
                return serializeTextNode(node);
            }

            const children = serializeToHtml(node.children);

            switch (node.type) {
                case 'flex-column':
                    return `<div class="d-flex flex-column">${children}</div>`;
                case 'block-quote':
                    return `<blockquote class="sdo-text-align-${node.alignment}">${children}</blockquote>`;
                case 'bulleted-list':
                    return `<ul>${children}</ul>`;
                case 'heading-one':
                    return `<h1 class="sdo-text-align-${node.alignment}">${children}</h1>`;
                case 'heading-two':
                    return `<h2 class="sdo-text-align-${node.alignment}">${children}</h2>`;
                case 'heading-three':
                    return `<h3 class="sdo-text-align-${node.alignment}">${children}</h3>`;
                case 'list-item':
                    return `<li class="sdo-text-align-${node.alignment}">${children}</li>`;
                case 'numbered-list':
                    return `<ol>${children}</ol>`;
                case 'link':
                    return `<a class="link" target="${node.target}" href="${node.url}">${children}</a>`;
                case 'image':
                    return serializeImageNode(node as any, children);
                case 'citation':
                    return serializeCitation(node as any, children);
                case 'citation-image':
                    return serializeCitationImage(node as any, children);
                case 'table':
                    return `<div class="sdo-table-wrapper d-flex sdo-float-${node.float} sdo-flex-align-${
                        node.alignment
                    }" style="width: ${node.width ? `${node.width}${node.widthUnit};` : '100%'}">
                                <table>
                                    <tbody>${children}</tbody>
                                </table>
                            </div>`;
                case 'table-row':
                    return `<tr>${children}</tr>`;
                case 'table-cell':
                    return `<td class="sdo-text-align-${node.alignment}">${children}</td>`;
                case 'slideshow':
                    return serializeSlideshow(node as any, serializeToHtml);

                case 'html-block':
                    return `<div class="sdo-block-html d-flex sdo-float-${node.float} sdo-flex-align-${
                        node.alignment
                    } ${node.className || ''}" style="width: ${
                        node.width ? `${node.width}${node.widthUnit};` : '100%'
                    }">          
                        ${node.content}
                            </div>`;
                case 'paragraph':
                    return node.children.length === 1 && node.children[0].text === ''
                        ? '<br>'
                        : `<p class="sdo-text-align-${node.alignment}">${children}</p>`;
                case 'share-button':
                    return serializeShareButtonNode(node as any);
                default:
                    return `<div>${children}</div>`;
            }
        })
        .join('\n');

export const hasNode = (nodes: Node[], type: string): boolean =>
    nodes.some(n => n.type === type || hasNode(n.children || [], type));

export const getSlideshowLibCode = () => `<style>${gliderCss}</style><script>${gliderJs}</script>`;

export const getShareButtonCode = () => `<script>
      function onShareButtonClick(url) {
        window.open(url.replace('[advertorialURL]', encodeURIComponent(document.referrer)));
      }
  </script>`;

export const iframeSizingCode = `
<script>(function () {
    var lastDocumentHeight = 0;
    function syncHeightWithParrent () {
        var currentDocumentHeight = document.body.offsetHeight;
        if (lastDocumentHeight !== currentDocumentHeight) {
            lastDocumentHeight = currentDocumentHeight;
            window.parent.postMessage({ frameHeight: currentDocumentHeight }, '*');
        }
    }
    window.addEventListener('load', syncHeightWithParrent)
    window.addEventListener('resize', syncHeightWithParrent)
})()</script>
`;

export const generateAdvertorialOutput = (nodes: Node[], trackingScripts: string[]) => `
  ${serializeToHtml(nodes)}
  ${trackingScripts.join('')}
  ${defaultStyles}
  ${hasNode(nodes, 'slideshow') ? getSlideshowLibCode() : ''}
  ${hasNode(nodes, 'share-button') ? getShareButtonCode() : ''}
  ${iframeSizingCode}
`;
