import { AdBoard, AdBoardFormModel } from 'platform/adBoard/adDboard.types';
import { Teaser } from 'platform/teaser/teaser.type';
import { Campaign } from 'platform/campaign/campaign.type';

export const toFormModel = (
    adBoard: Partial<AdBoard>,
    campaigns: Campaign[],
    teasers: Teaser[]
): AdBoardFormModel => {
    const { id, name } = adBoard;
    return {
        id,
        name: name || '',
        campaignId: adBoard.campaignId,
        teaserIds: adBoard.teaserIds || [],
        campaigns,
        teasers,
    };
};

export const toApi = (model: AdBoardFormModel): Partial<AdBoard> => {
    const { id, name, campaignId, teaserIds } = model;
    return {
        id,
        name,
        campaignId,
        teaserIds,
    };
};
