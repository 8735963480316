import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import classNames from 'classnames';
import { Nav, NavItem, NavLink, Alert } from 'reactstrap';
import { WithFormProps } from 'platform/common/common.type';
import { fetchCampaigns } from 'platform/campaign/campaign.service';
import { required, containsScriptIframeImg } from 'platform/common/utils/validators.util';
import { useModal } from 'platform/common/components/Modal/Modal';
import { usePromise } from 'platform/common/hooks/usePromise';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormInput from 'platform/common/components/FormInput/FormInput';
import FormRow from 'platform/common/components/FormRow/FormRow';
import FormSelect from 'platform/common/components/FormSelect/FormSelect';
import withCreateForm from 'platform/common/components/WithCreateForm/WithCreateForm';
import withEditForm from 'platform/common/components/WithEditForm/WithEditForm';
import { States } from 'platform/common/constants/states.constant';
import AdvertorialChangelog from 'platform/advertorial/AdvertorialChangelog';
import { useSelector } from 'react-redux';
import { generateStaticSocialButtons } from 'platform/advertorial/social.service';
import FormUploadInput from 'platform/common/components/FormUploadInput/FormUploadInput';
import { base64ToDataUrl, dataUrlToBase64 } from 'platform/common/utils/file.util';
import { Advertorial } from './advertorial.type';
import {
    createAdvertorial,
    fetchAdvertorial,
    updateAdvertorial,
    lockAdvertorial,
    unlockAdvertorial,
} from './advertorial.service';
import { generateAdvertorialOutput } from './AdvertorialEditor/serialize';
import AdvertorialPreview from './AdvertorialPreview';
import FormAdvertorialEditor, { Node } from './AdvertorialEditor/FormAdvertorialEditor';
import FormDynamicOptionList from '../common/components/FormDynamicOptionList/FormDynamicOptionList';
import { selectors as authSelectors } from '../app/auth.duck';

export type AdvertorialFormType = {
    id: number;
    name: string;
    content: Node[];
    campaignId: number;
    streamingLink: string;
    trackingScripts: string[];
    locked?: boolean;
    lockedByUser?: string;

    headerHeadline: string;
    headerImageCaption: string;
    headerText: string;
    socialIconText: string;
    socialIconSubject: string;

    image?: {
        content: string;
        name: string;
    };

    external: boolean;
    externalHtmlOutput?: string;
};

const FormFields = ({
    formProps,
    campaignsOptions,
    campaignsLoading,
}: {
    formProps: FormikProps<AdvertorialFormType>;
    campaignsLoading: boolean;
    campaignsOptions: { value: number; label: string }[];
}) => {
    const { showModal } = useModal();
    return (
        <>
            <ControlledCard title="General info">
                <FormRow label="Name">
                    <FormInput name="name" type="text" validate={required} />
                </FormRow>
                <FormRow label="Campaign">
                    <FormSelect
                        name="campaignId"
                        validate={required}
                        options={campaignsOptions}
                        isLoading={campaignsLoading}
                    />
                </FormRow>
                <FormRow label="Tracking scripts">
                    <FormDynamicOptionList
                        name="trackingScripts"
                        buttonTitle="Add"
                        inputValidation={containsScriptIframeImg}
                    />
                </FormRow>
            </ControlledCard>
            <ControlledCard title="Header">
                <FormRow label="Headline" inline>
                    <FormInput
                        style={{ width: '100%' }}
                        name="headerHeadline"
                        type="text"
                        validate={required}
                    />
                    <span className="pl-1 text-muted font-xs">
                        {formProps.values.headerHeadline?.length || 0}
                    </span>
                </FormRow>
                <FormRow label="Image">
                    <FormUploadInput
                        name="image"
                        acceptableMimeTypes="image/jpeg, image/png, image/gif"
                        validate={required}
                    />
                </FormRow>
                <FormRow label="Caption" inline>
                    <FormInput
                        style={{ width: '100%' }}
                        name="headerImageCaption"
                        type="text"
                        validate={required}
                    />
                    <span className="pl-1 text-muted font-xs">
                        {formProps.values.headerImageCaption?.length || 0}
                    </span>
                </FormRow>
                <FormRow label="Text" inline>
                    <FormInput
                        style={{ width: '100%' }}
                        name="headerText"
                        type="textarea"
                        validate={required}
                    />
                    <span className="pl-1 text-muted font-xs">
                        {formProps.values.headerText?.length || 0}
                    </span>
                </FormRow>
            </ControlledCard>
            <ControlledCard title="Social Icons">
                <FormRow label="Text" inline>
                    <FormInput
                        style={{ width: '100%' }}
                        name="socialIconText"
                        type="text"
                        validate={required}
                    />
                    <span className="pl-1 text-muted font-xs">
                        {formProps.values.socialIconText?.length || 0}
                    </span>
                </FormRow>
                <FormRow label="Subject" inline>
                    <FormInput
                        style={{ width: '100%' }}
                        name="socialIconSubject"
                        type="text"
                        validate={required}
                    />
                    <span className="pl-1 text-muted font-xs">
                        {formProps.values.socialIconSubject?.length || 0}
                    </span>
                </FormRow>
            </ControlledCard>
            <ControlledCard title="Content">
                {formProps.values.external && (
                    <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: formProps.values.externalHtmlOutput! }}
                    />
                )}
                {!formProps.values.external && (
                    <FormAdvertorialEditor
                        name="content"
                        validate={required}
                        placeholder="Enter advertorial content"
                        onPreview={() =>
                            showModal(toggle => (
                                <AdvertorialPreview
                                    isOpen
                                    toggle={toggle}
                                    bodyContent={generateAdvertorialOutput(
                                        formProps.values.content,
                                        formProps.values.trackingScripts
                                    )}
                                />
                            ))
                        }
                        streamingLink={formProps.values.streamingLink}
                    />
                )}
            </ControlledCard>
        </>
    );
};

// Responsible for lock state evaluation and lock keeping and releasing
// We presume that initial lock is done before advertorial load
const useLocking = (advertorial: AdvertorialFormType): string | null => {
    const account = useSelector(authSelectors.account);

    const { locked, lockedByUser, id } = advertorial;
    const lockedByMe = lockedByUser === account.login;

    // Setup lock refresh if me is the locker and it's not new
    React.useEffect(() => {
        if (!locked || !lockedByMe || !id) return () => {};
        const interval = setInterval(() => lockAdvertorial(id), 30 * 1000);
        return () => {
            clearInterval(interval);
            unlockAdvertorial(id);
        };

        // This effect should work only on mount/unmount and advertorial should not be changed, because its from initial values
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return locked && !lockedByMe
        ? `${lockedByUser} is currently editing Advertorial ${advertorial.name}`
        : null;
};

const AdvertorialForm = (props: WithFormProps<AdvertorialFormType>) => {
    enum Tab {
        ADVERTORIAL = 'Advertorial',
        CHANGELOG = 'Changelog',
    }

    const { onSubmit, onClose, labels, initialValues, canEdit } = props;

    const [{ data: campaignsOptions, loading: campaignsLoading }] = usePromise(
        [],
        () =>
            fetchCampaigns({ statuses: [States.ACTIVE] }).then(campaigns =>
                campaigns.map(c => ({ value: c.id, label: c.name }))
            ),
        []
    );

    const [activeTab, setActiveTab] = useState(Tab.ADVERTORIAL);

    const isTabActive = (tab: Tab) => activeTab === tab;

    const lockWarning = useLocking(initialValues);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(formProps: FormikProps<AdvertorialFormType>) => (
                <CardForm
                    title={`${labels.prefix} advertorial`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    onClose={onClose}
                    disabled={!canEdit || !!lockWarning}
                    onSubmit={formProps.submitForm}
                >
                    {initialValues.id && (
                        <>
                            <div className="mb-4 custom-tabs">
                                <Nav tabs>
                                    {Object.values(Tab).map(label => (
                                        <NavItem key={label}>
                                            <NavLink
                                                onClick={() => setActiveTab(label)}
                                                className={classNames({ active: isTabActive(label) })}
                                            >
                                                {label}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </div>
                            {isTabActive(Tab.ADVERTORIAL) && (
                                <>
                                    {lockWarning && <Alert color="warning">{lockWarning}</Alert>}
                                    <FormFields
                                        formProps={formProps}
                                        campaignsOptions={campaignsOptions}
                                        campaignsLoading={campaignsLoading}
                                    />
                                </>
                            )}
                            {isTabActive(Tab.CHANGELOG) && <AdvertorialChangelog id={initialValues.id} />}
                        </>
                    )}
                    {!initialValues.id && (
                        <FormFields
                            formProps={formProps}
                            campaignsOptions={campaignsOptions}
                            campaignsLoading={campaignsLoading}
                        />
                    )}
                </CardForm>
            )}
        </Formik>
    );
};

const toAdvertorialForm = (advertorial: Advertorial): AdvertorialFormType => ({
    ...advertorial,
    image:
        advertorial.imageContent && advertorial.imageFilename
            ? {
                  content: base64ToDataUrl(advertorial.imageContent),
                  name: advertorial.imageFilename,
              }
            : undefined,
    trackingScripts: advertorial.trackingScripts || [],
    content: (() => {
        if (advertorial.jsonSource) {
            const val = JSON.parse(advertorial.jsonSource);
            if (Array.isArray(val) && val.length) {
                return val;
            }
        }
        return [{ type: 'paragraph', children: [{ text: '' }] }];
    })(),
});

const toAdvertorialApi = (advertorial: AdvertorialFormType): Advertorial => ({
    ...advertorial,
    jsonSource: JSON.stringify(advertorial.content),
    htmlOutput: generateAdvertorialOutput(advertorial.content, advertorial.trackingScripts),
    htmlSocialIconsStatic: generateStaticSocialButtons(
        advertorial.socialIconSubject,
        advertorial.socialIconText
    ),
    imageContent: advertorial.image && dataUrlToBase64(advertorial.image.content),
    imageFilename: advertorial.image && advertorial.image.name,
});

const newAdvertorial: Partial<AdvertorialFormType> = {
    id: undefined,
    campaignId: undefined,
    name: '',
    content: [{ type: 'paragraph', children: [{ text: '' }] }],
    trackingScripts: [],

    headerHeadline: '',
    headerText: '',
    socialIconText: '',
    socialIconSubject: '',
    external: false,
};

const withCreateOptions = {
    onOpen: async () => newAdvertorial,
    onSubmit: (advertorial: AdvertorialFormType) => createAdvertorial(toAdvertorialApi(advertorial)),
    size: 1200,
};

const withEditOptions = {
    onOpen: async (params: { id: string }) => {
        const id = Number(params.id);
        await lockAdvertorial(id);
        const advertorial = await fetchAdvertorial(id);
        return toAdvertorialForm(advertorial);
    },
    onSubmit: (advertorial: AdvertorialFormType) => updateAdvertorial(toAdvertorialApi(advertorial)),
    size: 1200,
};

export const AdvertorialFormCreate = withCreateForm(withCreateOptions)(AdvertorialForm);

export const AdvertorialFormEdit = withEditForm(withEditOptions)(AdvertorialForm);
