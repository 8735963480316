import { forOwn } from 'lodash-es';

export const serializeToQuery = (obj: { [key: string]: any }, prefix?: string) => {
    const str: string[] = [];

    forOwn(obj, (v, k) => {
        const key = prefix || k;
        str.push(
            typeof v === 'object'
                ? serializeToQuery(v, key)
                : `${encodeURIComponent(key)}=${encodeURIComponent(v)}`
        );
    });

    return str.join('&');
};

export const currentTimeSeconds = () => new Date().getTime() / 1000;

export const getWrappedComponentDisplayName = (WrappedComponent: React.ComponentType, prefix: string) => {
    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    return `${prefix}(${wrappedComponentName})`;
};
