import axiosBff from 'platform/axios';
import { IdResponse } from 'platform/common/common.type';
import { User } from './users/user.type';
import { Role } from './roles/role.type';
import { Seat } from './seats/seat.type';

export const fetchUsers = (
    params: {
        name?: string;
        states?: string[];
        seatId?: number;
    } = {}
) =>
    axiosBff
        .get<User[]>('/users/api/users', { params })
        .then(response => response.data);

export const fetchUser = (id: number): Promise<User> =>
    axiosBff.get<User>(`/users/api/users/${id}`).then(response => response.data);

export const createUser = (user: Partial<User>) =>
    axiosBff.post<IdResponse>('/users/api/users', user).then(response => response.data);

export const updateUser = (user: Partial<User>) =>
    axiosBff.put<IdResponse>('/users/api/users', user).then(response => response.data);

export const deleteUser = (id: number) =>
    axiosBff.delete<IdResponse>(`/users/api/users/${id}`).then(response => response.data);

export const fetchRoles = () => axiosBff.get<Role[]>('/users/api/roles').then(response => response.data);

export const fetchRole = (id: number) =>
    axiosBff.get<Role>(`/users/api/roles/${id}`).then(response => response.data);

export const createRole = (model: Partial<Role>) =>
    axiosBff.post<IdResponse>('/users/api/roles', model).then(response => response.data);

export const updateRole = (model: Role) =>
    axiosBff.put<IdResponse>('/users/api/roles', model).then(response => response.data);

export const deleteRole = (id: number) =>
    axiosBff.delete<IdResponse>(`/users/api/roles/${id}`).then(response => response.data);

export const fetchSeats = (params: { name?: string; states?: string[] } = {}) =>
    axiosBff
        .get<Seat[]>('/users/api/seats', { params })
        .then(response => response.data);

export const fetchSeat = (id: number) =>
    axiosBff.get<Seat>(`/users/api/seats/${id}`).then(response => response.data);

export const createSeat = (model: Partial<Seat>) =>
    axiosBff.post<IdResponse>('/users/api/seats', model).then(response => response.data);

export const updateSeat = (model: Partial<Seat>) =>
    axiosBff.put<IdResponse>(`/users/api/seats/${model.id || ''}`, model).then(response => response.data);

export const fetchUsersWithSeats = (params: {
    name?: string;
    states?: string[];
    seatId?: number;
}): Promise<(User & { seatTitle: string })[]> =>
    Promise.all([fetchUsers(params), fetchSeats({})]).then(([userList, seatList]) =>
        userList.map(user => {
            const seat = seatList.find(item => item.id === user.seatId);
            return {
                ...user,
                seatTitle: (seat && seat.name) || 'Unknown',
            };
        })
    );
