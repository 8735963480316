import React from 'react';
import { Formik } from 'formik';
import { WithFormProps } from 'platform/common/common.type';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { email, required } from 'platform/common/utils/validators.util';
import FormStateSelect from 'platform/common/components/FormStateSelect/FormStateSelect';
import FormSelect from 'platform/common/components/FormSelect/FormSelect';
import FormRow from 'platform/common/components/FormRow/FormRow';
import CardForm from 'platform/common/components/CardForm/CardForm';
import Separator from 'platform/common/components/Separator/Separator';
import withEditForm from 'platform/common/components/WithEditForm/WithEditForm';
import withCreateForm from 'platform/common/components/WithCreateForm/WithCreateForm';
import { TWO_FACTOR_AUTHENTICATION_OPTIONS } from 'platform/userManagement/userManagement.constant';
import UserMapper from './user.mapper';
import { UserFormModel } from './user.type';
import { USER_STATE_OPTIONS, USER_STATE_TRANSITIONS } from './userStates.constant';
import * as userService from '../userManagement.service';

const UserForm = (props: WithFormProps<UserFormModel>) => {
    const { onSubmit, onClose, labels, initialValues, canEdit } = props;

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {formProps => (
                <CardForm
                    title={`${labels.prefix} User`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    onClose={onClose}
                    disabled={!canEdit}
                    onSubmit={formProps.submitForm}
                >
                    <ControlledCard title="General info">
                        <FormRow label="Name">
                            <FormInput name="name" type="text" validate={required} />
                        </FormRow>
                        <FormRow label="Status">
                            <FormStateSelect
                                name="state"
                                options={USER_STATE_OPTIONS}
                                transitions={USER_STATE_TRANSITIONS}
                                initialValue={initialValues.state}
                                validate={required}
                            />
                        </FormRow>
                        <FormRow label="Email">
                            <FormInput name="login" type="text" validate={[required, email]} />
                        </FormRow>
                        <FormRow label="Mobile phone No.">
                            <FormInput name="phone" type="text" validate={required} />
                        </FormRow>
                        <FormRow label="Seat">
                            <FormSelect
                                name="seatId"
                                options={initialValues.seats.map(seat => ({
                                    value: seat.id,
                                    label: seat.name,
                                }))}
                                isClearable={false}
                                validate={required}
                            />
                        </FormRow>
                        <FormRow label="Role">
                            <FormSelect
                                name="roleId"
                                options={initialValues.roles.map(role => ({
                                    value: role.id,
                                    label: role.title,
                                }))}
                                validate={required}
                            />
                        </FormRow>

                        <Separator label="Optional" />

                        <FormRow label="2 factor authentication">
                            <FormSelect
                                name="twoFactorAuthenticationType"
                                validate={required}
                                options={TWO_FACTOR_AUTHENTICATION_OPTIONS}
                            />
                        </FormRow>
                    </ControlledCard>
                </CardForm>
            )}
        </Formik>
    );
};

const withCreateOptions = {
    onOpen: async () => {
        const [roles, seats] = await Promise.all([userService.fetchRoles(), userService.fetchSeats({})]);
        return UserMapper.toFormModel({}, roles, seats);
    },
    onSubmit: (model: UserFormModel) => userService.createUser(UserMapper.toApi(model)),
};

const withEditOptions = {
    onOpen: async (params: { id: string }) => {
        const [user, roles, seats] = await Promise.all([
            userService.fetchUser(Number(params.id)),
            userService.fetchRoles(),
            userService.fetchSeats({}),
        ]);
        return UserMapper.toFormModel(user, roles, seats);
    },
    onSubmit: (model: UserFormModel) => userService.updateUser(UserMapper.toApi(model)),
};

export const UserFormCreate = withCreateForm(withCreateOptions)(UserForm);

export const UserFormEdit = withEditForm(withEditOptions)(UserForm);
