import React from 'react';
import { Formik, FormikProps } from 'formik';
import { WithFormProps } from 'platform/common/common.type';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { required, hexColor, positiveNumber } from 'platform/common/utils/validators.util';
import FormRow from 'platform/common/components/FormRow/FormRow';
import FormSwitch from 'platform/common/components/FormSwitch/FormSwitch';
import CardForm from 'platform/common/components/CardForm/CardForm';
import withEditForm from 'platform/common/components/WithEditForm/WithEditForm';
import withCreateForm from 'platform/common/components/WithCreateForm/WithCreateForm';
import {
    fetchTeaserTemplate,
    createTeaserTemplate,
    updateTeaserTemplate,
} from 'platform/adminPanel/masterTemplates/masterTemplates.service';
import { TeaserTemplate, TemplateFormatType } from 'platform/adminPanel/masterTemplates/masterTemplates.type';
import FormSelect from 'platform/common/components/FormSelect/FormSelect';
import { capitalize } from 'lodash-es';
import { fetchWebsites } from 'platform/adminPanel/websites/website.service';
import { usePromise } from 'platform/common/hooks/usePromise';
import FormUploadInput from 'platform/common/components/FormUploadInput/FormUploadInput';
import { dataUrlToBase64, base64ToDataUrl } from 'platform/common/utils/file.util';
import FormColorInput from 'platform/common/components/FormColorInput/FormColorInput';
import Separator from 'platform/common/components/Separator/Separator';
import FormCodeMirror from 'platform/common/components/CodeMirror/FormCodeMirror';
import { FONT_OPTIONS } from 'platform/adminPanel/masterTemplates/masterTemplates.constant';
import { hashColorCode, unHashColorCode } from 'platform/common/utils/formatters.util';

export type TeaserTemplateFormType = {
    backgroundImage?: {
        content: string;
        name: string;
    };
    additionalCss?: string;
    backgroundColour?: string;
    colourDescription?: string;
    colourHeadline?: string;
    colourTitle?: string;
    font?: string;
    formatType: TemplateFormatType;
    id: number;
    markAsAdvertisement?: boolean;
    markAsAdvertisementText?: string;
    name: string;
    sizeDescription?: number;
    sizeHeadline?: number;
    sizeTitle?: number;
    websiteId: number;
};

const pxAddOn = {
    title: 'px',
};

const TeaserTemplateForm = (props: WithFormProps<TeaserTemplateFormType>) => {
    const { onSubmit, onClose, labels, initialValues, canEdit } = props;

    const [{ data: websiteOptions, loading: isWebsitesLoading }] = usePromise(
        [],
        () => fetchWebsites().then(websites => websites.map(w => ({ value: w.id, label: w.name }))),
        []
    );

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(formProps: FormikProps<TeaserTemplateFormType>) => (
                <CardForm
                    title={`${labels.prefix} teaser template`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    onClose={onClose}
                    disabled={!canEdit}
                    onSubmit={formProps.submitForm}
                >
                    <ControlledCard title="General info">
                        <FormRow label="Name">
                            <FormInput name="name" type="text" validate={required} />
                        </FormRow>
                        <FormRow label="Website">
                            <FormSelect
                                name="websiteId"
                                validate={required}
                                options={websiteOptions}
                                isLoading={isWebsitesLoading}
                            />
                        </FormRow>
                        <FormRow label="Fomat type">
                            <FormSelect
                                name="formatType"
                                validate={required}
                                options={Object.values(TemplateFormatType).map(t => ({
                                    value: t,
                                    label: capitalize(t),
                                }))}
                            />
                        </FormRow>

                        <FormRow label="Font">
                            <FormSelect name="font" options={FONT_OPTIONS} />
                        </FormRow>

                        <Separator />
                        <FormRow label="Font color">
                            <FormRow label="Headline">
                                <FormColorInput name="colourHeadline" validate={hexColor} />
                            </FormRow>
                            <FormRow label="Description">
                                <FormColorInput name="colourDescription" validate={hexColor} />
                            </FormRow>
                            <FormRow label="Action title">
                                <FormColorInput name="colourTitle" validate={hexColor} />
                            </FormRow>
                        </FormRow>

                        <Separator />
                        <FormRow label="Font size">
                            <FormRow label="Headline">
                                <FormInput
                                    name="sizeHeadline"
                                    type="text"
                                    validate={positiveNumber}
                                    rightAddOn={pxAddOn}
                                />
                            </FormRow>
                            <FormRow label="Description">
                                <FormInput
                                    name="sizeDescription"
                                    type="text"
                                    validate={positiveNumber}
                                    rightAddOn={pxAddOn}
                                />
                            </FormRow>
                            <FormRow label="Action title">
                                <FormInput
                                    name="sizeTitle"
                                    type="text"
                                    validate={positiveNumber}
                                    rightAddOn={pxAddOn}
                                />
                            </FormRow>
                        </FormRow>
                        <Separator />

                        <FormRow label="Background color">
                            <FormColorInput name="backgroundColour" validate={hexColor} />
                        </FormRow>

                        {formProps.values.formatType === TemplateFormatType.FIX && (
                            <FormRow label="Background image">
                                <FormUploadInput
                                    name="backgroundImage"
                                    acceptableMimeTypes="image/jpeg, image/png, image/gif"
                                />
                            </FormRow>
                        )}
                        <FormRow label="Mark as advertisement">
                            <FormSwitch name="markAsAdvertisement" />
                        </FormRow>
                        {formProps.values.markAsAdvertisement && (
                            <FormRow label="Custom advertisement label">
                                <FormInput type="text" name="markAsAdvertisementText" placeholder="Anzeige" />
                            </FormRow>
                        )}

                        <FormRow label="Additional CSS">
                            <FormCodeMirror name="additionalCss" options={{ mode: 'css' }} />
                        </FormRow>
                    </ControlledCard>
                </CardForm>
            )}
        </Formik>
    );
};

export const toTeaserTemplateForm = (teaserTemplate: TeaserTemplate): TeaserTemplateFormType => ({
    backgroundImage: {
        content: teaserTemplate.backgroundImageContent
            ? base64ToDataUrl(teaserTemplate.backgroundImageContent)
            : '',
        name: teaserTemplate.backgroundImageFilename || '',
    },
    additionalCss: teaserTemplate.additionalCss,
    backgroundColour: unHashColorCode(teaserTemplate.backgroundColour),
    colourDescription: unHashColorCode(teaserTemplate.colourDescription),
    colourHeadline: unHashColorCode(teaserTemplate.colourHeadline),
    colourTitle: unHashColorCode(teaserTemplate.colourTitle),
    font: teaserTemplate.font,
    formatType: teaserTemplate.formatType,
    id: teaserTemplate.id,
    markAsAdvertisement: teaserTemplate.markAsAdvertisement,
    markAsAdvertisementText: teaserTemplate.markAsAdvertisementText || '',
    name: teaserTemplate.name,
    sizeDescription: teaserTemplate.sizeDescription,
    sizeHeadline: teaserTemplate.sizeHeadline,
    sizeTitle: teaserTemplate.sizeTitle,
    websiteId: teaserTemplate.websiteId,
});

export const toTeaserTemplateApi = (teaserTemplate: TeaserTemplateFormType): TeaserTemplate => ({
    backgroundImageContent: teaserTemplate.backgroundImage?.content
        ? dataUrlToBase64(teaserTemplate.backgroundImage.content)
        : undefined,
    backgroundImageFilename: teaserTemplate.backgroundImage?.name
        ? teaserTemplate.backgroundImage.name
        : undefined,
    additionalCss: teaserTemplate.additionalCss || undefined,
    backgroundColour: hashColorCode(teaserTemplate.backgroundColour),
    colourDescription: hashColorCode(teaserTemplate.colourDescription),
    colourHeadline: hashColorCode(teaserTemplate.colourHeadline),
    colourTitle: hashColorCode(teaserTemplate.colourTitle),
    font: teaserTemplate.font || undefined,
    formatType: teaserTemplate.formatType,
    id: teaserTemplate.id,
    markAsAdvertisement: teaserTemplate.markAsAdvertisement,
    markAsAdvertisementText: teaserTemplate.markAsAdvertisementText || undefined,
    name: teaserTemplate.name,
    sizeDescription: teaserTemplate.sizeDescription || undefined,
    sizeHeadline: teaserTemplate.sizeHeadline || undefined,
    sizeTitle: teaserTemplate.sizeTitle || undefined,
    websiteId: teaserTemplate.websiteId,
});

const newTeaserTemplate: Partial<TeaserTemplateFormType> = {
    id: undefined,
    websiteId: undefined,
    formatType: TemplateFormatType.FIX,
    name: '',
    backgroundImage: undefined,
    additionalCss: '',
    backgroundColour: undefined,
    colourDescription: undefined,
    colourHeadline: undefined,
    colourTitle: undefined,
    font: undefined,
    markAsAdvertisement: false,
    markAsAdvertisementText: '',
    sizeDescription: undefined,
    sizeHeadline: undefined,
    sizeTitle: undefined,
};

const withCreateOptions = {
    onOpen: async () => newTeaserTemplate,
    onSubmit: async (teaserTemplate: TeaserTemplateFormType) =>
        createTeaserTemplate(toTeaserTemplateApi(teaserTemplate)),
};

const withEditOptions = {
    onOpen: async (params: { id: string }) => {
        const teaserTemplate = await fetchTeaserTemplate(Number(params.id));
        return toTeaserTemplateForm(teaserTemplate);
    },
    onSubmit: async (teaserTemplate: TeaserTemplateFormType) =>
        updateTeaserTemplate(toTeaserTemplateApi(teaserTemplate)),
};

export const TeaserTemplateCreate = withCreateForm(withCreateOptions)(TeaserTemplateForm);

export const TeaserTemplateEdit = withEditForm(withEditOptions)(TeaserTemplateForm);
