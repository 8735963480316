import React from 'react';
import { Formik } from 'formik';
import { WithFormProps } from 'platform/common/common.type';
import { email, required, url } from 'platform/common/utils/validators.util';
import FormInput from 'platform/common/components/FormInput/FormInput';
import FormRow from 'platform/common/components/FormRow/FormRow';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import CardForm from 'platform/common/components/CardForm/CardForm';
import withEditForm from 'platform/common/components/WithEditForm/WithEditForm';
import withCreateForm from 'platform/common/components/WithCreateForm/WithCreateForm';
import FormStateSelect from 'platform/common/components/FormStateSelect/FormStateSelect';
import FormSelect from 'platform/common/components/FormSelect/FormSelect';
import FormDynamicOptionList from 'platform/common/components/FormDynamicOptionList/FormDynamicOptionList';
import * as feedService from './feed.service';
import { Feed } from './feed.type';

const FeedForm = (props: WithFormProps<Feed>) => {
    const { onClose, labels, initialValues, onSubmit, canEdit } = props;
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {formProps => (
                <CardForm
                    title={`${labels.prefix} Feed`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    onClose={onClose}
                    disabled={!canEdit}
                    onSubmit={formProps.submitForm}
                >
                    <ControlledCard title="General info">
                        <FormRow label="Status">
                            <FormStateSelect
                                name="status"
                                initialValue={initialValues.status}
                                validate={required}
                            />
                        </FormRow>
                        <FormRow label="Name">
                            <FormInput name="name" type="text" validate={required} />
                        </FormRow>
                        <FormRow label="URL">
                            <FormInput name="url" type="text" validate={[required, url]} />
                        </FormRow>
                        <FormRow label="Type">
                            <FormSelect
                                name="type"
                                options={[
                                    { value: 'CONTENT', label: 'Content' },
                                    { value: 'VIDEO', label: 'Video' },
                                ]}
                            />
                        </FormRow>
                        <FormRow label="Frequency">
                            <FormSelect
                                name="frequency"
                                options={[
                                    { value: 'DAILY', label: 'Daily' },
                                    { value: 'HOURLY', label: 'Hourly' },
                                ]}
                            />
                        </FormRow>
                        <FormRow label="Emails">
                            <FormDynamicOptionList name="emails" buttonTitle="Add" inputValidation={email} />
                        </FormRow>
                    </ControlledCard>
                </CardForm>
            )}
        </Formik>
    );
};

const withCreateOptions = {
    onOpen: () => Promise.resolve({ name: '', url: '' }),
    onSubmit: (model: Feed) => feedService.createFeed(model),
};

const withEditOptions = {
    onOpen: (params: { id: string }) => feedService.fetchFeed(Number(params.id)),
    onSubmit: (model: Feed) => feedService.updateFeed(model),
};

export const FeedFormCreate = withCreateForm(withCreateOptions)(FeedForm);

export const FeedFormEdit = withEditForm(withEditOptions)(FeedForm);
