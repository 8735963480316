import * as React from 'react';
import { Button } from 'reactstrap';

type ErrorBoundaryProps = {
    errorEscape: () => any;
};

type ErrorBoundaryState = {
    hasError: boolean;
};

const withErrorBoundary = (WrappedComponent: React.ComponentType<any>) =>
    class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
        static defaultProps = {
            errorEscape: undefined,
        };
        state: ErrorBoundaryState = {
            hasError: false,
        };
        componentDidCatch() {
            this.setState({ hasError: true });
        }

        render() {
            if (this.state.hasError) {
                return (
                    <div>
                        <h1> Something went wrong. </h1>
                        <Button onClick={() => this.setState({ hasError: false })}>Refresh</Button>{' '}
                        {this.props.errorEscape && <Button onClick={this.props.errorEscape}>Close</Button>}
                    </div>
                );
            }
            return <WrappedComponent {...(this.props as any)} />;
        }
    };

export default withErrorBoundary;
