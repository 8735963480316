import React from 'react';
import { useDispatch } from 'react-redux';
import { routerActions } from 'connected-react-router';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { fetchCampaigns } from 'platform/campaign/campaign.service';
import { usePromise } from 'platform/common/hooks/usePromise';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import { DATA_TYPES } from 'platform/common/dataType';
import { TableCell } from 'platform/common/common.type';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import { AdvertorialFormCreate, AdvertorialFormEdit } from 'platform/advertorial/AdvertorialForm';
import { Route, Switch } from 'react-router-dom';
import { includesIgnoreCase } from 'platform/common/utils/filter.util';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import { Campaign } from 'platform/campaign/campaign.type';
import { copyToClipboard } from 'platform/common/utils/clipboard.util';
import { toastSuccess } from 'platform/common/utils/toast.util';
import { STATE_OPTIONS, States } from 'platform/common/constants/states.constant';
import StateBadge from 'platform/common/components/StateBadge/StateBadge';
import { duplicateAdvertorial, fetchAdvertorials } from './advertorial.service';
import { Advertorial } from './advertorial.type';

type AdvertorialWithCampaign = Advertorial & {
    campaign?: Campaign;
};

const AdvertorialList = () => {
    const dispatch = useDispatch();

    const [{ data: campaigns, loading: campaignsLoading }] = usePromise([], fetchCampaigns, []);

    const [campaignIds, setCampaingIds] = React.useState<number[]>([]);
    const [states, setStates] = React.useState<States[]>([States.ACTIVE]);

    const [{ data, loading: advertorialsLoading }, refetch] = usePromise(
        [],
        () => fetchAdvertorials({ campaignIds }),
        [campaignIds]
    );

    const [searchQuery, setSearchQuery] = React.useState('');

    const advertorials = data
        .filter(t => includesIgnoreCase(t.name, searchQuery) || includesIgnoreCase(t.id, searchQuery))
        .map(advertorial => ({
            ...advertorial,
            campaign: campaigns.find(c => c.id === advertorial.campaignId),
        }))
        .filter(a => !states.length || (a.campaign && states.includes(a.campaign.status)));

    const columnDefinitions = [
        {
            Header: 'Status',
            type: DATA_TYPES.TEXT,
            width: 80,
            Cell: (cell: TableCell<AdvertorialWithCampaign>) => (
                <StateBadge state={cell.original.campaign?.status} />
            ),
        },
        {
            Header: 'ID',
            accessor: (a: AdvertorialWithCampaign) => a.id,
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'Name',
            accessor: (a: AdvertorialWithCampaign) => a.name,
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Campaign',
            accessor: (a: AdvertorialWithCampaign) => a.campaign?.name || '-',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Edited',
            accessor: 'lastModifiedOn',
            width: 160,
            Cell: (cell: TableCell<AdvertorialWithCampaign>) => (
                <UpdatedOn date={cell.original.lastModifiedOn} updatedBy={cell.original.lastModifiedBy} />
            ),
        },
        {
            className: () => 'edit-dropdown pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: (cell: TableCell<AdvertorialWithCampaign>) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () =>
                                dispatch(routerActions.push(`/advertorial/edit/${cell.original.id}`)),
                        },
                        {
                            label: 'Copy streaming link',
                            action: () => {
                                copyToClipboard(cell.original.streamingLink);
                                toastSuccess(
                                    `Streaming link for advertorial "${cell.original.name}" copied to clipboard.`
                                );
                            },
                        },
                        {
                            label: 'Duplicate',
                            action: () =>
                                cell.original.id && duplicateAdvertorial(cell.original.id).then(refetch),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <div>
            <div className="my-4">
                <PageHeader title="Advertorial module" />
            </div>
            <ListFilters>
                <SelectWithAddon
                    name="Campaign"
                    value={campaignIds}
                    isLoading={campaignsLoading}
                    options={campaigns.map(campaign => ({
                        label: campaign.name,
                        value: campaign.id,
                    }))}
                    onChange={setCampaingIds}
                    isMulti
                />
                <SelectWithAddon
                    name="Status"
                    value={states}
                    options={STATE_OPTIONS}
                    onChange={setStates}
                    isMulti
                />
            </ListFilters>
            <div className="mb-4">
                <ListToolbar
                    value={searchQuery}
                    onCreate={() => {
                        dispatch(routerActions.push('/advertorial/new'));
                    }}
                    onSearch={setSearchQuery}
                />
            </div>
            <FormattedTable
                className="-striped"
                columns={columnDefinitions}
                data={advertorials}
                loading={advertorialsLoading}
            />
            <Switch>
                <Route
                    path={'/advertorial/new'}
                    render={props => (
                        <AdvertorialFormCreate
                            {...props}
                            redirectTo={'/advertorial'}
                            afterSubmit={(advertorial: Advertorial) =>
                                dispatch(routerActions.push(`/advertorial/edit/${advertorial.id}`))
                            }
                            canEdit
                        />
                    )}
                />
                <Route
                    path={'/advertorial/edit/:id'}
                    render={props => (
                        <AdvertorialFormEdit
                            {...props}
                            redirectTo={'/advertorial'}
                            canEdit
                            onClose={refetch}
                        />
                    )}
                />
            </Switch>
        </div>
    );
};

export default AdvertorialList;
