// function borrowed from graphql/util/trace.util.js ->
// https://github.com/openzipkin/zipkin-js/blob/master/packages/zipkin/src/tracer/randomTraceId.js
const randomTraceId = () => {
    const digits = '0123456789abcdef';
    let n = '';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 16; i++) {
        const rand = Math.floor(Math.random() * 16);
        n += digits[rand];
    }
    return n;
};

export const generateTraceHeaders = () => {
    const randomId = randomTraceId();
    return {
        'X-B3-TraceId': randomId,
        'X-B3-SpanId': randomId,
    };
};
