import axios from '../axios';
import { serializeToQuery } from '../common/utils';

const appSecret = 'cGxhdGZvcm06d0JrZTcyTUtuWjlE';

export const login = (username: string, password: string) =>
    axios.request({
        method: 'POST',
        url: '/users/oauth/token',
        data: {
            username,
            password,
            grant_type: 'password',
        },
        transformRequest: [value => serializeToQuery(value)],
        headers: {
            Authorization: `Basic ${appSecret}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

export const fetchCurrentUser = () => axios.get('/users/api/account');

export const fetchCurrentUserProfile = () => axios.get('/users/api/users/profile');

export const updateCurrentUserProfile = (params: any) => axios.put('/users/api/users/profile', params);

export const updateCurrentUserPassword = (params: any) =>
    axios.patch('/users/api/users/profile/password', params);

export const sendPasswordResetEmail = (userName: string) =>
    axios.patch('/users/api/users/password-reset', { login: userName });

export const setPassword = (activationKey: string, password: string, reset: boolean = false) => {
    const url = reset
        ? `/users/api/users/password-reset/${activationKey}`
        : `/users/api/users/password/${activationKey}`;
    return axios.patch(url, { password });
};

export const resendInvitation = (id: number) => axios.patch(`/users/api/users/password-resend/${id}`);

export const impersonate = (params: { login?: string; disable: boolean }) =>
    axios.post('/users/api/impersonation', params);

export const send2FaSmsCode = () => axios.post('/users/api/2fa/sms');

export const verify2FaCode = (code: string) => axios.post('/users/api/2fa', null, { params: { code } });

export const fetch2FaCode = (activationKey: string) =>
    axios.get(`/users/api/users/2fa-activate/${activationKey}`);

export const confirm2FaSetup = (activationKey: string, code: string) =>
    axios.post(`/users/api/users/2fa-activate/${activationKey}`, null, { params: { code } });
