import * as React from 'react';
import { Button, Card, CardBody, CardHeader, Form } from 'reactstrap';
import FormHeader from '../FormHeader/FormHeader';
import AnimatedButton from '../AnimatedButton/AnimatedButton';
import './CardForm.scss';

type Props = {
    title: string;
    subtitle: string | React.ReactNode;
    disabled?: boolean;
    onClose: () => any;
    onSubmit?: () => any;
    animationDelay?: number;
    children: React.ReactNode;
    cardBodyClass?: string;
};

const CardForm = ({
    title,
    subtitle,
    disabled = false,
    onClose,
    onSubmit,
    animationDelay,
    children,
    cardBodyClass = '',
}: Props) => (
    <Form className="h-100">
        <Card className="CardForm h-100">
            <CardHeader>
                <FormHeader title={title} subtitle={subtitle}>
                    <Button color="secondary" onClick={onClose}>
                        Close
                    </Button>
                    {onSubmit ? (
                        <AnimatedButton
                            className="CardForm-submit"
                            disabled={disabled}
                            onClick={onSubmit}
                            animationDelay={animationDelay}
                        >
                            Save
                        </AnimatedButton>
                    ) : null}
                </FormHeader>
            </CardHeader>
            <CardBody className={cardBodyClass}>{children}</CardBody>
        </Card>
    </Form>
);

export default CardForm;
