import { RootState } from 'platform/rootState.type';
import { ComponentType } from 'react';
import { Location } from 'history';
import { TwoFactorAuthenticationType } from 'platform/userManagement/userManagement.constant';

export enum NavigationItemType {
    SEPARATOR = 'SEPARATOR',
}

export type Profile = {
    name: string;
    login: string;
    phone: string;
    company: string;
    demoModeEnabled: boolean;
    roleId: number;
};

export type Account = {
    id: number;
    phone: string;
    name: string;
    impersonate: boolean;
    authorities: string[];
    platformLogoUrl: string;
    login: string;
    twoFactorAuthenticationType: TwoFactorAuthenticationType;
};

export type NavigationItem = {
    name: string;
    path?: string;
    redirectTo?: string;
    icon?: any;
    requiresAuthority?: string | { all: string[] } | { any: string[] };
    children?: NavigationItem[];
    isActivePath?: (args: { location: Location }) => boolean;
    active?: boolean;
    pin?: string;
    type?: NavigationItemType;
    mapStoreToQueryString?: (state: RootState) => any;
};

export type RoutingItem = NavigationItem & {
    component: ComponentType<any>;
};
