import { Popover, Button } from 'reactstrap';
import { uniqueId } from 'lodash-es';
import * as React from 'react';
import classNames from 'classnames';
import './PopoverDropdown.scss';

export type PopoverDropdownItem = {
    action: Function;
    label: React.ReactNode;
    key?: React.Key;
    disabled?: boolean;
};

type Props = {
    toggler: React.ReactNode;
    closeOnItemClick?: boolean;
    items?: PopoverDropdownItem[];
    togglerClassName?: string;
    popoverClassName?: string;
    placement?: string;
};

type State = {
    isOpen: boolean;
    id: string;
};

class PopoverDropdown extends React.PureComponent<Props, State> {
    static defaultProps = {
        closeOnItemClick: true,
        placement: 'bottom-end',
    };

    state: State = {
        isOpen: false,
        id: uniqueId('PopoverDropdown'),
    };

    toggle = (event?: React.MouseEvent<HTMLButtonElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState(state => ({ isOpen: !state.isOpen }));
    };

    render() {
        return (
            <React.Fragment>
                <Button
                    className={classNames('PopoverDropdown-toggler', this.props.togglerClassName)}
                    id={this.state.id}
                    onClick={this.toggle}
                    color="link"
                >
                    {this.props.toggler}
                </Button>
                <Popover
                    className={classNames('PopoverDropdown-popover', this.props.popoverClassName)}
                    placement={this.props.placement as any}
                    isOpen={this.state.isOpen}
                    target={this.state.id}
                    toggle={this.toggle}
                    boundariesElement="window"
                    trigger="legacy"
                    flip={false}
                    hideArrow
                >
                    {(this.props.items || []).map((item, index) => (
                        <button
                            className="dropdown-item"
                            disabled={item.disabled}
                            type="button"
                            key={item.key || index}
                            onClick={() => {
                                if (this.props.closeOnItemClick) {
                                    this.toggle();
                                }
                                item.action();
                            }}
                        >
                            {item.label}
                        </button>
                    ))}
                </Popover>
            </React.Fragment>
        );
    }
}

export default PopoverDropdown;
