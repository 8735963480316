import UserList from './users/UserList';
import RoleList from './roles/RoleList';
import SeatList from './seats/SeatList';

export const USER_MANAGEMENT_TAB_LIST = [
    {
        url: '/user-management/users',
        name: 'users',
        label: 'Users',
        component: UserList,
    },
    {
        url: '/user-management/roles',
        name: 'roles',
        label: 'Roles',
        component: RoleList,
    },
    {
        url: '/user-management/seats',
        name: 'seats',
        label: 'Seats',
        component: SeatList,
    },
];
