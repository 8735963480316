import React, { useState, useEffect } from 'react';
import { noop, uniqueId } from 'lodash-es';
import { Validator } from 'platform/common/utils/validators.util';
import InputButton from './InputButton';
import RemovableLineItem from './RemovableLineItem';

type Props = {
    addOnTitle: string;
    buttonTitle: string;
    placeholder: string;
    onAdd: (value: string[]) => any;
    onRemove: (value: string[]) => any;
    values: string[];
    className: string;
    inputValidation: Validator | Validator[];
    valueDecorator?: (value: string) => string;
};

const defaultProps = {
    values: [],
    placeholder: '',
    addOnTitle: '',
    className: '',
    inputValidation: noop,
};

const UNIQUE_ID_KEY = 'DynamicOptionListEntry';

const toValue = <T extends { value: string }>(item: T) => item.value;

const DynamicOptionList = ({
    addOnTitle,
    buttonTitle,
    placeholder,
    className,
    inputValidation,
    onAdd,
    onRemove,
    values,
    valueDecorator,
}: Props) => {
    const [entries, setEntries] = useState<{ id: string; value: string }[]>([]);

    useEffect(() => {
        setEntries((values || []).map(item => ({ id: uniqueId(UNIQUE_ID_KEY), value: item })));
    }, [values]);

    const onAddClick = (value: string) => {
        if (!value) {
            return;
        }
        const previewValue = valueDecorator ? valueDecorator(value.trim()) : value;
        const newEntries = [...entries, { id: uniqueId(UNIQUE_ID_KEY), value: previewValue }];
        setEntries(newEntries);
        onAdd(newEntries.map(toValue));
    };

    const onRemoveClick = (id: string) => {
        const newEntries = entries.filter(item => item.id !== id);
        setEntries(newEntries);
        onRemove(newEntries.map(toValue));
    };
    return (
        <div className={`DynamicOptionList ${className}`}>
            <InputButton
                inputValidation={inputValidation}
                addOnTitle={addOnTitle}
                placeholder={placeholder}
                buttonTitle={buttonTitle}
                onClick={onAddClick}
            />
            {entries.map((entry, index) => (
                <RemovableLineItem
                    key={entry.id}
                    className={entries.length - 1 === index ? 'mt-2' : 'my-2'}
                    id={entry.id}
                    onRemove={() => onRemoveClick(entry.id)}
                >
                    {entry.value}
                </RemovableLineItem>
            ))}
        </div>
    );
};

DynamicOptionList.defaultProps = defaultProps;

export default DynamicOptionList;
