import { Authority } from 'platform/app/authority.constant';
import { MODULES } from './roles.constant';

export class AuthorityConnector {
    module: string;
    viewAuthority: string;
    editAuthority?: string;

    constructor(module: string, viewAuthority: string, editAuthority?: string) {
        this.module = module;
        this.viewAuthority = viewAuthority;
        this.editAuthority = editAuthority;
    }
}

export const AUTHORITY_CONNECTORS = [
    new AuthorityConnector(MODULES.USERS, Authority.ROLE_USERS_VIEW_EDIT, Authority.ROLE_USERS_VIEW_EDIT),
    new AuthorityConnector(
        MODULES.TEASER_MODULE,
        Authority.ROLE_TEASERS_VIEW_EDIT,
        Authority.ROLE_TEASERS_VIEW_EDIT
    ),
    new AuthorityConnector(
        MODULES.ADVERTORIAL_MODULE,
        Authority.ROLE_ADVERTORIALS_VIEW_EDIT,
        Authority.ROLE_ADVERTORIALS_VIEW_EDIT
    ),
    new AuthorityConnector(
        MODULES.CAMPAIGN_MODULE,
        Authority.ROLE_CAMPAIGNS_VIEW_EDIT,
        Authority.ROLE_CAMPAIGNS_VIEW_EDIT
    ),
    new AuthorityConnector(
        MODULES.ADMIN_PANEL,
        Authority.ROLE_ADMIN_PANEL_VIEW_EDIT,
        Authority.ROLE_ADMIN_PANEL_VIEW_EDIT
    ),
    new AuthorityConnector(
        MODULES.AD_BOARD_MODULE,
        Authority.ROLE_AD_BOARD_VIEW_EDIT,
        Authority.ROLE_AD_BOARD_VIEW_EDIT
    ),
    new AuthorityConnector(MODULES.CAMPAIGNS, Authority.ROLE_CAMPAIGNS, Authority.ROLE_CAMPAIGNS),
    new AuthorityConnector(MODULES.AD_GROUPS, Authority.ROLE_AD_GROUPS, Authority.ROLE_AD_GROUPS),
    new AuthorityConnector(MODULES.ADS, Authority.ROLE_ADS, Authority.ROLE_ADS),
    new AuthorityConnector(MODULES.EXPORT_LEADS, Authority.ROLE_EXPORT_LEADS, Authority.ROLE_EXPORT_LEADS),
    new AuthorityConnector(
        MODULES.CONFIGURE_INTEGRATIONS,
        Authority.ROLE_CONFIGURE_INTEGRATIONS,
        Authority.ROLE_CONFIGURE_INTEGRATIONS
    ),
];
