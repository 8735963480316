import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import useToggle from 'platform/common/hooks/useToggle';
import { getInitials } from 'platform/common/utils/formatters.util';
import { actions as authActions } from '../../auth.duck';
import './UserButton.scss';
import { RootState } from '../../../rootState.type';

const UserButton = () => {
    const dispatch = useDispatch();

    const { account } = useSelector((state: RootState) => state.session);

    const [dropdownOpen, toggleDropdownOpen] = useToggle(false);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdownOpen}>
            <DropdownToggle id="user-button" className="user-button dropdown-toggle">
                <span className="user-button__initials">
                    {account.impersonate ? 'IMP' : getInitials(account.name)}
                </span>
            </DropdownToggle>
            <DropdownMenu right className={dropdownOpen ? 'show' : ''}>
                <DropdownItem header tag="div" className="text-center">
                    <strong>Account</strong>
                </DropdownItem>
                <DropdownItem
                    id="profile"
                    onClick={() => {
                        dispatch(push('/profile'));
                    }}
                >
                    <i className="fa fa-user" />
                    My profile
                </DropdownItem>
                {account.impersonate && (
                    <DropdownItem
                        onClick={() => {
                            dispatch(authActions.stopImpersonation());
                        }}
                    >
                        <i className="fa fa-user-secret" />
                        Stop Impersonation
                    </DropdownItem>
                )}
                <DropdownItem
                    id="logout"
                    onClick={() => {
                        dispatch(authActions.logout());
                    }}
                >
                    <i className="fa fa-lock" />
                    Logout
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default UserButton;
