import * as React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import './DateRangePicker.scss';
import { DATE_MOMENT_FORMAT } from '../../constants/dateConfiguration.constant';
import DateRangePickerPopover from './DateRangePickerPopover';
import { DATE_RANGE, DateRanges, formatDate } from '../../utils/date.util';

type Props = {
    id?: string;
    from: string | null;
    to: string | null;
    preset: string | null;
    minDate?: string;
    maxDate?: string;
    className?: string;
    placement: string;
    displayFormat: string;
    disabled: boolean;
    forceRangeSelection: boolean;
    ranges?: DateRanges;
    onChange: (from: string, to: string, preset: string | null) => any;
    onBlur?: () => any;
    optionalEndDate?: boolean;
    inputGroupStyle?: React.CSSProperties;
};

type State = {
    from?: moment.Moment;
    to?: moment.Moment;
    preset: string | null;
    minDate?: moment.Moment;
    maxDate?: moment.Moment;
    tooltipOpen: boolean;
};

const displayDateRange = (
    from: moment.Moment | undefined,
    to: moment.Moment | undefined,
    preset: string | null,
    format: string,
    ranges: DateRanges | undefined
) => {
    if (!from && !to) {
        return '';
    }

    const formattedFrom = from ? from.format(format) : '';
    const formattedTo = to ? to.format(format) : 'no end date';

    if (preset) {
        const presetLabel = (ranges && ranges[preset].label) || preset;
        return `${presetLabel} (${formattedFrom} - ${formattedTo})`;
    }

    return `${from ? from.format(format) : ''} — ${to ? to.format(format) : 'no end date'}`;
};

const momentIfDefined = (date: string | null | undefined) => (date ? moment(date) : undefined);

const propsToState = (props: Props): State => {
    const from = momentIfDefined(props.from);
    const to = momentIfDefined(props.to);
    const preset = props.preset;
    const minDate = momentIfDefined(props.minDate);
    const maxDate = momentIfDefined(props.maxDate);
    return { from, to, preset, minDate, maxDate, tooltipOpen: false };
};

class DateRangePicker extends React.Component<Props, State> {
    static defaultProps = {
        placement: 'bottom',
        disabled: false,
        forceRangeSelection: false,
        displayFormat: DATE_MOMENT_FORMAT,
        ranges: DATE_RANGE,
        preset: null,
    };

    state = propsToState(this.props);

    iconContainerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        this.forceUpdate();
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.from !== prevProps.from ||
            this.props.to !== prevProps.to ||
            this.props.preset !== prevProps.preset
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(propsToState(this.props));
        }
    }

    togglePopover = () => {
        if (this.props.disabled) {
            return;
        }
        this.setState(prevState => ({
            tooltipOpen: !prevState.tooltipOpen,
        }));
    };

    onRangeChange = (from: string, to: string, preset: string | null) => {
        this.setState({
            from: moment(from),
            to: moment(to),
            preset,
        });

        this.props.onChange(formatDate(from), formatDate(to), preset);
        this.togglePopover();
    };

    render() {
        const {
            className,
            placement,
            ranges,
            disabled,
            id,
            optionalEndDate,
            forceRangeSelection,
            onBlur,
            inputGroupStyle,
            displayFormat,
        } = this.props;
        const { from, to, minDate, maxDate, preset } = this.state;

        return (
            <React.Fragment>
                <div
                    ref={this.iconContainerRef}
                    className={className}
                    style={{ boxSizing: 'content-box', width: 'auto' }}
                    onClick={this.togglePopover}
                    role="button"
                    tabIndex={0}
                >
                    <InputGroup
                        className={classNames('DateRangePicker--inputGroup', { disabled })}
                        style={inputGroupStyle}
                    >
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="far fa-calendar-alt" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            id={id}
                            type="text"
                            readOnly
                            value={displayDateRange(from, to, preset, displayFormat, ranges)}
                            onBlur={onBlur}
                        />
                    </InputGroup>
                </div>
                {this.iconContainerRef.current && this.state.tooltipOpen && (
                    <DateRangePickerPopover
                        target={this.iconContainerRef.current}
                        placement={placement}
                        toggle={this.togglePopover}
                        from={from}
                        to={to}
                        preset={preset}
                        minDate={minDate}
                        maxDate={maxDate}
                        ranges={ranges}
                        onChange={this.onRangeChange}
                        onCancel={this.togglePopover}
                        optionalEndDate={optionalEndDate}
                        forceRangeSelection={forceRangeSelection}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default DateRangePicker;
