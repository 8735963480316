import axiosBff from 'platform/axios';
import { IdResponse } from 'platform/common/common.type';
import { Website } from './website.type';

export const fetchWebsites = () =>
    axiosBff.get<Website[]>('/sdo/api/websites').then(response => response.data);

export const fetchWebsite = (id: number) =>
    axiosBff.get<Website>(`/sdo/api/websites/${id}`).then(response => response.data);

export const createWebsite = (model: Partial<Website>) =>
    axiosBff.post<IdResponse>('/sdo/api/websites', model).then(response => response.data);

export const updateWebsite = (model: Website) =>
    axiosBff.put<IdResponse>('/sdo/api/websites', model).then(response => response.data);
