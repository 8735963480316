import { flow } from 'lodash-es';
import { withBlockDrop } from 'platform/advertorial/AdvertorialEditor/utils';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';
import { withCitations } from './editorCitation';
import { withHtmlBlock } from './editorHtmlBlock';
import { withImages } from './editorImage';
import { withLinks } from './editorLink';
import { withSlideshow } from './editorSlideshow';
import { withTables } from './editorTable';

import { withParagraph } from './editorParagraph';

export const createAdvertorialEditor = flow(
    createEditor,
    withReact,
    withHistory,
    withLinks,
    withImages,
    withCitations,
    withTables,
    withSlideshow,
    withHtmlBlock,
    withBlockDrop,
    withParagraph
);
