import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { routerActions } from 'connected-react-router';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { useCancelableEffect } from 'platform/common/hooks/useCancelableEffect';
import { TableCell } from 'platform/common/common.type';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import StateBadge from 'platform/common/components/StateBadge/StateBadge';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import { DATA_TYPES } from 'platform/common/dataType';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import { SeatFormCreate, SeatFormEdit } from './SeatForm';
import { USER_STATE_OPTIONS } from '../users/userStates.constant';
import { Seat } from './seat.type';
import * as userService from '../userManagement.service';

type State = {
    loading: boolean;
    data: Seat[];
    states: string[];
    searchQuery: string;
};

const SeatList = () => {
    const dispatch = useDispatch();

    const [state, setState] = useState<State>({
        loading: true,
        data: [],
        searchQuery: '',
        states: ['ACTIVE'],
    });

    const fetchSeats = (states: string[], searchQuery: string) => {
        setState(currentState => ({ ...currentState, loading: true }));
        userService
            .fetchSeats({ states, name: searchQuery.toLowerCase() })
            .then(response => {
                setState(currentState => ({ ...currentState, data: response }));
            })
            .finally(() => {
                setState(currentState => ({ ...currentState, loading: false }));
            });
    };

    useCancelableEffect(() => {
        fetchSeats(state.states, state.searchQuery);
    }, [state.searchQuery, state.states]);

    const columnDefinitions = [
        {
            Header: 'Status',
            accessor: 'status',
            type: DATA_TYPES.TEXT,
            width: 80,
            Cell: (cell: TableCell<Seat>) => <StateBadge state={cell.value} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'Name',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Company name',
            accessor: 'companyName',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 160,
            Cell: (cell: TableCell<Seat>) => (
                <UpdatedOn date={cell.original.updatedOn} updatedBy={cell.original.updatedBy} />
            ),
        },
        {
            className: () => 'edit-dropdown pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: (cell: TableCell<Seat>) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () =>
                                dispatch(routerActions.push(`/user-management/seats/${cell.original.id}`)),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <div>
            <ListFilters>
                <SelectWithAddon
                    name="Status"
                    value={state.states}
                    options={USER_STATE_OPTIONS}
                    onChange={states => setState(currentState => ({ ...currentState, states }))}
                    isMulti
                />
            </ListFilters>
            <div className="mb-4">
                <ListToolbar
                    value={state.searchQuery}
                    onCreate={() => {
                        dispatch(routerActions.push('/user-management/seats/new'));
                    }}
                    onSearch={searchQuery => setState(currentState => ({ ...currentState, searchQuery }))}
                />
            </div>
            <FormattedTable
                className="-striped"
                columns={columnDefinitions}
                data={state.data}
                loading={state.loading}
            />
            <Switch>
                <Route
                    path={'/user-management/seats/new'}
                    render={props => (
                        <SeatFormCreate
                            {...props}
                            redirectTo={'/user-management/seats'}
                            canEdit
                            afterSubmit={(seat: Seat) =>
                                dispatch(routerActions.push(`/user-management/seats/${seat.id}`))
                            }
                        />
                    )}
                />
                <Route
                    path={'/user-management/seats/:id'}
                    render={props => (
                        <SeatFormEdit
                            {...props}
                            redirectTo={'/user-management/seats'}
                            canEdit
                            onClose={() => fetchSeats(state.states, state.searchQuery)}
                        />
                    )}
                />
            </Switch>
        </div>
    );
};

export default SeatList;
