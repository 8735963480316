import React from 'react';
import { Formik } from 'formik';
import { Button, Form } from 'reactstrap';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { required } from 'platform/common/utils/validators.util';
import FormRow from 'platform/common/components/FormRow/FormRow';
import SimpleCard from 'platform/common/components/SimpleCard/SimpleCard';
import { Profile as ProfileType } from 'platform/app/app.type';

type Props = {
    onSubmit: (data: any) => void;
    initialValues: Partial<ProfileType>;
};

const ProfileGeneralInfoForm = ({ initialValues, onSubmit }: Props) => (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {fromProps => (
            <Form onSubmit={fromProps.handleSubmit}>
                <SimpleCard title="General">
                    <FormRow label="Name">
                        <FormInput name="name" type="text" validate={required} />
                    </FormRow>
                    <FormRow label="Email">
                        <FormInput name="login" type="text" disabled />
                    </FormRow>

                    <div className="d-flex justify-content-end">
                        <Button type="submit" color="primary">
                            Save
                        </Button>
                    </div>
                </SimpleCard>
            </Form>
        )}
    </Formik>
);

export default ProfileGeneralInfoForm;
