import { Form, Formik, FormikProps } from 'formik';
import { toBlockAlignmentClass } from 'platform/advertorial/AdvertorialEditor/alignment';
import {
    Alignment,
    blockAlignmentButtons,
    lengthOptions,
    LengthUnit,
} from 'platform/advertorial/AdvertorialEditor/common.types';
import { AddSlideButton, SlideElement } from 'platform/advertorial/AdvertorialEditor/editorSlide';
import { Float, floatButtons, getFloatClasses } from 'platform/advertorial/AdvertorialEditor/float';
import FormButtonArray from 'platform/common/components/FormButtonArray/FormButtonArray';
import FormInput from 'platform/common/components/FormInput/FormInput';
import FormRow from 'platform/common/components/FormRow/FormRow';
import FormSwitch from 'platform/common/components/FormSwitch/FormSwitch';
import { useModal } from 'platform/common/components/Modal/Modal';
import { positiveNumber, required } from 'platform/common/utils/validators.util';
import React from 'react';
import { Button as ReactstrapButton, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { Editor, Node, Transforms } from 'slate';
import { ReactEditor, useFocused, useSelected, useSlate } from 'slate-react';
import { Button, Icon } from './components';
import { elementMatcher } from './utils';
import BlockControlls from './BlockControlls';

type SlideshowParams = {
    height: number;
    autoplay: boolean;
    width: number | undefined;
    float: Float;
    alignment: Alignment;
    widthUnit: LengthUnit;
};

const insertSlideshow = (editor: Editor, slideshowParams: SlideshowParams) => {
    Transforms.insertNodes(editor, {
        type: 'slideshow',
        ...slideshowParams,
        children: [{ type: 'slideshow-placeholder', children: [{ text: '' }] }],
    });
};

const SlideshowModal = ({
    isOpen,
    toggle,
    done,
    initial,
}: {
    isOpen: boolean;
    toggle: () => void;
    done: (params: SlideshowParams) => void;
    initial?: SlideshowParams;
}) => (
    <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Slideshow</ModalHeader>
        <ModalBody>
            <Formik
                initialValues={{
                    height: initial?.height ?? 600,
                    width: initial?.width ?? undefined,
                    autoplay: initial?.autoplay ?? true,
                    float: initial?.float ?? 'none',
                    alignment: initial?.alignment ?? Alignment.left,
                    widthUnit: initial?.widthUnit || LengthUnit.px,
                }}
                onSubmit={done}
            >
                {(formProps: FormikProps<SlideshowParams>) => (
                    <Form>
                        <FormRow label="Height">
                            <FormInput
                                type="number"
                                name="height"
                                validate={[required, positiveNumber]}
                                rightAddOn={{ title: 'px' }}
                            />
                        </FormRow>
                        <FormRow label="Autoplay">
                            <FormSwitch name="autoplay" />
                        </FormRow>
                        <FormRow label="Width unit">
                            <FormButtonArray name="widthUnit" buttons={lengthOptions} />
                        </FormRow>
                        <FormRow label="Width (leave blank for full width)">
                            <FormInput
                                name="width"
                                type="number"
                                validate={positiveNumber}
                                rightAddOn={{ title: formProps.values.widthUnit }}
                            />
                        </FormRow>
                        <FormRow label="Float (text will wrap around)">
                            <FormButtonArray name="float" buttons={floatButtons} />
                        </FormRow>
                        {formProps.values.float === 'none' && (
                            <FormRow label="Alignment">
                                <FormButtonArray name="alignment" buttons={blockAlignmentButtons} />
                            </FormRow>
                        )}
                        <div className="d-flex justify-content-end">
                            <ReactstrapButton color="secondary" onClick={toggle}>
                                Cancel
                            </ReactstrapButton>
                            <ReactstrapButton className="ml-2" color="primary" type="submit">
                                Ok
                            </ReactstrapButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalBody>
    </Modal>
);

const getActive = (editor: Editor) => {
    const [slideshow] = Editor.nodes(editor, { match: n => n.type === 'slideshow' });
    return slideshow;
};

export const SlideshowToolbarButton = () => {
    const editor = useSlate();
    const { showModal } = useModal();
    const ref = React.useRef<HTMLButtonElement>(null);
    return (
        <Button
            ref={ref}
            onMouseDown={(event: Event) => {
                event.preventDefault();
                const { selection } = editor;

                const slideshowSelection = getActive(editor);

                if (!slideshowSelection) {
                    showModal(toggle => (
                        <SlideshowModal
                            isOpen
                            toggle={toggle}
                            done={slideshowParams => {
                                Transforms.select(editor, selection || [0]);
                                insertSlideshow(editor, slideshowParams);
                                toggle();
                            }}
                        />
                    ));
                } else {
                    const slideshowElement = (slideshowSelection[0] as any) as SlideshowElement;
                    showModal(toggle => (
                        <SlideshowModal
                            initial={slideshowElement}
                            isOpen
                            toggle={toggle}
                            done={slideshowParams => {
                                Transforms.select(editor, selection || [0]);
                                Transforms.setNodes(editor, slideshowParams, {
                                    match: n => n.type === 'slideshow',
                                });
                                toggle();
                            }}
                        />
                    ));
                }
            }}
        >
            <Icon className="fas fa-images" />
            {ref.current && <UncontrolledTooltip target={ref.current}>Slideshow</UncontrolledTooltip>}
        </Button>
    );
};

type SlideshowElement = SlideshowParams & {
    type: 'slideshow';
    children: Node[];
};

export const SlideshowInEditor = ({
    attributes,
    children,
    element,
}: {
    element: SlideshowElement;
    attributes: any;
    children: React.ReactNode;
}) => {
    const selected = useSelected();
    const focused = useFocused();
    const editor = useSlate();
    const { showModal } = useModal();
    return (
        <div
            {...attributes}
            className={`mb-4 d-flex ${toBlockAlignmentClass(element.alignment)} ${getFloatClasses(
                element.float || 'none'
            )}`}
        >
            <div
                style={{
                    width: element.width ? `${element.width}${element.widthUnit}` : '100%',
                    boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
                    position: 'relative',
                    background: '#eee',
                }}
                className="d-block pt-4 p-2"
            >
                <BlockControlls
                    label="Slideshow"
                    onEdit={() => {
                        showModal(toggle => (
                            <SlideshowModal
                                initial={element}
                                isOpen
                                toggle={toggle}
                                done={params => {
                                    Transforms.select(editor, ReactEditor.findPath(editor, element));
                                    Transforms.setNodes(editor, params, elementMatcher('slideshow'));
                                    toggle();
                                }}
                            />
                        ));
                    }}
                    element={element}
                />
                <div>{children}</div>
                <div contentEditable={false}>
                    <AddSlideButton parentElement={element} />
                </div>
            </div>
        </div>
    );
};

const serializeSlideshowImage = (serializeToHtml: (node: Node[]) => string, height: number = 500) => (
    element: SlideElement
) => `
<div class="sdo-slide">
    <img style="
        display: block;
        height: ${height}px;
        width: 100%;
        object-fit: cover" src="${element.url}" class="sdo-slide-img">
    <div class="sdo-slide-caption">${serializeToHtml(element.children)}</div>
</div>
`;

export const serializeSlideshow = (element: SlideshowElement, serializeToHtml: (node: Node[]) => string) => `
<div class="sdo-slideshow-container d-flex sdo-float-${element.float} sdo-flex-align-${element.alignment}">
    <div class="glider-contain" style="width: ${
        element.width ? `${element.width}${element.widthUnit};` : '100%'
    }">
      <div class="glider sdo-slideshow"  data-autoplay="${element.autoplay}">
           ${element.children.map(serializeSlideshowImage(serializeToHtml, element.height)).join('\n')}
      </div>

      <button role="button" aria-label="Previous" class="glider-prev sdo-glider-prev">&laquo;</button>
      <button role="button" aria-label="Next" class="glider-next sdo-glider-next">&raquo;</button>
    </div>
</div>
`;

export const withSlideshow = <T extends Editor>(editor: T): T => {
    const { normalizeNode, insertBreak, insertData, isVoid } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.insertBreak = () => {
        const { selection } = editor;

        if (selection) {
            const [slideshow] = Editor.nodes(editor, { match: n => n.type === 'slideshow' });

            if (slideshow) {
                return;
            }
        }

        insertBreak();
    };

    // eslint-disable-next-line no-param-reassign
    editor.isVoid = node => {
        if (node.type === 'slideshow-placeholder') return true;
        return isVoid(node);
    };

    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    editor.insertData = (data: any) => {
        const encodedElementPath = data.getData('application/x-slate-cdo-slide');
        if (encodedElementPath) {
            const decoded = decodeURIComponent(atob(encodedElementPath));
            const sourcePath = JSON.parse(decoded);

            const slideEntry = Array.from(
                Editor.levels(editor, { at: editor.selection?.focus, ...elementMatcher('slide') })
            ).find(entry => Editor.isBlock(editor, entry[0]))!;

            if (!slideEntry) return;

            const [, slideElementPath] = slideEntry;

            if (!slideElementPath) return;

            Transforms.moveNodes(editor, {
                at: sourcePath,
                to: slideElementPath,
            });
            Transforms.select(editor, slideElementPath);
        } else {
            insertData(data);
        }
    };

    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = ([node, path]) => {
        if (node.type === 'slideshow') {
            let removed = 0;
            const childrenEntries = Array.from(Node.children(editor, path));
            const slideshowHasSlides = childrenEntries.some(([child]) => child.type === 'slide');
            childrenEntries.reverse().forEach(([child, childPath]) => {
                if (child.type === 'slide') return;
                if (child.type === 'slideshow-placeholder' && !slideshowHasSlides) return;
                Transforms.removeNodes(editor, { at: childPath });
                removed += 1;
            });
            // As the node is not mutated, we don't know whether our slideshow is childless so we use this
            // 'removed' counter. And we have to add one child now, because slate will try to add text
            // node in it againg and we will have infinite loop
            if (node.children.length === removed) {
                Transforms.insertNodes(
                    editor,
                    {
                        type: 'slideshow-placeholder',
                        children: [{ text: '' }],
                    },
                    { at: path.concat(0) }
                );
            }
            return null;
        }

        return normalizeNode([node, path]);
    };

    return editor;
};
