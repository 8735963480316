import React, { useState } from 'react';
import classNames from 'classnames';
import { Col, Container, Input, Row } from 'reactstrap';
import { AccessLevel, MODULE_TITLES } from '../roles.constant';
import './RoleEditList.scss';
import { ModuleAccessLevel } from '../authorityConnector.utils';

type Props = {
    onChange: (newValue: ModuleAccessLevel[]) => void;
    moduleAccessLevels: {
        [key: string]: ModuleAccessLevel[];
    };
};

const RoleEditList = (props: Props) => {
    const { moduleAccessLevels, onChange } = props;
    const [expandedCards, setExpandedCards] = useState(Object.keys(moduleAccessLevels));

    const updateRoles = (module: string, accessLevel: AccessLevel) => () => {
        const result = Object.keys(moduleAccessLevels)
            .flatMap(key => moduleAccessLevels[key])
            .map(row => ({
                ...row,
                accessLevel: row.module === module ? accessLevel : row.accessLevel,
            }));
        onChange(result);
    };

    const isCardExpanded = (card: string) => !!expandedCards.find(name => name === card);

    const toggleGroup = (name: string) => {
        if (isCardExpanded(name)) {
            setExpandedCards(cards => cards.filter(c => c !== name));
        } else {
            setExpandedCards(cards => [...cards, name]);
        }
    };

    return (
        <Container>
            <Row className="RoleEditList-headerRow text-center">
                <Col md={{ offset: 6, size: 2 }}>View & Edit</Col>
                <Col md={2}>No Access</Col>
            </Row>
            {Object.keys(moduleAccessLevels).map(value => (
                <div key={value}>
                    <Row className="RoleEditList-groupRow">
                        <Col md={1}>
                            <i
                                tabIndex={0}
                                role="button"
                                onClick={() => toggleGroup(value)}
                                className={classNames(
                                    'RoleEditList-expandArrow',
                                    isCardExpanded(value) ? 'fa fa-chevron-down' : 'fa fa-chevron-up'
                                )}
                            />
                        </Col>
                        <Col md={11} className="RoleEditList-headerTitle">
                            {value}
                        </Col>
                    </Row>
                    {isCardExpanded(value) &&
                        moduleAccessLevels[value].map(rule => (
                            <Row key={rule.module} className="RoleEditList-row">
                                <Col md={{ size: 5, offset: 1 }}>{MODULE_TITLES[rule.module]}</Col>
                                <Col md={2} className="text-center">
                                    <Input
                                        type="radio"
                                        name={rule.module}
                                        onChange={updateRoles(rule.module, AccessLevel.VIEW_AND_EDIT)}
                                        defaultChecked={rule.accessLevel === AccessLevel.VIEW_AND_EDIT}
                                        disabled={!rule.moduleIsEditable}
                                    />
                                </Col>
                                <Col md={2} className="text-center">
                                    <Input
                                        type="radio"
                                        name={rule.module}
                                        onChange={updateRoles(rule.module, AccessLevel.NO_ACCESS)}
                                        defaultChecked={rule.accessLevel === AccessLevel.NO_ACCESS}
                                    />
                                </Col>
                            </Row>
                        ))}
                </div>
            ))}
        </Container>
    );
};

export default RoleEditList;
