import * as React from 'react';
import './LabelLink.scss';

type Props = {
    onClick: () => any;
    className: string;
    children: React.ReactNode;
};

const defaultProps = {
    className: '',
};

const LabelLink = ({ onClick, className, children }: Props) => (
    <span role="button" tabIndex={0} className={`LabelLink ${className}`} onClick={onClick}>
        {children}
    </span>
);

LabelLink.defaultProps = defaultProps;

export default LabelLink;
