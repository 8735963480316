import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { routerActions } from 'connected-react-router';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { useCancelableEffect } from 'platform/common/hooks/useCancelableEffect';
import { TableCell } from 'platform/common/common.type';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import { DATA_TYPES } from 'platform/common/dataType';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import * as campaignService from 'platform/campaign/campaign.service';
import { Campaign } from 'platform/campaign/campaign.type';
import { TeaserFormCreate, TeaserFormEdit } from 'platform/teaser/TeaserForm';
import { copyToClipboard } from 'platform/common/utils/clipboard.util';
import { toastSuccess } from 'platform/common/utils/toast.util';
import { STATE_OPTIONS, States } from 'platform/common/constants/states.constant';
import { includesIgnoreCase } from 'platform/common/utils/filter.util';
import StateBadge from 'platform/common/components/StateBadge/StateBadge';
import * as teaserService from './teaser.service';
import { Teaser } from './teaser.type';

type TeaserWithCampaign = Teaser & {
    campaign?: Campaign;
};

type State = {
    loading: boolean;
    data: TeaserWithCampaign[];
    searchQuery: string;
    campaignIds: number[];
    states: States[];
};

type MetaData = {
    campaigns: Campaign[];
    loading: boolean;
};

const TeaserList = () => {
    const dispatch = useDispatch();

    const [state, setState] = useState<State>({
        loading: true,
        data: [],
        searchQuery: '',
        campaignIds: [],
        states: [States.ACTIVE],
    });

    const [metaData, setMetaData] = useState<MetaData>({
        loading: true,
        campaigns: [],
    });

    const fetchTeasers = (campaignIds: number[]) => {
        setState(currentState => ({ ...currentState, loading: true }));
        teaserService
            .fetchTeasers({ campaignIds })
            .then(response => {
                setState(currentState => ({
                    ...currentState,
                    data: response.map(teaser => ({
                        ...teaser,
                        campaign: metaData.campaigns.find(campaign => campaign.id === teaser.campaignId),
                    })),
                }));
            })
            .finally(() => {
                setState(currentState => ({ ...currentState, loading: false }));
            });
    };

    useEffect(() => {
        campaignService
            .fetchCampaigns()
            .then(campaigns => {
                setMetaData(currentMetaData => ({
                    ...currentMetaData,
                    campaigns,
                }));
            })
            .finally(() => setMetaData(currentMetaData => ({ ...currentMetaData, loading: false })));
    }, []);

    useCancelableEffect(() => {
        if (metaData.loading) return;
        fetchTeasers(state.campaignIds);
    }, [state.campaignIds, metaData.loading]);

    const columnDefinitions = [
        {
            Header: 'Status',
            type: DATA_TYPES.TEXT,
            width: 80,
            Cell: (cell: TableCell<TeaserWithCampaign>) => (
                <StateBadge state={cell.original.campaign?.status} />
            ),
        },
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 80,
        },
        {
            Header: 'Teaser',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Campaign',
            accessor: (a: TeaserWithCampaign) => a.campaign?.name || '-',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Edited',
            accessor: 'lastModifiedOn',
            width: 160,
            Cell: (cell: TableCell<TeaserWithCampaign>) => (
                <UpdatedOn date={cell.original.lastModifiedOn} updatedBy={cell.original.lastModifiedBy} />
            ),
        },
        {
            className: () => 'edit-dropdown pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: (cell: TableCell<TeaserWithCampaign>) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () => dispatch(routerActions.push(`/teaser/${cell.original.id}`)),
                        },
                        {
                            label: 'Copy streaming link',
                            action: () => {
                                copyToClipboard(cell.original.streamingLink);
                                toastSuccess(
                                    `Streaming link for teaser "${cell.original.name}" copied to clipboard.`
                                );
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    const teasers = state.data
        .filter(
            t => includesIgnoreCase(t.name, state.searchQuery) || includesIgnoreCase(t.id, state.searchQuery)
        )
        .filter(a => !state.states.length || (a.campaign && state.states.includes(a.campaign.status)));

    return (
        <div>
            <div className="my-4">
                <PageHeader title="Teaser module" />
            </div>
            <ListFilters>
                <SelectWithAddon
                    name="Campaign"
                    value={state.campaignIds}
                    isLoading={metaData.loading}
                    options={metaData.campaigns.map(campaign => ({
                        label: campaign.name,
                        value: campaign.id,
                    }))}
                    onChange={campaignIds => setState(currentState => ({ ...currentState, campaignIds }))}
                    isMulti
                />
                <SelectWithAddon
                    name="Status"
                    value={state.states}
                    options={STATE_OPTIONS}
                    onChange={states => setState(currentState => ({ ...currentState, states }))}
                    isMulti
                />
            </ListFilters>
            <div className="mb-4">
                <ListToolbar
                    value={state.searchQuery}
                    onCreate={() => {
                        dispatch(routerActions.push('/teaser/new'));
                    }}
                    onSearch={searchQuery => setState(currentState => ({ ...currentState, searchQuery }))}
                />
            </div>
            <FormattedTable
                className="-striped"
                columns={columnDefinitions}
                data={teasers}
                loading={state.loading}
            />
            <Switch>
                <Route
                    path={'/teaser/new'}
                    render={props => (
                        <TeaserFormCreate
                            {...props}
                            redirectTo={'/teaser'}
                            canEdit
                            afterSubmit={(teaser: Teaser) =>
                                dispatch(routerActions.push(`/teaser/${teaser.id}`))
                            }
                        />
                    )}
                />
                <Route
                    path={'/teaser/:id'}
                    render={props => (
                        <TeaserFormEdit
                            {...props}
                            redirectTo={'/teaser'}
                            canEdit
                            onClose={() => fetchTeasers(state.campaignIds)}
                        />
                    )}
                />
            </Switch>
        </div>
    );
};

export default TeaserList;
