import { debounce } from 'lodash-es';
import React from 'react';
import ReactTable from 'react-table';
import classNames from 'classnames';
import { TableColumnProcessed } from './FormattedTable';
import './ReactTableWithStickyColumns.scss';

type Props = {
    setRef?: (ref: HTMLElement | null) => void;
    columns?: TableColumnProcessed[];
    data: Object[];
};

type State = {
    columnWidths: {
        [key: string]: number;
    };
    tableWidth: number;
};

const colIdToHtmlId = (id?: string) => (id ? `th-${id}` : '');

const STICK_FREE_WIDTH_RATIO = 0.5;
const DISABLE_STICKINESS_BREAKPOINT = 768;

class ReactTableWithStickyColumns extends React.Component<Props, State> {
    state: State = {
        columnWidths: {},
        tableWidth: 1000,
    };

    componentDidMount() {
        window.addEventListener('resize', this.storeColumnWidths);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.storeColumnWidths);
        this.storeColumnWidths.cancel();
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.data !== this.props.data || prevProps.columns !== this.props.columns) {
            this.storeColumnWidths();
        }
    }

    columnWidthsStored = false;
    storeColumnWidths = debounce(() => {
        this.columnWidthsStored = true;
        const trackedColumns: HTMLElement[] = Array.from(
            document.querySelectorAll('.track-column-width') || []
        );
        const table: HTMLElement | null = document.querySelector('.rt-table');

        this.setState({
            columnWidths: trackedColumns.reduce((acc, node) => ({ ...acc, [node.id]: node.offsetWidth }), {}),
            tableWidth: table ? table.offsetWidth : this.state.tableWidth,
        });
    }, 1);

    getTheadThProps = (
        state: any,
        rowInfo: any,
        column: TableColumnProcessed & { parentColumn?: TableColumnProcessed }
    ) => {
        // we trigger storing here as the columns are not yet rendered in componentDidMount
        if (!this.columnWidthsStored) this.storeColumnWidths();

        if (!column.sticky && !(column.parentColumn && column.parentColumn.sticky)) return {};
        return {
            id: colIdToHtmlId(column.id),
            className: classNames(column.className, 'track-column-width'),
        };
    };

    sumColumnWidth = (columns: TableColumnProcessed[] = []): number =>
        columns
            .map(column => this.state.columnWidths[colIdToHtmlId(column.id)] || 0)
            .reduce((acc, val) => acc + val, 0);

    addStickyClasses = (
        columns: TableColumnProcessed[] = [],
        parrentSticky: boolean = false,
        nextParrentSticky: boolean = false,
        parrentLeft: number = 0
    ): TableColumnProcessed[] => {
        if (window.innerWidth < DISABLE_STICKINESS_BREAKPOINT) return columns;

        let haltSticking = false;

        return columns.map((column, index) => {
            const left = parrentLeft + this.sumColumnWidth(columns.slice(0, index));
            if (left + this.sumColumnWidth([column]) > this.state.tableWidth * STICK_FREE_WIDTH_RATIO)
                haltSticking = true;

            if (haltSticking || !(column.sticky || parrentSticky)) {
                return {
                    ...column,
                    columns: column.columns && this.addStickyClasses(column.columns),
                };
            }

            const nextSiblingSticky = columns[index + 1] && (columns[index + 1].sticky || parrentSticky);
            const nextColSticky = !haltSticking && (nextSiblingSticky || nextParrentSticky);
            return {
                ...column,
                className: classNames(column.className, 'sticky-column', {
                    'last-sticky-column': !nextColSticky,
                }),
                headerClassName: classNames(column.headerClassName, 'sticky-column', {
                    'last-sticky-column': !nextColSticky,
                }),
                columns: column.columns && this.addStickyClasses(column.columns, true, nextColSticky, left),
                style: { ...column.style, left },
                headerStyle: { ...column.headerStyle, left },
            };
        });
    };

    render() {
        return (
            <ReactTable
                {...this.props}
                // @ts-ignore
                ref={this.props.setRef}
                columns={this.addStickyClasses(this.props.columns)}
                onResizedChange={this.storeColumnWidths}
                getTheadThProps={this.getTheadThProps}
                getTheadGroupThProps={this.getTheadThProps}
            />
        );
    }
}

export default ReactTableWithStickyColumns;
