import * as React from 'react';
import classNames from 'classnames';
import './LabelWithSubtext.scss';

type Props = {
    label: string;
    subtext: string;
    className: string;
};

class LabelWithSubtext extends React.PureComponent<Props> {
    static defaultProps = {
        label: '',
        subtext: '-',
        className: '',
    };

    render() {
        const { label, subtext, className } = this.props;
        return (
            <div className={classNames('LabelWithSubtext', className)}>
                <span title={label}>{label}</span>
                <br />
                <span className="LabelWithSubtext-subtext" title={subtext}>
                    {subtext}
                </span>
            </div>
        );
    }
}

export default LabelWithSubtext;
