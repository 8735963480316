import React from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import './UploadInput.scss';
import { precisionRound } from '../../utils/formatters.util';
import { readFileData, FileInfo, ReadFileAs, readFileListData } from '../../utils/file.util';

type PropsCommon = {
    acceptableMimeTypes: string;
    subtitle: string;
    readFileAs: ReadFileAs;
    disabled: boolean;
    invalid: boolean;
    className?: string;
};

type PropsSingle = {
    multiple?: false;
    value?: FileInfo;
    onFileUpload: (file: FileInfo) => void;
};

type PropsMultiple = {
    multiple: true;
    value?: FileInfo[];
    onFileUpload: (files: FileInfo[]) => void;
};

type Props = PropsCommon & (PropsSingle | PropsMultiple);

const FileDescription = ({ file }: { file: FileInfo }) => (
    <>
        <Col xs={8}>{file.name || 'File'}</Col>
        {file.size && (
            <Col xs={4} className="text-right">
                {precisionRound(Number(file.size) / 1024, 1)} KB
            </Col>
        )}
    </>
);

const UploadInput = (props: Props) => (
    <div className={classNames('UploadInput', props.className)}>
        <Dropzone
            accept={props.acceptableMimeTypes}
            multiple={props.multiple}
            onDrop={(acceptedFiles, rejectedFiles) =>
                props.multiple
                    ? readFileListData({
                          acceptedFiles,
                          rejectedFiles,
                          readFileAs: props.readFileAs,
                          callback: props.onFileUpload,
                      })
                    : readFileData({
                          acceptedFiles,
                          rejectedFiles,
                          readFileAs: props.readFileAs,
                          callback: props.onFileUpload,
                      })
            }
            disabled={props.disabled}
        >
            {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                    {...getRootProps()}
                    className={classNames('UploadInput-dropzone form-control text-center', {
                        'UploadInput-dropzone--active': isDragActive,
                        'is-invalid': props.invalid,
                    })}
                >
                    <input {...getInputProps()} />
                    <div>{props.value ? 'Re-upload' : 'Upload'}</div>
                </div>
            )}
        </Dropzone>
        <small className="text-muted">{props.subtitle}</small>
        {!props.multiple && props.value && (
            <Row>
                <FileDescription file={props.value} />
            </Row>
        )}
        {props.multiple && props.value && (
            <Row>
                {props.value.map(file => (
                    <FileDescription file={file} />
                ))}
            </Row>
        )}
    </div>
);

UploadInput.defaultProps = {
    disabled: false,
    invalid: false,
    readFileAs: 'dataURL',
};

export default UploadInput;
