import React from 'react';

type Effect = (canceled: () => boolean) => void;

const wrappEffect = (effect: Effect) => () => {
    let canceled = false;
    const cancel = () => {
        canceled = true;
    };
    const isCanceledFn = () => canceled;
    effect(isCanceledFn);
    return cancel;
};

export const useCancelableEffect = (effect: Effect, dependancies: any[]) => {
    React.useEffect(wrappEffect(effect), dependancies);
};
