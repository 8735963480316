import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AnimatedButton from '../AnimatedButton/AnimatedButton';

type Props = {
    isOpen: boolean;
    toggle: () => any;
    onOk: () => any;
    title: string;
    children?: React.ReactNode;
    okLabel: string;
    cancelLabel: string;
    onCancel?: () => any;
    className?: string;
};

const defaultProps = {
    title: '',
    okLabel: 'OK',
    cancelLabel: 'Cancel',
};

const ConfirmationModal = ({
    isOpen,
    toggle,
    onOk,
    title,
    children,
    okLabel,
    cancelLabel,
    onCancel,
    ...rest
}: Props) => (
    <Modal isOpen={isOpen} toggle={toggle} {...rest}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
            <AnimatedButton disabled={false} onClick={onOk}>
                {okLabel}
            </AnimatedButton>{' '}
            <Button color="secondary" onClick={onCancel || toggle}>
                {cancelLabel}
            </Button>
        </ModalFooter>
    </Modal>
);

ConfirmationModal.defaultProps = defaultProps;

export default ConfirmationModal;
