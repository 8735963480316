import * as React from 'react';
import { formatDateTime } from '../../utils/date.util';
import LabelWithSubtext from '../LabelWithSubtext/LabelWithSubtext';

type Props = {
    date?: string;
    updatedBy: string;
};

class UpdatedOn extends React.PureComponent<Props> {
    static defaultProps = {
        updatedBy: 'Unknown',
    };

    render() {
        const { date, updatedBy } = this.props;
        return <LabelWithSubtext label={formatDateTime(date)} subtext={updatedBy} />;
    }
}

export default UpdatedOn;
