import React, { ReactNode } from 'react';
import { useField } from 'formik';
import { CSSModule, FormFeedback, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { validator, Validator } from 'platform/common/utils/validators.util';
import PlainInput from '../PlainInput/PlainInput';

type AddOn = {
    className?: string;
    title?: string;
    onClick?: () => any;
};

type Props = {
    id?: string;
    leftAddOn?: AddOn;
    rightAddOn?: AddOn;
    label: string;
    onFocus?: () => any;
    disabled: boolean;
    type: string;
    className?: string;
    name: string;
    style?: CSSModule;
    validate?: Validator | Validator[];
    placeholder?: string;
};

const defaultProps = {
    label: '',
    disabled: false,
};

const WithAddOn = (
    WrappedComponent: ReactNode,
    isInvalid: boolean,
    leftAddOn?: AddOn,
    rightAddOn?: AddOn
) => (
    <InputGroup className={`${isInvalid ? 'form-control is-invalid m-0 p-0' : ''}`}>
        {leftAddOn && (
            <InputGroupAddon addonType="prepend" onClick={leftAddOn.onClick}>
                <InputGroupText>
                    <i className={leftAddOn.className} />
                    {leftAddOn.title}
                </InputGroupText>
            </InputGroupAddon>
        )}
        {WrappedComponent}
        {rightAddOn && (
            <InputGroupAddon addonType="append" onClick={rightAddOn.onClick}>
                <InputGroupText>
                    <i className={rightAddOn.className} />
                    {rightAddOn.title}
                </InputGroupText>
            </InputGroupAddon>
        )}
    </InputGroup>
);

const FormInput = ({
    id,
    leftAddOn,
    rightAddOn,
    type,
    label,
    onFocus,
    disabled,
    className,
    name,
    validate,
    placeholder,
    ...rest
}: Props) => {
    const [field, meta] = useField({ name, validate: validate && validator(validate) });
    const isInvalid = Boolean(meta.touched && meta.error);
    const hasAddOn = !!leftAddOn || !!rightAddOn;
    const componentProps = {
        isInvalid,
        type,
        onFocus,
        disabled,
        placeholder: placeholder ?? (hasAddOn ? label : undefined),
        id,
        key: id || name,
        ...field,
        ...rest,
    };

    const inputField = <PlainInput {...componentProps} />;

    const message = isInvalid ? <FormFeedback>{meta.error}</FormFeedback> : undefined;

    if (hasAddOn) {
        return (
            <>
                {WithAddOn(inputField, isInvalid, leftAddOn, rightAddOn)}
                {message}
            </>
        );
    }

    return (
        <div className={className || 'flex-grow'}>
            {inputField}
            {message}
        </div>
    );
};

FormInput.defaultProps = defaultProps;

export default FormInput;
