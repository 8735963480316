import * as React from 'react';
import memoizeOne from 'memoize-one';
import { noop } from 'lodash-es';
import ButtonDropdown from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import dataExport from './dataExport';
import { TableColumn } from '../FormattedTable/FormattedTable';

export type ExportOptions = {
    loadData: Function;
    filename: string;
    exportHeader?: boolean;
    headers?: string[];
    additionalExports?: {
        loadData: Function;
        filename: string;
        label: string;
        exportHeader?: boolean;
    }[];
};

type Props = {
    columns: TableColumn[];
    exportOptions: ExportOptions;
    disabled: boolean;
    className: string;
};

const defaultProps = {
    fetchData: noop,
    disabled: false,
    className: '',
};

class DataExportButton extends React.PureComponent<Props> {
    static defaultProps = defaultProps;

    getItems = memoizeOne((columns, exportOptions) => [
        {
            label: 'CSV',
            action: async () => {
                const { loadData, filename, exportHeader, headers } = exportOptions;
                const data = await loadData();
                dataExport.downloadCsv(columns, data, filename, exportHeader, headers);
            },
        },
        {
            label: 'XLSX',
            action: async () => {
                const { loadData, filename, exportHeader, headers } = exportOptions;
                const data = await loadData();
                dataExport.downloadXlsx(columns, data, filename, exportHeader, headers);
            },
        },
        ...(this.props.exportOptions.additionalExports || []).map(additionalExport => {
            const { loadData, filename, label, exportHeader } = additionalExport;
            return {
                label,
                action: async () => {
                    const data = await loadData();
                    dataExport.downloadXlsx(columns, data, filename, exportHeader);
                },
            };
        }),
    ]);

    render() {
        const { disabled, className, columns, exportOptions } = this.props;
        return (
            <ButtonDropdown
                color="secondary"
                className={className}
                disabled={disabled}
                items={this.getItems(columns, exportOptions)}
                dropdownMenuRight
            >
                <i className="fa fa-download" /> Export
            </ButtonDropdown>
        );
    }
}

export default DataExportButton;
