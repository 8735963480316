import { DragEvent } from 'react';
import { Editor, NodeEntry, Element, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

export const getActiveEntryOfType = (editor: Editor, elementType: string): NodeEntry<any> => {
    const [entry] = Editor.nodes(editor, { match: n => n.type === elementType });
    return entry;
};

export const elementMatcher = (elementType: string) => ({ match: (n: Element) => n.type === elementType });

export const withBlockDrop = <T extends Editor>(editor: T): T => {
    const { insertData } = editor;

    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    editor.insertData = (data: any) => {
        const encodedElementPath = data.getData('application/x-slate-cdo-block');
        if (encodedElementPath) {
            const decoded = decodeURIComponent(atob(encodedElementPath));
            const sourcePath = JSON.parse(decoded);

            const deepestBlockEntry = Array.from(
                Editor.levels(editor, { at: editor.selection?.focus, reverse: true })
            ).find(entry => Editor.isBlock(editor, entry[0]))!;

            if (!deepestBlockEntry) return;
            const [, deepestBlockElementPath] = deepestBlockEntry;

            Transforms.moveNodes(editor, {
                at: sourcePath,
                to: deepestBlockElementPath,
            });
            Transforms.select(editor, deepestBlockElementPath);
        } else {
            insertData(data);
        }
    };
    return editor;
};

export const handleDragStart = (
    editor: Editor,
    element: Element,
    dataType: string = 'application/x-slate-cdo-block'
) => (event: DragEvent<any>) => {
    // @ts-ignore
    const path = ReactEditor.findPath(editor, element);
    const encoded = btoa(encodeURIComponent(JSON.stringify(path)));
    event.dataTransfer.setData(dataType, encoded);
    // some fake data for native drop to work in all cases
    event.dataTransfer.setData('text/plain', 'component');
    // event.dataTransfer.setData('application/x-slate-fragment', '');
    event.dataTransfer.setDragImage(new Image(), 10, 10);

    event.persist();
};
