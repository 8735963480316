import React from 'react';
import { WithFormProps } from 'platform/common/common.type';
import { AdBoardFormModel } from 'platform/adBoard/adDboard.types';
import { Formik, FormikProps } from 'formik';
import CardForm from 'platform/common/components/CardForm/CardForm';
import withCreateForm from 'platform/common/components/WithCreateForm/WithCreateForm';
import withEditForm from 'platform/common/components/WithEditForm/WithEditForm';
import * as campaignService from 'platform/campaign/campaign.service';
import { States } from 'platform/common/constants/states.constant';
import * as AdBoardMapper from 'platform/adBoard/adBoard.mapper';
import * as adBoardService from 'platform/adBoard/adBoard.service';
import * as teaserService from 'platform/teaser/teaser.service';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { required } from 'platform/common/utils/validators.util';
import FormSelect from 'platform/common/components/FormSelect/FormSelect';
import { arrayMove } from 'react-sortable-hoc';

const FormFields = ({
    initialValues,
    formProps,
}: {
    initialValues: AdBoardFormModel;
    formProps: FormikProps<AdBoardFormModel>;
}) => (
    <ControlledCard title="General info">
        <FormRow label="Name">
            <FormInput name="name" type="text" validate={required} />
        </FormRow>
        <FormRow label="Campaign">
            <FormSelect
                name="campaignId"
                options={initialValues.campaigns.map(campaign => ({
                    value: campaign.id,
                    label: campaign.name,
                }))}
                isClearable={false}
                validate={required}
            />
        </FormRow>
        <FormRow label="Teasers">
            <FormSelect
                name="teaserIds"
                options={initialValues.teasers
                    .filter(teaser => formProps.values.campaignId === teaser.campaignId)
                    .map(teaser => ({
                        value: teaser.id,
                        label: teaser.name,
                    }))}
                sortEnd={(values, sort) => arrayMove(values, sort.oldIndex, sort.newIndex)}
                isMulti
                isClearable={false}
                validate={required}
            />
        </FormRow>
    </ControlledCard>
);

const AdBoardForm = (props: WithFormProps<AdBoardFormModel>) => {
    const { onSubmit, onClose, labels, initialValues, canEdit } = props;

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(formProps: FormikProps<AdBoardFormModel>) => (
                <CardForm
                    title={`${labels.prefix} AdBoard`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    onClose={onClose}
                    disabled={!canEdit}
                    onSubmit={formProps.submitForm}
                >
                    <FormFields initialValues={initialValues} formProps={formProps} />
                </CardForm>
            )}
        </Formik>
    );
};

const withCreateOptions = {
    onOpen: async () => {
        const [campaigns, teasers]: any = await Promise.all([
            campaignService.fetchCampaigns({ statuses: [States.ACTIVE] }),
            teaserService.fetchTeasers(),
        ]);
        return AdBoardMapper.toFormModel({}, campaigns, teasers);
    },
    onSubmit: (model: AdBoardFormModel) => adBoardService.createAdBoard(AdBoardMapper.toApi(model)),
};

const withEditOptions = {
    onOpen: async (params: { id: string }) => {
        const [adBoard, campaigns, teasers]: any = await Promise.all([
            adBoardService.fetchAdBoard(Number(params.id)),
            campaignService.fetchCampaigns({ statuses: [States.ACTIVE] }),
            teaserService.fetchTeasers(),
        ]);
        return AdBoardMapper.toFormModel(adBoard, campaigns, teasers);
    },
    onSubmit: (model: AdBoardFormModel) => adBoardService.updateAdBoard(AdBoardMapper.toApi(model)),
};

export const AdBoardFormCreate = withCreateForm(withCreateOptions)(AdBoardForm);

export const AdBoardFormEdit = withEditForm(withEditOptions)(AdBoardForm);
