import React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap';
import FormInput from 'platform/common/components/FormInput/FormInput';
import { required } from '../../../common/utils/validators.util';

type Props = {
    loading: boolean;
    submitError: boolean;
    errorMessage?: string;
    onSubmit: (data: any) => void;
};

const LoginForm = (props: Props) => {
    const { loading, submitError, errorMessage, onSubmit } = props;
    return (
        <Formik
            initialValues={{
                username: '',
                password: '',
            }}
            onSubmit={onSubmit}
        >
            {formProps => (
                <Form data-testid="loginForm" onSubmit={formProps.handleSubmit} method="post">
                    {submitError && <Alert color="danger">{errorMessage}</Alert>}

                    <p className="font-weight-bold">Sign In to your account</p>

                    <FormGroup>
                        <FormInput
                            data-testid="userName"
                            className="mb-4"
                            id="username"
                            name="username"
                            type="text"
                            leftAddOn={{ className: 'fa fa-user' }}
                            label="Email"
                            validate={required}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormInput
                            data-testid="password"
                            id="password"
                            name="password"
                            type="password"
                            leftAddOn={{ className: 'fa fa-lock' }}
                            validate={required}
                            label="Password"
                        />
                    </FormGroup>
                    <Row>
                        <Col xs="6">
                            <Button
                                data-testid="loginButton"
                                type="submit"
                                color="primary"
                                className="px-4"
                                disabled={loading}
                            >
                                Login
                            </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                            <Link className="px-0" to="/login/forgot-password">
                                Forgot password?
                            </Link>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
