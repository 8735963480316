import * as React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { Store } from 'redux';
import { History } from 'history';
import { Action } from 'platform/common/common.type';
import { RootState } from 'platform/rootState.type';
import { login } from 'platform/app/app.route';
import { ModalProvider } from 'platform/common/components/Modal/Modal';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import App from '../App/App';
import unhandledRejectionHandler from '../UnhandledRejectionHandler/UnhandledRejectionHandler';

type Props = {
    store: Store<RootState, Action>;
    history: History;
};

const AppComponent = unhandledRejectionHandler(App);

const Root = ({ store, history }: Props) => (
    <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
            <ModalProvider>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route path={login.path} component={login.component} />
                        <Route path="/" component={AppComponent} />
                    </Switch>
                </ConnectedRouter>
            </ModalProvider>
        </DndProvider>
    </Provider>
);

export default hot(module)(Root);
