import React from 'react';
import { useField } from 'formik';
import Switch from '../Switch/Switch';

type Props = {
    name: string;
    className?: string;
    disabled?: boolean;
};

const FormSwitch = (props: Props) => {
    const { name, ...rest } = props;
    const [field] = useField(name);
    return <Switch name={name} onChange={field.onChange} value={field.value} {...rest} />;
};

export default FormSwitch;
