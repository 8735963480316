import React from 'react';
import { Container } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import withErrorBoundary from 'platform/common/components/WithErrorBoundary/WithErrorBoundary';
import userManagement from 'platform/userManagement/userManagement.route';
import websites from 'platform/adminPanel/websites/websites.route';
import feeds from 'platform/adminPanel/feeds/feeds.route';
import advertisers from 'platform/adminPanel/advertisers/advertiser.route';
import campaign from 'platform/campaign/campaign.route';
import teaser from 'platform/teaser/teaser.route';
import adBoard from 'platform/adBoard/adBoard.route';
import advertorial from 'platform/advertorial/advertorial.route';
import masterTemplates from 'platform/adminPanel/masterTemplates/masterTemplates.route';
import { RoutingItem } from '../../app.type';
import { profile } from '../../app.route';
import AppHeader from '../AppHeader/AppHeader';
import Sidebar from '../Sidebar/Sidebar';
import { RootState } from '../../../rootState.type';
import { selectors as authSelectors } from '../../auth.duck';
import NotFoundPage from '../NotFound/NotFound';

const routes: RoutingItem[] = [
    profile,
    userManagement,
    websites,
    feeds,
    advertisers,
    campaign,
    teaser,
    adBoard,
    advertorial,
    masterTemplates,
].map(item => ({
    ...item,
    component: withErrorBoundary(item.component),
}));

const App = () => {
    const isAuthenticated = useSelector((state: RootState) => authSelectors.isAuthenticated(state.session));

    if (!isAuthenticated) {
        return null;
    }

    return (
        <div className="app">
            <AppHeader />
            <div className="app-body">
                <Sidebar />
                <main className="main">
                    <Container fluid>
                        <Switch>
                            {routes.map(route => (
                                <Route key={route.path} path={route.path} component={route.component} />
                            ))}
                            <Route component={NotFoundPage} />
                        </Switch>
                    </Container>
                </main>
            </div>
        </div>
    );
};

export default App;
