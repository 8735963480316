import React from 'react';
import { useModal } from 'platform/common/components/Modal/Modal';
import { capitalize, sortBy, isEmpty } from 'lodash-es';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import LabelLink from 'platform/common/components/LabelLink/LabelLink';
import { usePromise } from 'platform/common/hooks/usePromise';
import * as advertorialService from 'platform/advertorial/advertorial.service';
import { DATA_TYPES } from 'platform/common/dataType';
import { TableCell } from 'platform/common/common.type';
import { getChangelogRows, Changelog, renderValue } from 'platform/common/utils/changelog.util';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import AdvertorialPreview from 'platform/advertorial/AdvertorialPreview';
import { generateAdvertorialOutput } from 'platform/advertorial/AdvertorialEditor/serialize';

type Props = {
    id: number;
};

const RenderJson = ({ jsonSource }: { jsonSource?: string }) => {
    const { showModal } = useModal();
    const value = jsonSource && JSON.parse(jsonSource);
    return isEmpty(value) ? (
        <span>-</span>
    ) : (
        <LabelLink
            onClick={() =>
                showModal(toggle => (
                    <AdvertorialPreview
                        isOpen
                        toggle={toggle}
                        bodyContent={generateAdvertorialOutput(value, [])}
                    />
                ))
            }
        >
            <i className="fa fa-eye mr-2" />
        </LabelLink>
    );
};

const AdvertorialChangelog = ({ id }: Props) => {
    const [{ data, loading }] = usePromise(
        [],
        () =>
            advertorialService.fetchChangelog(id).then(response =>
                sortBy(response, cl => -cl.version).flatMap((newObject, index, array) => {
                    const oldObject = index === array.length - 1 ? {} : array[index + 1];
                    return getChangelogRows(
                        newObject,
                        oldObject,
                        newObject.lastModifiedOn,
                        newObject.lastModifiedBy,
                        ['htmlOutput', 'version', 'lastModifiedOn', 'lastModifiedBy']
                    );
                })
            ),
        []
    );

    return (
        <FormattedTable
            className="-striped"
            columns={[
                {
                    Header: 'Datetime',
                    accessor: 'dateTime',
                    type: DATA_TYPES.DATE_TIME,
                    width: 140,
                },
                {
                    Header: 'User',
                    accessor: 'user',
                    width: 160,
                },
                {
                    Header: 'Field',
                    maxWidth: 120,
                    Cell: (row: TableCell<Changelog>) => capitalize(row.original.field),
                },
                {
                    Header: 'Old value',
                    minWidth: 180,
                    Cell: (row: TableCell<Changelog>) =>
                        row.original.field === 'jsonSource' ? (
                            <RenderJson jsonSource={row.original.oldValue} />
                        ) : (
                            <Tooltip
                                placement="left"
                                className="with-ellipsis"
                                renderTooltip={() => <div className="p-2">{row.original.oldValue}</div>}
                            >
                                {renderValue(row.original.oldValue)}
                            </Tooltip>
                        ),
                },
                {
                    Header: 'New value',
                    minWidth: 180,
                    Cell: (row: TableCell<Changelog>) =>
                        row.original.field === 'jsonSource' ? (
                            <RenderJson jsonSource={row.original.newValue} />
                        ) : (
                            <Tooltip
                                placement="left"
                                className="with-ellipsis"
                                renderTooltip={() => <div className="p-2">{row.original.newValue}</div>}
                            >
                                {renderValue(row.original.newValue)}
                            </Tooltip>
                        ),
                },
            ]}
            data={data}
            loading={loading}
        />
    );
};

export default AdvertorialChangelog;
