import React from 'react';
import { Location } from 'history';
import { useSelector } from 'react-redux';
import { escapeRegExp, flow } from 'lodash-es';
import { selectors as authSelectors } from 'platform/app/auth.duck';
import { NavigationItem } from '../../app.type';
import { RootState } from '../../../rootState.type';
import SidebarView from './SidebarView';
import navigationItems from './navigation';

export const pathMatches = (itemPath: string | undefined, currentPath: string) =>
    new RegExp(`^${escapeRegExp(itemPath)}(/.*)?$`, 'i').test(currentPath);

const setActiveItems = (location: Location<any>) =>
    function _setActiveItems(items: NavigationItem[]): NavigationItem[] {
        return items.map(item => ({
            ...item,
            active: pathMatches(item.path, location.pathname),
            children: item.children && _setActiveItems(item.children),
        }));
    };

const filterByAuthority = (hasAuthority: (authority: NavigationItem['requiresAuthority']) => boolean) => (
    items: NavigationItem[]
): NavigationItem[] => {
    const authorized = (item: NavigationItem) =>
        !item.requiresAuthority || hasAuthority(item.requiresAuthority);

    return items.filter(authorized).map(
        item =>
            ({
                ...item,
                children: item.children && item.children.filter(authorized),
            } as NavigationItem)
    );
};

const Sidebar = () => {
    const { location } = useSelector((state: RootState) => state.router);
    const hasAuthority = useSelector((state: RootState) => authSelectors.hasAuthority(state.session));

    const items = flow(filterByAuthority(hasAuthority), setActiveItems(location))(navigationItems);

    return <SidebarView navItems={items} />;
};

export default Sidebar;
