import * as React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import classNames from 'classnames';
import { camelCase } from 'lodash-es';

type Props = {
    rowHtmlId?: string;
    label?: string;
    children?: React.ReactNode;
    inline?: boolean;
    rowClassName?: string;
    childrenColClassName?: string;
    labelClassName?: string;
    labelWidth?: number;
};

const childrenWithIds = (baseId: string, children: React.ReactNode) =>
    React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) {
            return child;
        }
        if ((child as React.ReactElement).props.id) {
            return child;
        }
        const id = baseId + (index > 0 ? index : '');
        return React.cloneElement(child as React.ReactElement, { id });
    });

const FormRow = ({
    rowHtmlId,
    label,
    children,
    inline = false,
    rowClassName,
    childrenColClassName,
    labelClassName = 'text-right',
    labelWidth = 4,
}: Props) => {
    const baseId = rowHtmlId || camelCase(label);
    return (
        <FormGroup row className={rowClassName}>
            <Label md={labelWidth} className={labelClassName} for={baseId}>
                {label}
            </Label>
            <Col
                md={12 - labelWidth}
                className={classNames('align-self-center', childrenColClassName, {
                    'form-inline d-flex align-items-center': inline,
                })}
            >
                {childrenWithIds(baseId, children)}
            </Col>
        </FormGroup>
    );
};

export default FormRow;
