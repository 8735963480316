import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { RootState } from 'platform/rootState.type';
import { Action } from 'platform/common/common.type';
import { fulfilled } from 'platform/common/utils/actionSuffixes.util';
import auth, { IMPERSONATE, LOGOUT, STOP_IMPERSONATION } from 'platform/app/auth.duck';

export default (history: History) => {
    const combinedReducers = combineReducers({
        session: auth,
        router: connectRouter(history),
    });

    const rootReducer = (state: RootState | undefined, action: Action): RootState => {
        switch (action.type) {
            case LOGOUT:
            case fulfilled(IMPERSONATE):
            case fulfilled(STOP_IMPERSONATION):
                if (!state) {
                    throw new Error('state is not defined');
                }

                return {
                    ...combinedReducers(undefined, action),
                    session: auth(state.session, action),
                    router: state.router,
                };
            default:
                return combinedReducers(state, action);
        }
    };

    return rootReducer;
};
