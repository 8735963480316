export enum TwoFactorAuthenticationType {
    NONE = 'NONE',
    SMS = 'SMS',
    AUTHENTICATOR = 'AUTHENTICATOR',
}

export enum TwoFactorAuthenticationTypeLabel {
    NONE = 'None',
    SMS = 'SMS',
    AUTHENTICATOR = 'Authenticator',
}

export const TWO_FACTOR_AUTHENTICATION_OPTIONS = [
    {
        label: TwoFactorAuthenticationTypeLabel.NONE,
        value: TwoFactorAuthenticationType.NONE,
    },
    {
        label: TwoFactorAuthenticationTypeLabel.SMS,
        value: TwoFactorAuthenticationType.SMS,
    },
    {
        label: TwoFactorAuthenticationTypeLabel.AUTHENTICATOR,
        value: TwoFactorAuthenticationType.AUTHENTICATOR,
    },
];
