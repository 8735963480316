import axiosBff from 'platform/axios';
import { IdResponse } from 'platform/common/common.type';
import { Advertorial } from './advertorial.type';

type AdvertorialChangelog = Required<Advertorial> & { version: number };

export const fetchAdvertorials = (
    params: {
        campaignIds?: number[];
    } = {}
) =>
    axiosBff
        .get<Advertorial[]>('/sdo/api/advertorials', { params })
        .then(response => response.data);

export const fetchAdvertorial = (id: number) =>
    axiosBff.get<Advertorial>(`/sdo/api/advertorials/${id}`).then(response => response.data);

export const createAdvertorial = (model: Partial<Advertorial>) =>
    axiosBff.post<IdResponse>('/sdo/api/advertorials', model).then(response => response.data);

export const duplicateAdvertorial = (id: number) =>
    axiosBff.post<IdResponse>(`/sdo/api/advertorials/${id}/duplicate`).then(response => response.data);

export const updateAdvertorial = (model: Partial<Advertorial>) =>
    axiosBff.put<IdResponse>('/sdo/api/advertorials', model).then(response => response.data);

export const uploadImage = (file: { content: string; filename: string }) =>
    axiosBff.post<{ fullUrl: string }>('/sdo/api/advertorials/image', file).then(response => response.data);

export const fetchChangelog = (id: number) =>
    axiosBff
        .get<AdvertorialChangelog[]>(`/sdo/api/advertorials/${id}/changelog`)
        .then(response => response.data);

export const lockAdvertorial = (id: number) =>
    axiosBff.patch(`/sdo/api/advertorials/lock/${id}`).then(response => response.data);

export const unlockAdvertorial = (id: number) =>
    axiosBff.patch(`/sdo/api/advertorials/unlock/${id}`).then(response => response.data);
