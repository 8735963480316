import { LOCATION_CHANGE, push } from 'connected-react-router';
import { get, flatMap } from 'lodash-es';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';
import { fulfilled, pending, rejected } from 'platform/common/utils/actionSuffixes.util';
import { currentTimeSeconds } from 'platform/common/utils';
import { User } from 'platform/userManagement/users/user.type';
import { Action } from 'platform/common/common.type';
import { RootState } from 'platform/rootState.type';
import { TwoFactorAuthenticationType } from 'platform/userManagement/userManagement.constant';
import * as authService from './auth.service';
import navigationItems from './components/Sidebar/navigation';
import { Account, NavigationItem, Profile } from './app.type';

const ERROR_MESSAGES = {
    INVALID: 'This combination of username and password was not recognized.',
    LOCKED: 'Your account is locked. Please try again in 5 minutes.',
    INACTIVE: 'This user is inactive. Please contact system administrator.',
    PASSWORD_RESET_TOKEN_EXPIRED: 'Password reset token has expired.',
    SMS_NOT_SENT: 'There was an error while sending verification code sms. Retry later.',
    VERIFICATION_CODE_INVALID: 'Verification code does not match. Please try again or resend.',
    UNKNOWN: 'Oops! Something went wrong. Try again later.',
};

export const LOGIN = 'app/auth/LOGIN';
const PASSWORD_RESET = 'app/auth/PASSWORD_RESET';
const SET_PASSWORD = 'app/auth/SET_PASSWORD';
const SEND_2FA_CODE = 'app/auth/SEND_2FA_CODE';
const FETCH_2FA_CODE = 'app/auth/FETCH_2FA_CODE';
export const VERIFY_2FA_CODE = 'app/auth/VERIFY_2FA_CODE';
export const VERIFY_2FA_SETUP = 'app/auth/VERIFY_2FA_SETUP';
export const LOGOUT = 'app/auth/LOGOUT';
export const IMPERSONATE = 'app/auth/IMPERSONATE';
export const IMPERSONATING = 'app/auth/IMPERSONATING';
export const STOP_IMPERSONATION = 'app/auth/STOP_IMPERSONATION';
export const USER_UNAUTHORIZED = 'app/auth/USER_UNAUTHORIZED';
const FETCH_CURRENT_USER = 'app/auth/FETCH_CURRENT_USER';
const FETCH_CURRENT_USER_PROFILE = 'app/auth/FETCH_CURRENT_USER_PROFILE';
const UPDATE_CURRENT_USER_PROFILE = 'app/auth/UPDATE_CURRENT_USER_PROFILE';
const UPDATE_CURRENT_USER_PASSWORD = 'app/auth/UPDATE_CURRENT_USER_PASSWORD';
const RESEND_INVITATION = 'app/auth/RESEND_INVITATION';
const SAVE_RETURN_LOCATION = 'app/auth/SAVE_RETURN_LOCATION';
const HIDE_MAINTENANCE_MODE_MESSAGE = 'app/auth/HIDE_MAINTENANCE_MODE_MESSAGE';
const TOGGLE_DEBUG_MODE = 'app/auth/TOGGLE_DEBUG_MODE';

export type AuthState = {
    token: string;
    tokenExpires: number;
    account: Partial<Account>;
    profile: Partial<Profile>;
    loading: boolean;
    error: boolean;
    errorMessage?: string;
    recoveryLinkSent: boolean;
    returnLocation?: Location;
    maintenanceModeMessageHidden: boolean;
    impersonating: boolean;
    // This can be enabled only through dev console or local storage
    debugMode: boolean;
};

const defaultState: AuthState = {
    token: '',
    tokenExpires: 0,
    account: {},
    profile: {},
    loading: false,
    error: false,
    errorMessage: undefined,
    recoveryLinkSent: false,
    maintenanceModeMessageHidden: false,
    impersonating: false,
    debugMode: false,
};

const reducer = (state: AuthState = defaultState, action: Action): AuthState => {
    switch (action.type) {
        case pending(UPDATE_CURRENT_USER_PASSWORD):
        case pending(PASSWORD_RESET):
        case pending(SET_PASSWORD):
        case pending(SEND_2FA_CODE):
        case pending(FETCH_2FA_CODE):
        case pending(VERIFY_2FA_SETUP):
        case pending(VERIFY_2FA_CODE): {
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: undefined,
                recoveryLinkSent: false,
            };
        }
        case pending(LOGIN): {
            return {
                ...defaultState,
                loading: true,
                returnLocation: state.returnLocation,
            };
        }
        case fulfilled(LOGIN): {
            const session = action.payload.data;
            return {
                ...state,
                loading: false,
                token: session.access_token,
                tokenExpires: session.expires_in + currentTimeSeconds(),
            };
        }
        case rejected(LOGIN): {
            const {
                status,
                data: { error, error_description: errorDescription },
            } = action.payload.response;

            let errorMessage;
            if (status === 400 && error === 'invalid_grant') {
                errorMessage = ERROR_MESSAGES.INVALID;
            } else if (status === 401 && errorDescription === 'Maximum login attempts reached') {
                errorMessage = ERROR_MESSAGES.LOCKED;
            } else if (status === 401 && errorDescription === 'ACCESS_DENIED_USER_INACTIVE') {
                errorMessage = ERROR_MESSAGES.INACTIVE;
            } else {
                errorMessage = ERROR_MESSAGES.UNKNOWN;
            }

            return {
                ...state,
                loading: false,
                error: true,
                errorMessage,
            };
        }
        case fulfilled(PASSWORD_RESET): {
            return {
                ...state,
                loading: false,
                recoveryLinkSent: true,
            };
        }
        case rejected(PASSWORD_RESET): {
            return {
                ...state,
                loading: false,
                error: true,
            };
        }
        case fulfilled(SET_PASSWORD): {
            return {
                ...state,
                loading: false,
            };
        }
        case rejected(SET_PASSWORD): {
            const {
                data: { errorCode },
            } = action.payload.response;

            const errorMessage =
                errorCode === 'ACTION_KEY_INVALID'
                    ? ERROR_MESSAGES.PASSWORD_RESET_TOKEN_EXPIRED
                    : ERROR_MESSAGES.UNKNOWN;

            return {
                ...state,
                loading: false,
                error: true,
                errorMessage,
            };
        }
        case fulfilled(SEND_2FA_CODE): {
            return {
                ...state,
                loading: false,
            };
        }
        case rejected(SEND_2FA_CODE): {
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: ERROR_MESSAGES.SMS_NOT_SENT,
            };
        }
        case fulfilled(VERIFY_2FA_CODE): {
            return {
                ...state,
                loading: false,
            };
        }
        case rejected(VERIFY_2FA_CODE): {
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: ERROR_MESSAGES.VERIFICATION_CODE_INVALID,
            };
        }
        case fulfilled(FETCH_2FA_CODE): {
            return {
                ...state,
                loading: false,
            };
        }
        case rejected(FETCH_2FA_CODE): {
            return {
                ...state,
                error: true,
            };
        }
        case fulfilled(VERIFY_2FA_SETUP): {
            return {
                ...state,
                loading: false,
            };
        }
        case rejected(VERIFY_2FA_SETUP): {
            return {
                ...state,
                error: true,
                loading: false,
                errorMessage: ERROR_MESSAGES.VERIFICATION_CODE_INVALID,
            };
        }
        case fulfilled(FETCH_CURRENT_USER): {
            return {
                ...state,
                account: action.payload.data,
            };
        }
        case fulfilled(FETCH_CURRENT_USER_PROFILE): {
            return { ...state, profile: action.payload.data };
        }
        case fulfilled(UPDATE_CURRENT_USER_PROFILE): {
            return { ...state, profile: action.payload.data };
        }
        case rejected(UPDATE_CURRENT_USER_PASSWORD): {
            return {
                ...state,
                error: true,
            };
        }
        case LOGOUT: {
            return {
                ...defaultState,
            };
        }
        case LOCATION_CHANGE: {
            const { pathname } = action.payload.location;
            if (pathname.startsWith('/login')) {
                return {
                    ...state,
                    loading: false,
                    error: false,
                    errorMessage: undefined,
                    recoveryLinkSent: false,
                };
            }
            return state;
        }
        case SAVE_RETURN_LOCATION: {
            return {
                ...state,
                returnLocation: action.payload,
            };
        }
        case HIDE_MAINTENANCE_MODE_MESSAGE: {
            return {
                ...state,
                maintenanceModeMessageHidden: true,
            };
        }
        case TOGGLE_DEBUG_MODE: {
            return {
                ...state,
                debugMode: !state.debugMode,
            };
        }
        case IMPERSONATING: {
            return {
                ...state,
                impersonating: action.payload,
            };
        }
        default:
            return state;
    }
};

export default reducer;

const authorityListSelector = (state: AuthState): string[] => get(state, 'account.authorities', []);

const hasAuthority = createSelector(
    authorityListSelector,
    (allowedAuthorities: string[]) => (
        authority?: string | { any: string[] } | { all: string[] }
    ): boolean => {
        const isAuthorityInAllowedAuthorities = (a: any) => (a ? allowedAuthorities.includes(a) : true);
        if (authority && typeof authority === 'object') {
            if ('any' in authority && authority.any.length) {
                return authority.any.some(isAuthorityInAllowedAuthorities);
            }
            if ('all' in authority && authority.all.length) {
                return authority.all.every(isAuthorityInAllowedAuthorities);
            }
        }

        return isAuthorityInAllowedAuthorities(authority);
    }
);

const is2FANeeded = (state: AuthState): boolean => get(state, 'account.needed2FA', false);

const fetch2FaType = (state: AuthState): TwoFactorAuthenticationType =>
    get(state, 'account.twoFactorAuthenticationType', TwoFactorAuthenticationType.NONE);

const isDemoModeEnabled = (state: AuthState): boolean => get(state, 'profile.demoModeEnabled');

const emailSelector = (state: AuthState): string => get(state, 'account.login');

const fetchCurrentUser = () => ({
    type: FETCH_CURRENT_USER,
    payload: authService.fetchCurrentUser(),
});

const fetchCurrentUserProfile = () => ({
    type: FETCH_CURRENT_USER_PROFILE,
    payload: authService.fetchCurrentUserProfile(),
});

const logout = () => ({
    type: LOGOUT,
});

const getFirstAvailableRoute = (
    items: NavigationItem[],
    authorized: (authority: NavigationItem['requiresAuthority']) => boolean
) =>
    items
        .reduce(
            (acc, item) => [...acc, ...(item.children && item.children.length ? item.children : [item])],
            [] as NavigationItem[]
        )
        .find(item => authorized(item.requiresAuthority));

const deepFind = <T>(
    items: T[] = [],
    predicate: (item: T) => boolean,
    pickChildren: (item: T) => T[]
): T | undefined => {
    const item = items.find(predicate);
    if (item) return item;
    const children = flatMap(items, pickChildren);
    if (!children || !children.length) return undefined;
    return deepFind(children, predicate, pickChildren);
};

export const isLocationAuthorized = (
    location: Location,
    navItems: NavigationItem[],
    authorized: (authority: NavigationItem['requiresAuthority']) => boolean
) => {
    const itemForLocation =
        deepFind(
            navItems,
            item => item.path === location.pathname,
            item => item.children || []
        ) ||
        deepFind(
            navItems,
            item => location.pathname.startsWith(`${item.path}/`),
            item => item.children || []
        );

    if (!itemForLocation) return true;
    return authorized(itemForLocation.requiresAuthority);
};

const redirectToMainRoute = () => (dispatch: Dispatch, getState: () => { session: AuthState }) => {
    const state = getState();

    const authorized = hasAuthority(state.session);

    const { returnLocation } = state.session;
    if (returnLocation && isLocationAuthorized(returnLocation, navigationItems, authorized)) {
        return dispatch(push(returnLocation));
    }

    const availableRoute = getFirstAvailableRoute(navigationItems, authorized);

    if (!availableRoute) {
        return undefined;
    }
    const path = availableRoute.redirectTo || availableRoute.path;
    if (!path) {
        return undefined;
    }
    return dispatch(push(path));
};

const login = ({ username, password }: { username: string; password: string }) => ({
    type: LOGIN,
    payload: authService.login(username, password),
});

const sendPasswordResetEmail = ({ username }: { username: string }) => ({
    type: PASSWORD_RESET,
    payload: authService.sendPasswordResetEmail(username),
});

const setPassword = (
    activationKey: string,
    password: string,
    { reset = false }: { reset: boolean }
) => async (dispatch: Dispatch) => {
    await dispatch({
        type: SET_PASSWORD,
        payload: authService.setPassword(activationKey, password, reset),
    });
    dispatch(push('/login'));
};

const send2FaSmsCode = () => ({
    type: SEND_2FA_CODE,
    payload: authService.send2FaSmsCode(),
});

const fetch2FaCode = (activationKey: string) => ({
    type: FETCH_2FA_CODE,
    payload: authService.fetch2FaCode(activationKey),
});

const saveReturnLocation = (location: Location) => ({
    type: SAVE_RETURN_LOCATION,
    payload: location,
});

const verify2FaCode = ({ code }: { code: string }) => ({
    type: VERIFY_2FA_CODE,
    payload: authService.verify2FaCode(code),
});

const confirm2FaSetup = (activationKey: string, { code }: { code: string }) => async (dispatch: Dispatch) => {
    await dispatch({
        type: VERIFY_2FA_SETUP,
        payload: authService.confirm2FaSetup(activationKey, code),
    });
    dispatch(push('/login'));
};

const isAuthenticated = (state: AuthState): boolean => Boolean(state.token);

const canImpersonate = (state: AuthState): boolean => get(state, 'account.canImpersonate', false);

const seatId = (state: AuthState): number => get(state, 'account.seatId', undefined);

const impersonating = (state: AuthState): boolean => state.impersonating;

const impersonate = (userToImpersonate: string) => async (dispatch: Dispatch<any>) => {
    dispatch({ type: IMPERSONATING, payload: true });

    await dispatch({
        type: IMPERSONATE,
        payload: authService.impersonate({ disable: false, login: userToImpersonate }),
    });

    await Promise.all([dispatch(fetchCurrentUser()), dispatch(fetchCurrentUserProfile())]);

    dispatch({ type: IMPERSONATING, payload: false });
    dispatch(redirectToMainRoute());
};

const stopImpersonation = () => async (dispatch: Dispatch) => {
    await dispatch({
        type: STOP_IMPERSONATION,
        payload: authService.impersonate({ disable: true }),
    });
    dispatch(fetchCurrentUser());
    dispatch(fetchCurrentUserProfile());
};

const updateCurrentUserProfile = (profile: Partial<Profile>) => async (dispatch: Dispatch) => {
    await dispatch({
        type: UPDATE_CURRENT_USER_PROFILE,
        payload: authService.updateCurrentUserProfile(profile),
    });
    dispatch(fetchCurrentUser());
    dispatch(fetchCurrentUserProfile());
};

const updateCurrentUserPassword = ({
    oldPassword,
    newPassword,
}: {
    oldPassword: string;
    newPassword: string;
}) => ({
    type: UPDATE_CURRENT_USER_PASSWORD,
    payload: authService.updateCurrentUserPassword({ oldPassword, newPassword }),
});

const resendInvitation = (user: User) => ({
    type: RESEND_INVITATION,
    payload: authService.resendInvitation(user.id),
});

const hideMaintenanceModeMessage = () => ({
    type: HIDE_MAINTENANCE_MODE_MESSAGE,
});

const userUnauthorized = (source: any) => ({ type: USER_UNAUTHORIZED, payload: source });

const toggleDebugMode = () => ({ type: TOGGLE_DEBUG_MODE });

export const actions = {
    logout,
    login,
    impersonate,
    stopImpersonation,
    fetchCurrentUser,
    fetchCurrentUserProfile,
    sendPasswordResetEmail,
    setPassword,
    fetch2FaCode,
    send2FaSmsCode,
    verify2FaCode,
    confirm2FaSetup,
    redirectToMainRoute,
    updateCurrentUserProfile,
    updateCurrentUserPassword,
    resendInvitation,
    saveReturnLocation,
    hideMaintenanceModeMessage,
    userUnauthorized,
    toggleDebugMode,
};

export const selectors = {
    isAuthenticated,
    hasAuthority,
    canImpersonate,
    emailSelector,
    is2FANeeded,
    fetch2FaType,
    seatId,
    isDemoModeEnabled,
    impersonating,
    debugMode: (state: AuthState) => state.debugMode,
    account: (state: RootState) => state.session.account,
};
