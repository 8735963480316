import { capitalize } from 'lodash-es';

export enum Alignment {
    left = 'left',
    right = 'right',
    center = 'center',
    justify = 'justify',
}

export const blockAlignmentButtons = [Alignment.left, Alignment.right, Alignment.center].map(value => ({
    label: capitalize(value),
    value,
}));

export type NodeWithAlignment = {
    alignment: Alignment;
};

export enum Target {
    _top = '_top',
    _self = '_self',
    _blank = '_blank',
}

export const targetOptions = Object.keys(Target).map(value => ({ label: value, value }));

export enum LengthUnit {
    px = 'px',
    rem = 'rem',
    em = 'em',
    '%' = '%',
}

export const lengthOptions = Object.keys(LengthUnit).map(value => ({ label: value, value }));
