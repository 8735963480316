import * as React from 'react';
import classnames from 'classnames';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import Select, { FormatOptionLabelType } from './Select';

type OptionType = { [key: string]: any };

type Props = {
    name: string;
    value: any;
    options: OptionType[];
    onChange?: (value: any) => void;
    onFocus?: () => void;
    onMenuClose?: () => void;
    isMulti?: boolean;
    isLoading?: boolean;
    isClearable?: boolean;
    returnOnlyValues?: boolean;
    getOptionLabel?: (value: OptionType) => any;
    getOptionValue?: (value: OptionType) => any;
    selectStyle?: { [key: string]: any };
    formatOptionLabel?: FormatOptionLabelType;
    isDisabled?: boolean;
    className?: string;
};

const SelectWithAddon = (props: Props) => (
    <InputGroup className={classnames('flex-nowrap', props.className)}>
        <InputGroupAddon addonType="prepend">{props.name}</InputGroupAddon>
        <Select
            value={props.value}
            options={props.options}
            onChange={props.onChange}
            onFocus={props.onFocus}
            onMenuClose={props.onMenuClose}
            isMulti={props.isMulti}
            isLoading={props.isLoading}
            isClearable={props.isClearable}
            closeMenuOnSelect={!props.isMulti}
            getOptionLabel={props.getOptionLabel}
            getOptionValue={props.getOptionValue}
            selectStyle={props.selectStyle}
            returnOnlyValues={props.returnOnlyValues}
            formatOptionLabel={props.formatOptionLabel}
            isDisabled={props.isDisabled}
        />
    </InputGroup>
);

export default SelectWithAddon;
