import { get, isEmpty, isNil, isObject, memoize, curry } from 'lodash-es';
import { isDefined } from 'platform/common/common.type';
import { isPositiveWholeNumber } from './number.util';

export type Validator = (value: string | number) => string | undefined;

export const maxLength = memoize((length: number) => (value: string) =>
    value && value.length > length ? `must be less than ${length + 1} symbols` : undefined
);
export const minLength = memoize((length: number) => (value: string) =>
    value && value.length < length ? `must be ${length} characters or more` : undefined
);
export const maxWordLength = memoize((length: number) => (value: string) =>
    value && value.split(/\s+/).some(word => word.length > length)
        ? `single word must be less than ${length + 1} symbols`
        : undefined
);
const isValueValid = (value: any) => {
    if ((Array.isArray(value) || isObject(value)) && isEmpty(value)) {
        return false;
    }
    return value === 0 || !!value;
};
export const match = (matchName: string, errorText: string) => (value: any, allValues: any) =>
    value !== allValues[matchName] ? errorText : undefined;

export const required = (value: any) => (isValueValid(value) ? undefined : 'required');
export const requiredNumber = (value: any) => (value !== undefined ? undefined : 'required');
export const requiredDatepickerValues = (value: any) =>
    value && value.from && value.to ? undefined : 'required';
export const number = (value: any) => (value && isNaN(Number(value)) ? 'must be a number' : undefined);
export const nonNegativeNumber = (value: any) =>
    value && (isNaN(value) || Number(value) < 0) ? 'must be a positive number or zero' : undefined;
export const positiveNumber = (value: any) =>
    !isNil(value) && value !== '' && (isNaN(value) || Number(value) <= 0)
        ? 'must be a positive number'
        : undefined;

export const positiveWholeNumber = (value: any) =>
    !isNil(value) && !isPositiveWholeNumber(value) ? 'must be a positive whole number' : undefined;

export const nonNegativeWholeNumber = (value: any) =>
    !isNil(value) && !(isPositiveWholeNumber(value) || Number(value) === 0)
        ? 'must be a positive whole number or zero'
        : undefined;

export const text = (value: any) => (value && !/^[a-zA-Z ]*$/g.test(value) ? 'must be a text' : undefined);
export const email = (value: string) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'must be a valid email address'
        : undefined;
export const urlHttps = (value: string) =>
    value && !/^https:\/\/\S+$/i.test(value)
        ? 'must be a valid secure url (should start with https://)'
        : undefined;

export const url = (value: string) =>
    value && !/^(http|https):\/\/\S+$/i.test(value) ? 'must be a valid url' : undefined;

export const range = (min: number, max: number) => (value: any) =>
    isNil(value) || (value >= min && value <= max) ? undefined : `Must be in range [${min}; ${max}]`;

export const secureUrls = (value: string) =>
    value && value.toLowerCase().includes('http://') ? 'URLs must be secure (https)' : undefined;

export const limitFloatTo = memoize(decimalPlaces => (value: number) => {
    const wholePartAndReminder = value.toString().split('.');

    if (wholePartAndReminder[1] && wholePartAndReminder[1].length > decimalPlaces) {
        return `Number must not have more than ${decimalPlaces} decimal places`;
    }
    return undefined;
});

export const lessThanOrEqual = (fieldName: string, errorText: string) => (value: any, allValues: any) =>
    Number(value) <= Number(get(allValues, fieldName)) ? undefined : errorText;

export const onlyOneFieldShouldBeValid = (fieldName: string, errorText: string) => (
    value: any,
    allValues: any
) => {
    const isFirstFieldValid = isValueValid(value);
    const isSecondFieldValid = isValueValid(get(allValues, fieldName));
    return (isFirstFieldValid && isSecondFieldValid) || (!isFirstFieldValid && !isSecondFieldValid)
        ? errorText
        : undefined;
};

export const validator = (validators: Validator | Validator[]) => (value: any): string | undefined => {
    if (Array.isArray(validators)) {
        const errors = validators.filter(v => v(value));
        return errors.length ? errors[0](value) : undefined;
    }

    return validators(value);
};

export const hexColor = (value: any) =>
    value && !/^[0-9a-f]{6}$/i.test(value) ? 'Must be six digit hex color code' : undefined;

const hasStartTag = curry((value: string, tag: string) => value.includes(`<${tag}`));
const hasEndTag = curry((value: string, tag: string) => value.includes(`</${tag}>`));
const hasStartButNoEndTag = curry(
    (value: string, tag: string) => hasStartTag(value, tag) && !hasEndTag(value, tag)
);

const containsOneOfTags = (openTags: string[] = [], closedTags: string[] = []) => (value: string) => {
    if (!value) {
        return undefined;
    }

    const allTags = [...closedTags, ...openTags];
    if (!allTags.map(hasStartTag(value)).some(Boolean)) {
        return `must have ${allTags.map(tag => `<${tag}>`).join(' or ')} tag`;
    }

    const errors = closedTags
        .map(tag => hasStartButNoEndTag(value, tag) && `must have closing </${tag}> tag`)
        .filter(isDefined)
        .join(', ');

    return errors || undefined;
};

export const containsScriptIframeImg = containsOneOfTags(['img'], ['script', 'iframe']);
