import React from 'react';
import { UnreachableCaseError } from 'platform/common/common.type';
import { Editor, Transforms } from 'slate';
import { useSlate } from 'slate-react';
import { UncontrolledTooltip } from 'reactstrap';
import { Alignment } from './common.types';
import { Button, Icon } from './components';

const toggleAlignment = (editor: Editor, alignment: Alignment) => {
    const [, deepestBlockElementPath] = Array.from(
        Editor.levels(editor, { at: editor.selection?.focus, reverse: true })
    ).find(entry => Editor.isBlock(editor, entry[0]))!;

    if (deepestBlockElementPath) {
        Transforms.setNodes(editor, { alignment }, { at: deepestBlockElementPath });
    }
};

export const toBlockAlignmentClass = (alignment?: Alignment): string => {
    switch (alignment) {
        case Alignment.left:
            return 'justify-content-start';
        case Alignment.right:
            return 'justify-content-end';
        case Alignment.center:
            return 'justify-content-center';
        case Alignment.justify:
            return 'justify-content-between';
        case undefined:
            return '';
        default:
            throw new UnreachableCaseError(alignment);
    }
};

export const AlignButton = ({
    alignment,
    children,
    alt,
}: {
    alignment: Alignment;
    alt: string;
    children?: any;
}) => {
    const editor = useSlate();
    const ref = React.useRef<HTMLButtonElement>(null);
    return (
        <Button
            ref={ref}
            active={false}
            onMouseDown={(event: Event) => {
                event.preventDefault();
                toggleAlignment(editor, alignment);
            }}
        >
            <Icon className={`fas fa-align-${alignment}`} />
            {children}
            {ref.current && <UncontrolledTooltip target={ref.current}>{alt}</UncontrolledTooltip>}
        </Button>
    );
};
