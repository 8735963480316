import * as React from 'react';
import ReactSelect, { components as rsComponents } from 'react-select';
import { StylesConfig } from 'react-select/src/styles';
import { SelectComponentsConfig } from 'react-select/src/components';
import { FormatOptionLabelMeta } from 'react-select/src/Select';
import { isNil, isObject } from 'lodash-es';
import { SortableContainer, SortableElement, SortableHandle, SortEndHandler } from 'react-sortable-hoc';
import MenuWithSelectAll from './MenuWithSelectAll';

type OptionType = { [key: string]: any };

export type FormatOptionLabelType = (
    option: OptionType,
    labelMeta: FormatOptionLabelMeta<OptionType>
) => React.ReactNode;

type Props = {
    inputId?: string;
    value: any;
    sortEnd?: SortEndHandler;
    options: OptionType[];
    onChange?: (value: any) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    onMenuClose?: () => void;
    onInputChange?: (newValue: string) => void;
    className?: string;
    isMulti: boolean;
    isLoading: boolean;
    isClearable: boolean;
    isDisabled: boolean;
    isInvalid: boolean;
    isSearchable: boolean;
    closeMenuOnSelect: boolean;
    returnOnlyValues: boolean;
    placeholder?: string;
    getOptionLabel: (value: OptionType) => any;
    getOptionValue: (value: OptionType) => any;
    selectStyle?: { [key: string]: any };
    components?: SelectComponentsConfig<OptionType>;
    formatOptionLabel?: FormatOptionLabelType;
};

const defaultProps = {
    isMulti: false,
    isLoading: false,
    isDisabled: false,
    isInvalid: false,
    isSearchable: true,
    isClearable: true,
    closeMenuOnSelect: true,
    returnOnlyValues: true,
    getOptionLabel: (option: OptionType) => option.label,
    getOptionValue: (option: OptionType) => option.value,
};

export const getSelectStyles = (isInvalid: boolean, selectStyle?: { [key: string]: any }): StylesConfig => ({
    control: (provided, state) => ({
        ...provided,
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        minHeight: '33px',
        boxShadow: state.isFocused ? 'none' : provided.boxShadow,
        borderColor: `${isInvalid ? '#f86c6b' : '#cbd0d9'} !important`,
        minWidth: selectStyle && selectStyle.minWidth ? selectStyle.minWidth : '150px',
    }),
    placeholder: provided => ({
        ...provided,
        padding: '2px',
        color: '#aaa',
    }),
    valueContainer: provided => ({
        ...provided,
        padding: '2px 2px 2px 10px',
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: '6px',
        cursor: 'pointer',
        transform: 'scale(0.8)',
        '&:hover': {
            color: '#f86c6b',
        },
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: '6px',
        cursor: 'pointer',
    }),
    multiValueRemove: provided => ({
        ...provided,
        cursor: 'pointer',
        color: '#394251',
        opacity: 0.6,
    }),
    multiValue: provided => ({
        ...provided,
        backgroundColor: '#ebedf0',
    }),
    menu: provided => ({
        ...provided,
        marginTop: '4px',
        zIndex: 200,
    }),
    option: provided => ({
        ...provided,
        cursor: 'pointer',
    }),
});

const Select = (props: Props) => {
    function isGroupSelect() {
        return Array.isArray(props.options) && props.options.length > 0 && props.options[0].options;
    }

    const value = (() => {
        if (Array.isArray(props.value) && props.value.some(v => !isObject(v))) {
            return props.value
                .map(v => props.options.find(option => props.getOptionValue(option) === v))
                .filter(option => !isNil(option));
        }
        if (!isObject(props.value)) {
            if (isGroupSelect()) {
                return props.options
                    .reduce<OptionType[]>((arr, item) => {
                        arr.push(item.options ? item.options : item);
                        return arr;
                    }, [])
                    .flat()
                    .find((el: OptionType) => el.value === props.value);
            }
            return props.options.find(option => props.value === props.getOptionValue(option));
        }
        return props.value;
    })();

    const SortableMultiValue = SortableElement((p: any) => {
        // this prevents the menu from being opened/closed when the user clicks
        // on a value to begin dragging it. ideally, detecting a click (instead of
        // a drag) would still focus the control and toggle the menu, but that
        // requires some magic with refs that are out of scope for this example
        const onMouseDown = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
        };
        const innerProps = { ...p.innerProps, onMouseDown };
        return <rsComponents.MultiValue {...p} innerProps={innerProps} />;
    });

    const SortableMultiValueLabel = SortableHandle((p: any) => <rsComponents.MultiValueLabel {...p} />);
    const SortableSelect = SortableContainer(ReactSelect);

    return (
        <SortableSelect
            useDragHandle={props.sortEnd !== undefined}
            axis="xy"
            onSortEnd={props.sortEnd}
            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            helperClass="sortable-select-helper"
            value={value === undefined ? null : value}
            className={props.className}
            styles={getSelectStyles(props.isInvalid, props.selectStyle)}
            options={props.options}
            onChange={valueOptions => {
                if (!props.onChange) return;

                const values = (() => {
                    if (props.isMulti && !valueOptions) return [];
                    if (!props.returnOnlyValues) return valueOptions;
                    if (Array.isArray(valueOptions)) return valueOptions.map(props.getOptionValue);
                    // we need to preserve this null for compatibility with redux-form
                    if (valueOptions === null) return null;
                    return props.getOptionValue(valueOptions || {});
                })();

                props.onChange(values);
            }}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onMenuClose={props.onMenuClose}
            isMulti={props.isMulti}
            getOptionLabel={props.getOptionLabel}
            getOptionValue={props.getOptionValue}
            closeMenuOnSelect={props.closeMenuOnSelect}
            isLoading={props.isLoading}
            isClearable={props.isClearable}
            isDisabled={props.isDisabled}
            isSearchable={props.isSearchable}
            inputId={props.inputId}
            components={{
                ...(props.isMulti && { Menu: MenuWithSelectAll }),
                ...props.components,
                MultiValue: SortableMultiValue,
                MultiValueLabel: SortableMultiValueLabel,
            }}
            onInputChange={props.onInputChange}
            placeholder={props.placeholder}
            isOptionDisabled={option => option.disabled}
            formatOptionLabel={props.formatOptionLabel}
        />
    );
};

Select.defaultProps = defaultProps;

export default Select;
